import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { Router } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { Auth0HttpInterceptor } from './shared/helpers/auth0.http.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { CacheInterceptor } from './shared/helpers/cache.interceptor';

import { environment } from '../environments/environment';
import { ScreenService } from './shared/services/screen.service';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { MedigiFooterModule } from './layouts/footer/medigi/footer.component';
import { HeaderModule } from './layouts/header/header.component';
import { SideNavOuterToolbarModule } from './layouts/side-nav-outer-toolbar/side-nav-outer-toolbar.component';
import { NavModule } from './layouts/nav/medigi-nav/nav.component';
import { DatePipe } from '@angular/common';
import { SwitchFacilitiesModule } from './shared/components/switch-facilities/switch-facilities.component';
import { DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import * as Sentry from '@sentry/angular-ivy';
import { MedshortsNavModule } from './layouts/nav/medshorts-nav/nav.component';
import { OutsourcingNavModule } from './layouts/nav/outsourcing-nav/nav.component';
import { MedshortsFooterModule } from './layouts/footer/medshorts/footer.component';
import { OutsourcingFooterModule } from './layouts/footer/outsourcing/footer.component';
import { JwtModule } from './pages/jwt/jwt.module';
import { ApiCacheInterceptor } from './shared/helpers/api.cache.interceptor';
import { DirectModule } from './shared/components/direct/direct.module';
import { ApiDupeInterceptor } from './shared/helpers/api.dupe.interceptor';

if (environment.sentry.dsn) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.env_name,
    autoSessionTracking: true,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [environment.api_url_v2],
        networkRequestHeaders: ['SourceDomain']
      }),
    ],
  

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,

    MedigiFooterModule,
    MedshortsFooterModule,
    OutsourcingFooterModule,
    JwtModule,
    HeaderModule,
    NavModule,
    MedshortsNavModule,
    OutsourcingNavModule,
    SideNavOuterToolbarModule,
    SwitchFacilitiesModule,
    DirectModule,
    DxScrollViewModule,
    DxPopupModule
  ],
  providers: [
    ScreenService,
    GoogleAnalyticsService,

    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Auth0HttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiDupeInterceptor, multi: true },
    
    DatePipe,

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },

    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
