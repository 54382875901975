import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { DxButtonModule, DxCheckBoxModule, DxFileUploaderModule, DxFormModule, DxPopupModule } from 'devextreme-angular';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { PasswordUpdateModule } from 'src/app/shared/components/password-update/password-update.component';
import { PersonalInfoModule, UserProfile } from 'src/app/shared/components/personal-info/personal-info.component';
import { SubscribeEmailsModule } from 'src/app/shared/components/subscribe-emails/subscribe-emails.component';
import { UserPictureModule } from 'src/app/shared/components/user-picture/user-picture.component';
import { NewPassword } from '../../models/user';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user/user.service';
import { UserPicturePreviewModule } from '../user-picture-preview/user-picture-preview.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  loading: boolean;
  isBuyer: boolean;
  form: any;
  profile: any;
  isPasswordPopupOpen: boolean = false;

  new_password: NewPassword = { password: '', match_password: '' };
  profile_data: UserProfile = { id: null, phone: '', email: '' };
  hydraEnabled = environment.hydraEnabled;

  infoButtonOptions: any = {
    text: 'Update Info',
    type: 'success',
    useSubmitBehavior: true
  };

  passwordButtonOptions: any = {
    text: 'Update Password',
    type: 'success',
    useSubmitBehavior: true
  };


  constructor(private notificationService: NotificationService,
    private userService: UserService,
    private auth0: Auth0Service) { }

  ngOnInit() {
    this.loading = true;
    this.getMe();
  }

  getMe() {
    this.userService.getMe().subscribe(res => {
      this.profile = res;
      this.profile_data.id = this.profile.data.id;
      this.profile_data.phone = this.profile.data.phone;
      this.profile_data.email = this.profile.data.email;
      this.isBuyer = this.profile.data.is_buyer;
      this.loading = false;
    });
  }

  onProfileSubmit(e) {
    const data = {
      id: this.profile.data.id,
      phone: this.profile_data.phone.replace(/\(*\)*\-* */gi, ''),
      email: this.profile_data.email
    };
    this.updateUser(data);
    e.preventDefault();
  }

  updateUser(data) {
    this.userService.updateMe(data).subscribe(
      () => this.notificationService.showNotify('Profile information was updated', 'success'),
      error => this.notificationService.showNotify(error, 'error')
    );
  }

  onPasswordSubmit(e) {
    this.userService.updatePassword(this.new_password).subscribe(
      () => {
        this.loading = false;
        this.notificationService.showNotify('Password was updated, please log in.', 'success');
        this.auth0.logout();
      },
      error => {
        this.notificationService.showNotify(error, 'error');
        this.loading = false
      }
    );
    e.preventDefault();
  }

  passwordComparison = () => {
    return this.form.instance.option('formData').password;
  }

  openUpdatePasswordPopup() {
    this.isPasswordPopupOpen = true;
  }

  closingPasswordPopup() {
    this.isPasswordPopupOpen = false;
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxPopupModule,
    UserPicturePreviewModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxFormModule,
    DxCheckBoxModule,
    PasswordUpdateModule,
    PersonalInfoModule,
    SubscribeEmailsModule,
    UserPictureModule
  ],

  declarations: [UserProfileComponent],
  exports: [UserProfileComponent]
})
export class UserProfileModule { }

