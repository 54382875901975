import {Shipping} from './shipping';
import {Company} from './profile';
import {Facility} from './facility';
import { Card } from './card';

export class Cart {

  constructor() {
    this.items = [];
    this.shipping_options = [];
  }
  status_messages: {};
  items: any[];
  sub_total: number;
  shipping_total: number;
  savings_total: number;
  transaction_fee_total: number;
  total: number;
  shipping_options: Shipping[];
  selected_shipping: Shipping;
  notifications: any[];
  errors: any[];
  warnings: any[];
  order_type: string;
  paying_by_card: boolean;
}

export class PaymentTerms {

  constructor() {
    this.creditLimit = 0.0;
  }

  terms: string;
  balance: number;
  creditLimit: number;
  remaining: number;
}

export class PaymentOption {

  constructor() {
    this.cards = [];
    this.type = '';
    this.intent_id = '';
  }

  customer_id: string;
  company: Company;
  facility: Facility;
  cards: Card[];
  amount_to_charge: number;
  client_token:number;
  client_secret: string;
  user_full_name: string;
  intent_id: string;
  public_key: string;
  type: string;
}

export class CardForm {
  constructor(fullName, address1, city, zip) {
    this.fullName = fullName;
    this.address1 = address1;
    this.city = city;
    this.zip = zip;
  }
  fullName: string;
  address1: string;
  city: string;
  zip: number;
}

export class ShipForm {
  constructor(po = '', address_note = '', note = '') {
    this.po = po;
    this.note = note;
    this.address_note = address_note;
  }
  po: string;
  note: string;
  address_note: string;
}


