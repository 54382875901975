import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NdcService } from 'src/app/shared/services/listing/ndc.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RfpService } from 'src/app/shared/services/order/rfp.service';
import { confirm } from 'devextreme/ui/dialog';
import { CompanyService } from 'src/app/shared/services/user/company.service';
import { ProposalService } from 'src/app/shared/services/order/proposal.service';
import { DatePipe } from '@angular/common';
import { ExclusionService } from 'src/app/shared/services/order/exclusion.service';
import { PricingService } from 'src/app/shared/services/pricing.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SellerDataService } from 'src/app/shared/services/listing/sellerdata.service';

@Component({
  selector: 'app-rfp-form',
  templateUrl: './rfp-form.component.html'
})

export class RfpFormComponent implements OnInit {
  @Input() rfpId: any;
  @Input() rfp: any;
  @Input() companyId: any;
  @Input() isBuyer: any;
  @Input() isSeller: any;
  @Input() ndc: any;
  @Input() ndcItem: any;
  @Input() catalogType: any = 'medigi';
  @Input() resubmit: any = false;

  @Output() output = new EventEmitter<boolean>();
  @Output() onNdcSet = new EventEmitter<any>();
  @Output() onRfpCancel = new EventEmitter<any>();
  @Output() onContentChange = new EventEmitter<any>();

  RFP_DAYS = 9;
  description: string;

  datePipe: DatePipe = new DatePipe('en-US');
  minDate = new Date(Date.now() + (1000 * 60 * 60 * 24 * (this.RFP_DAYS+1)));
  today = Date.now();
  startDate = new Date(Date.now() + (1000 * 60 * 60 * 24 * (this.RFP_DAYS+1)));
  quantity: any;
  duration: any = 3;
  period: any = 'monthly';
  price: any;
  shippingPrice: any;
  defaultShippingPrice: any;

  listing: any;
  ndcs: any;
  sellerNdcs: any;
  selectedSellerNdc: any;
  selectedSellerListing: any;
  companyIds: number[];

  showNdcTable = false;
  showSubmitTo = false;

  searchDisabled = false;

  

  suppliers: any[];
  suppliersGcn: any[];
  loadingSuppliersGcn = true;
  selectedCompanyExclusions = [];
  suppliersSelectedCount = 0;
  suppliersSelected: any[];

  validationErrorList = [];

  paramsStats: any;

  exclusion: any;

  sugestionsList: any[] = [];
  minSearchLength = 5;
  amu: any;
  isAcknowledged = false;
  shippingAcknowledged = false;
  shippingPriceUpdated = false;
  showAcknowledgePopup = false;
  showSpinner = false;
  ndcIsSelected = false;
  facilityName: string;
  declineRfpVisible = false;
  declineReason: string;

  proposals: any[] = [];
  acceptedProposal: any;
  totalPacks: number;

  currentUser: any;

  constructor(private ndcService: NdcService,
              public rfpService: RfpService,
              private proposalService: ProposalService,
              private companyService: CompanyService,
              private exclusionService: ExclusionService,
              private pricingService: PricingService,
              private userService: UserService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.getMe();
    this.minDate = this.rfp?.start_date || new Date(Date.now() + (1000 * 60 * 60 * 24 * (this.RFP_DAYS+1)));
    this.startDate = this.rfp?.start_date || new Date(Date.now() + (1000 * 60 * 60 * 24 * (this.RFP_DAYS+1)));
    if (this.rfp) {
      this.period = this.rfp.period;
      this.ndc = this.rfp.ndc;
      this.catalogType = this.rfp.catalog_type;

      if (this.isSeller) {
        this.resubmit = this.rfp.parent_id > 0;
      }

      if (this.resubmit && this.isBuyer) {
        this.ndc = this.rfp.accepted_proposal.ndc;
        this.quantity = this.rfp.accepted_proposal.quantity;
        this.period = this.rfp.accepted_proposal.period;
        this.startDate = this.rfp.accepted_proposal.renewal_date;
      }

      //this.getProposals(this.rfp.id);
    }
    if (this.ndc) {
      this.searchItem();
    }
  }

  showAcknowledge() {
    this.showAcknowledgePopup = true;
  }

  getMe() {
    this.userService.getMe().subscribe(
      result => {
        this.currentUser = result.data;
        this.facilityName = this.facilityName || result.data?.primary_facility?.name;
      }
    );
  }

  ndcBasedSearch(e) {
    if (this.ndcIsSelected) { return; }
    if (this.ndc?.length) { this.description=null; }
    this.updateSugestedItems(e);
  }

  descriptionBasedSearch(e) {
    if (this.ndcIsSelected) { return; }
    if (this.description?.length) { this.ndc=null; }
    this.updateSugestedItems(e);
  }

  updateSugestedItems(e) {
    const search = this.setSearchParams();
    if (this.ndc?.length >= this.minSearchLength 
      || this.description?.length >= this.minSearchLength) {
        this.getSugestionsList(search, null, e);
    }
  }

  setSearchParams(search = {}) {
    if (this.ndc) {
      this.ndc = this.ndc.replaceAll('-', '');
      search['ndc'] = this.ndc;
      return search;
    }
    if (this.description) {
      search['desc'] = this.description;
      return search;
    }
  }

  getSugestionsList(search: {}, search_type, e: any) {
    if (search_type) {search['search_type'] = search_type;}
    this.ndcService.get(search).subscribe(
      result => this.sugestionsList = result.data,
      error => this.notificationService.showNotify(error, 'error')
    );
  }

  clearSearch() {
    this.setDefaultValues();
  }

  ndcSelected(fdb) {
    this.ndcIsSelected = true;
    this.ndc = fdb.ndc;
    this.description = fdb.ln60;
  }

  checkNdcAndSearch() {
    this.ndc = this.ndc.replaceAll('-','');
    this.searchItem();
  }

  getNdcExclusions() {
    this.exclusionService.getNdcExclusions(this.ndc).subscribe(
      () => this.searchItem(),
      error => this.notificationService.showNotify(error, 'error')
    );
  }

  setDefaultValues() {
    this.searchDisabled = false;
    this.quantity = null;
    this.duration = 3;
    this.listing = null;
    this.startDate = new Date(Date.now() + (1000 * 60 * 60 * 24 * (this.RFP_DAYS+1)));
    this.ndc = null;
    this.description = null;
    this.ndcs = null;
    this.suppliersGcn = null;
    this.validationErrorList = [];
    this.sugestionsList = [];
    this.ndcIsSelected = true;
    this.onNdcSet.emit(null);
  }

  getSellers() {
    const companyIds = this.companyIds.join(',');
    this.companyService.getCompanies({type: 'Supplier', medigi_agreed: 1, company_ids: companyIds}).subscribe(
      result => this.onGetCompaniesSuccess(result),
      () => this.notificationService.showNotify('Failed to get list of suppliers', 'error')
    ).add(() => this.loadingSuppliersGcn = false);
  }

  private onGetCompaniesSuccess(result: any) {
    this.suppliers = result.data.filter(x => x.enable_rfp === 1);
    this.suppliersGcn = this.suppliers.filter(x => this.companyIds.includes(x.id));
    this.suppliers.forEach(s => { s['included'] = true; });
    this.suppliersSelected = JSON.parse(JSON.stringify(this.suppliersGcn));
    this.suppliersSelectedCount = this.suppliersGcn.length;
    this.onContentChange.emit();
    this.showSubmitTo = !this.rfp?.id;
  }

  getRequest() {
    this.proposalService.getProposals(this.rfpId).subscribe(
      result => this.rfp = result.data,
      () => this.notificationService.showNotify('Failed to get item data', 'error')
    );
  }

  getAmuByGcn() {
    if (this.isBuyer && !this.resubmit) {
      this.pricingService.getAmuByGcn(this.listing.gcn_seqno, this.listing.pack_size).subscribe(
        result => this.onGetAmuSuccess(result),
        () => this.amu = {}
      );
    }
  }

  private onGetAmuSuccess(result: any) {
    this.amu = result.data;
  }

  searchItem(search_type=null) {
    if (!this.ndc && !this.description) {
      this.notificationService.showNotify('Please provide NDC or Description for search', 'error');
      return;
    }
    let params = this.ndc ? {ndc: this.ndc} : {desc: this.description};
    if (search_type) {params['search_type'] = search_type;}
    this.getNdcs(params);
  }


  getNdcs(params) {
    this.ndcService.get(params).subscribe(
      result => this.setNdcs(result.data),
      error => this.notificationService.showNotify(error, 'error')
    );
  }

  private setNdcs(ndcs: any) {
    if (ndcs.length) {
      this.ndcs = ndcs;
      this.setListing(ndcs[0]);
    } else {
      this.listing = null;
      this.ndcs = null;
      this.notificationService.showNotify('Faild to find item by using this NDC', 'error');
    }
  }

  showNdcGrid() {
    this.showNdcTable = true;
  }

  showShowSubmitTo() {
    this.showSubmitTo = true;
  }

  setNdc(data) {
    this.setRfpRelatedValues(data);
    this.getAmuByGcn();
    this.checkNdcAndSearch();
    this.searchDisabled = true;
  }

  setRfpRelatedValues(data: any) {
    this.ndcIsSelected = true;
    this.listing = data;
    this.ndc = data.ndc;
    this.description = null;
    this.showNdcTable = false;
    this.showSubmitTo = false;
  }

  setListing(data) {
    this.setRfpRelatedValues(data);
    this.getAmuByGcn();
    this.onNdcSet.emit(data);
    let params = {gcn: this.listing.gcn_seqno, pack_size: this.listing.pack_size, search_type: 'rfp'};
    if (this.catalogType === '503b' || this.resubmit) {
      params['ndc'] = this.ndc;
    }
    if(this.isBuyer) {
      this.getGcns(params);
      this.paramsStats = { ndc: this.ndc, type: 'gpi', annual: true };
    }
    if (this.isSeller) {
      params['company_id'] = this.companyId;
      this.getSellerNdcs(params)
    }
  }

  getGcns(data) {
    this.loadingSuppliersGcn = true;
    this.ndcService.get(data).subscribe(
      result => {
        if (result.data.length) {
          this.listing = result.data.find(x => x.ndc === this.ndc) || result.data[0];
          this.ndc = this.listing.ndc;
          this.onGcnsResultSuccess(result);
        }
      }, 
      error => {
        this.notificationService.showNotify(error, 'error');
        this.clearSearch();
      }
    ).add(() => this.loadingSuppliersGcn = false);
  }

  onGcnsResultSuccess(result: any) {
    let alternatives = this.catalogType === '503b'  || this.resubmit ? result.data.filter(x => x.ndc === this.ndc) : result.data;
    this.getCompanyIdsAndSellers(alternatives);
  }

  private getCompanyIdsAndSellers(alternatives: any) {
    this.companyIds = alternatives.map(x => { return x.company_id; }).filter(x => x > 0).filter((v, i, a) => a.indexOf(v) === i);
    this.getSellers();
  }

  getSellerNdcs(params) {
    this.ndcService.get(params).subscribe(
      result => {
        this.sellerNdcs = result.data;
        if (this.sellerNdcs.length === 1) {
          this.selectedSellerNdc = this.sellerNdcs[0].ndc;
        }
      },
      error => this.notificationService.showNotify(error, 'error')
    );
  }

  calcSelectedSuppliers() {
    this.suppliersSelected = this.suppliersGcn.filter(x => x.included);
    this.suppliersSelectedCount = this.suppliersSelected.length;
  }

  askPlaceRequest() {
    const valid = this.isRecordValid();
    if (valid) {
      let result = confirm(`Submit your request to ${this.suppliersSelectedCount} supplier(s) for ${this.listing.ln60}?`, 'Confirm Your Request');
      result.then((dialogResult) => { if (dialogResult) { this.placeRequest(); } });
    }
  }

  placeRequest() {
    this.showSpinner = true;
    const { exclusions, inclusions } = this.suppliersGcnFilters();
    const data = this.getRequestData(exclusions, inclusions);
    this.rfpService.placeRequest(data).subscribe(
      () => this.onPlaceRequestSuccess(),
      error => this.notificationService.showNotify(error, 'error')
    ).add(() => this.showSpinner = false);
  }

  suppliersGcnFilters() {
    const exclusions = this.suppliersGcn.filter(x => x.included == false).map(x => { return x.id; });
    const inclusions = this.suppliersGcn.filter(x => x.included == true).map(x => { return x.id; });
    return { exclusions, inclusions };
  }

  private getRequestData(exclusions: any[], inclusions: any[]) {
    return {
      ndc: this.listing.ndc,
      csp: this.listing.csp,
      gcn: this.listing.gcn_seqno,
      description: this.listing.description,
      catalog_type: this.catalogType,
      quantity: this.quantity,
      duration: this.duration,
      period: this.period,
      start_date: this.datePipe.transform(this.startDate, 'MM/dd/yyyy'),
      exclusions: exclusions,
      inclusions: inclusions,
      resubmit: this.resubmit,
      request_id: this.rfp?.id
    };
  }

  onPlaceRequestSuccess() {
    this.notificationService.showNotify('Request was placed', 'success');
    this.output.emit(true);
  }

  askPlaceProposal() {
    const valid = this.isRecordValid();
    if (valid) {
      let result = confirm('Do you want to confirm your Proposal?', 'Confirm Your Proposal');
      result.then((dialogResult) => { if (dialogResult) { this.placeProposal(); } });
    }
  }

  placeProposal() {
    this.showSpinner = true;
    const data = {
      price: this.price,
      shipping_price: this.shippingPrice,
      period: this.period,
      ndc: this.selectedSellerListing.ndc,
      csp: this.selectedSellerListing.csp,
      description: this.selectedSellerListing.description,
      quantity: this.quantity,
      request_id: this.rfp.id,
      start_date: this.datePipe.transform(this.startDate, 'MM/dd/yyyy'),
      catalog_type: this.catalogType
    };

    this.proposalService.placeProposal(data).subscribe(
      () => {
        this.notificationService.showNotify('Proposal was placed', 'success');
        this.output.emit(true);
      },
      error => this.notificationService.showNotify(error, 'error')
    ).add(() => this.showSpinner = false);
  }

  cancelRfp() {
    let result = confirm('This will clear your changes. Are you sure?', 'Confirm RFP cancellation.');

    result.then((dialogResult) => {
      if (dialogResult) {
        this.onRfpCancel.emit(true);
      }
    });
  }

  closeRfp() {
    this.onRfpCancel.emit(true);
  }

  changeRequestStatus(id, status) {
    let result = null;

    if (status === 'cancelled') {
      result = confirm('This will change you request status to "Cancelled". Are you sure?', 'Confirm Status Update');
    }

    result.then((dialogResult) => {
      if (dialogResult) {
        this.changeStatus(id, status)
      }
    });
  }

  changeStatus(id, status) {
    this.rfpService.updateRfp(id, {id, status}).subscribe(
      () => this.onChangeStatusSuccess(),
      error => this.notificationService.showNotify(error, 'error'),
    )
  }

  private onChangeStatusSuccess() {
    this.notificationService.showNotify('Status was updated', 'success');
    this.output.emit(true);
  }

  declineRequest() {
    this.showSpinner = true;
    const data = {id: this.rfp.id, decline_reason: this.declineReason};
    this.rfpService.declineRequestSeler(this.rfp.id, data).subscribe(
      () => {
        this.notificationService.showNotify('Request was decliend', 'success');
        this.output.emit(true);
      },
      error => this.notificationService.showNotify(error, 'error')
    ).add(() => this.showSpinner = false);
  }

  isRecordValid() {
    this.validationErrorList = [];

    if (!this.ndc) {
      this.validationErrorList.push('NDC is required');
    }
    if (!this.startDate) {
      this.validationErrorList.push('Start date is required');
    }
    if (!this.quantity) {
      this.validationErrorList.push('Quantity is required');
    }

    if (this.isBuyer) {
      if (!this.duration) {
        this.validationErrorList.push('Contract duration is required');
      }
      if (!this.period) {
        this.validationErrorList.push('Purchase frequency is required');
      }
      if (!this.suppliersGcn.filter(x => x.included).length) {
        this.validationErrorList.push('Sellers selection required');
      }
    }

    if (this.isSeller) {
      if (!this.price) {
        this.validationErrorList.push('Price is required');
      }
      if (this.shippingPrice === null) {
        this.validationErrorList.push('Shipping price is required');
      }
    }

    return this.validationErrorList.length === 0;
  }

  onSellerNdcChanged() {
    this.selectedSellerListing = this.sellerNdcs.find(x => x.ndc === this.selectedSellerNdc);

    const unitReqQty = this.rfp.quantity*this.rfp.csp;
    const ceilQty = Math.ceil(unitReqQty/this.selectedSellerListing.csp); 
    const floorQty = Math.floor(unitReqQty/this.selectedSellerListing.csp);
    const unitCeilQty = this.selectedSellerListing?.csp * ceilQty;
    const unitFloorQty = this.selectedSellerListing?.csp * floorQty;
    
    if (Math.abs(unitCeilQty - unitReqQty) < Math.abs(unitReqQty - unitFloorQty)) {
      this.quantity = ceilQty;
    } else {
      this.quantity = floorQty;
    }
    this.totalPacks = this.rfp.duration * this.quantity * this.rfpService.PERIOD_DURATION_MAP[this.period];
  }

  showDeclineRfpVisibleView() {
    this.declineRfpVisible = true;
  }

  hideSubmitTo() {
    this.showSubmitTo = false;
  }


  getProposals(rfpId) {
    this.proposalService.getProposals(rfpId).subscribe(
      result => {
        this.proposals = result.data;
        this.acceptedProposal = this.proposals.find(x => x.status === 'accepted');
        if (this.resubmit && this.acceptedProposal) {
          this.duration = this.rfp.duration;
          this.quantity = this.acceptedProposal.quantity;
          this.period = this.acceptedProposal.period;
          this.startDate = this.acceptedProposal.resubmit_schedule_date;
        }
      }
    )
  }

  onQtyChanged() {
    this.totalPacks = this.rfp.duration * this.quantity * this.rfpService.PERIOD_DURATION_MAP[this.period];
    this.getShippingPrice();
  }
 
  onPeriodChanged() {
    this.quantity = this.totalPacks/this.rfp.duration/this.rfpService.PERIOD_DURATION_MAP[this.period];
  }

  getShippingPrice() {
    const data = {
      id: this.rfp.id,
      rfp_id: this.rfp.id,
      ndc: this.selectedSellerNdc,
      item_request: {
        catalog_type: this.catalogType
      },
      price: this.price,
      quantity: this.quantity,
      buyer_user_id: this.rfp.buyer_user_id,
      buyer_facility_id: this.rfp.buyer_facility_id,
      seller_company_id: this.currentUser.company_id,
      csp: this.selectedSellerListing.csp,
      rfp_total_packs: this.totalPacks,
    }
    this.proposalService.calc(data).subscribe(
      result => {
        this.defaultShippingPrice = result.data.price_meta.shipping_price;
        this.shippingPrice = this.shippingPriceUpdated ? this.shippingPrice : this.defaultShippingPrice;
      }
    );
  }

  shippingPriceUpdate() {
    this.shippingPriceUpdated = this.shippingPrice && this.defaultShippingPrice !== this.shippingPrice;
    this.shippingAcknowledged = false;
  }

}