<div *ngIf="mode=='sidebar'" class="pt-1 pr-1 mb-1">
  <div class="row">
    <div *ngIf="showC2Switch" class="col text-right">CII Cart</div>
    <div *ngIf="showC2Switch" class="col text-left">
      <dx-switch [value]="cartModeC2" (onValueChanged)="switchCartModeC2($event)"></dx-switch>
    </div>
    <div class="col d-flex flex-row align-items-center justify-content-between">
      <p class="mb-0 pl-3 pt-3"><span class="cart-title">Your Cart</span><span class="sub-title ml-2">    {{itemsInCart}} {{itemsInCart > 1 ? 'items' : 'item'}}</span></p>
      <i class="dx-icon-close mr-3" style="cursor: pointer;" (click)="closeCart()"></i>
    </div>
  </div>
</div>

<div *ngIf="mode!=='sidebar' && showC2Switch" class="pr-1 mb-2">
  <div class="form">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label text-right" style="width: 90%!important;">CII Cart</div>
        <div class="dx-field-value" style="width: 10%!important;">
          <dx-switch [value]="cartModeC2" (onValueChanged)="switchCartModeC2($event)"></dx-switch>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="cartModeC2" class="text-info text-right">* Items are CII</div>
</div>

<div *ngIf="!cartData?.items?.length">
  <span class="dx-font-m ml-5 mb-5">Your cart is empty</span>
</div>

<div *ngIf="mode==='sidebar'">
  <div *ngIf="cartData?.errors?.length">
    <div *ngFor="let errors of cartData.errors" class="alert alert-danger fade show" role="alert">
      {{errors}}
    </div>
  </div>
  <div *ngIf="cartData?.warnings?.length">
    <div *ngFor="let warning of cartData.warnings" class="alert alert-warning fade show" role="alert">
      {{warning}}
    </div>
  </div>
  <div *ngIf="cartData?.notifications?.length">
    <div *ngFor="let notification of cartData.notifications" class="alert alert-warning fade show" role="alert">
      {{notification}}
    </div>
  </div>

  <div *ngIf="cartData?.items?.length">
    <div style="height: calc(100vh - 380px);">
      <dx-scroll-view>
        <div *ngFor="let listing of cartData.items index as li" class="mb-2" style="width: 450px !important; position: relative;">
          <div class="row px-3 mb-1 dx-font-m">
            <div class="col-9 mb-2 listing-name"><b>{{listing.name}}</b>
              <!-- <app-listing-wishlist-toggle class="ml-1" [listing]="listing" [allowToggling]="true"></app-listing-wishlist-toggle> -->
            </div>
            <div class="col-3 pl-0 pr-3 d-flex flex-row justify-content-end">
              <dx-button class="mr-1 removeFromCartBtn" type="danger" icon="trash" 
              (onClick)="removeItem(listing.id, listing.is_c2, listing.name)" stylingMode="text" [disabled]="loading">
              </dx-button>
              <dx-button id="edit_{{listing.id}}" type="default" icon="edit" 
              (onClick)="editItem(listing)" stylingMode="text" [disabled]="loading">
              </dx-button>
            </div>
            <span *ngIf="!hydraEnabled" class="ml-3 dx-font-s company-name">{{listing.company_name}} | </span>
            <img *ngIf="listing.catalog_type==='medigi' && !hydraEnabled" class="company-logo-side ml-1" src="/assets/img/logos/medigi-logo.png" alt="medigi logo">
            <img *ngIf="listing.catalog_type==='503b' && !hydraEnabled" class="company-logo-side ml-1" src="/assets/img/logos/503B_Color.svg" alt="503B logo">
            <img *ngIf="listing.catalog_type==='medshorts' && !hydraEnabled" class="company-logo-side ml-1" src="/assets/img/logos/ms_logo_teal.png" alt="medshorts logo">
            <img *ngIf="hydraEnabled" class="company-logo-side ml-3" [src]="listing.company_logo" alt="{{listing.company_name}} logo">
            <app-ms-tooltip *ngIf="listing.is_c2" badgeText="CII" id="listing_c2_{{listing.id}}"
                                tooltipText="This item is CII medication" extraClass="badge badge-warning">
            </app-ms-tooltip>
          </div>
          <div class="px-3 mb-2 dx-font-s">
            <div *ngFor="let line of listing.pricing.prices">
              <div *ngIf="line.quantity">
                <div class="d-flex flex-row justify-content-between align-items-center mr-3">
                  <div>
                    {{line.quantity}} Packs ({{line.quantity * listing.eaches_per_pack}} Eaches) at {{line.price | usdCurrency}}
                  </div> 
                  <div>
                    <span class="price-text text-right">{{line.price * line.quantity | usdCurrency}}</span>
                  </div>
                </div>
                <div class="mt-2"><span class="feild-title">Account:</span> {{upperCasePriceType(line.price_type)}}</div>
                <div *ngIf="!hydraEnabled" class="my-2"><span class="feild-title">Expires:</span> {{line.expiry_date}}</div>
                <div *ngIf="listing.pricing.amu?.savings_per_pack > 0">
                  <div *ngIf="listing.catalog_type==='medigi'" class="dx-font-xs mb-1">
                    <div *ngIf="!hydraEnabled" class="d-flex flex-row align-items-center">
                      <div>
                        <p class="feild-title mr-1 mb-0">Savings: </p>
                      </div>
                      <div>
                        <span class="mr-1">Line <span class="text-success mr-1"> {{line.quantity * listing.pricing.amu?.savings_per_pack | usdCurrency}} ({{listing.pricing.amu?.savings_percent}}%)</span>|</span>
                      </div>
                      <div>
                        <span class="mr-1">Per Pack <span class="text-success mr-1"> {{listing.pricing.amu?.savings_per_pack | usdCurrency}}</span>|</span>
                      </div>
                      <div>
                        <span class="mr-1">Per Unit <span class="text-success mr-1"> {{listing.pricing.amu?.savings_per_pack / listing.eaches_per_pack | usdCurrency}}</span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-3 mb-2">
            <div *ngIf="!hydraEnabled" class="dx-font-s mb-2 feild-title">{{listing.pricing.price_meta?.shipping_layer?.description}}</div>
            <span *ngIf="hydraEnabled && listing.pricing.price_meta?.shipping_layer?.name === 'free_shipping'" class="feild-title mb-1">Shipping is Free.</span>
            <span *ngIf="hydraEnabled && listing.pricing.price_meta?.shipping_layer?.name !== 'free_shipping'" class="feild-title mb-1">{{listing.pricing.price_meta?.shipping_layer?.description}}</span>
            <div *ngIf="listing.pricing.price_meta?.buyer_transaction_fee !== 0.00" class="mt-2">
                <span class="feild-title">Transaction Fee:</span>
                {{listing.pricing.price_meta?.buyer_transaction_fee | usdCurrency}}
            </div>
            <span class="info-503b mt-2 dx-font-xxs" *ngIf="listing.catalog_type==='503b'">
              <p>* This is a compounded product from an FDA registered 503B Outsourcing Facility </p>
            </span>
            <hr *ngIf="li<cartData.items.length-1">
          </div>
        </div>
      </dx-scroll-view>
    </div>
    <div class="row">
        <div class="col">
            <p class="pl-3 pt-3"><i>{{deliveryComment}}</i></p>
        </div>
    </div>
    <hr style="margin-top: 10px; margin-bottom: 5px;">
    <div *ngIf="cartData">
      <div class="mr-4 mt-2 mb-3" style="position: relative; bottom: 0;">
        <div class="d-flex flex-column align-items-end">
          <div *ngIf="cartData?.sub_total" class="sub-total-title mb-2">Sub-Total: <span class="sub-total-text">{{cartData.sub_total | usdCurrency}}</span></div>
          <div *ngIf="cartData.shipping_total >= 0" class="sub-total-title mb-2">Total Shipping: <span class="sub-total-text">{{cartData.shipping_total | usdCurrency}}</span></div>
          <div *ngIf="paymentOption === 'credit_card' && cartData.transaction_fee_total > 0" class="sub-total-title mb-2">Total Transaction Fee: <span class="sub-total-text">{{cartData.transaction_fee_total | usdCurrency}}</span></div>
          <div *ngIf="!hydraEnabled" class="sub-total-title">Savings: <span class="sub-total-text text-success">{{cartData.savings_total | usdCurrency}}</span></div>
          <hr style="width: 225px;">
        </div>
        <div *ngIf="cartData.total" class="total-title mb-2">Total: <span class="total-text">{{cartData.total | usdCurrency}}</span></div>
        <div class="d-flex flex-row justify-content-end">
          <dx-button type="success" (onClick)="checkout()" stylingMode="contained" text="PROCEED TO CHECKOUT"></dx-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mode!=='sidebar'" class="p-2">
  <div *ngIf="cartData?.errors?.length">
    <div *ngFor="let errors of cartData.errors" class="alert alert-danger fade show" role="alert">
      {{errors}}
    </div>
  </div>
  <div *ngIf="cartData?.warnings?.length">
    <div *ngFor="let warnings of cartData.warnings" class="alert alert-warning fade show" role="alert">
      {{warnings}}
    </div>
  </div>
  <div *ngIf="cartData?.notifications?.length">
    <div *ngFor="let notification of cartData.notifications" class="alert alert-warning fade show" role="alert">
      {{notification}}
    </div>
  </div>
  <div *ngIf="cartData?.items?.length"> 
      <div *ngFor="let listing of cartData.items" class="pl-3 mb-3" style="width: 100%;">
        <div class="mt-1 d-flex flex-row justify-content-between align-items-center">
          <div>
            <b><a class="listing-name mb-1 dx-font-m" style="cursor: pointer" [routerLink]="[]" (click)="editItem(listing)">{{listing.name}}</a></b>
            <!-- <app-listing-wishlist-toggle class="ml-1" [listing]="listing" [allowToggling]="false"></app-listing-wishlist-toggle> -->
          </div>
          <div>
            <dx-button class="text-right mr-1 removeFromCartBtn" type="danger" icon="trash" (onClick)="removeItem(listing.id, listing.is_c2, listing.name)"
              stylingMode="text" [disabled]="loading">
            </dx-button>
            <dx-button class="text-right" id="edit{{listing.id}}" type="default" icon="edit" (onClick)="editItem(listing)"
                        stylingMode="text" [disabled]="loading">
            </dx-button>
          </div>
        </div>
        <div class="mb-1">
          <span *ngIf="!hydraEnabled" class="company-name">{{listing.company_name}} |</span>
          <img *ngIf="listing.catalog_type==='medigi' && !hydraEnabled" class="company-logo-side ml-1" src="/assets/img/logos/medigi-logo.png" alt="medigi logo">
          <img *ngIf="listing.catalog_type==='503b' && !hydraEnabled" class="company-logo-side ml-1" src="/assets/img/logos/503B_Color.svg" alt="503B logo">
          <img *ngIf="listing.catalog_type==='medshorts' && !hydraEnabled" class="company-logo-side ml-1" src="/assets/img/logos/ms_logo_teal.png" alt="medshorts logo">
          <img *ngIf="hydraEnabled" class="company-logo-side" [src]="listing.company_logo" alt="{{listing.company_name}} logo">
          <app-ms-tooltip *ngIf="listing.is_c2" badgeText="CII" id="listing_c2_{{listing.id}}"
                            tooltipText="This item is CII medication" extraClass="badge badge-warning">
          </app-ms-tooltip>
        </div>
        <div class="row">
          <div class="col-12 dx-font-s">
            <div *ngFor="let line of listing.pricing.prices">
              <div *ngIf="line.quantity">
                <div class="d-flex flex-row justify-content-between align-items-center mr-3">
                  <div>
                    {{line.quantity}} Packs ({{line.quantity * listing.eaches_per_pack}} Eaches) at {{line.price | usdCurrency}}
                  </div> 
                  <div>
                    <span class="price-text text-right">{{line.price * line.quantity | usdCurrency}}</span>
                  </div>
                </div>
                <div class="mt-2"><span class="feild-title">Account:</span> {{upperCasePriceType(line.price_type)}}</div>
                <div *ngIf="!hydraEnabled"><span class="feild-title">Expires:</span> {{line.expiry_date}}</div>
                <div *ngIf="listing.pricing.amu?.savings_per_pack > 0">
                  <div *ngIf="listing.catalog_type==='medigi' && !hydraEnabled" class="dx-font-xs mb-1">
                    <div class="d-flex flex-row align-items-center">
                      <div>
                        <p class="feild-title mr-1 mb-0">Savings: </p>
                      </div>
                      <div>
                        <span class="mr-1">Line <span class="text-success mr-1"> {{line.quantity * listing.pricing.amu?.savings_per_pack | usdCurrency}} ({{listing.pricing.amu?.savings_percent}}%)</span>|</span>
                      </div>
                      <div>
                        <span class="mr-1">Per Pack <span class="text-success mr-1"> {{listing.pricing.amu?.savings_per_pack | usdCurrency}}</span>|</span>
                      </div>
                      <div>
                        <span class="mr-1">Per Unit <span class="text-success mr-1"> {{listing.pricing.amu?.savings_per_pack / listing.eaches_per_pack | usdCurrency}}</span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span *ngIf="!hydraEnabled" class="feild-title mb-1">{{listing.pricing.price_meta?.shipping_layer?.description}}</span>
            <span *ngIf="hydraEnabled && listing.pricing.price_meta?.shipping_layer?.name === 'free_shipping'" class="feild-title mb-1">Shipping is Free.</span>
            <span *ngIf="hydraEnabled && listing.pricing.price_meta?.shipping_layer?.name !== 'free_shipping'" class="feild-title mb-1">{{listing.pricing.price_meta?.shipping_layer?.description}}</span>
            <div *ngIf="listing.pricing.price_meta?.buyer_transaction_fee != 0.0" class="mt-2">
                <span class="feild-title">Transaction Fee:</span>
                {{listing.pricing.price_meta?.buyer_transaction_fee | usdCurrency}}
            </div>
            <span class="info-503b mt-1 dx-font-xs" *ngIf="listing.catalog_type==='503b'">
              <p>* This is a compounded product from an FDA registered 503B Outsourcing Facility </p>
            </span>
            <div class="dx-font-s">
              <hr>
            </div>
            <span><i>{{deliveryComment}}</i></span>
          </div>
        </div>
      </div>

    <div *ngIf="cartData?.shipping_options.length" class="card card-body pl-3 mt-2 dx-font-m" style="width: 100%;">
      <div class="mb-2">Choose Shipping:</div>
      <div class="dx-field">
        <div class="dx-field-label" style="width: 100% !important;">
          <dx-radio-group [items]="cartData.shipping_options" [valueExpr]="'id'" [disabled]="mode!='sidebar'"
            (onValueChanged)="setShipping()" [(value)]="selected_shipping_id">
            <div *dxTemplate="let shipping of 'item'">
              <div class="dx-font-m row" style="min-width: 300px; width: 100%;">
                <div class="col-7">{{shipping.name}}</div>
                <div class="col-5 text-right">
                  <span>{{shipping.price | usdCurrency}}</span>
                  <span>
                    <i id="shipping_desc{{shipping.id}}" class="dx-icon dx-icon-info"></i>
                    <app-ms-tooltip target="shipping_desc{{shipping.id}}" [tooltipText]="shipping.description">
                    </app-ms-tooltip>
                  </span>
                </div>
              </div>
            </div>
          </dx-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-popup *ngIf="addVisible" [(visible)]="addVisible"  class="popup" 
          [showTitle]="false" [showTitle]="false" [dragEnabled]="true"
          [hideOnOutsideClick]="true" [showCloseButton]="true"
          [resizeEnabled]="false" [minWidth]="1200" [height]="'95%'">
  <dx-scroll-view
          width="100%"
          height="100%"
          [showScrollbar]="'always'">
    <app-add-to-cart [listingId]="currentListing.id" mode="edit" [showStats]="true" [isPopup]="true" (update)="addOutput()"></app-add-to-cart>
  </dx-scroll-view>
</dx-popup>
