import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'dateToColor'
})
export class DateToColorPipe implements PipeTransform {

  transform(expiry_date: Date): string {
    var month = -1 * moment().diff(moment(expiry_date, 'MM-DD-YYYY'), 'months');
    var green = '#64C075';
    var yellow = '#FFCB1F';
    var orange = '#FB8500';

    if(month >= 12) {
      return green;
    }
    else if (month >= 6) {
      return yellow;
    }
    else {
      return orange;
    }
  }

}
