<div class="row">
  <div class="col-12 d-flex flex-row">
    <div class="col-3">
      <app-user-picture></app-user-picture>
    </div>
    <div class="col-9">
      <div>
        <h4 class="new-card-subtitle">Personal Info</h4>
        <hr class="new-card-subtitle-underline">
      </div>

      <div class="col-12">
        <form action="your-action" (submit)="onProfileSubmit($event)">
          <dx-form id="form" method="post" [formData]="profile_data" [readOnly]="false" [showColonAfterLabel]="true"
            [showValidationSummary]="false" validationGroup="customerData" labelMode="floating" [colCount]="2">
            <dxi-item itemType="group">
              <dxi-item dataField="phone" [editorOptions]="{
                          mask: '(000) 000-0000',
                          useMaskedValue: false,
                          maskInvalidMessage: 'The phone must have a correct USA phone format'
                      }">
              </dxi-item>
              <dxi-item dataField="email">
                <dxi-validation-rule type="required" message="Email is required">
                </dxi-validation-rule>
                <dxi-validation-rule type="email" message="Email is invalid">
                </dxi-validation-rule>
              </dxi-item>
            </dxi-item>
            <dxi-item itemType="group">
              <dxi-item>
                <div class="mt-3 mb-2 d-flex flex-row justify-content-between">
                  <p>Password:</p>
                  <a class="link" (click)="openUpdatePasswordPopup()">Reset Password</a>
                </div>
              </dxi-item>
              <dxi-item itemType="button" [buttonOptions]="infoButtonOptions" horizontalAlignment="right">
              </dxi-item>
            </dxi-item>
          </dx-form>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!hydraEnabled" class="row">
  <div class="col-12">
    <div>
      <h4 class="new-card-subtitle">Email Subscriptions</h4>
      <hr class="new-card-subtitle-underline">
    </div>

    <div *ngIf="!loading">
      <app-subscribe-emails [userId]="profile?.data?.id"></app-subscribe-emails>
    </div>
  </div>
</div>

<dx-popup
  [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="true" [width]="400" [height]="400"
  title="Reset Your Password" [showCloseButton]="true" [(visible)]="isPasswordPopupOpen" (hiding)="closingPasswordPopup()">
<app-password-update></app-password-update>
</dx-popup>
