import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ExclusionService {

  constructor(private http: HttpClient) {}

  public getExclusions() {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/ndc_exclusion`);
  }

  public getNdcExclusions(ndc) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/ndc_exclusion/${ndc}`);
  }

  public creategetExclusion(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/ndc_exclusion`, data);
  }

  public exclusionStatusChange(data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/ndc_exclusion/${data.id}`, data);
  }

}
