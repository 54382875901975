<div *ngIf="!loggedIn && !router.url.includes('registration') && !content?.body" class="container-fluid section-2 py-4" [ngStyle]="{'background-color': content?.bgcolor}">
  <div class="row">
    <div class="col-md-12">
      <div style="max-width: 800px; margin: 0 auto">
        <app-static-footer></app-static-footer>
      </div>
    </div>
  </div>
  <hr>
</div>

<footer *ngIf="!loggedIn && !content?.body" class="static-footer" [ngStyle]="{'background-color': content?.bgcolor}">
  <div *ngIf="!mobileUser" class="container">
    <div>
      <h2 *ngIf="content.title" class="display-4" [ngStyle]="{'color': content.color}">{{content.title}}</h2>
      <div *ngIf="content.body" [ngStyle]="{'color': content.color}" [innerHtml]="content.body | bypassSecurity"></div>
    </div>
    <div class="footer-container pb-5 pt-3">
      <div class="top-line">
        <div class="top-line-left-side">
          <a routerLink="/">home</a>
          <a class="green" routerLink="/registration/buyer">register facility <i class="dx-icon-chevronright"></i></a>
        </div>
        <div class="top-line-right-side">
          <img src="{{client.logo_url}}" alt="logo" width="150px">
        </div>
      </div>
      <div class="bottom-line">
        <div class="bottom-line-left-side">
          {{client.supplier_info.address}} <span *ngIf="client.comm_phone" > | </span> <a *ngIf="client.comm_phone" class="green"
            href="tel: {{client.comm_phone}}">{{client.comm_phone}}</a> <span *ngIf="client.comm_email"> | </span> <a *ngIf="client.comm_email" class="green"
            href="mailto: {{client.comm_email}}">{{client.comm_email}}</a><br>
          <small *ngIf="!hydraEnabled">&copy;{{copyrightYear}} Medigi LLC.</small>
        </div>
        <div class="bottom-line-right-side">
          <img *ngIf="client.supplier_info.linkedin" class="social" src="assets/img/static-pages/medigi/v2-images/company-logos/linkedin.svg" alt="Linked In"
            (click)="goToSocials(client.supplier_info.linkedin)">
          <img *ngIf="client.supplier_info.facebook" class="social" src="assets/img/static-pages/medigi/v2-images/company-logos/facebook.svg" alt="Facebook"
            (click)="goToSocials(client.supplier_info.facebook)">
          <img *ngIf="client.supplier_info.twitter" class="social" src="assets/img/static-pages/medigi/v2-images/company-logos/twitter.svg" alt="Twitter"
            (click)="goToSocials(client.supplier_info.twitter)">
          <img *ngIf="client.supplier_info.instagram" class="social" src="assets/img/static-pages/medigi/v2-images/company-logos/insta.svg" alt="Instagram"
            (click)="goToSocials(client.supplier_info.instagram)">
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="mobileUser">
    <div class="mobile-footer-container">
      <div *ngIf="content.meta" [innerHtml]="content.meta | bypassSecurity"></div>
      <div class="mobile-top-line">
        <div>
          <img src="{{client.logo_url}}" alt="logo">
        </div>
        <div class="bottom-line-right-side">
          <img *ngIf="client.supplier_info.linkedin" src="assets/img/static-pages/medigi/v2-images/company-logos/linkedin.svg" alt="Linked In"
            (click)="goToSocials(client.supplier_info.linkedin)">
          <img *ngIf="client.supplier_info.facebook" src="assets/img/static-pages/medigi/v2-images/company-logos/facebook.svg" alt="Facebook"
            (click)="goToSocials(client.supplier_info.facebook)">
          <img *ngIf="client.supplier_info.twitter" src="assets/img/static-pages/medigi/v2-images/company-logos/twitter.svg" alt="Twitter"
            (click)="goToSocials(client.supplier_info.twitter)">
          <img *ngIf="client.supplier_info.instagram" src="assets/img/static-pages/medigi/v2-images/company-logos/insta.svg" alt="Instagram"
            (click)="goToSocials(client.supplier_info.instagram)">
        </div>
      </div>
      <div class="mobile-middle-line mt-3">
        <div class="links">
          <a routerLink="/">home</a>
        </div>
        <div class="links">
          <a class="green" routerLink="/registration/buyer">register facility <i class="dx-icon-chevronright"></i></a>
        </div>
      </div>
      <div class="bottom-line mt-3 mb-3">
        <div class="bottom-line-left-side">
          {{client.supplier_info.address}} | <a *ngIf="client.comm_phone" class="green"
            href="tel: {{client.comm_phone}}"></a>{{client.comm_phone}} | <a *ngIf="client.comm_email" class="green"
            href="mailto: {{client.comm_email}}">{{client.comm_email}}</a><br>
          <small>&copy;{{copyrightYear}} medigi | All Rights Reserved</small>
        </div>
      </div>
    </div>
  </div>
</footer>

<div *ngIf="!loggedIn && content?.body" [ngStyle]="{'color': content.color}" [innerHtml]="content.body | bypassSecurity"></div>