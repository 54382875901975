<dx-data-grid 
  [dataSource]="invoices" 
  [columnAutoWidth]="true" 
  [showBorders]="true"
  [showColumnLines]="true"
  [rowAlternationEnabled]="true"
  [cacheEnabled]="false"
  (onExporting)="onExporting($event)">

  <dxo-selection mode="single"></dxo-selection>

  <dxi-column dataField="account_type" caption="Acc. Type" [groupIndex]="0"></dxi-column>
  <dxi-column dataField="invoice_date" dataType="date" caption="Invoice Date"></dxi-column>
  <dxi-column dataField="ndc" caption="NDC"></dxi-column>
  <dxi-column dataField="description" caption="Description"></dxi-column>
  <dxi-column dataField="purchased_packages" caption="Purchased Pkg."></dxi-column>
  <dxi-column dataField="purchased_units" caption="Purchased Units"></dxi-column>
  <dxi-column dataField="pkg_cost" caption="Pkg. Cost" cellTemplate="cellTemplate"></dxi-column>
  <dxi-column dataField="unit_cost" caption="Unit Price" cellTemplate="cellTemplate"></dxi-column>
  <dxi-column dataField="extended_cost" caption="Extended Cost" cellTemplate="cellTemplate"></dxi-column>
  <div *dxTemplate="let cell of 'cellTemplate'">
    <div class="current-value">{{ cell.text | currency: "USD": "symbol" }}</div>
  </div>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showNavigationButtons]="true"></dxo-pager>
  <dxo-export [enabled]="true" [(fileName)]="title"></dxo-export>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-summary>
    <dxi-group-item column="account_type" summaryType="count" displayFormat="{0} orders">
    </dxi-group-item>
    <dxi-group-item column="purchased_packages" summaryType="sum" [showInGroupFooter]="false" [alignByColumn]="true"
      displayFormat="Total: {0}">
    </dxi-group-item>
    <dxi-group-item column="pkg_cost" summaryType="avg" displayFormat="Avg: {0}" [showInGroupFooter]="false"
      [alignByColumn]="true">
      <dxo-value-format type="currency" [precision]="2"></dxo-value-format>
    </dxi-group-item>
    <dxi-group-item column="extended_cost" summaryType="sum" [showInGroupFooter]="false" [alignByColumn]="true"
      displayFormat="Total: {0}">
      <dxo-value-format type="currency" [precision]="2"></dxo-value-format>
    </dxi-group-item>
  </dxo-summary>
</dx-data-grid>