<div class="col-12 d-flex flex-row">
  
  <div class="col-6">
    <div class="dx-field">
      <dx-check-box text="Monthly Executive Summary (First of the Month)" [(value)]="emailList.monthly_executive"></dx-check-box>
    </div>
    
    <div class="dx-field">
      <dx-check-box text="Weekly Marketplace Changes (Friday's)" [(value)]="emailList.weekly_changes"></dx-check-box>
    </div>
  </div>
  
  <div class="col-6">
    <div class="dx-field">
      <dx-check-box text="Favorite Items Alert" [(value)]="emailList.fav_listings"></dx-check-box>
    </div>
    
    <div class="dx-field">
      <dx-check-box text="Weekly News / Trending Products (Tuesday's)" [(value)]="emailList.trending_products"></dx-check-box>
    </div>
  </div>

</div>

<div class="text-right mt-3">
  <dx-button text="Update Subscriptions" type="success" (onClick)="onSubmit()"></dx-button>
</div>