<dx-drawer
    id="drawer-side"
    class="layout-body"
    position="before"
    [closeOnOutsideClick]="shaderEnabled"
    [openedStateMode]="menuMode"
    [revealMode]="menuRevealMode"
    [maxSize]="200"
    [minSize]="minMenuSize"
    [shading]="shaderEnabled"
    [(opened)]="menuOpened">
   
    <app-side-navigation-menu
        [items]="menuItems"
        [menuToggleEnabled]="false"
        (menuToggle)="menuOpened = !menuOpened;"
        [isMedigiAdmin]="medigiAdmin"
        [compactMode]="!menuOpened"
        [selectedItem]="selectedRoute"
        class="dx-base-background" 
        *dxTemplate="let dataMenu of 'panel'"
        (selectedItemChanged)="navigationChanged($event)">
    </app-side-navigation-menu>

    <dx-scroll-view class="full-height-scrollable">

        <app-header *ngIf="auth0.loggedIn === true" [title]="title" [menuToggle]="menuOpened"></app-header>
        
        <app-header *ngIf="auth0.loggedIn !== true" [menuToggleEnabled]="false" [title]="title"></app-header>
    
        <div class="content mainframe pt-4">
            <ng-content></ng-content>
        </div>

        <div class="content-block">
            <ng-content select="app-medigi-footer"></ng-content>
        </div>
    </dx-scroll-view>
</dx-drawer>

<app-medigi-clickwrap *ngIf="this.user" [(visible)]="showClickwrap"></app-medigi-clickwrap>