<footer>
  <div style="background-image: url(/assets/img/static-pages/medshorts/MS_Web_Gradient_Without_lines.jpg);background-position: center; background-repeat: no-repeat; background-size: cover;">
      <div id="lg-footer">
        <div id="top-footer-section">
          <div id="top-footer-left-side">
            <a routerLink="/"><img id="footer-img" src="assets/img/static-pages/medshorts/MS Logo_white.png" alt="Medshorts Logo" style="width:14rem; height: 2.75rem;"></a>
            <span id="footer-line"></span>
            <section id="footer-links">
              <div id="support-links">
                <ul>
                  <li><a class="link-title bold-font">Support</a></li>
                  <li><a (click)="navigateToSubPages('register')">Register</a></li>
                  <li><a (click)="navigateToSubPages('contact')">Contact Us</a></li>
                  <li><a (click)="navigateToSubPages('demo')">Request a Demo</a></li>
                </ul>
              </div>
            </section>
            <section id="footer-contact-info" class="text-white">
              <p class="body-font bold-font">Medshorts</p>
              <p id="footer-email" class="underline"><a class="text-white" routerLink="mailto: info@medshorts.com">info@medshorts.com</a></p>
              <p id="footer-phone-num">844-733-5633</p>
              <p >200 East Robinson Street | Suite 1250 | Orlando, Florida 32801</p>
            </section>
          </div>
          <section class="footer-social-media">
            <ul>
              <li>
                <a routerLink="https://www.facebook.com/medigidirect" target="_blank"><img src="/assets/img/static-pages/medshorts/Facebook-icon.png" alt="Twitter logo" /></a>
              </li>
              <li>
                <a routerLink="https://twitter.com/medigiDirect" target="_blank"><img src="/assets/img/static-pages/medshorts/Twitter-icon.png" alt="Twitter logo" /></a>
              </li>
              <li>
                <a routerLink="https://www.instagram.com/medigidirect" target="_blank"><img src="/assets/img/static-pages/medshorts/Instagram-icon.png" alt="Instagram logo" /></a>
              </li>
              <li>
                <a routerLink="https://www.linkedin.com/company/medigidirect" target="_blank"><img src="/assets/img/static-pages/medshorts/LinkedIn-icon.png" alt="LinkedIn logo" /></a>
              </li>
            </ul>
          </section>
        </div>
        <section id="copyright" class="text-white">
          <p>Copyright © {{copyrightYear}}. All Rights Reserved by MedShorts.com</p>
        </section>
      </div>
    </div>
</footer>