import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class StorageService {

  private storageSub = new Subject<string>();

  watch(): Observable<any> {
    return this.storageSub.asObservable();
  }

  get(key: string): string {
    return localStorage.getItem(key);
  }

  getObject(key) {
    return JSON.parse(this.get(key));
  }


  set(key: string, data: string) {
    localStorage.setItem(key, data);
    this.storageSub.next(key);
  }

  setObject(key, data) {
    this.set(key, JSON.stringify(data));
  }

  remove(key) {
    localStorage.removeItem(key);
    this.storageSub.next(`removed ${key}`);
  }

  createParamsForFilter(filter: any[]) {
    var params = [];
    if (filter[0] === 'created_date' || typeof filter[0] === 'string' || filter[0] instanceof String) {
      params.push({field: String(filter[0]), value: String(filter[2])});
    } else {
      if (!filter.find(x => x==='or')) {
        filter.forEach(f => {
          if (f!=='and' && f!=='or') {
            const data = this.createParamsForFilter(f);
            if (!params.find(x => x.field === f[0])) {
              params = params.concat(data);
            }
          }
        });
      }
    }
    return params;
  }
}
