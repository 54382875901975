<div *ngIf="isVetted">
  <div class="row mb-1">
    <dx-load-panel 
      [(visible)]="showSpinner" 
      [showIndicator]="true" 
      [showPane]="true"
      [shading]="true"
      [hideOnOutsideClick]="false">
    </dx-load-panel>
    <div class="col-12">
      <h3>Base Price <app-ms-tooltip id="aacTip" [tooltipText]="basePriceTooltip" extraClass="bade-ms-default" width="400px"></app-ms-tooltip></h3>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6">
      <div class="dx-field">
        <div class="dx-field-label pr-0" style="width: 80px">per pack:</div>
        <div class="dx-field-value">
            <dx-number-box
              [(value)]="basePriceSliderOpt.value"
              [min]="basePriceSliderOpt.floor"
              [step]="basePriceSliderOpt.step"
              [showSpinButtons]="false"
              width="100"
              (onValueChanged)="updatePriceCeil($event)">
              <dxo-format type="currency" [precision]="2"></dxo-format>
            </dx-number-box>
        </div>
      </div>
    </div>
    <div class="col-1 pl-0">
<!--      <dx-button-->
<!--      [disabled]="!priceChanged && !amuChanged"-->
<!--      stylingMode="contained"-->
<!--      type="danger" -->
<!--      icon="clear"  -->
<!--      hint="Clear Input" -->
<!--      (onClick)="clearFlagData()">-->
<!--    </dx-button>-->
    </div>
    <div class="col-5">
      <div *ngIf="listing.monthly_exact_savings" class="dx-field">
        <div class="dx-field-label pr-0 row-1">Savings:</div>
        <div class="dx-field-value row-2">
          {{listing.monthly_exact_savings | currency : 'USD' : 'symbol'}}
          <div *ngIf="listing.lowest_lot_discount">({{listing.lowest_lot_discount | number : '1.2-2'}}%)</div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dx-slider [visible]="false" [min]="basePriceSliderOpt.floor" [step]="basePriceSliderOpt.step" [max]="basePriceSliderOpt.ceil" [(value)]="basePriceSliderOpt.value"
                  [rtlEnabled]="false" (onValueChanged)="basePriceChanged($event)">
      </dx-slider>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-12">
      <h3>AMU <app-ms-tooltip id="amuTip" tooltipText="Average Monthly Utilization" extraClass="bade-ms-default"></app-ms-tooltip></h3>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6">
      <div class="dx-field">
        <div class="dx-field-label pr-0" style="width: 80px">packs:</div>
        <div class="dx-field-value">
            <dx-number-box
              [(value)]="overrideAmuSliderOpt.value"
              [min]="0"
              [step]="1"
              [showSpinButtons]="false"
              width="100"
              (onValueChanged)="updateAmuCeil($event)">
            </dx-number-box>
        </div>
      </div>
    </div>
    <div class="col-1 pl-0 pt-0">
<!--      <dx-button-->
<!--      [disabled]="!priceChanged && !amuChanged"-->
<!--      icon="clear" -->
<!--      stylingMode="contained"-->
<!--      type="danger" -->
<!--      hint="Clear Input" -->
<!--      (onClick)="clearAmuOverrideData()">-->
<!--    </dx-button>-->
    </div>
    <div class="col-12">
      <dx-slider [visible]="false" [min]="overrideAmuSliderOpt.floor" [step]="overrideAmuSliderOpt.step" [max]="overrideAmuSliderOpt.ceil" [(value)]="overrideAmuSliderOpt.value"
        (onValueChanged)="overrideAmuChanged($event)" [rtlEnabled]="false">
      </dx-slider>
      <p class="dx-font-xs" *ngIf="listing?.pricing?.amu?.override_amu"><em>The AMU value above is your current override.<br>Your original AMU was <strong>{{listing.pricing.amu.amu}}</strong>.</em></p>
    </div>
  </div>
  <div class="row mb-3 mt-3">
    <div class="col-md-12">
      <div class="float-right">
        <dx-button 
          width="120px" 
          stylingMode="contained" 
          type="success"
          [disabled]="!priceChanged && !amuChanged" 
          (onClick)="submitOverrideData()" 
          class="mr-2" 
          text="Submit">
        </dx-button>
        <dx-button 
          width="120px" 
          stylingMode="contained" 
          type="normal"  
          (onClick)="cancelFlagDataUpdate()" 
          class="mr-2"
          text="Cancel">
        </dx-button>
      </div>
  </div>
  </div>
  <div class="mb-3"></div>
  <dx-popover target="#link1" position="top" [width]="300" [(visible)]="currentPriceVisible">
    <div *dxTemplate="let data = model of 'content'">
      Adjust the slider or enter value to reflect your
      typical acquisition cost for this product,
      This will be used to show actual savings.
    </div>
  </dx-popover>

  <dx-popover *ngIf="size == 'full'" target="#link2" position="top" [width]="300" [(visible)]="fairPriceVisible">
    <div *dxTemplate="let data = model of 'content'">
      Your short-dated price suggestion will be used as feedback to our manufacturers to encourage more competitive discounts.
    </div>
  </dx-popover>
</div>
