import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
    selector: 'app-seller-company-logo',
    templateUrl: './seller-company-logo.component.html'
})
export class SellerCompanyLogoComponent implements OnInit {

    @Input() listing: any;
    @Input() mode = 'url';
    @Input() redirectTo = 'profile'; // profile, catalog, about
    @Input() maxWidth = '100px';
    @Input() maxHeight = '30px';

    constructor(private storageService: StorageService,
                private router: Router,
                private googleAnalyticsService: GoogleAnalyticsService) { }

    ngOnInit() {
    }
    
    redirectToPage(ev) {
        ev.preventDefault();
        if (this.redirectTo === 'catalog') {
            this.goToSellerCatalog(this.listing.company_id);
        } else if (this.redirectTo === 'profile') {
            this.goToSellerProfile(this.listing.company_id);
        }else if (this.redirectTo === 'about') {
            this.goToSellerAboutTab(this.listing.company_id);
        }
    }

    goToSellerCatalog(sellerId) {
        this.storageService.set('catalogSellerId', sellerId.toString());
        this.router.navigate(['/catalog'], { queryParams: { sellerId: sellerId },  queryParamsHandling: "merge" });
    }

    goToSellerProfile(sellerId) {
        this.router.navigate(['/seller-profile', sellerId]);
    }

    goToSellerAboutTab(sellerId) {
        this.storageService.set('catalogSellerId', sellerId.toString());
        this.router.navigate(['/catalog'], { queryParams: { sellerId: sellerId, tab: 'about' },  queryParamsHandling: "merge" });
    }

    sendToGa(eventName, eventCategory) {
        this.googleAnalyticsService.eventEmitter(eventCategory, eventName);
      }
}
