<dx-popup
    title="Introducing Medigi"
    [(visible)]="visible"
    (onHidden)="onClose($event)"
    [height]="'auto'"
    [width]="500"
    [showCloseButton]="true"
    [hideOnOutsideClick]="true"
>
    <p>
        As a registered MedShorts user,  you are able to purchase from the Medigi catalog at no additional cost.
        You’ll find a greater variety of listings such as full-dated stock and medications from 503B Outsourcing
        Facilities.  Through a License Agreement between MedShorts, LLC and Medigi, LLC all orders will be
        invoiced by the same entity (MedShorts, LLC) already enrolled as a vendor at your institution/pharmacy.
        By clicking the link below you’ll be redirected to medigi.com. The same terms and conditions as
        agreed on Medshorts.com apply to your use of medigi.com.
    </p>

    <div class="float-right">
        <dx-button analytics-on analytics-event="Button Click" analytics-category="Clickwrap" analytics-label="Clicked Agreed on Navigate to medigi popup" (onClick)="transferCompany()" type="default" text="I Acknowledge"></dx-button>
    </div>
</dx-popup>
