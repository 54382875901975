import { localhost, supplier1, supplier2, supplier3, supplier4, dysportdirect, hikmadirect } from 'src/environments/hydra/configs';

export const hydraSupplierConfigMap = {
    'localhost:4201': localhost,
    
    'supplier1.hydra.medigi.com': supplier1,
    'supplier2.hydra.medigi.com': supplier2,
    'supplier3.hydra.medigi.com': supplier3,
    'supplier4.hydra.medigi.com': supplier4,

    'supplier1.hydrademo.medigi.com': supplier1,
    'supplier2.hydrademo.medigi.com': supplier2,
    'supplier3.hydrademo.medigi.com': supplier3,
    'supplier4.hydrademo.medigi.com': supplier4,

    'dysportdirect.medigi.com': dysportdirect,
    'dysportdirect.com': dysportdirect,
    'www.dysportdirect.com': dysportdirect,
    'www.hikmadirect.com': hikmadirect,
    'hikmadirect.com': hikmadirect
}