import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { DirectService } from 'src/app/shared/services/user/direct.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-direct-footer',
  templateUrl: './direct-footer.component.html',
  styleUrls: ['./direct-footer.component.scss']
})
export class DirectFooterComponent implements OnInit {

  @Input() content: any;
  @Input() loggedIn: boolean = false;
  showInquiry: boolean = false;
  copyrightYear = new Date().getFullYear();

  mobileUser;
  hydraEnabled;
  client: any;

  constructor(
      private directService: DirectService,
      private screenSize: ScreenService,
      private userService: UserService,
      public router: Router,
      private elementRef: ElementRef,
      private renderer: Renderer2
    ) { }

  async ngOnInit() {
    this.hydraEnabled = environment.hydraEnabled;
    this.mobileUser = this.screenSize.sizes['screen-small'] === true || this.screenSize.sizes['screen-x-small'] === true;
    this.copyrightYear = new Date().getFullYear();
    this.getMsClient();
    setTimeout(() => {
      // wait for DOM rendering
      this.directService.reinsertLinks(this.elementRef, this.renderer);
    });
  }

  getMsClient() {
    this.userService.getMsClient$().subscribe(
      result => this.client = result.data
    );
  }

  goToSocials(link) {
    window.open(link, "_blank");
  }

}
