<span *ngIf="csosSignUpRequested">
  Sign up for CSOS purchases was requested
</span>

<span *ngIf="!csosSignUpRequested">
  <dx-button id="c2-request" text="CII Product - Request Access" (click)="signupVisibleEnable()" type="default" style="width: 100%;"></dx-button>

  <app-ms-tooltip target="c2-request" width="300" tooltipText="This is a CII restricted product. To purchase, a registration
   process must be completed with the manufacturer to electronically sign your 222.">
  </app-ms-tooltip>
</span>


<dx-popup *ngIf="signupVisible" 
  class="popup"
  [height]="470"
  [width]="450" 
  [showTitle]="true" 
  title="CII Responsible Person Info"
  [hideOnOutsideClick]="true" 
  [(visible)]="signupVisible"
  [resizeEnabled]="false"
  [showCloseButton]="true">

  <p>
    You are requesting CSOS Approval for <span style="font-weight: bold;">{{companyName}}</span>.
    Please provide contact information for the person who controls the CSOS signing certificate for {{facilityName}}
    <span style="font-weight: bold;">DEA# {{contactInfo.dea}}</span> and will sign the order
  </p>

  <dx-check-box 
    [(value)]="userIsCSOS"
    (onValueChanged)="onUserIsCSOSChanged()"
    text="I control the certificate and will sign the order"
    class="mb-2">
  </dx-check-box>

  <form action="your-action" (submit)="onFormSubmit($event)">
    <div class="dx-field">
      <div class="dx-field-label">Contact Name</div>
      <div class="dx-field-value">
        <dx-text-box [readOnly]="userIsCSOS" [(value)]="contactInfo.name">
          <dx-validator>
            <dxi-validation-rule type="required" message="Contact name is required"></dxi-validation-rule>
            <dxi-validation-rule type="stringLength" [min]="2" message="Name must have at least 2 symbols">
            </dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">Email</div>
      <div class="dx-field-value">
        <dx-text-box [readOnly]="userIsCSOS" [(value)]="contactInfo.email">
          <dx-validator>
            <dxi-validation-rule type="required" message="Email is required"></dxi-validation-rule>
            <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">Phone</div>
      <div class="dx-field-value">
        <dx-text-box [readOnly]="userIsCSOS" [(value)]="contactInfo.phone" mask="(000) 000-0000" maskInvalidMessage="The phone must have a correct USA phone format" [useMaskedValue]="true">
          <dx-validator>
            <dxi-validation-rule type="required" message="Phone is required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>
    <div class="text-right">
      <dx-button id="button" text="Sign Up" type="success" [useSubmitBehavior]="true" horizontalAlignment="right"></dx-button>
    </div>
  </form>
</dx-popup>
