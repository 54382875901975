import { EnvironmentInjector, Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../shared/services/user/user.service';
import { StorageService } from '../shared/services/storage.service';
import { environment } from '../../environments/environment';
import { GoogleAnalyticsService } from '../shared/services/google-analytics.service';
import { StorageType } from '../shared/models/constants';
import { AppInfoService } from '../shared/services/app-info.service';

@Injectable({
  providedIn: 'root'
})

export class Auth0Service {
  // Create an observable of Auth0 instance of client
  hydraEnabled = environment.hydraEnabled;
  clientId = environment.auth0.clientId;
  appMetadata = environment.auth0.appMetadata;
  user: any;


  auth0Client$ = (from(
    createAuth0Client({
      domain: environment.auth0.domain,
      client_id: environment.auth0.clientId,
      redirect_uri: `${window.location.origin}/callback`,
      audience: environment.auth0.apiIdentifier,
      response_type: 'token id_token',
      scope: 'read:current_user',
      nonce: 'NONCE',
      state: 'OPAQUE_VALUE',
    })
    ) as Observable<Auth0Client>).pipe(
      shareReplay(1), // Every subscription receives the same shared value
      catchError(err => throwError(err))
    );


  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );


  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: any;
  private loggedInSubject$ = new BehaviorSubject<any>(null);

  constructor(private router: Router,
              private userService: UserService,
              private appInfo: AppInfoService,
              private storageService: StorageService,
              private http: HttpClient,
              private googleAnalticsService: GoogleAnalyticsService) { }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  public userSubject(): BehaviorSubject<any> {
    return this.loggedInSubject$;
  }

  public get currentUserValue(): any {
    return this.userProfileSubject$.value;
  }

  localAuthSetup(msClient) {
    // if (environment.hydraEnabled) {
    //   this.clientId = msClient.hydraConfig.auth0_client_id;
    // } else {
    //   this.clientId = environment.auth0.clientId;
    // }
    // This should only be called on app initialization
    // Set up local authentication streams

    // this.auth0Client$ = (from(
    //   createAuth0Client({
    //     domain: environment.auth0.domain,
    //     client_id: this.clientId,
    //     redirect_uri: `${window.location.origin}/callback`,
    //     audience: environment.auth0.apiIdentifier,
    //     response_type: 'token id_token',
    //     scope: 'read:current_user',
    //     nonce: 'NONCE',
    //     state: 'OPAQUE_VALUE',
    //   }) 
    //  ) as Observable<Auth0Client>).pipe(
    //     shareReplay(1), // Every subscription receives the same shared value
    //     catchError(err => throwError(err))
    //   );

    //   this.isAuthenticated$ = this.auth0Client$.pipe(
    //     concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    //     tap(res => this.loggedIn = res)
    //   );
    //   this.handleRedirectCallback$  = this.auth0Client$.pipe(
    //     concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    //   );


    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      // If authenticated, response will be user object
      // If not authenticated, response will be 'false'
      this.loggedIn = !!response;
      if (this.loggedIn) {
        // This is a page refresh
        console.log(response);
        this.loggedInSubject$.next(response[this.appMetadata].id);
      }
    });
  }

  async login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists

    //Set redirect target for app.components.ts
    const destination = redirectPath !== '/' && redirectPath !== undefined ? `?destination=${redirectPath}` : '';
    // try {
    //   await this.logout();
    // }
    // catch {}
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback${destination}`,
        appState: { target: redirectPath }
      });
    });
  }

  handleAuthCallback() {
    // this.clearStoragesAndSetTheme();
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // Have client, now call method to handle auth callback redirect
      tap((cbRes: any) => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; get user and login status
        return combineLatest([this.getUser$(), this.isAuthenticated$]);
      })
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, loggedIn]) => {
      this.router.navigate(['/loading']);
      const id = user[this.appMetadata].id;
      this.loginToMedshorts(id);
    });
  }

  async loginToMedshorts(id: number) {
    try {
      const auth0Token = await this.getIdTokenClaims$().toPromise();
      this.storageService.set(StorageType.auth0Token, auth0Token.__raw);
      try {
        let  msClientResult = await this.userService.getMsClient$().toPromise();
        this.storageService.set('msClient', JSON.stringify(msClientResult.data));
        this.appInfo.setDomainConfigs();
      } catch {
        // All is good
      }

      this.user = await this.userService.login(id);
      this.loggedInSubject$.next(id);
      this.googleAnalticsService.eventEmitter(this.user.email, `Logged In ${this.user.role}`);

    } catch (e) {
      this.logout();
      this.googleAnalticsService.eventEmitter(this.user.email, `Login Failure`);
    }
  }

  async logout() {
    // Kill session on backend
    await this.logoutFromMedshorts().toPromise();
    await this.logoutAuth0()
    this.clearStoragesAndSetTheme();
  }

  logoutAsync() {
    this.logoutFromMedshorts().toPromise();
    this.clearStoragesAndSetTheme();
    this.logoutAuth0();
  }

  logoutAuth0() {
    let logout_redirect = window.location.origin;
    if(environment.supplierConfig['logout_redirect']) {
      logout_redirect = environment.supplierConfig['logout_redirect'];
    }
    // Ensure Auth0 client instance exists
    return new Promise((resolve, reject) => {
      return this.auth0Client$.subscribe((client: Auth0Client) => {
        // Call method to log out
        resolve(client.logout({
          client_id: environment.auth0.clientId,
          returnTo: logout_redirect
        }));
      });
    });
  }

  getTokenSilently$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  getIdTokenClaims$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getIdTokenClaims(options)))
    );
  }

  public logoutFromMedshorts() {
    // Server request for terminating the session
    return this.http.get(`${environment.api_url_v1}/services/user/auth/logout`);
  }

  public clearStoragesAndSetTheme(){
    // save theme selection
    const configs = this.appInfo.configs;
    // Remove all from local and session storage
    localStorage.clear();
    sessionStorage.clear();
    // restore theme value
    this.appInfo.configs = configs;
  }

  public deleteIsAuthedCookie() {
    document.cookie = 'auth0.is.authenticated=false; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
  }

  public getCookie(name) {
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=')});
  }

  public updateUserSubject(user) {
    this.userProfileSubject$.next(user);
  }
}
