<div *ngIf="mode==='profile'">
  <div *ngIf="primaryFacility" class="container-fluid">
    <div class="row">
      <div class="col-sm-4">
        <div>
          <p>
            <span>Shopping for:</span>&nbsp;
            <app-ms-tooltip *ngIf="!readOnly" id="phrmcy_sel" width="320" tooltipText="<p>You can change your selected facility here.</p>
                                        <p>This will affect your matching listings, which are based on facility purchase history.</p>
                                        <p>It will also set your delivery address.</p>">

            </app-ms-tooltip>
          </p>
        </div>
      </div>
      <div class="col-sm-8">
        <div *ngIf="canSwitch && facilitiesList?.length; else read_only">
          <dx-select-box class="mb-1" [items]="facilitiesList" displayExpr="name" valueExpr="id"
            placeholder="Select a facility..." [showClearButton]="false" [value]="primaryFacilityId" [disabled]="false"
            (onValueChanged)="onFacilityChanged($event)">
            <div *dxTemplate="let data of 'field'">
              <div class="custom-item">
                <dx-text-box class="product-name" [value]="data.name" [readOnly]="true" [stylingMode]="'outlined'"></dx-text-box>
              </div>
            </div>
            <div *dxTemplate="let data of 'item'">
              {{data.name}} - {{data.dea_number}}
            </div>
          </dx-select-box>
        </div>
        <ng-template #read_only>
          <div>{{primaryFacility.name}}</div>
          <div>DEA#: {{ primaryFacility.dea_number }}</div>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <hr />
    </div>
    <div class="row">
      <div class="col-sm-4">
        <p><span>Shipping To:</span></p>
      </div>
      <div class="col-sm-8">
        <div *ngIf="deliveryAddress">
          {{ deliveryAddress.line_1 | titlecase}}<br />
          <div *ngIf="deliveryAddress.line_2">{{ deliveryAddress.line_2 | titlecase }}</div>
          <div *ngIf="deliveryAddress.line_3">{{ deliveryAddress.line_3 | titlecase }}</div>
          {{ deliveryAddress.city | titlecase }},
          {{ deliveryAddress.state }},
          {{ deliveryAddress.postal_code }}
        </div>
      </div>
    </div>
    <div class="row">
      <hr />
    </div>
    <div class="row">
      <div class="col-sm-4">
        <p><span>Account Number:</span></p>
      </div>
      <div class="col-sm-8">
        <div>
          <p><span>{{company_id}} - {{primaryFacilityId}}</span></p>
        </div>
      </div>

      <div class="row" *ngIf="facilitiesList && !primaryFacility">
        <div class="col-md-12">
          <div class="alert alert-warning align-middle">
            Your <a routerLink="/profile" class="ms-blue"><span tooltip title="Edit Profile or Switch Facility">current facility</span></a>
            is not set.<br />
            Please select one to be able to make purchases.
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!facilitiesList">
        <div class="col-md-12">
          <div class="alert alert-warning align-middle">
            You don't have any facilities set up, so you cannot make purchases or see product matches with your facility
            history. Please contact us at info@medigi.com
            or using the site chat below for help getting set up!
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!isVetted">
        <div class="col-md-12">
          <div class="alert alert-warning align-middle">
            <p>You have not yet been approved as a buyer, so you cannot see pricing yet.</p>
            <p>This process is automatic once we review your documentation - please contact
              us at info@medigi.com or through the site chat if you have any questions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="mode==='header'">
  <div *ngIf="primaryFacility">
    <span *ngIf="canSwitch && facilitiesList?.length; else read_only">
      <div style="display: flex;">
        <div style="flex: 0 0 65%;">
          <dx-select-box class="mb-1" [items]="facilitiesList" displayExpr="name" valueExpr="id"
            placeholder="Select a facility..." [showClearButton]="false" [value]="primaryFacilityId" [disabled]="false"
            (onValueChanged)="onFacilityChanged($event)" width="300">
            <div *dxTemplate="let data of 'field'">
              <div class="custom-item">
                <dx-text-box class="product-name" [value]="data.name" [readOnly]="true" [stylingMode]="'outlined'"></dx-text-box>
              </div>
            </div>
            <div *dxTemplate="let data of 'item'">
              {{data.name}} - {{data.dea_number}}
            </div>
          </dx-select-box>
        </div>
        <div style="flex: 1;">
          <ng-template *ngTemplateOutlet="tplAddressTooltip"></ng-template>
        </div>
      </div>
    </span>

    <ng-template #read_only>
      <div>{{primaryFacility.name}}</div>
      <div>DEA#: {{ primaryFacility.dea_number }}
        <ng-template *ngTemplateOutlet="tplAddressTooltip"></ng-template>
      </div>
    </ng-template>

  </div>
</div>

<div *ngIf="mode==='link'">
  <div *ngIf="primaryFacility">
    <span>
      Shopping for:
      <a (click)="switchFacility()" style="cursor: pointer;">{{primaryFacility.name}}</a>
      <ng-template *ngTemplateOutlet="tplAddressTooltip"></ng-template>
    </span>
  </div>
</div>

<div *ngIf="mode==='topNav'">
  <div *ngIf="primaryFacility" class="d-flex flex-row align-items-center">
    <div class="d-flex flex-row align-items-center">
      <i class="header-icon dx-icon-pharmacies"></i>
      <p class="mb-0 mr-2">Ordering for: </p>
    </div>
    <div class="fake-dropdown-container" (click)="openFacilityDialog()">
      <div class="fake-dropdown-facility-name" title="Change Facility">
        <p class="mb-0">{{primaryFacility.name}}</p>
      </div>
      <div class="fake-dropdown-button">
        <i class="dx-icon-chevrondown"></i>
      </div>
    </div>
  </div>
</div>

<div id="new-theme" *ngIf="showFacilityInfo">
  <div class="facility-panel">
    <div class="top-section">
      <div class="section-row">
        <div class="mb-3 ml-5 bold-font">SHOPPING FOR: </div>
        <div class="mb-3 mr-5">
          <dx-select-box class="mb-1" [items]="facilitiesList" displayExpr="name" valueExpr="id"
            placeholder="Select a facility..." [showClearButton]="false" [value]="primaryFacilityId" [disabled]="!canSwitch"
            (onValueChanged)="onFacilityChanged($event)" width="225" [stylingMode]="'underlined'">
            <dxo-drop-down-options [width]="'500'"></dxo-drop-down-options>
            <div *dxTemplate="let data of 'field'">
              <div class="custom-item">
                <dx-text-box class="product-name" [value]="data.name" [readOnly]="true" [stylingMode]="'outlined'"></dx-text-box>
              </div>
            </div>
            <div *dxTemplate="let data of 'item'">
              {{data.name}} - {{data.dea_number}}
            </div>
          </dx-select-box>
        </div>
      </div>
      <div class="section-row">
        <div class="mb-3 ml-5 bold-font">SHIPPING TO: </div>
        <div class="mb-3 mr-5">
          <div *ngIf="deliveryAddress">
            {{ deliveryAddress.line_1 | titlecase}}<br />
            <div *ngIf="deliveryAddress.line_2">{{ deliveryAddress.line_2 | titlecase }}</div>
            <div *ngIf="deliveryAddress.line_3">{{ deliveryAddress.line_3 | titlecase }}</div>
            {{ deliveryAddress.city | titlecase }},
            {{ deliveryAddress.state }},
            {{ deliveryAddress.postal_code }}
          </div>
        </div>
      </div>
      <div class="section-row">
        <div class="mb-3 ml-5 bold-font" style="float: left;">DEA NUMBER: </div>
        <div class="mb-3 mr-5 dea">
          {{ primaryFacility.dea_number }}
        </div>
      </div>
    </div>
    <div class="bottom-section mr-5 ml-5">
      <div *ngIf="budget.amount && !hydraEnabled">
        <div class="mb-2" *ngIf="budget.amount && budgetEndDate < today">
          <span class="dx-font-xs text-danger"> {{budget.target == 'company' ? 'System': 'Facility'}} Budget Expired!
          </span>
        </div>
        <div class="mb-3">
          <div class="bold-font">FACILITY BUDGET SPENT: {{budgetPercentage}}%</div>
          <div>
            <div class="mb-2" *ngIf="savings.amount && savingsEndDate < today">
              <span class="dx-font-xs text-danger"> {{savings.target == 'company' ? 'System': 'Facility'}} Savings
                Expired! </span>
            </div>
            <div class="mb-2" *ngIf="budget.amount || savings.amount">
              <div *ngIf="budget.amount">
                <span class="dx-font-xs text-danger" *ngIf="budgetPercentage > 100"> {{budget.target == 'company' ?
                  'System': 'Facility'}} Budget Exceeded! </span>
                <span class="dx-font-xs text-warning" *ngIf="budgetPercentage == 100"> {{budget.target == 'company' ?
                  'System': 'Facility'}} Budget Met! </span>
                <dx-bullet class="bullet" color="#73ACF0" targetColor="#FFFFFF" [startScaleValue]="0"
                  [endScaleValue]="budget.amount" [value]="budgetAmountUsed" [target]="budget.amount">
                  <dxo-tooltip [zIndex]="1000" [customizeTooltip]="customizeBudgetTooltip"></dxo-tooltip>
                </dx-bullet>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="savings.amount && !hydraEnabled" class="mb-3">
        <div class="bold-font">PHAMRMACY SAVINGS EARNED: {{savingsPercentage}}%</div>
        <div>
          <div class="mb-2" *ngIf="savings.amount">
            <span class="dx-font-xs text-success" *ngIf="savingsPercentage > 100"> {{savings.target == 'company' ?
              'System': 'Facility'}} Savings Goal Exceeded! </span>
            <span class="dx-font-xs text-info" *ngIf="savingsPercentage == 100"> {{savings.target == 'company' ?
              'System': 'Facility'}} Savings Met! </span>
            <dx-bullet class="bullet" color="#73ACF0" targetColor="#FFFFFF" [startScaleValue]="0"
              [endScaleValue]="savings.amount" [value]="savingsAmountUsed" [target]="savings.amount">
              <dxo-tooltip [zIndex]="1000" [customizeTooltip]="customizeSavingsTooltip"></dxo-tooltip>
            </dx-bullet>
          </div>
        </div>
      </div>
      <div *ngIf="formattedBudgetStart && !hydraEnabled" class="bold-font mb-3">
        PERIOD: {{formattedBudgetStart}} - {{formattedBudgetEnd}}
      </div>
      <div *ngIf="!hydraEnabled" class="d-flex align-items-center justify-content-between mb-3">
        <dx-button *ngIf="canManageBudget" type="default" stylingMode="outlined" [text]="'Manage Budget'"
          (onClick)="goToBudgetManager()"></dx-button>
      </div>
    </div>
  </div>
</div>

<app-overlay *ngIf="showFacilityInfo" (closePopup)="showFacilityInfo = !showFacilityInfo"></app-overlay>

  <ng-template #tplAddressTooltip>
    <app-ms-tooltip *ngIf="deliveryAddress" id="phrmcy_sel_addr" width="320"
      tooltipText="<div>DEA#: {{ primaryFacility.dea_number }}</div> 
                  <div>{{ deliveryAddress.line_1 | titlecase}}</div>
                  <div>{{ deliveryAddress.line_2 | titlecase }}</div>
                  <div>{{ deliveryAddress.line_3 | titlecase }}</div>
                  <div>{{ deliveryAddress.city | titlecase }}, {{ deliveryAddress.state }}, {{ deliveryAddress.postal_code }}</div>">
    </app-ms-tooltip>
  </ng-template>