import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingNotesComponent } from './listing-notes.component';
import { DxButtonModule, DxLoadIndicatorModule, DxRadioGroupModule, DxTextAreaModule, DxTextBoxModule } from 'devextreme-angular';
import { MedigiPopupModule } from '../medigi-popup/medigi-popup.module';

@NgModule({
  imports: [
    CommonModule,
    DxRadioGroupModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    MedigiPopupModule
  ],
  declarations: [ListingNotesComponent],
  exports: [ListingNotesComponent]
})
export class ListingNotesModule { }
