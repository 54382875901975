import { CommonModule } from '@angular/common';
import { Component, NgModule, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxPopupModule, DxTooltipModule } from 'devextreme-angular';
import { DxTreeViewModule, DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import * as events from 'devextreme/events';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { PharmacyService } from 'src/app/shared/services/user/pharmacy.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { OrdersService } from 'src/app/shared/services/order/orders.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserProfileModule } from 'src/app/shared/components/user-profile/user-profile.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy {

  _compactMode = false;

  @ViewChild(DxTreeViewComponent, { static: true }) menu: DxTreeViewComponent;
  @Output() selectedItemChanged = new EventEmitter<string>();
  @Output() openMenu = new EventEmitter<any>();
  @Output() menuToggle = new EventEmitter<boolean>();
  @Input() items: any[];
  @Input() isMedigiAdmin = false;
  @Input() menuToggleEnabled = false;
  @Input()
  set selectedItem(value: string) {
    if (this.menu.instance) {
      this.menu.instance.selectItem(value);
    }
  }
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;
    if (val && this.menu.instance) {
      this.menu.instance.collapseAll();
    }
  }

  user: any;
  tooltipContent: string;
  target: any;
  isVisible = false;
  today: Date = new Date();
  subscriptions: Array<Subscription> = [];
  isProfilePopupOpen: boolean = false;
  largeLogo = ""
  smallLogo = ""
  logoAltText = "";
  supplierConfig = environment.supplierConfig;

  constructor(private elementRef: ElementRef,
              public sessionService: SessionService,
              public pharmacyService: PharmacyService,
              private router: Router,
              private userService: UserService){}

  ngOnInit() {
    this.getNavLogo();
    this.getUserInfo();
  }

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e) => {
      this.openMenu.next(e);
    });
  }

  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
    this.removeEventListeners();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onContentReady(event) {
    this.updateSelection(event);
    this.addEventListeners();
  }

  getUserInfo() {
    this.userService.getMe().subscribe(
      result => this.user = result.data);
  }

  updateSelection(event) {
    const nodeClass = 'dx-treeview-node';
    const selectedClass = 'dx-state-selected';
    const leafNodeClass = 'dx-treeview-node-is-leaf';
    const element: HTMLElement = event.element;

    const rootNodes = element.querySelectorAll(`.${nodeClass}:not(.${leafNodeClass})`);
    Array.from(rootNodes).forEach(node => {
      node.classList.remove(selectedClass);
    });

    let selectedNode = element.querySelector(`.${nodeClass}.${selectedClass}`);
    while (selectedNode && selectedNode.parentElement) {
      if (selectedNode.classList.contains(nodeClass)) {
          selectedNode.classList.add(selectedClass);
      }

      selectedNode = selectedNode.parentElement;
    }
  }

  onItemClick(event) {
    this.selectedItemChanged.emit(event);
  }

  onMenuInitialized(event) {
    event.component.option('deferRendering', false);
  }

  removeEventListeners() {
    const elements = this.elementRef.nativeElement.querySelectorAll('.dx-treeview-item');
    elements.forEach(el => {
      el.removeEventListener('mouseenter', this.setTooltip);
      el.removeEventListener('mouseleave', this.hideTooltip);
    });
  }

  addEventListeners() {
    const elements = this.elementRef.nativeElement.querySelectorAll('.dx-icon');
    elements.forEach((el, index) => {
      el.addEventListener('mouseenter', this.setTooltip.bind(this, index));
      el.addEventListener('mouseleave', this.hideTooltip.bind(this));
    });
  }

  setTooltip(index, e) {
    this.tooltipContent = this.items[index].text;
    this.target = e.target;
    this.isVisible = (typeof this.items[index].text !== 'undefined');
  }

  hideTooltip() {
    this.isVisible = false;
  }

  openUserProfile(){
    this.isProfilePopupOpen = true;
  }

  closingPopup() {
    this.isProfilePopupOpen = false;
  }

  toggleMenu = () => { this.menuToggle.emit(); };
  
  getNavLogo() {
    if (!environment.hydraEnabled || !this.supplierConfig.logo) {
      this.setDefaultLogos();
    } else {
      try {
        this.smallLogo = this.supplierConfig.logo.small;
        this.largeLogo = this.supplierConfig.logo.large;
        this.logoAltText = this.supplierConfig.logo.alt;
      } catch {
        this.setDefaultLogos();
      }
    }
  }

  setDefaultLogos() {
    this.largeLogo = "/assets/img/logos/medigi-logo.png"
    this.smallLogo = "/assets/img/logos/medigi-blue-icon.svg"
    this.logoAltText = "Medigi Logo";
  }
}


@NgModule({
  imports: [ CommonModule,
             BrowserModule,
             DxTreeViewModule, 
             DxTooltipModule,
             UserProfileModule,
            DxPopupModule ],
  declarations: [ SideNavigationMenuComponent ],
  exports: [ SideNavigationMenuComponent ]
})
export class SideNavigationMenuModule { }
