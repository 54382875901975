import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalibrationFileUploadComponent } from './calibration-file-upload.component';
import { DocumentUploadModule } from '../document-upload/document-upload.component';

@NgModule({
  imports: [
    CommonModule,
    DocumentUploadModule
  ],
  exports: [CalibrationFileUploadComponent],
  declarations: [CalibrationFileUploadComponent]
})
export class CalibrationFileUploadModule { }
