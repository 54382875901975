import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// tslint:disable-next-line: ban-types
declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    if (environment.production) {
      gtag('event', eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue
      });
    }
  }

  public search(searchConfigs) {
    if (environment.production) {
      let searchedCatalogs = [];
      
      for (let catalogType in searchConfigs.catalogTypes) {
        if (searchConfigs.catalogTypes[catalogType] === true) {
          searchedCatalogs.push(catalogType);
        }
      }
      gtag('event', 'Catalogs Searched',  {
        event_category: searchedCatalogs
      });

      for (let listingTag in searchConfigs.listingTags) {
        if (searchConfigs.listingTags[listingTag] === true) {
          gtag('event', 'Quick Searched', {
            event_category: listingTag
          });
        }
       }

      if (searchConfigs.searchText) {
        gtag('event', 'search', {
          search_term: searchConfigs.searchText
        });
      }

      if (searchConfigs.sellerId) {
        gtag('event', 'Company Searched', {
          event_category: searchConfigs.sellerId
        });
      }

      gtag('event', 'Packaging Searched', {
        event_category: searchConfigs.packTypes
      });

      gtag('event', 'Expiration Rage Searched', {
        event_category: searchConfigs.range
      });
    }
  }

  public viewItem(item) {
    if (environment.production) {
      gtag('event', 'view_item', {
        currency: 'USD',
        value: item.price,
        item: this.cartItemsToGA(item)
      });
    }
  }

  public addToCart(item, value) {
    if (environment.production) {
      gtag('event', 'add_to_cart', {
        currency: 'USD',
        value: value,
        item: this.cartItemsToGA(item)
      });
    }
  }

  public beginCheckout(cart, value){
    if (environment.production) {
    gtag('event', 'begin_checkout', {
      currency: 'USD', 
      value: value,
      items: this.cartItemsToGA(cart)
    });
    }
  }

  public purchase(cart, totalPrice, shippingPrice, ogId) {
    if (environment.production) {
      gtag('event', 'purchase', {
        transaction_id: ogId,
        currency: 'USD',
        shipping: shippingPrice,
        value: totalPrice,
        items: this.cartItemsToGA(cart)
      });
    }
  }

  cartItemsToGA(lines: any[]): Item[] {
    const cart: any = [lines];
    const items: Item[] = [];
    cart.forEach(line => {
      const item: any = {
        index: line.id,
        name: line.name,
        brand: line.company_name,
        item_category: line?.catalog_type,
        price: line.totalPrice,
        quantity: line.itemTotalQuantity
      };
      items.push(item);
    });
    return items;
  }
}

export class Item {
  index?: string; // listing id
  name?: string; // listing name
  item_brand?: string; // company
  price?: number; // single listing price
  item_category?: string; // catalog type
  quantity?: number; // quantity
}

