<dx-popup *ngIf="mode==='popup' && show"
          class="popup"
          position="center"
          [showTitle]="true"  
          [dragEnabled]="true"
          [title]="title"
          [showCloseButton]="true" 
          [hideOnOutsideClick]="false"
          [height]="rfpHeight"
          [width]="1100"
          [height]="950"
          [(visible)]="show" [resizeEnabled]="false" (onHiding)="popupClose()">
    <dx-scroll-view>
      <app-rfp-form 
        [rfp]="rfp" 
        [ndc]="ndc" 
        [companyId]="companyId" 
        [isBuyer]="isBuyer" 
        [isSeller]="isSeller" 
        [ndcItem]="ndcItem"
        [catalogType]="catalogType"
        [resubmit]="resubmit"
        (onRfpCancel)="popupClose()"
        (output)="onUpdate($event)" 
        (onNdcSet)="onNdcSet($event)" 
        (onContentChange)="onContentChange()">
      </app-rfp-form>
    </dx-scroll-view>
</dx-popup>

<app-rfp-form 
  *ngIf="mode==='form'" 
  [rfp]="rfp"
  [ndc]="ndc"
  [companyId]="companyId" 
  [isBuyer]="isBuyer"
  [isSeller]="isSeller"
  [ndcItem]="ndcItem"
  [catalogType]="catalogType"
  [resubmit]="resubmit"
  (output)="onUpdate($event)"
  (onNdcSet)="onNdcSet($event)" 
  (onContentChange)="onContentChange()">
</app-rfp-form>