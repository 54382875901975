import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, NgModule, ViewChild } from '@angular/core';
import { CartService } from 'src/app/shared/services/cart/cart.service';
import { Subject, Subscription } from 'rxjs';
import * as _ from 'underscore';
import { takeUntil } from 'rxjs/operators';
import { DxNumberBoxModule, DxButtonModule, DxSelectBoxModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxValidationSummaryModule, DxValidatorModule, DxLoadIndicatorModule, DxCheckBoxModule, DxTabsModule, DxDataGridComponent, DxLoadPanelModule, DxGalleryModule } from 'devextreme-angular';
import { CommonModule, Location } from '@angular/common';
import { NotificationService } from '../../services/notification.service';
import { CatalogService } from '../../services/listing/catalog.service';
import { ListingDetailsModule } from '../listing-details/listing-details.component';
import { PipeModule } from 'src/app/pipe.module';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { MsTooltipModule } from '../ms-tooltip/ms-tooltip.component';
import { UserService } from '../../services/user/user.service';
import { MedShortsAlternativesGridModule } from '../medshorts-alternatives-grid/medshorts-alternative-grid.component';
import { OfferModule } from '../offers/offer.component';
import { CsosSignupComponent } from './csos-signup/csos-signup.component';
import { StatisticsService } from '../../services/analytica/statistics.service';
import { StatDataTileModule } from '../stat-data-tile/stat-data-tile.module';
import { SpendsTypeComparedModule } from '../spends-type-compared/spends-type-compared.module';
import { QtyPerMonthModule } from '../qty-per-month/qty-per-month.component';
import { PriceFlagModule } from '../price-flag/price-flag.component';
import { Cart } from '../../models/cart';
import { PurchasingHistoryModule } from '../purchasing-history/purchasing-history.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { MomentModule } from 'ngx-moment';
import { RfpModule } from '../rfp/rfp.module';
import { SellerCompanyLogoModule } from "../seller-company-logo/seller-company-logo.module";
import { ListingWishlistToggleModule } from '../listing-wishlist-toggle/listing-wishlist-toggle.component';
import { SessionService } from '../../services/session.service';
import { ListingNotesModule } from '../listing-notes/listing-notes.module';
import * as moment from 'moment';
import { copyToClipboard } from '../../helpers/clipboard';
import { environment } from 'src/environments/environment';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})

export class AddToCartComponent implements OnInit, OnDestroy {

  @Input() listingId: any;
  @Input() inputListing: any = false;
  @Input() currentListing: any;
  @Input() mode;
  @Input() showStats;
  @Input() isPopup;
  @Input() showMedShortsAlternatives: boolean = false;
  @Input() catalogType: any;
  @Output() update = new EventEmitter<boolean>();
  @Output() pfChanged = new EventEmitter<boolean>();
  @ViewChild('addToCartGrid', { static: false }) dataGrid: DxDataGridComponent;

  subscriptions: Subscription = new Subscription();

  csosApproved = false;
  csosRequested = false;

  atcBtnDisabled = true;
  amuBtnDisabled = false;
  caseQty;
  packQty;
  minQty = 1;
  firstPrice: any;
  firstPriceSavingsPerPack: any;
  total = 0.0;
  subTotal = 0.0;
  shipping = 0.0;
  cart;
  errorPopupVisible = false;
  min = false;
  freeShippingDescription = '';
  freeShippingThreshold = 0;
  errorPopupMessage: string;
  paramsStats: any;
  companySubTotal: any = 0;
  companyAddTotal: any;
  cartRefreshing = false;
  cartLoading = true;
  userEmail: string;
  hasMultipleLots: boolean = false;
  numberofLots = 0;
  areAllLotsShown = false;
  inventoryMap = {};
  showMoreLotsBtnIcon = 'chevrondown';
  showMoreLotsBtnText = '';
  qtyErrorMessage = '';
  totalPriceThreshold = ''
  caseOrCases = '';
  packOrPacks = '';

  isListingSoftBlocked: boolean = false;
  agreeToPurchaseSoftBlock = false;
  agreeToSellerTerms = false;

  statistics: any = this.getDefaultSpends();
  gpo_phs_wac = '0% | 0% | 0%';
  savingsDiff = 0;
  aac = 0;
  minimumPackPurchase = 0;
  minimumCasePurchase = 0;
  savingsTooltip: any;
  bucketsStats: any[] = [];

  firstInvoiceDate: any;
  lastInvoiceDate: any;
  suggestedPurchaseQty = 0;
  suggestedPurchaseSavings = 0;

  offerPopupVisible: boolean;
  priceFlagPopupVisible: boolean = false;
  showThings = false;
  selectedTabIndex = 0;
  facilityVetted = false;
  companyHasTerms = false;

  alternatives: any = null;
  alternativesLoaded = false;

  hydraEnabled = environment.hydraEnabled;
  client: any;
  HydraRFPEnabled = false;
  HydraBidEnabled = false;

  tabs = [
    {
      id: 0,
      text: 'Savings',
      icon: 'money',
      showTab: [true, false, false]
    },
    {
      id: 1,
      text: 'Purchase Quantity Split',
      icon: 'chart',
      showTab: [false, true, false]
    },
    {
      id: 2,
      text: 'Purchasing History',
      icon: 'formula',
      showTab: [false, false, true]
    }
  ];

  showTab = [true, false, false];
  invoices: any;
  math = Math;
  priceSignalTooltip: any;
  isSeller = false;
  isBuyer = false;
  ndcItem: any;
  rfpVisible = false;

  listingLoadError = false;
  primaryFacility: any;
  userId = '';
  dimgpupVisible = false;
  private onDestroy$: Subject<void> = new Subject<void>();

  addBtnClicked = false;

  constructor(private cartService: CartService,
              private userService: UserService,
              public location: Location,
              private catalogService: CatalogService,
              private statisticsService: StatisticsService,
              private notifyService: NotificationService,
              private sessionService: SessionService,
              private googleAnalyticsService: GoogleAnalyticsService) {}

  async ngOnInit() {
    this.setSwitchFacilityTrigger();
    await this.getInitialData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setSwitchFacilityTrigger() {
    this.sessionService.switchFacilityTrigger.subscribe(() => this.getInitialData());
  }

  _copyToClipboard(listing_id) {
    copyToClipboard(`${environment.url}/detailed/${listing_id}`);
  };

  private async getInitialData() {
    this.listingLoadError = false;
    this.cartLoading = true;
    await this.prepareData();
    if (this.listingLoadError) {
      return;
    }
    await this.getMe();
    this.cartRefreshingCheck();
    this.calculateTotals();
    this.calculateSavings();
    this.checkFacilityAndCompany();
    this.cartLoading = false;
    this.googleAnalyticsService.viewItem(this.currentListing.name);
    if(window.location.href.indexOf("ref=LS") > -1 || window.location.href.indexOf("ref%3DLS") > -1){
      this.googleAnalyticsService.eventEmitter(`User: ${this.userId.toString()} Facility: ${this.primaryFacility.id.toString()}`, 'Logic Stream Referral');
    }
    if (this.hydraEnabled) {
      this.client = await this.userService._getMsClient();
      this.HydraRFPEnabled = this.client?.hydra_config?.rfp_enabled && this.currentListing?.catalog_type != 'medshorts' && this.currentListing.dea!='2' ;
      this.HydraBidEnabled = this.client?.hydra_config?.bid_enabled;
    }

  }

  async prepareData() {
    if(this.inputListing) {
      // Create a deep copy of the input listing, so we don't mess with the copy in the catalog array
      this._prepareData(JSON.parse(JSON.stringify(this.inputListing)));
    }
    else {
      const result = await this.catalogService.getListing(this.listingId).toPromise().catch((e) => {
        console.error(e);
        this.listingLoadError = true
      });
      if (this.listingLoadError) {
        return;
      }
      this._prepareData(result.data);
    }
  }

  onRowUpdated() {
    this.calculateTotals();
    this.setAddBtnState();
    this.calcCompanyAddTotal();
    this.checkForQtyError();
  }

  mapShipping() {
    this.currentListing.pricing.prices.forEach(item => {
      const shipping_layer = item?.stack.find(x => x.name === 'free_shipping');
      if (shipping_layer) {
        this.freeShippingDescription = shipping_layer.description;
        this.freeShippingThreshold = shipping_layer?.context?.free_shipping_threshold;
      };
    });
  }

  checkFacilityAndCompany() {
    if (!this.facilityVetted && this.isBuyer) {
      this.notifyService.showNotify('The facility you have selected is not vetted, adding to cart disabled.', 'error');
    }

    if (!this.companyHasTerms && this.isBuyer && !this.hydraEnabled) {
      this.notifyService.showNotify('Your company does not have terms, prices hidden until terms are set.', 'error');
    }
  }

  setAddBtnState() {
    let caseLimitationsNotReached = false;
    if (this.packQty===0) {
      this.atcBtnDisabled = false || this.mode === 'add';
      return;
    }
    if (this.currentListing.case_lock) {
      if (this.currentListing.stock >= this.currentListing.case_size && this.packQty < this.currentListing.case_size) {
        caseLimitationsNotReached = true;
      }
      if (this.currentListing.stock < this.currentListing.case_size && this.packQty < this.currentListing.stock) {
        caseLimitationsNotReached = true;
      }
    }
    this.atcBtnDisabled = (this.packQty < this.currentListing.min_packs && this.currentListing.stock > this.currentListing.min_packs)
                          || (this.currentListing.case_size > 1 && !this.caseQty)
                          || caseLimitationsNotReached
                          || !this.currentListing.pricing.prices.find(x=> x.quantity>0)
                          || !this.facilityVetted
                          || (!this.companyHasTerms && !this.hydraEnabled);
  }

  selectAlternativeListing($event) {
    let listing = $event.data

    // Add the current listing to the alternatives array.
    this.alternatives.push(this.currentListing)

    // Set the current listing to the passed in row and prepare data
    this.currentListing = listing;
    this.updateListingData();

    if(this.cart) {
      // No need to refetch cart, this will update from the subscription
      this.onGetCartSuccess(this.cart);
    }

    // Remove the selected listing and resort the array
    this.alternatives = this.alternatives.filter(x => x.id !== listing.id);
    this.alternatives = this.alternatives.sort((n1, n2) => {
      if (n1.pricing.price > n2.pricing.price) return 1;
      if (n1.pricing.price < n2.pricing.price) return -1;
      return 0;
    });
  }

  cartRefreshingCheck() {
    const s = this.cartService.cartRefreshing.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.cartRefreshing = result;
      if (!this.cartRefreshing && !this.addBtnClicked) {
        this.prepareData();
      }
    });
    this.subscriptions.add(s)
  }

  _prepareData(result) {
    this.currentListing = result;
    this.currentListing.pricing.prices.forEach(price => {
      price.quantity = 0;
    });
    this.numberofLots = this.currentListing.pricing.prices.length;
    if (!this.areAllLotsShown){
      this.showMoreLotsBtnText = 'See ' + this.currentListing.pricing.prices.length + ' total lots containing ' + this.currentListing.stock.toLocaleString() + ' stock';
      while (this.currentListing.pricing.prices.length > 3){
        this.currentListing.pricing.prices.splice(this.currentListing.pricing.prices.length - 1);
      }
    }
    this.currentListing?.pricing?.prices.forEach(price => {
      if(this.currentListing?.case_size > 1) {
        price.case_quantity = this.inventoryMap[price.id] || 0;
      } else {
        price.quantity = this.inventoryMap[price.id] || 0;
      }
    });
    this.fetchAlternatives();
    this.updateListingData();
    this.getCartAndSpendsByType();
    this.checkForQtyError();
  }

  showMoreLots() {
    if(!this.areAllLotsShown) {
      this.areAllLotsShown = true;
      this.showMoreLotsBtnIcon = 'chevronup';
      this.showMoreLotsBtnText = 'Show Fewer Lots';
      
    }
    else if (this.areAllLotsShown) {
      this.areAllLotsShown = false;
      this.showMoreLotsBtnIcon = 'chevrondown';
      this.showMoreLotsBtnText = 'See ' + this.currentListing.pricing.prices.length + ' total lots containg ' + this.currentListing.stock.toLocaleString() + ' stock'
    }
    this.prepareData();
  }

  fetchAlternatives() {
    if (this.currentListing.alternatives) {
      this.catalogService.get({gcns: this.currentListing.gcn}).toPromise()
          .then(result => {
            this.alternativesLoaded = true;
            let data: any[] = result.data;
            //data.forEach(x => x = this.catalogService.reMapListing(x, this.currentListing));
            // Remove the current listing
            data = data.filter(x => x.id !== this.currentListing.id);
            // Set all initial quantities to 0
            data = data.map(x => {
              x.pricing.prices.forEach(price => {price.quantity = 0;});
              return x;
            })
            // Remove those that don't match pack size for now
            data = data.filter(x => x.package_size === this.currentListing.package_size);
            data = data.sort((n1, n2) => {
              if (n1.pricing.price > n2.pricing.price) return 1;
              if (n1.pricing.price < n2.pricing.price) return -1;
              return 0;
            });
            this.alternatives = data;
          })
          .catch((e) => {
            console.error(e);
            throw 'Data Loading Error';
          });

    }
  }

  updateListingData() {
    this.minimumPackPurchase = this.currentListing.min_packs;
    if (this.currentListing.case_size) {
      this.minimumPackPurchase = Math.max(this.currentListing.case_size, this.currentListing.min_packs);
    }

    this.aac = this.currentListing.pricing.amu?.avg_aac_per_pack || 0;
    this.savingsTooltip = {id: 'aac', tooltipText: `Savings calculated based on your (EST Wholesale - the Per Pack Price) * your AMU packs. <br>If this is inaccurate please update your EST Wholesale for this product using the price flags`};
    this.paramsStats = { ndc: this.currentListing.ndc_number, unit_size: this.currentListing.package_size, gpi: this.currentListing.gcn, type: 'gpi', annual: true, package_qty: this.currentListing.csp };
    this.savingsDiff = this.aac ? this.currentListing.min_packs * (this.aac - this.currentListing.pricing.price_meta.price) : 0;
    if (this.currentListing.pricing.prices.length > 1) {
      this.hasMultipleLots = true;
    }
  }

  checkForQtyError() {
    this.qtyErrorMessage = '';
    this.totalPriceThreshold = '';
    if(this.packQty === 0) {
      return;
    }

    this.currentListing.soft_block || this.agreeToPurchaseSoftBlock ? this.isListingSoftBlocked = true : this.isListingSoftBlocked = false;
    this.currentListing.case_size > 1 ? this.minimumCasePurchase = Math.ceil((this.currentListing.min_packs / this.currentListing.case_size)) : this.minimumCasePurchase = 0;

    this.currentListing.min_packs > 1 ? this.packOrPacks = ' packs' : this.packOrPacks = ' pack';
    this.minimumCasePurchase > 1 ? this.caseOrCases = ' cases' : this.caseOrCases = ' case';
  
    if (!this.isListingSoftBlocked && this.packQty < this.currentListing.min_packs && this.currentListing.min_packs <= this.currentListing.stock) {
      if (this.minimumCasePurchase >= 1) {
        this.qtyErrorMessage = 'Listing Minimum: ' + this.minimumCasePurchase + this.caseOrCases;
      }
      else if (this.minimumPackPurchase >= 1){
        this.qtyErrorMessage = 'Listing Minimum: ' + this.currentListing.min_packs + this.packOrPacks;
      }
    }

    if (!this.isListingSoftBlocked && this.companyAddTotal > 0) {
      this.totalPriceThreshold = 'Seller Minimum Order Total: $' + this.currentListing.company_minimum_order_total + ' from ' + this.currentListing.company_name;
    }
  }

  getCartAndSpendsByType() {
    const s = this.cartService.get(this.currentListing.is_c2).pipe(takeUntil(this.onDestroy$))
    .subscribe(result => this.onGetCartSuccess(result));
    this.subscriptions.add(s);
  }

  private onGetCartSuccess(result: Cart) {
    this.updateCartData(result);
    if (this.showStats && !this.hydraEnabled) {
      this.getHistory();
      this.getSpendsByType();
    }
    if (this.currentListing.pricing.amu?.user_id) {
      this.getPriceSignalTooltip();
    }
  }

  updateCartData(data) {
    if ( data != null ) {
      this.cart = data;
      const itemInCart = this.cartService.getId(this.currentListing.id, this.currentListing.is_c2);
      if (itemInCart) {
        this.mode = 'edit';
        // // this is the prior pricing work by travis
        // this.currentListing.pricing.prices.forEach(p => {
        //     itemInCart.pricing.prices.forEach(p2 => {
        //         if(p.id === p2.id) {
        //             p.quantity = p2.quantity;
        //         }
        //     });
        // });
        this.currentListing.pricing.prices.forEach(price => {
          let item = itemInCart.pricing.prices.find(x => x.id === price.id);
            if (item) {
              price.quantity = item.quantity;
              if(this.currentListing?.case_size > 1) {
                price.case_quantity = item.quantity/this.currentListing.case_size;
              }
            }
          }
        );
      } else {
        this.mode = 'add';
      }
      this.calcCompanyTotals();
    }
  }

  private calcCompanyTotals() {
    // Reset the company totals before recalculating
    this.companyAddTotal = 0;
    this.companySubTotal = 0;
    this.subTotal = 0;
    this.calculateTotals();
    this.calcCompanySubTotal();
    this.mapShipping();
    this.setAddBtnState();
  }

  calcCompanySubTotal() {
    // Only get the ones that are not the current item, since that will have its own subtotal
    let currentCompanyItems = this.cart.items.filter(x => (x.company_id === this.currentListing.company_id && x.id != this.currentListing.id));
    currentCompanyItems.forEach(listing => this.updateCompanySubTotalBsedOnListing(listing));
    this.calcCompanyAddTotal();
  }

  private updateCompanySubTotalBsedOnListing(listing: any) {
    listing.pricing.prices.forEach(price => {
      if (price.quantity) {
        this.companySubTotal += price.quantity * price.price;
      }
    });
  }

  calcCompanyAddTotal() {
    this.companyAddTotal = this.currentListing.company_minimum_order_total - this.companySubTotal - this.subTotal;
  }

  onAddClick() {
    this.addBtnClicked = true;
    this.addItem();
    this.googleAnalyticsService.addToCart(this.currentListing, 'add_to_cart');
  }

  addItem() {
    this.cartService.addItem(this.currentListing).then(result => {
      let data = this.currentListing.is_c2 ? result.cart_c2 : result.cart;
      if (data.errors.length) {
        this.errorPopupVisible = data.errors;
        this.errorPopupMessage = data.errors[0];
        this.currentListing.pricing.prices?.forEach(price => {
          price.quantity = 0;
          price.case_quantity = 0;
        });
        this.mode = 'add';
      } else {
        this.update.emit(true);
        this.updateCartData(data);
      }
      this.addBtnClicked = false;
    });
  }

  onCancelClick() {
    this.update.emit(false);
  }

  qtyAmountChange = (e, currentRowData) => {
    let currentRow = this.currentListing.pricing.prices.find(x => x.id === currentRowData.id);

    currentRow.quantity = e.value ? e.value : 0;
    this.inventoryMap[currentRowData.id] = currentRow.quantity;

    this.calculateSavings();
    this.onRowUpdated();
  }

  caseQtyAmountChange = (e, currentRowData) => {
    // TODO: stock case issue
    const stockUnderCaseSize = currentRowData.stock < this.currentListing.case_size;

    let currentRow = this.currentListing.pricing.prices.find(x => x.id === currentRowData.id);
    currentRow.case_quantity = e.value;
    if (stockUnderCaseSize) {
      currentRow.quantity = currentRow.stock;
    } else {
      currentRow.quantity = currentRow.case_quantity * this.currentListing.case_size;
    }
    this.inventoryMap[currentRowData.id] = currentRow.case_quantity;

    this.calculateSavings();
    this.onRowUpdated();
  }

  removeLotQty(currentRowData) {
    let result = confirm(`Are you sure you want to remove <b>${this.currentListing.name}</b> from your cart?`, 'Confirm Removal');
    result.then((dialogResult) => { if (dialogResult) {
      this.qtyAmountChange({value: 0}, currentRowData);
      this.addItem();
     }});
  }

  calculateSavings() {
    let { totalQty, totalCost } = this.calculateAvgSavings();
    this.calculateBucketSavings(totalQty, totalCost);
  }

  calculateAvgSavings() {
    this.savingsDiff = 0;
    let totalCost = 0;
    let totalQty = 0;
    this.currentListing.pricing.prices.forEach(p => {
      totalQty += p.quantity;
      totalCost += p.quantity * p.price;
    });
    this.firstPrice = this.currentListing.pricing.prices[0];
    this.firstPriceSavingsPerPack = this.aac ? this.aac - this.firstPrice.price : 0;
    this.savingsDiff = this.aac ? this.aac * totalQty - totalCost : 0;
    return { totalQty, totalCost };
  }

  calculateBucketSavings(totalQty: number, totalCost: number) {
    const amu = this.currentListing.pricing.amu;
    this.bucketsStats = [
      {
        account_type: 'GPO',
        aac: amu?.gpo_savings?.avg_unit_aac || amu?.gpo_savings?.avg_pack_aac || 0,
        amu: amu?.gpo_amu || 0,
        value: amu?.gpo_savings?.comparison_avg_spend || 0,
        mdgSpends: amu?.gpo_savings?.monthly_avg_spend || 0,
        mdgSavings: amu?.gpo_savings?.monthly_avg_savings || 0,
        pctSpend: amu?.gpo_pct_spend || 0,
        pctAmu: amu?.gpo_amu_pct || 0
      },
      {
        account_type: 'WAC',
        aac: amu?.wac_savings?.avg_unit_aac || amu?.wac_savings?.avg_pack_aac || 0,
        amu: amu?.wac_amu || 0,
        value: amu?.wac_savings?.comparison_avg_spend || 0,
        mdgSpends: amu?.wac_savings?.monthly_avg_spend || 0,
        mdgSavings: amu?.wac_savings?.monthly_avg_savings || 0,
        pctSpend: amu?.wac_pct_spend || 0,
        pctAmu: amu?.wac_amu_pct || 0
      },
      {
        account_type: '340B',
        aac: amu?.three40b_savings?.avg_unit_aac || amu?.three40b_savings?.avg_pack_aac || 0,
        amu: amu?.three40b_amu || 0,
        value: amu?.three40b_savings?.comparison_avg_spend || 0,
        mdgSpends: amu?.three40b_savings?.monthly_avg_spend || 0,
        mdgSavings: amu?.three40b_savings?.monthly_avg_savings || 0,
        pctSpend: amu?.three40b_pct_spend || 0,
        pctAmu: amu?.three40b_amu_pct || 0
      },
      {
        account_type: 'Undefined',
        aac: amu?.undefined_savings?.avg_pack_aac || 0,
        amu: amu?.undefined_amu || 0,
        value: amu?.undefined_savings?.comparison_avg_spend || 0,
        mdgSpends: amu?.undefined_savings?.monthly_avg_spend || 0,
        mdgSavings: amu?.undefined_savings?.monthly_avg_savings || 0,
        pctSpend: amu?.undefined_pct_spend || 0,
        pctAmu: amu?.undefined_amu_pct || 0
      }
    ];

    this.bucketsStats.forEach(x => x.savings = x.aac ? x.aac * totalQty - totalCost : 0);
  }

  calculateBucketSuggestedPurchase() {
    if (this.bucketsStats.length) {
      this.suggestedPurchaseQty = 0;
      this.suggestedPurchaseSavings = 0;
      this.bucketsStats.forEach(x => {
        this.suggestedPurchaseQty = this.suggestedPurchaseQty + (x.savings > 0 ? x.amu : 0);
        this.suggestedPurchaseSavings = this.suggestedPurchaseSavings + (x.savings > 0 ? x.amu * x.aac - x.amu * this.aac : 0);
      });
    }
  }

  calculateTotals() {
    this.setDefaultTotals();

    this.currentListing.pricing.prices.forEach(item => {
      if (item.quantity) {
        this.calculateItemTotals(item);
      }
    });

    this.total = this.shipping + this.subTotal;
    this.currentListing.order_quantity = this.packQty;
  }

  setDefaultTotals() {
    this.total = 0;
    this.packQty = 0;
    this.caseQty = 0;
    this.subTotal = 0.0;
    this.shipping = 0.0;
  }

  calculateItemTotals(item) {
    this.subTotal += item.quantity * item.price;
    this.packQty += item.quantity;
    this.caseQty += item.case_quantity;
    this.shipping += item.shipping_price;
  }

  async getMe() {
    let result = await this.userService.getMe().toPromise();
    this.isSeller = result.data.roles.includes('Seller');
    this.isBuyer = result.data.roles.includes('Buyer');
    this.userId = result.data.user_id;
    this.primaryFacility = result.data.primary_facility;
    this.setCsosList(this.primaryFacility);
    this.userEmail = result.data.email;
    this.facilityVetted = !!result.data.primary_facility.vetted;
    this.companyHasTerms = !!result.data.company?.terms;
    this.setAddBtnState();
  }


  getPriceSignalTooltip() {
    this.userService.getUser(this.currentListing.pricing.amu.user_id).subscribe(
      result =>
        this.priceSignalTooltip = {
          id: 'current_price_signal',
          tooltipText: `AMU updated by ${result.data.first_name} ${result.data.last_name} on ${this.currentListing.pricing.amu.updated_on[0]}`
        }
    );
  }

  setCsosList(facility) {
    this.csosApproved = facility?.csos?.includes(this.currentListing?.company_id);
    this.csosRequested = facility?.csos_requested?.includes(this.currentListing?.company_id);
  }

  getGPW(e) {
    this.gpo_phs_wac = e;
  }

  getSpendsByType() {
    if (!this.bucketsStats.length) {
      this.selectedTabIndex = 0;
      this.showTab = [true, false, false];
    }

    this.suggestedPurchaseQty = 0;
    this.suggestedPurchaseSavings = 0;

    this.suggestedPurchaseToMinimumAdjustments();
    this.calculateSavings();
    this.showThings = true;
  }

  setCurrentRFP(listing) {
    if(this.isSeller) {
      return;
    }
    this.currentListing = listing;
    if (this.currentListing?.catalog_type === '503b') {
      this.ndcItem = this.makeNdcItem(listing);
    }
    this.showRFP({reset: false});
  }

  showRFP = (options) => {
    if(options?.reset) {
      this.currentListing = null;
    }
    this.rfpVisible = true;
  }

  closeRfpPopup() {
    this.rfpVisible = false;
  }

  calcSuggestedPurchaseData() {
    this.bucketsStats.forEach(x => {
      x['savings'] = x.aac ? this.currentListing.min_packs * (x.aac - this.currentListing.pricing.price_meta.price) : 0;
      x['mdgSpends'] = this.currentListing.pricing.price_meta.price*x.amu;
      x['mdgSavings'] = x.aac*x.amu - x['mdgSpends'];
      const price = this.currentListing.pricing.price_meta.price;
      if (x.aac >= price) {
        this.suggestedPurchaseQty += x.amu;
        this.suggestedPurchaseSavings += x.amu * x.aac - x.amu * price;
      }
    });
    this.calcCompanyTotals();
  }

  suggestedPurchaseToMinimumAdjustments() {
    this.suggestedPurchaseQty = Math.max(this.currentListing.min_packs, this.currentListing.case_size, 1);
    this.suggestedPurchaseQty = Math.min(this.currentListing.stock, this.suggestedPurchaseQty);
  }

  getDefaultSpends() {
    return { spendsAnnual: { sum: 0, totalQty: 0 }, spendsMonthly: { sum: 0, totalQty: 0 }, avgUnitsPerWeek: 0 };
  }

  selectTab(e) {
    this.showTab = this.tabs[e.itemIndex].showTab;
  }

  openPriceFlagPopup()  {
    this.priceFlagPopupVisible = true;
  }

  setCurrentOffer(listing) {
    this.currentListing = listing;
    this.offerPopupVisible = true;
  }

  offerPopupClosed(ev) {
    this.offerPopupVisible = false;
    if (ev?.message) {
      this.notifyService.showNotify(ev.message, ev.success ? 'success' : 'error');
    }
  }

  reloadAllData() {
    // Called when a price flag is updated, triggers a reload of the parent catalog, as well as updating the current listing. TODO: This is a lot, rationalize
    this.pfChanged.emit(true);
    this.inputListing = null;
    this.prepareData();
    this.priceFlagPopupVisible = false;
  }

  fmtDate(date) {
    return moment(date, 'MM/DD/YYYY');
  }

  getHistory() {
    const params = {search_by: 'gpi', search: this.currentListing.ndc, gpi: this.currentListing.gcn_seqno, unit_size: this.currentListing.package_size};
    this.statisticsService.getHistory(params).pipe(takeUntil(this.onDestroy$))
        .subscribe(
            result => {
              this.invoices = result.data;
              if (this.invoices.length) {
                this.lastInvoiceDate = this.invoices[0].invoice_date;
                this.firstInvoiceDate = this.invoices[this.invoices.length - 1].invoice_date;
              }
            },
            () => {}
        );
  }

  makeNdcItem(listing: any) {
    return {
      DU: listing.description,
      MFG: listing.company_name,
      company_id: listing.company_id,
      csp: listing.csp,
      description: listing.name,
      gcdf_desc: listing.description,
      gcn_seqno: listing.gcn_seqno,
      hic2_desc: listing.category,
      hic3_desc: listing.product_type_name,
      hic_desc: listing.pharm_class,
      listing_package_type: listing.package_type,
      ln60: listing.name,
      ndc: listing.ndc,
      pack_size: listing.pack_size,
      route: listing.route,
      schedule: listing.dea,
      storage_cond: listing.storage_conditions
    };
  }
  
  documentOpen = (document) => window.open(document.url, '_blank').focus();  

  getAccountType(accountType) {
    let className = accountType;
    if (accountType === '340b') {
      className = 'b340';
    }

    return 'puchase-option-pill ' + className
  }

  showDailyMed() {
    this.dimgpupVisible = true;
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxValidationSummaryModule,
    DxValidatorModule,
    DxiValidationRuleModule,
    DxLoadIndicatorModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxLoadPanelModule,
    DxGalleryModule,
    ListingDetailsModule,
    PipeModule,
    PriceFlagModule,
    StatDataTileModule,
    MsTooltipModule,
    SpendsTypeComparedModule,
    MsTooltipModule,
    MedShortsAlternativesGridModule,
    OfferModule,
    QtyPerMonthModule,
    PurchasingHistoryModule,
    MomentModule,
    RfpModule,
    SellerCompanyLogoModule,
    ListingWishlistToggleModule,
    ListingNotesModule
  ],
  declarations: [AddToCartComponent, CsosSignupComponent],
  exports: [ AddToCartComponent ]
})
export class AddToCartModule { }
