import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RegistrationService {

  constructor(private http: HttpClient) { }

  public getRegistrationData(): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/user/register`);
  }

  public systemRegistration(data: any): Observable<any> {
    return this.http.post(`${environment.api_url_v1}/services/user/register`, data);
  }

  public sellerRegistration(data: any): Observable<any> {
    return this.http.post(`${environment.api_url_v1}/services/user/register/seller`, data);
  }

  public preRegistration(data: any): Observable<any> {
    return this.http.post(`${environment.api_url_v1}/services/user/register/prereg_message`, data);
  }
}
