import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';
import { DxTooltipModule } from 'devextreme-angular';
import { PipeModule } from 'src/app/pipe.module';

@Component({
  selector: 'app-offer-total',
  templateUrl: './offer-total.component.html',
  styleUrls: ['../offer.component.scss']
})
export class OfferTotalComponent implements OnInit {
  @Input() offerQuantity: number;
  @Input() offerPrice: number;
  @Input() calculations: any;
  @Input() currentListing: any;
  @Input() savingsOnly = false;

  showSavingsTooltip = false;
  showShippingTooltip = false;

  constructor() { }

  ngOnInit() { }
}

@NgModule({
  imports: [
    CommonModule,
    DxTooltipModule,
    PipeModule
  ],
  exports: [ OfferTotalComponent ],
  declarations: [ OfferTotalComponent ]
})
export class OfferTotalModule { }
