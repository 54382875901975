import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxPopupModule } from 'devextreme-angular';
import { MedigiPopupComponent } from './medigi-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DxPopupModule
    ],
    declarations: [MedigiPopupComponent],
    exports: [MedigiPopupComponent]
})
export class  MedigiPopupModule { }
