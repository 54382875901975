import { Component, OnInit, NgModule, Input } from '@angular/core';
import { defaultContactUsData, ContactUsData } from 'src/app/shared/models/registration';
import { Landing } from 'src/app/shared/models/landing';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppInfoService } from 'src/app/shared/services/app-info.service';
import { CommonModule } from '@angular/common';
import { DxFormModule, DxTextAreaModule } from 'devextreme-angular';
import { RegistrationService } from 'src/app/shared/services/user/registration.service';

@Component({
  selector: 'app-static-footer',
  templateUrl: './static-footer.component.html'
})
export class StaticFooterComponent implements OnInit {
  @Input() content: any;
  contactData: ContactUsData = JSON.parse(JSON.stringify(defaultContactUsData));
  hasMessage = false;
  buttonNextOptions: any = {
    text: 'Submit',
    type: 'default',
    width: 150,
    useSubmitBehavior: true
  };

  landingConfigs: Landing;

  constructor(private registrationService: RegistrationService,
              private notificationService: NotificationService,
              private appService: AppInfoService) { }

  ngOnInit() {
    this.getAppConfigs();
  }

  getAppConfigs() {
    this.appService.get().subscribe(result => this.landingConfigs = result);
  }

  onSubmit(e) {
    this.registrationService.preRegistration(this.contactData).subscribe(
        () => {
          this.hasMessage = true;
          this.contactData = JSON.parse(JSON.stringify(defaultContactUsData));
          this.notificationService.showNotify('Your message was sent!', 'success', 5000);
        },
        () => this.notificationService.showNotify('Error sending message', 'error', 3000)
      );
    e.preventDefault();
  }

}


@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxTextAreaModule
  ],
  declarations: [ StaticFooterComponent ],
  exports: [ StaticFooterComponent ]
})
export class StaticFooterModule { }
