import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { RfpService } from '../../services/order/rfp.service';

@Component({
  selector: 'app-rfp',
  templateUrl: './rfp.component.html'
})
export class RfpComponent implements OnInit {

  @Input() show = false;
  @Input() mode = 'popup';
  @Input() companyId: any;
  @Input() isBuyer: any;
  @Input() isSeller: any;
  @Input() rfp: any;
  @Input() rfpId: any;
  @Input() ndc: any;
  @Input() ndcItem: any;
  @Input() catalogType: any;
  @Input() resubmit: any = false;

  title: any;
  rfpHeight: any;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() output: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  constructor(private rfpService: RfpService) { }

  ngOnInit() {
    this.setDefaultTitleAndHeight();
    if (this.isSeller) {
      this.getRfp();
    }
  }

  getRfp() {
    this.rfpService.getRequest(this.rfpId).subscribe(result => this.rfp = result.data);
  }

  setDefaultTitleAndHeight() {
    this.title = this.isBuyer ? 'Search by NDC or Product Name' : 'Submit Proposal';
    this.rfpHeight = this.isBuyer ? 'auto' : 675;
  }

  popupClose() {
    this.onClose.emit();
  }

  onUpdate(e) {
    this.output.emit(e);
    this.show = false;
    this.onClose.emit();
    this.repaintPopup();
  }

  onNdcSet(data) {
    if (data) {
      this.title = this.isBuyer ? `Build RFP for ${data.ln60}` : `Submit Proposal for ${data.ln60}`
      this.repaintPopup();
      return;
    }
    this.setDefaultTitleAndHeight();
  }

  onContentChange() {
    this.repaintPopup();
  }

  repaintPopup() {
    if (this.popup?.instance) {this.popup.instance.repaint();}
  }
}
