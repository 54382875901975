<div class="mb-2 p-2 dx-card responsive-paddings" >
  <div class="row p-0 m-0">
    <div *ngIf="iconEnable" class="col-3 p-0 m-0" style="width:30px">
      <span class="{{icon}} icon stat-data" style="font-size: 50px;"></span>
    </div>
    <div class="col p-0 m-0 text-right">
      <div style="height: 31px;">
        <span *ngIf="type==='default'" class="stat-data" [style.fontSize]="text?.length > 15 ? '1.25em' : '2em'">{{text}}</span>
        <span *ngIf="type==='danger'" class="stat-data text-danger" [style.fontSize]="text?.length > 15 ? '1.25em' : '2em'">{{text}}</span>
        <span *ngIf="type==='info'" class="stat-data text-info" [style.fontSize]="text?.length > 15 ? '1.25em' : '2em'">{{text}}</span>
        <span *ngIf="type==='success'" class="stat-data text-success" [style.fontSize]="text?.length > 15 ? '1.25em' : '2em'">{{text}}</span>
        <span *ngIf="type==='warning'" class="stat-data text-warning" [style.fontSize]="text?.length > 15 ? '1.25em' : '2em'">{{text}}</span>
      </div>
      <ng-content></ng-content>
      <div class="stat-title font-weight-bold" style="height: 20px;">
        {{title}}
        <app-ms-tooltip *ngIf="tooltip" badgeText="i" id="stats_{{tooltip.id}}"
          [tooltipText]="tooltip.tooltipText" extraClass="badge badge-dark">
        </app-ms-tooltip>
      </div>
    </div>
  </div>
</div>