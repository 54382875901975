<div *ngIf="mode === 'expanded'">
  <div class="direct-block jumbotron jumbotron-fluid mb-0" [ngStyle]="{'background-color': content.bgcolor}">
    <div id="isi" class="container-fluid" [ngStyle]="{'background': content.bgcolor || '#FFFFFF'}">
      <div [innerHtml]="content.body | bypassSecurity"></div>
    </div>
  </div>
  <div id="float-isi" style="margin:0 2rem; background: rgba(255, 255, 255, .95)">
    <div class="container-fluid">
      <div class="row pt-2" [ngStyle]="{'max-height': '135px'}">
        <div class="col-12">
          <div id="dynamicContent" #dynamicContent [innerHtml]="content.url | bypassSecurity"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mode !== 'expanded'">
  <div class="dx-card pt-2 pb-2">
    <div id="dynamicContent2" #dynamicContent2 [innerHtml]="content.url | bypassSecurity"></div>
  </div>
</div>

<dx-popup 
    *ngIf="popupVisible"
    [showCloseButton]="true"
    [showTitle]="false"
    [(visible)]="popupVisible"
    [resizeEnabled]="true" 
    [dragEnabled]="false"
    [closeOnOutsideClick]="true">
    <div id="dynamicContentPup" #dynamicContentPup></div>
</dx-popup>