<header id="main-header">
    <dx-toolbar class="header-toolbar" [ngClass]="menuToggle ? 'menu-opened-width' : 'menu-colapesed-width'">

        <dxi-item *ngIf="auth0.loggedIn && isBuyer" location="left" locateInMenu="auto">
            <div *dxTemplate>
                <app-search-panel></app-search-panel>
            </div>
        </dxi-item> 
        
        <dxi-item *ngIf="auth0.loggedIn && isBuyer" location="after" locateInMenu="auto" width="200">
            <div *dxTemplate="let data of 'content'">
                <app-switch-facilities mode="topNav" (click)="openedSwitchFacility()"></app-switch-facilities>
            </div>
        </dxi-item>

        <dxi-item *ngIf="auth0.loggedIn" location="after" locateInMenu="auto" menuItemTemplate="menuItem">
            <div *dxTemplate="let data of 'item'">
                <dx-button class="user-button authorization" height="100%" stylingMode="text">
                    <div *dxTemplate="let data of 'content'">
                        <app-user-panel [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
                    </div>
                </dx-button>
            </div>
        </dxi-item>
        
        <div *dxTemplate="let data of 'menuItem'">
            <app-user-panel [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
        </div>

        <dxi-item *ngIf="auth0.loggedIn && isBuyer && !hydraEnabled" location="after" locateInMenu="auto">
            <div *dxTemplate>
                <app-facility-and-user-stats [isHeaderButton]="true" (click)="openedStats()"></app-facility-and-user-stats>
            </div>
        </dxi-item>

        <dxi-item *ngIf="auth0.loggedIn" location="after" locateInMenu="auto">
            <div *dxTemplate>
                <app-notifications [isBuyer]="isBuyer" [isSeller]="isSeller" (click)="openedNotifications()"></app-notifications>
            </div>
        </dxi-item>

        <dxi-item *ngIf="auth0.loggedIn && isBuyer" location="after" locateInMenu="auto">
            <div *dxTemplate>
                <app-cart-panel (cartToggle)="cartOpened = !cartOpened;"></app-cart-panel>
            </div>
        </dxi-item>
        
        <div *dxTemplate="let data of 'helpItem'">
            <app-help-dropdown-btn menuMode="list"></app-help-dropdown-btn>
        </div>
        
        <dxi-item *ngIf="isImpersonating" location="after" locateInMenu="auto" menuItemTemplate="helpItem">
            <div *dxTemplate="let data of 'item'">
                User ID: {{userId}}
            </div>
        </dxi-item>
        <dxi-item *ngIf="auth0.loggedIn && isImpersonating" location="after" locateInMenu="auto" menuItemTemplate="helpItem">
            <div *dxTemplate="let data of 'item'">
                <dx-button stylingMode="text" icon="runner" (onClick)="unimpersonate()"></dx-button>
            </div>
        </dxi-item>

    </dx-toolbar>
</header>

<div *ngIf="auth0.loggedIn && isBuyer" id="cartSidenav" class="sidenav bg-lightgray shadow dx-base-backgorund"
    [ngClass]="cartOpened ? 'cart-show' : 'cart-hide'">
    <app-cart-side mode="sidebar" (visibleOut)="closeCar($event)"></app-cart-side>
</div>


<dx-popup
    *ngIf="isDocumentUploadOpen"
    [showTitle]="true"
    title="{{documentUploadPopupTitle}}"
    [dragEnabled]="true"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="isDocumentUploadOpen"
    [width]="documentUploadPopupWidth"
    [height]="'auto'">
    <div class="mb-3">
        <dx-button class="mr-3" type="default" [stylingMode]="'contained'" (onClick)="updateDocumentUploadPopup('SL')" text="State License"></dx-button>
        <dx-button class="mr-3" type="default" [stylingMode]="'contained'" (onClick)="updateDocumentUploadPopup('DEA')" text="DEA License"></dx-button>
        <dx-button *ngIf="!hydraEnabled" type="default" [stylingMode]="'contained'" (onClick)="updateDocumentUploadPopup('RX')" text="Purchase History"></dx-button>
    </div>
    <app-document-upload 
        *ngIf="documentUploadFileType === 'SL'"
        [showDelete]="true"
        context="facility"
        [companyId]="companyId"
        [facilityId]="facilityId"
        type="state_license">
    </app-document-upload>

    <app-document-upload 
        *ngIf="documentUploadFileType === 'DEA'"
        [showDelete]="true"
        context="facility"
        [companyId]="companyId"
        [facilityId]="facilityId"
        type="dea_license">
    </app-document-upload>

    <app-calibration-file-upload 
        *ngIf="documentUploadFileType === 'RX'"
        [facilityId]="facilityId" 
        [companyId]="companyId">
    </app-calibration-file-upload>
</dx-popup>

<dx-popup 
[showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="true" [width]="850" [height]="485"
title="{{user?.first_name}} {{user?.last_name}}" [showCloseButton]="true" [(visible)]="isProfilePopupOpen" (hiding)="closingPopup()">
  <app-user-profile></app-user-profile>
</dx-popup>
