import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { StorageType } from '../models/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class Auth0HttpInterceptor implements HttpInterceptor {

  constructor(private auth: Auth0Service, private storageService: StorageService) { }

  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const token = this.storageService.get(StorageType.auth0Token);
    if(token) {
      const tokenReq = this.cloneRequest(req, token);
      return next.handle(tokenReq);
    } else {
      const tokenReq = this.cloneRequest(req);
      return next.handle(tokenReq);
    }
    
  }

  getTokenSilently(req: HttpRequest<any>, next: HttpHandler){
    return this.auth.getTokenSilently$().pipe(
      switchMap(token => {
        this.storageService.set(StorageType.auth0Token, token);
        const tokenReq = this.cloneRequest(req, token);
        return next.handle(tokenReq);
      }),
      catchError(() => {
        const tokenReq = this.cloneRequest(req);
        return next.handle(tokenReq);
      })
    );
  }

  cloneRequest(req, token = null) {
    if (token) {
      return req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          SourceDomain: window.location.hostname,
          CatalogType: environment.catalogType[window.location.hostname] || 'medigi'
        },
        withCredentials: true
      });
    } else {
      return req.clone({
        setHeaders: {
          SourceDomain: window.location.hostname,
          CatalogType: environment.catalogType[window.location.hostname] || 'medigi'
        },
        withCredentials: true
      });
    }
  }
}
