import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class PharmacyService {

    constructor(private http: HttpClient) { }

    public getPrimaryFacility(): Observable<any> {
        return this.http.get<any>(`${environment.api_url_v1}/services/user/facility?type=primary`);
    }

    public getPharmacies(params?): Observable<any> {
        return this.http.get<any>(`${environment.api_url_v1}/services/user/facility`, {params});
    }

    public getPharmacy(id): Observable<any> {
        return this.http.get<any>(`${environment.api_url_v1}/services/user/facility/${id}`);
    }

    public downloadTerms(facilityId): Observable<Blob> {
        return this.http.get(`${environment.api_url_v1}/services/user/facility/${facilityId}/terms.pdf?dt=${new Date().getTime()}`, {responseType: 'blob'});
    }

    public downloadCombinedTerms(facilityId): Observable<Blob> {
        return this.http.get(`${environment.api_url_v1}/services/user/facility/${facilityId}/combined-terms.pdf?dt=${new Date().getTime()}`, {responseType: 'blob'});
    }

    public addPharmacy(data): Observable<any> {
        return this.http.post<any>(`${environment.api_url_v1}/services/user/facility`, data);
    }

    public updatePharmacy(id, data): Observable<any> {
        return this.http.put<any>(`${environment.api_url_v1}/services/user/facility/${id}`, data);
    }
}


