import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-medigi-popup',
    templateUrl: './medigi-popup.component.html',
    styleUrls: ['./medigi-popup.component.scss']
})

export class MedigiPopupComponent implements OnInit {
    @Output() onContentReady: EventEmitter<any> = new EventEmitter();
    @Output() onDisposing: EventEmitter<any> = new EventEmitter();
    @Output() onHidden: EventEmitter<any> = new EventEmitter();
    @Output() onHiding: EventEmitter<any> = new EventEmitter();
    @Output() onInitialized: EventEmitter<any> = new EventEmitter();
    @Output() onOptionChanged: EventEmitter<any> = new EventEmitter();
    @Output() onResize: EventEmitter<any> = new EventEmitter();
    @Output() onResizeEnd: EventEmitter<any> = new EventEmitter();
    @Output() onResizeStart: EventEmitter<any> = new EventEmitter();
    @Output() onShowing: EventEmitter<any> = new EventEmitter();
    @Output() onShown: EventEmitter<any> = new EventEmitter();
    @Output() onTitleRendered: EventEmitter<any> = new EventEmitter();
    @Output() visibleChange = new EventEmitter<boolean>();

    @Input() hideOnOutsideClick = true;
    @Input() container = undefined;
    @Input() contentTemplate = "content";
    @Input() deferRendering = true;
    @Input() disabled = false;
    @Input() dragEnabled = true;
    @Input() dragOutsideBoundary = false;
    @Input() focusStateEnabled = true;
    @Input() fullScreen = false;
    @Input() height = 'auto';
    @Input() hideOnParentScroll = false;
    @Input() hint = undefined;
    @Input() hoverStateEnabled = false;
    @Input() maxHeight = null;
    @Input() maxWidth = null;
    @Input() minHeight = null;
    @Input() minWidth = '400';
    @Input() resizeEnabled = false
    @Input() restorePosition = true
    @Input() rtlEnabled = false
    @Input() shading = true
    @Input() shadingColor = ""
    @Input() showCloseButton = true
    @Input() showTitle;
    @Input() tabIndex = 0
    @Input() title = '';
    @Input() titleComponent = null
    @Input() titleTemplate = "title"
    @Input() visible:boolean;
    @Input() width = "80vw";
    @Input() position = { my: 'center', at: 'center', of: window };

    constructor() { }

    ngOnInit() { }

    onHidingInner(ev) {
        this.visibleChange.emit(false);
        this.onHiding.emit([ev])
    }
}