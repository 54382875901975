import notify from "devextreme/ui/notify";

export const copyToClipboard = async (value) => {
    try {
        if (!navigator.clipboard) {
            const error = "Browser doesn't have support for native clipboard."
            notify({message: error, position: {my: 'center top', at: 'center top'}}, 'error', 2500);
            throw new Error(error);
        }
        await navigator.clipboard.writeText(value);
        notify({message: "Link to Listing copied to clipboard", position: {my: 'center top', at: 'center top'}}, 'success', 2500);
    } catch (error) {
        notify({message: error.toString(), position: {my: 'center top', at: 'center top'}}, 'error', 2500);
        throw new Error(error.toString());
    }
};