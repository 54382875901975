import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stat-data-tile',
  templateUrl: './stat-data-tile.component.html',
  styleUrls: ['./stat-data-tile.component.scss']
})
export class StatDataTileComponent {

  @Input() type='default';
  @Input() text: any;
  @Input() title: any;
  @Input() iconEnable = false;
  @Input() icon = 'dx-icon-formula' 
  @Input() tooltip: any; 
  constructor() { }

}
