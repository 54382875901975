import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private http: HttpClient) {}

  public get(params=null) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/offer`, { params: params });
  }

  public getSummary(params=null) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/offer/summary/stats`, { params: params });
  }

  public getById(id) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/offer/${id}`);
  }

  public async getCalc(data) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/offer/calc`, {params:data}).toPromise();
  }

  public getLot(data) {
    if (data.listingId) {
      return this.http.get<any>(`${environment.api_url_v1}/services/order/offer/lot?listing_id=${data.listingId}`);
    }

    return this.http.get<any>(`${environment.api_url_v1}/services/order/offer/lot?id=${data.lotId}`);
  }

  public makeOffer(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/offer`, data);
  }

  public makeCounterOffer(data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/offer/counter`, data);
  }

  public acceptOffer(data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/offer/accept`, data);
  }

  public declineOffer(data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/offer/decline`, data);
  }

  public getDistributedLots(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/offer/distributed_lots`, data);
  }

  public getOfferableLots(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/offer/get_offerable_lots`, data);
  }

  
  public setVacationDate(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/offer/set_vacation`, data);
  }

  public getFacilities(buyerCompanyId, sellerCompanyId) {
    let params = {};
    if (buyerCompanyId) {
      params['buyer_company_id'] = buyerCompanyId;
    }
    if (sellerCompanyId) {
      params['seller_company_id'] = sellerCompanyId;
    }
    return this.http.get<any>(`${environment.api_url_v1}/services/order/offer/facilities`, { params });
  }

  public updateOffer(id, data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/offer/${id}`, data);
  }

  public extendOffers(data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/offer/extend`, data);
  }

  public placeOrder(offerId) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/offer/place_order/${offerId}`);
  }

}
