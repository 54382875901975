<!-- Top Navigation Button / Icon -->
<div *ngIf="!displayOnDashboard">
  <dx-button
          stylingMode="text"
          type="default"
          icon="bell"
          [disabled]="isDataLoading"
          (onClick)="openOrCloseNotifications()"
          hint="View Notifications">
  </dx-button>
  <span *ngIf="!isDataLoading && numberWithinNotificationsIcon > 0" class="badge badge-danger notification-badge">
    {{numberWithinNotificationsIcon}}
  </span>
</div>

<!-- Notifications Layout for the buyer Dashbaord Card -->
<div *ngIf="displayOnDashboard">
  <div>
    <h4 class="midgraytxt semibold">{{newNotifications}} New Notifications</h4>
  </div>

  <div *ngIf="newNotifications < 1" class="d-flex flex-column align-items-center justify-content-center">
    <img style="width: 100px; height: 100px;" src="assets/icons/component-icons/empty-notification-bell.svg" alt="bell-icon">
    <p class="empty-notification-text">You currently have no new notifications.</p>
  </div>

  <div *ngIf="newNotifications > 0" style="height: 175px; padding: 8px;">
    <dx-scroll-view>
      <div *ngFor="let notification of listOfUnreadNotifications">
        <div class="col-12 notification-box {{notification.level}}">
          <div class="d-flex align-items-center justify-content-between" style="width: 101%;">
            <h5 class="mt-1 mb-0"><b>{{notification.title}}</b></h5>
            <dx-button icon="clear" hint="Mark notification as read" (onClick)="markNotificationAsRead(notification.id)"></dx-button>
          </div>
          <p class="my-1 ml-0 mr-2">{{notification.content}}</p>
          <div class="d-flex align-items-center justify-content-between">
            <p class="mb-1 ml-0 mr-2 date-font">{{notification.created_date | date}}</p>
            <dx-button
                    *ngIf="notification.relative_url && notification.type !== null"
                    class="mb-2"
                    type="default"
                    stylingMode="outlined"
                    text="Manage {{notification.type}}"
                    (onClick)="navigateToNotificationsURL(notification)">
            </dx-button>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</div>

<!-- Notifications Layout for Side Bar -->
<div *ngIf="isNotificationsOpen && !displayOnDashboard">
  <div class="notification-side-bar">
    <div class="col-12">
      <div>
        <div class="d-flex flex-row justify-content-between align-items-center pl-2 pt-2">
          <h4 class="mb-0"><b>{{newNotifications}} New Notifications</b></h4>
          <dx-button class="mr-1" type="text" icon="clear" (onClick)="openOrCloseNotifications()"></dx-button>
        </div>
        <hr class="mb-2 mt-1">
        <div style="height: 90vh;">
          <div *ngIf="newNotifications < 1" class="d-flex flex-column align-items-center justify-content-center">
            <img style="width: 100px; height: 100px;" src="assets/icons/component-icons/empty-notification-bell.svg"
                 alt="bell-icon">
            <p class="empty-notification-text">You currently have no new notifications.</p>
          </div>
          <dx-scroll-view>
            <div *ngFor="let notification of listOfUnreadNotifications">
              <div class="col-12 notification-box {{notification.level}}">
                <div class="d-flex align-items-center justify-content-between" style="width: 102%;">
                  <h5 class="mt-1 mb-0"><b>{{notification.title}}</b></h5>
                  <dx-button icon="clear" hint="Mark notification as read"
                             (onClick)="markNotificationAsRead(notification.id)"></dx-button>
                </div>
                <p class="my-1 ml-0 mr-2">{{notification.content}}</p>
                <div class="d-flex align-items-center justify-content-between">
                  <p class="mb-1 ml-0 mr-2 date-font">{{notification.created_date | date}}</p>
                  <dx-button *ngIf="notification.relative_url && notification.type !== null" class="mb-2" type="default"
                             stylingMode="outlined" text="Manage {{notification.type}}"
                             (onClick)="navigateToNotificationsURL(notification)">
                  </dx-button>
                </div>
              </div>
            </div>
          </dx-scroll-view>
        </div>
      </div>
    </div>
  </div>
</div>

<app-overlay *ngIf="isNotificationsOpen" (closePopup)="isNotificationsOpen = !isNotificationsOpen"></app-overlay>