import { Component, OnInit, NgModule } from '@angular/core';

import { Auth0Service } from 'src/app/auth/auth0.service';
import { SessionData } from 'src/app/shared/models/session';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DxButtonModule } from 'devextreme-angular';

@Component({
  selector: 'app-medshorts-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class MedshortsNavComponent implements OnInit {

  session: SessionData;

  constructor(public auth0: Auth0Service) {}

  ngOnInit() {}

}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    DxButtonModule
  ],
  declarations: [ MedshortsNavComponent ],
  exports: [ MedshortsNavComponent ]
})
export class  MedshortsNavModule { }
