import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RfpComponent } from './rfp.component';
import { DxAccordionModule, DxAutocompleteModule, DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxLoadIndicatorModule, DxLoadPanelModule, DxNumberBoxModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTagBoxModule, DxTextAreaModule, DxTextBoxModule, DxValidationGroupModule, DxValidationSummaryModule, DxValidatorModule } from 'devextreme-angular';
import { RfpFormComponent } from './rfp-form/rfp-form.component';
import { NdcDescriptionComponent } from './ndc-description/ndc-description.component';
import { OfferStatsModule } from '../offer-stats/offer-stats.module';
import { PipeModule } from 'src/app/pipe.module';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { MsTooltipModule } from '../ms-tooltip/ms-tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxPopupModule,
    DxNumberBoxModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxDateBoxModule,
    DxScrollViewModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxAutocompleteModule,
    DxValidatorModule,
    DxiValidationRuleModule,
    DxValidationGroupModule,
    DxAccordionModule,
    OfferStatsModule,
    MsTooltipModule,
    PipeModule
  ],
  declarations: [ RfpComponent, RfpFormComponent, NdcDescriptionComponent ],
  exports: [ RfpComponent, RfpFormComponent, NdcDescriptionComponent ]
})
export class RfpModule { }
