import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedigiClickwrapComponent } from './medigi-clickwrap.component';
import { DxButtonModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxPopupModule,
    DxScrollViewModule
  ],
  declarations: [ MedigiClickwrapComponent ],
  exports: [ MedigiClickwrapComponent ]
})
export class MedigiClickwrapModule { }
