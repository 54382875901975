export const profile = [
  // {
  //   text: 'Schedule',
  //   path: '/order-schedule',
  //   icon: 'event'
  // },
  {
    text: 'Profile',
    path: '/profile',
    icon: 'user'
  }
];

export const lowerMenu = [
  {
    template: 'profileTemplate'
  },
  {
    template: 'collapseTemplate'
  }
]

export const reports_only = [
  {
    text: 'Reports',
    icon: 'analytics',
    path: '/reports'
  }
]


export const reports = [
  // {
  //   text: 'Reports',
  //   icon: 'datafield',
  //   path: '/reports'
  // }
  {
    text: 'Analytics',
    icon: 'analytics',
    items: [
      {
        text: 'Dashboard',
        path: '/quicksight'
      },
      {
        text: 'Reports',
        path: '/reports'
      }
    ]
  }
];

export const buyer = [
  {
    text: 'Home',
    path: '/buyer-home',
    icon: 'home'
  },
  {
    text: 'Catalogs',
    path: '/catalog',
    icon: 'catalogs'
  },
  {
    text: 'Orders',
    path: '/orders',
    icon: 'orders'
  }
];

export const hydra_buyer = [
  // {
  //   text: 'Landing',
  //   path: '/',
  //   icon: 'home'
  // },
  {
    text: 'Catalog',
    path: '/catalog',
    icon: 'catalogs'
  },
  {
    text: 'Orders',
    path: '/orders',
    icon: 'orders'
  }
];

export const scheduled_orders = [
  {
    text: 'Scheduled Orders',
    path: '/rfps/scheduled_orders',
    icon: 'event'
  },
]

export const bids = [  
  {
    text: 'Bids',
    path: '/offers',
    icon: 'bids'
  }
]

export const csos = [
  {
    text: 'Certificates',
    icon: 'c2',
    path: '/csoscerts'
  }
]

export const medigi = [
  {
    text: 'Medigi',
    path: '/medigi',
    icon: 'medigi'
  }
]


export const buyer_requests = [
  {
    text: 'Requests',
    path: '/requested-products',
    icon: 'requests'
  }
];

export const buyer_formulary = [
  {
    text: 'Formulary Review',
    icon: 'folder',
    items: [
      {
        text: 'Review',
        path: '/review',
      },
      {
        text: 'Formulary',
        path: '/catalogs/formulary'
      },
      {
        text: 'Wholesaler Catalog',
        path: '/catalogs/catalog'
      },
      {
        text: 'Purchasing History',
        path: '/catalogs/history'
      },
      {
        text: 'Reports',
        icon: 'folder',
        items: [
          {
            text: 'Processed Items',
            path: '/reports/processed'
          },
          {
            text: 'CDM to NDC',
            path: '/reports/cdm'
          },
        ]
      }
    ]
  }
]

export const buyer_editing = [
  {
    text: 'Facilities',
    path: '/pharmacies',
    icon: 'pharmacies'
  },
  {
    text: 'Users',
    path: '/users',
    icon: 'users'
  }
];

export const seller_home = [
  {
    text: 'Home',
    path: '/seller-home',
    icon: 'home'
  },
];

export const seller_listings = [
  {
    text: 'Listings',
    icon: 'listings',
    items: [
        {
            text: '> 12 Month Expiry',
            path: '/manage-listings/medigi',
        },
        {
            text: '< 12 Month Expiry',
            path: '/manage-listings/medshorts',
        }
    ]
  }
];


export const seller_hydra_listings = [
  {
    text: 'Listings',
    icon: 'listings',
    path: '/manage-listings/fulldate'
  }
]

export const seller_listings_503b = [
  {
    text: 'Listings',
    icon: 'listings',
    path: '/manage-listings/503b'
  }
];

export const seller_listings_shortdate = [
  {
    text: 'Listings',
    icon: 'listings',
    path: '/manage-listings/shortdate'
  }
];

export const rfps = [
  {
    text: 'RFPs',
    icon: 'rfps',
    path: '/rfps'
  }
]

export const seller_hydra_default = [
  {
    text: 'Orders',
    path: '/orders',
    icon: 'orders'
  }
]

export const seller_default = [
  {
    text: 'Portfolio',
    icon: 'catalogs',
    path: '/portfolio'
  },
  {
    text: 'Orders',
    path: '/orders',
    icon: 'orders'
  },
  {
    text: 'Scheduled Orders',
    path: '/rfps/scheduled_orders',
    icon: 'event'
  },
  {
    text: 'Bids',
    path: '/offers',
    icon: 'bids'
  },
  {
    text: 'RFPs',
    icon: 'rfps',
    path: '/rfps'
  }
];

export const seller_admin = [
  {
    text: 'Users',
    path: '/users',
    icon: 'users'
  },
  {
    text: 'Company',
    path: '/seller-editor/info',
    icon: 'companies'
  },
  {
    text: 'News Editor',
    path: '/seller-editor/news',
    icon: 'newsroom'
  }
];

export const hydra_seller_admin = [
  {
    text: 'Users',
    path: '/users',
    icon: 'users'
  }
];

export const hydra_admin = [
  {
    text: 'Dashboard',
    path: '/hydra-home',
    icon: 'home'
  },
  {
    text: 'Admin Users',
    icon: 'group',
    path: '/admin/user-manager',
  }
]

export const admin = [
  {
    text: 'Home',
    icon: 'home',
    path: '/admin/home',
  },
  {
    text: 'User Logs',
    path: '/admin/logs',
    icon: 'user-logs'
  },
  {
    text: 'Users',
    icon: 'group',
    path: '/admin/user-manager',
  },
  {
    text: 'Companies',
    icon: 'companies',
    path: '/admin/company-manager',
  },
  {
    text: 'Direct',
    icon: 'direct',
    path: '/admin/direct'
  },
  {
    text: 'Orders',
    path: '/orders',
    icon: 'orders'
  },
  {
    text: 'Scheduled Orders',
    path: '/rfps/scheduled_orders',
    icon: 'event'
  },
  {
    text: 'Carts',
    path: '/carts',
    icon: 'carts'
  },
  {
    text: 'Bids',
    path: '/offers',
    icon: 'bids'
  },
  {
    text: 'RFP',
    icon: 'rfps',
    path: '/rfps'
  },
  {
    text: 'Listings',
    path: '/admin/catalog',
    icon: 'listings'
  },
  {
    text: 'Requests',
    path: '/requested-products',
    icon: 'requests'
  },
  {
    text: 'Tasks',
    icon: 'tasks',
    path: '/admin/tasks'
  },
  {
    text: 'News',
    icon: 'newsroom',
    path: '/admin/news-list'
  },
  {
    text: 'Analytics',
    icon: 'analytics',
    items: [
      {
        text: 'Dashboard',
        path: '/quicksight'
      },
      {
        text: 'Reports',
        path: '/reports'
      }
    ]
  },
  {
    text: 'Data',
    icon: 'data',
    items: [
      {
        text: 'Account Strategists',
        path: '/admin/account-strategists'
      },
      {
        text: 'Finance',
        path: '/admin/finance'
      },
      {
        text: 'FDB',
        path: '/fdb'
      }
    ]
  },
  {
    text: 'GPT',
    icon: 'gpt',
    items: [
      {
        text: 'Reports',
        path: '/admin/gpt/reports'
      },
      {
        text: 'Tracking',
        path: '/admin/gpt/tracking'
      }
    ]
  },
  {
    text: 'Communication',
    icon: 'copy',
    items: [
      {
        text: 'Clients',
        path: '/admin/edi/clients'
      },
      {
        text: 'E-Mails',
        path: '/admin/edi/messages/email'
      },
      {
        text: 'EDI',
        path: '/admin/edi/messages/edi'
      },
      {
        text: 'Invoices',
        path: '/admin/edi/invoices'
      },
      {
        text: 'Shipment',
        path: '/admin/edi/shipments'
      }
    ]
  },
];

export const medshorts = {
  text: 'MedShorts',
  icon: 'ms-logo-black',
  path: '/medshorts'
}
