<span>
    <i *ngIf="listing.favorite" [title]="listing.favorite_comment" class="dx-icon-like mr-1 heart-active" style="cursor: pointer;" (click)="removeFromWishlist(listing.favorite_id)"></i>
    <i *ngIf="!listing.favorite" title="Favorite this product" class="dx-icon-like mr-1 heart-default" style="cursor: pointer;" (click)="addToWishlist()"></i>
    <i *ngIf="!hydraEnabled && listing.soft_block" id="listing_sb_{{listing.id}}_{{listing.soft_block_id}}" style="cursor: pointer;" class="dx-icon-warning mr-1 text-warning" (click)="showSoftBlockPopup()"></i>
    <i *ngIf="!hydraEnabled && !listing.soft_block" title="Soft Block this product" class="dx-icon-warning mr-1 heart-default" style="cursor: pointer;" (click)="showSoftBlockPopup()"></i>
</span>
<!-- [title]="listing.soft_block_reason"  -->
<app-ms-tooltip 
    *ngIf="listing.soft_block"
    width="400"
    target="listing_sb_{{listing.id}}_{{listing.soft_block_id}}"
    tooltipText="
        <div>
            An employee from your team has issued a soft block on this item.
            Soft blocks are to help teams identify products that are not
            preferred within the pharmacy. Details pertaining to the soft
            block can be found below. You may override the block and proceed
            to purchase the product with an acknowledgement at checkout.
        </div>
        <hr>
        <div>{{listing.soft_block_reason}}</div>
    " 
    extraClass="badge badge-warning">
</app-ms-tooltip>

<app-medigi-popup
  *ngIf="softBlockPopup"
  class="popup"
  [fullScreen]="true"
  [showTitle]="true"
  title="Mark listing as soft blocked"
  [dragEnabled]="true"
  (onHiding)="onSoftBlockEditorHide()"
  [(visible)]="softBlockPopup"
  [resizeEnabled]="true"
  [width]="500"
  [minHeight]="300"
>
    <form action="your-action" (submit)="upsertSoftBlock($event)">
        <div *ngIf="!currentSoftBlock" class="dx-field">
            <div class="dx-field-label" style="width:25%">Block the following:</div>
            <div class="dx-field-value" style="width:75%">
                <dx-radio-group
                    [items]="softBlockTypes"
                    valueExpr="value"
                    displayExpr="text"
                    [(value)]="selectedSoftBlockType"
                    >
                </dx-radio-group>
            </div>
        </div>
        <div *ngIf="currentSoftBlock" class="dx-field">
            <div class="dx-field-label" style="width:25%">Name:</div>
            <div class="dx-field-value" style="width:75%">{{currentSoftBlock?.first_name}} {{currentSoftBlock?.last_name}}</div>
        </div>
        <div *ngIf="currentSoftBlock" class="dx-field">
            <div class="dx-field-label" style="width:25%">Date:</div>
            <div class="dx-field-value" style="width:75%">{{currentSoftBlock?.created_date}}</div>
        </div>
        <div *ngIf="currentSoftBlock" class="dx-field">
            <div class="dx-field-label" style="width:25%">Blocked by:</div>
            <div *ngIf="currentSoftBlock?.listing_id" class="dx-field-value"  style="width:75%">Listing ({{listing.name}})</div>
            <div *ngIf="currentSoftBlock?.seller_company_id" class="dx-field-value"  style="width:75%">Company ({{listing.company_name}})</div>
            <div *ngIf="currentSoftBlock?.catalog_type" class="dx-field-value"  style="width:75%">Catalog ({{listing.catalog_type}})</div>
            <div *ngIf="currentSoftBlock?.gcn" class="dx-field-value"  style="width:75%">Any similar item from any manufacturer</div>
        </div>
        <br>
        <div class="dx-field">
            <div class="dx-field-label" style="width:25%">Comment:</div>
            <div *ngIf="currentUser?.is_admin || currentUser?.id === currentSoftBlock?.user_id" class="dx-field-value" style="width:75%">
                <dx-text-area [height]="130" [maxLength]="250" [(value)]="softBlockReason" placeholder="Reason for soft block...">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Soft block reason is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
            </div>
            <div *ngIf="!currentUser?.is_admin && currentUser?.id !== currentSoftBlock?.user_id" class="dx-field-value" style="width:75%">
                {{softBlockReason}}
            </div>
        </div>
        
        <br>
        <div class="text-right">
            <dx-button text="Cancel" width="120" icon="close" type="danger" class="mr-2" (onClick)="closeSoftBlockPopup()"></dx-button>
            <dx-button *ngIf="listing.soft_block_id" width="120" text="Delete" icon="trash" type="danger" class="mr-2" (onClick)="removeFromSoftBlock()"></dx-button>
            <dx-button width="120" text="Save" icon="save" type="success" [useSubmitBehavior]="true"></dx-button>
        </div>
    </form>

</app-medigi-popup>