import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  public getCompanies(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/company`, {params});
  }

  public companyProfileInformation(id: number, withUsers=false): Observable<any> {
    let params = {};
    params['with_users'] = withUsers;
    return this.http.get(`${environment.api_url_v1}/services/user/company/${id}`, {params});
  }

  public companyNameIdDict(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/company/supplier_dict`);
  }

  public updateCompany(id, data): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/company/${id}`, data);
  }

  public sendFinanceStatements(data): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/company/send_finance_statements`, data);
  }

}
