<div *ngIf="isSeller && listing"  class="row">
  <div class="col-6" style="border-right: 1px solid rgba(134, 134, 134, 0.489);">
    <div class="pb-2">
      <dx-accordion [collapsible]="true" [multiple]="true">
        <dxi-item title="Buyer's Requested Product">
          <app-ndc-description [listing]="listing"></app-ndc-description>
        </dxi-item>
        <dxi-item title="Buyer's Request Details">
          <div class="row mb-2">
            <div class="col-md-4"><span>Start Date:</span></div>
            <div class="col-md-8">{{rfp.start_date | date:'MM/dd/yyyy'}}</div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4"><span>Pack Quantity/{{rfpService.PERIOD_MAP[rfp.period]}}:</span></div>
            <div class="col-md-8">{{ rfp.quantity }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4"><span>Units Quantity/{{rfpService.PERIOD_MAP[rfp.period]}}:</span></div>
            <div class="col-md-8">{{ rfp?.quantity * rfp?.csp }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4"><span>Contract Duration:</span></div>
            <div class="col-md-8">{{ rfp.duration }} months</div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4"><span>Contract Period:</span></div>
            <div class="col-md-8">{{ rfp.period_name }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4"><span>Total Packs:</span></div>
            <div class="col-md-8">{{ rfp.total_quantity }}</div>
          </div>
          
        </dxi-item>
        <dxi-item *ngIf="proposals.length" title="Proposals History">
          <dx-scroll-view>
            <div *ngFor="let p of proposals; let i = index">
              <b>{{i+1}}.</b> Price: {{p.price | usdCurrency}} Qty: {{p.quantity}}
              <span *ngIf="p.decline_reason">
                Decline reason:
                <app-ms-tooltip
                  id="rfp_prop_decline_{{p.id}}" 
                  width="260"
                  extraClass="badge-light dx-font-xs"
                  [tooltipText]="p.decline_reason">
                </app-ms-tooltip>
              </span>
              <br>
              NDC: {{p.ndc}}
            </div>
          </dx-scroll-view>
        </dxi-item>
      </dx-accordion>
    </div>
  </div>
  <div class="col-6">
    <div class="pl-4 pr-4 pb-2 pt-2">
      <h4>Your Proposal Details</h4>
      <hr/>
      <div class="dx-fieldset">
        <div *ngIf="!rfp.active_proposal">
          <div class="dx-field" style="margin-bottom: 20px !important;">
            <div class="dx-field-label" style="width: 75%">Product Name:</div>
            <div class="dx-field-value">
              <dx-select-box 
                [dataSource]="sellerNdcs" 
                [(value)]="selectedSellerNdc"
                valueExpr="ndc" 
                displayExpr="description" 
                fieldTemplate="field"
                (onValueChanged)="onSellerNdcChanged()" 
                >
                <dxo-drop-down-options [width]="500"></dxo-drop-down-options>
                <div *dxTemplate="let data of 'field'">
                  <dx-text-box value="{{data?.description}}" [readOnly]="true" placeholder="Select Product"></dx-text-box>
                </div>
                <div *dxTemplate="let data of 'item'">
                  <span title="{{data.ndc}} - {{data.description}}">{{data.ndc}} - {{data.description}}</span>
                </div>
              </dx-select-box>
            </div>
          </div>
          <div class="dx-field" style="margin-bottom: 20px !important;">
            <div class="dx-field-label" style="width: 75%">NDC:</div>
            <div class="dx-field-value">
              {{selectedSellerNdc}}
            </div>
          </div>
          <div class="dx-field" style="margin-bottom: 20px !important;">
            <div class="dx-field-label" style="width: 75%">Pack Quantity/{{rfpService.PERIOD_MAP[period]}}:</div>
            <div class="dx-field-value">
              <dx-number-box
                [disabled]="!selectedSellerNdc" 
                [(value)]="quantity" 
                [min]="1" 
                [showSpinButtons]="false" 
                [showClearButton]="false" 
                [step]="0"
                (onValueChanged)="onQtyChanged()">
            </dx-number-box>
            </div>
          </div>
          <div class="dx-field" style="margin-bottom: 20px !important;">
            <div class="dx-field-label" style="width: 75%">Units Quantity/{{rfpService.PERIOD_MAP[period]}}:</div>
            <div *ngIf="quantity" class="dx-field-value">
              {{selectedSellerListing?.csp * quantity}}
            </div>
          </div>
          <div class="dx-field" style="margin-bottom: 20px !important;">
            <div class="dx-field-label" style="width: 75%">Price per Pack:</div>
            <div class="dx-field-value">
              <dx-number-box 
                [(value)]="price"
                format="$ #,##0.00"
                [step]="0"
                [min]="0.01"
                [showSpinButtons]="false"
                [showClearButton]="false"
                placeholder="Propose Pack Price"
                (onValueChanged)="getShippingPrice()">
              </dx-number-box>
            </div>
          </div>
          <div class="dx-field" style="margin-bottom: 20px !important;">
            <div class="dx-field-label" style="width: 75%">Shipping Price:</div>
            <div class="dx-field-value">
              <dx-number-box 
                [(value)]="shippingPrice"
                format="$ #,##0.00"
                [step]="0"
                [min]="0.00"
                [showSpinButtons]="false"
                [showClearButton]="false"
                placeholder="Shipping Price per Order"
                (onValueChanged)="shippingPriceUpdate()">
              </dx-number-box>
            </div>
          </div>
          <div class="dx-field" style="margin-bottom: 20px">
            <div class="dx-field-label" style="width: 75%">Start Date:</div>
            <div class="dx-field-value">
              <dx-date-box [min]="minDate" [(value)]="startDate" type="date"></dx-date-box>
            </div>
          </div>
        </div>
        <div class="dx-field" style="margin-bottom: 20px !important;">
            <div class="dx-field-label" style="width: 75%">Contract Duration (# of months):</div>
            <div class="dx-field-value">
              <span *ngIf="isSeller">{{ rfp.duration }}</span>
            </div>
        </div>
        <div class="dx-field" style="margin-bottom: 20px !important;">
          <div class="dx-field-label" style="width: 75%">Purchase Period:</div>
          <div class="dx-field-value">
            <dx-select-box 
              [dataSource]="rfpService.PERIOD_LIST" 
              [(value)]="period"
              valueExpr="value" 
              displayExpr="name"
              (onValueChanged)="onPeriodChanged()"
              >
            </dx-select-box>
          </div>
        </div>
        <div *ngIf="suppliersSelected?.length" class="dx-field" style="margin-bottom: 20px !important;">
          <span *ngFor="let supplier of suppliersSelected.slice(0, 3)" class="badge badge-pill badge-light">
            {{supplier.name}}
          </span>
          <span *ngIf="suppliersSelected.length>3">... </span>
        </div>

        <div class="dx-field mt-2">
          <div class="dx-field-label" style="width: 75%">Total Packs:</div>
          <div *ngIf="quantity" class="dx-field-value">{{totalPacks}} </div>
        </div>

        <div class="dx-field mt-2">
          <div class="dx-field-label" style="width: 75%">Total Units:</div>
          <div *ngIf="quantity" class="dx-field-value">{{totalPacks * selectedSellerListing?.csp}}</div>
        </div>

        <div class="dx-field" style="margin-bottom: 20px !important;">
          <div class="dx-field-label" style="width: 75%">Sub Total ($):</div>
          <div *ngIf="price" class="dx-field-value">{{rfp.duration * price * quantity * rfpService.PERIOD_DURATION_MAP[period] | usdCurrency}}</div>
        </div>

        <div class="dx-field" style="margin-bottom: 20px !important;">
          <div class="dx-field-label" style="width: 75%">Total ($):</div>
          <div *ngIf="price" class="dx-field-value">{{rfp.duration * price * quantity * rfpService.PERIOD_DURATION_MAP[period] + shippingPrice * rfp.duration * rfpService.PERIOD_DURATION_MAP[period] | usdCurrency}}</div>
        </div>

        <div class="row m-0 p-0">
          <div class="col-12 text-right m-0 p-0">
            <dx-check-box class="ml-2" [(value)]="shippingAcknowledged" text=""></dx-check-box>&nbsp;&nbsp;
            <span>Guarantee no more than <b>{{shippingPrice | usdCurrency}}</b> shipping charge per order</span>
          </div>
          <div class="col-12 text-right m-0 p-0">
            <dx-check-box class="ml-2" [(value)]="isAcknowledged" text=""></dx-check-box>&nbsp;&nbsp;
            <button (click)="showAcknowledgePopup = true;" class="btn btn-link px-0" type="button">Acknowledge Terms</button>
          </div>
          <div class="col-12 text-right m-0 p-0">
            <dx-button type="warning" text="Close" style="margin-right: 10px" (onClick)="closeRfp()"></dx-button>
            <dx-button *ngIf="!rfp.active_proposal" type="danger" text="Decline" style="margin-right: 10px" (onClick)="showDeclineRfpVisibleView()"></dx-button>
            <dx-button *ngIf="!rfp.active_proposal" [disabled]="!shippingAcknowledged || !isAcknowledged || !selectedSellerNdc || !price" type="default" text="Submit" (onClick)="askPlaceProposal()"></dx-button>
          </div>
        </div>
        <br>
        <div *ngIf="validationErrorList?.length" class="alert alert-danger" role="alert">
          <p *ngFor="let error of validationErrorList">{{error}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isBuyer && !listing" class="row">
  <div class="col-8">
    <div class="dx-fieldset mb-2">
      <div class="dx-field">
        <div class="dx-field-label" style="width: 20%">NDC:</div>
        <div class="dx-field-value">
          <dx-autocomplete placeholder="Search by NDC..." [(value)]="ndc" valueExpr="ndc"
                        [dataSource]="sugestionsList" searchMode="contains" [minSearchLength]="minSearchLength"
                        [searchTimeout]="800" (onValueChanged)="ndcBasedSearch($event)"
                        [disabled]="searchDisabled">
            <div *dxTemplate="let item of 'item'">
              <span (click)="setNdc(item)">{{item.ndc}} - {{item.description}}</span>
            </div>
          </dx-autocomplete>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label" style="width: 20%">Product Name:</div>
        <div class="dx-field-value">
          <dx-autocomplete placeholder="Search by Product Name..." [(value)]="description" valueExpr="ln60"
                        [dataSource]="sugestionsList" searchMode="contains" [minSearchLength]="minSearchLength"
                        [searchTimeout]="800" (onValueChanged)="descriptionBasedSearch($event)"
                        [disabled]="searchDisabled">
            <div *dxTemplate="let item of 'item'">
              <span (click)="setNdc(item)">{{item.description}}</span>
            </div>
          </dx-autocomplete>
        </div>
      </div>
      <div class="text-right" style="margin-top: 10px">
        <dx-button *ngIf="ndcs?.length > 1" type="default" (onClick)="showNdcGrid()" icon="datafield"></dx-button>
        &nbsp;
        <dx-button *ngIf="isBuyer" type="warning" (onClick)="clearSearch()" text="Clear"></dx-button>
        &nbsp;
        <dx-button type="default" (onClick)="checkNdcAndSearch()" text="Search" [disabled]="searchDisabled"></dx-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isBuyer && listing"  class="row">
  <div class="col-6">
    <div class="pl-4 pr-4 pb-2 pt-2">
      <h4>Request Details</h4>
      <hr/>
      <div class="dx-fieldset">

        <div class="dx-field">
          <div class="dx-field-label" style="width: 75%">Requested Pack Quantity/{{rfpService.PERIOD_MAP[period]}}:</div>
          <div class="dx-field-value">
            <dx-number-box [(value)]="quantity" [min]="1" [showSpinButtons]="false" [step]="0" [showClearButton]="false">
            </dx-number-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label" style="width: 75%">Calculated Units/{{rfpService.PERIOD_MAP[period]}}:</div>
          <div class="dx-field-value">
            <span>{{quantity * listing?.csp || 0}}</span>
          </div>
        </div>
        <div class="dx-field">
            <div class="dx-field-label" style="width: 75%">Contract Duration (# of months):</div>
            <div class="dx-field-value">
              <dx-select-box [items]="rfpService.CONTRACT_LENGTH" [(value)]="duration"></dx-select-box>
            </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label" style="width: 75%">Purchase Frequency:</div>
          <div class="dx-field-value">
            <dx-select-box 
              [dataSource]="rfpService.PERIOD_LIST" 
              [(value)]="period"
              valueExpr="value" 
              displayExpr="name"
            >
            </dx-select-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label" style="width: 75%">Requested Start Date<app-ms-tooltip id="rfp_st_dt" tooltipText="First PO Generation Date"></app-ms-tooltip>:</div>
          <div class="dx-field-value">
            <dx-date-box [min]="minDate" [(value)]="startDate" type="date"> </dx-date-box>
          </div>
        </div>

        <div class="dx-field m-0">
          <div class="dx-field-label" style="width: 75%">Submit To Suppliers:</div>
          <div class="dx-field-value">
            <dx-load-indicator *ngIf="loadingSuppliersGcn" height="20" width="20"></dx-load-indicator>
            <span *ngIf="suppliersGcn?.length">{{suppliersSelectedCount}} out of {{suppliersGcn.length}}</span>
            <dx-button *ngIf="suppliersGcn?.length" type="default" (onClick)="showShowSubmitTo()" icon="add"></dx-button>
          </div>
        </div>
        <div *ngIf="suppliersSelected?.length" class="dx-field">
          <span *ngFor="let supplier of suppliersSelected.slice(0, 3)" class="badge badge-pill badge-light">
            {{supplier.name}}
          </span>
          <span *ngIf="suppliersSelected.length>3">... </span>
        </div>

        <div class="dx-field mt-2">
          <div class="dx-field-label" style="width: 75%">Total Packs:</div>
          <div *ngIf="!rfp && quantity && duration" class="dx-field-value">
            {{quantity || 0}} packs every {{rfpService.PERIOD_MAP[period]}} for {{duration}} months totaling <b>{{duration*quantity*rfpService.PERIOD_DURATION_MAP[period] || 0}}</b> packs
          </div>
          <div *ngIf="rfp" class="dx-field-value">
            {{rfp?.quantity}} packs every {{rfpService.PERIOD_MAP[rfp?.period]}} for {{rfp?.duration}} months totaling <b>{{rfp?.duration * rfp?.quantity}}</b> packs
          </div>
        </div>

        <div class="row m-0 p-0">
          <div class="col-12 text-right m-0 p-0">
            <dx-check-box class="ml-2" [(value)]="isAcknowledged" text=""></dx-check-box>&nbsp;&nbsp;
            <button (click)="showAcknowledge()" class="btn btn-link px-0" type="button">User Acknowledgement</button>
          </div>
          <div class="col-12 text-right m-0 p-0">
            <dx-button *ngIf="!rfp?.id" type="warning" text="Clear" style="margin-right: 10px"(onClick)="clearSearch()" width="100"></dx-button>
            <dx-button *ngIf="!rfp?.id" type="danger" text="Cancel" style="margin-right: 10px" (onClick)="cancelRfp()" width="100"></dx-button>
            <dx-button *ngIf="rfp?.id" type="danger" text="Cancel" style="margin-right: 10px" (onClick)="changeRequestStatus(rfp.id, 'cancelled')" width="100"></dx-button>
            <dx-button [disabled]="!isAcknowledged || !suppliersSelectedCount || !quantity" type="default" text="Submit" (onClick)="askPlaceRequest()" width="100"></dx-button>
          </div>
        </div>
        <br>
        <div *ngIf="validationErrorList?.length" class="alert alert-danger" role="alert">
          <p *ngFor="let error of validationErrorList">{{error}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6" style="border-left: 1px solid rgba(134, 134, 134, 0.489);">
    <div class="pl-4 pr-4 pb-2 pt-2">
      <h4>Product Details</h4>
      <hr/>
      <app-ndc-description [listing]="listing" [amu]="amu"></app-ndc-description>
    </div>
  </div>
</div>

<dx-popup *ngIf="showNdcTable"
          title="Search Results" [showTitle]="true"  [dragEnabled]="true"
          [height]="'auto'" [width]="900" [showCloseButton]="true" [hideOnOutsideClick]="true"
          [(visible)]="showNdcTable" [resizeEnabled]="false">
  <dx-data-grid [dataSource]="ndcs" keyExpr="ndc" [columnAutoWidth]="true" [showBorders]="true" [cacheEnabled]="false">
    <dxo-header-filter [visible]="false"></dxo-header-filter>
    <dxo-filter-row  [visible]="true"></dxo-filter-row>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxi-column caption="Product Name" dataField="ln60" dataType="string"></dxi-column>
    <dxi-column caption="Unit Size" dataField="listing_package_size" dataType="string"></dxi-column>
    <dxi-column caption="Type" dataField="listing_package_type" dataType="string"></dxi-column>
    <dxi-column caption="Select" cellTemplate="actionTemplate"></dxi-column>
    <div *dxTemplate="let ndc of 'actionTemplate'" class="dx-font-m">
      <dx-button type="default" (onClick)="setNdc(ndc.data)" icon="rfp" ></dx-button>
    </div>
  </dx-data-grid>
</dx-popup>

<dx-popup *ngIf="showSubmitTo"
          title="Submit To Suppliers" [showTitle]="true"  [dragEnabled]="true"
          [height]="'auto'" [width]="'auto'" [maxWidth]="900" [minWidth]="500" [showCloseButton]="true" [hideOnOutsideClick]="true"
          [(visible)]="showSubmitTo" [resizeEnabled]="false">
  <div class="row">
    <div *ngFor="let supplier of suppliersGcn" class="col-4 mb-1">
      <dx-check-box [(value)]="supplier.included" [text]="supplier.name" (onValueChanged)="calcSelectedSuppliers()" ></dx-check-box>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right">
      <dx-button (onClick)="hideSubmitTo()" text="Ok" type="default" width="100"></dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup *ngIf="isSeller" [(visible)]="showAcknowledgePopup" [hideOnOutsideClick]="true" [dragEnabled]="true" [height]="600"
          [resizeEnabled]="true" [showTitle]="true" [width]="600" class="popup" title="Acknowledge Terms" [showCloseButton]="true">
  <dx-scroll-view>
    <p>
      By clicking “Submit”, you agree that you are authorized to provide a proposal on behalf of your organization. 
      You also agree that your organization can fill the orders at the price, quantity, and frequency as stated in 
      your proposal. The requestor will have the ability to accept or decline the proposal prior to the initial 
      order being processed. The purchase orders for the subsequent months will be automatically generated. 
      An accepted proposal is a legally binding agreement between you and the requestor. Unless the product is 
      damaged in transit or if the incorrect product or quantity was mistakenly shipped, no product that is ordered 
      will be subject to refunds or return. No cancellations or decreases to the agreed upon quantities are permitted.
    </p>
    <p>
      Medigi is a technology company that provides services on behalf of pharmaceutical manufacturers, including an 
      eCommerce platform for facilitating the sale of products and certain billing, payment, and collection services 
      in connection with such sales. Medigi does not at any time possess the products and is not in any way responsible 
      for the products ordered through its platform or any liability arising therefrom. Your use of the platform is 
      subject to the terms and conditions set forth in the participation agreement with Medigi, and MedShorts shall 
      pay such invoices, in accordance with the terms of such agreement.
    </p>
  </dx-scroll-view>
</dx-popup>

<dx-popup *ngIf="isSeller && declineRfpVisible" title="Decline Request" [hideOnOutsideClick]="true"
          [showTitle]="true"  [dragEnabled]="true"
          [height]="'auto'" [width]="600" [showCloseButton]="true" 
          [(visible)]="declineRfpVisible" [resizeEnabled]="false">

          <dx-text-area [height]="150" [maxLength]="2000" [(value)]="declineReason"> </dx-text-area>
          <div class="text-right mt-2">
            <dx-button (onClick)="declineRequest()" text="Submit" type="default" width="100"></dx-button>
          </div>
</dx-popup>

<dx-popup *ngIf="isBuyer" [(visible)]="showAcknowledgePopup" [hideOnOutsideClick]="true" [dragEnabled]="true" [height]="600"
          [resizeEnabled]="true" [showCloseButton]="true" [showTitle]="true" [width]="600" class="popup" title="User Acknowledgement">
  <dx-scroll-view>
    <p>
      By clicking “Submit”, you agree that you are authorized to request a proposal on behalf of {{facilityName}} 
      with the intent to source the product through Medigi. {{facilityName}} also agrees to keep the proposal 
      from manufacturer(s) confidential. {{facilityName}} will have the ability to accept or decline the proposal 
      prior to the initial order being processed. Requesting a proposal from manufacturer(s) does not obligate 
      you to accept any proposal once received. You will have the opportunity to review proposal(s) and accept or deny. 
      If the proposals are not submitted within the allotted timeframe, they will expire. 
    </p>
    <p>
      Medigi is a technology company that provides services on behalf of pharmaceutical manufacturers, including an 
      eCommerce platform for facilitating the sale of products and certain billing, payment, and collection services 
      in connection with such sales. Medigi does not at any time possess the products and is not in any way responsible 
      for the products ordered through its platform or any liability arising therefrom. Your use of the platform is 
      subject to the terms and conditions set forth in the participation agreement with Medigi, and MedShorts will 
      invoice {{facilityName}}, and {{facilityName}}  shall pay such invoices, in accordance with the terms of such 
      agreement.
    </p>
  </dx-scroll-view>
</dx-popup>

<dx-load-panel [position]="{ of: '#rfp' }" [(visible)]="showSpinner" [showIndicator]="true" [showPane]="true"
               [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
