import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferHistoryComponent } from './offer-history.component';


@NgModule({
    imports: [CommonModule],
    declarations: [OfferHistoryComponent],
    exports: [OfferHistoryComponent]
  })
  export class  OfferHistoryModule { }
