<div class="container">
    <div>
        <p *ngIf="overSixMonths" class="text-info">
            Looks like your history is at least 6 months out of date, please upload a more recent file for more accurate savings.
        </p>
        <p class="text-justify">By updating your purchase history, you put our high-tech
            algorithms to work to deliver products and pricing that reflect your pharmacy's true dollars saved. Simply
            export a <b>6 to 12-month</b> purchase history using your wholesaler reporting tool and upload it below.
        </p>
        <p style="margin-bottom: 0;">
            Know your true dollars saved by uploading your pharmacy’s historic data to your portal. 
            Simply export a 3-month purchase history using your wholesaler reporting tool and upload it below. 
            <b>This report should include the following:</b>
        </p>
        <ul class="ml-3">
            <li>Invoice date</li>
            <li>DEA#</li>
            <li>NDC</li>
            <li>Label Name</li>
            <li>Sold Quantity</li>
            <li>Pack Price</li>
            <li>Purchase Type: GPO/340B/WAC</li>
        </ul>
        <p>
            <a class="mdg-green" href="assets/docs/Purchase History Template.xlsx">
                Please refer to our medigi Purchase History Template
                <span class="dx-icon-link"></span>
            </a>
        </p>
        <p class="text-justify" style="margin-bottom: 0;"> 
            Within <b>48-72 hours</b> of the purchase history upload: <br>
            Our team will calibrate the file and fully implement your historic data into your catalog.
            The gray pricing flags within a product listing turn blue, and your savings projections are based on actual dollars.
            All users from your pharmacy will be able to make procurement decisions with savings defined from Actual Acquisition
            Costs (AAC) instead of the default Wholesale Acquisition Costs (WAC) for each listing.
            All outbound analytics in your medigi Reports will include true dollars saved going forward.
        </p>
    </div>
    <app-document-upload
        *ngIf="companyId && facilityId"
        context="facility" 
        type="Calibration_File"
        [showDelete]="true" 
        [companyId]="companyId"
        [facilityId]="facilityId"
        [allowedFileExtensions]="['.xlsx', '.XLSX']"
        [persistUploadButton]="true">
    </app-document-upload>
    <div class=" alert alert-info">
        Allow 48 hours for our team to calibrate your history file.
    </div>
</div>