import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'versionUrl'
})
export class VersionUrlPipe implements PipeTransform {
  transform(inputUrl: string, delta?: number) {
      const url = new URL(inputUrl);
      const params = new URLSearchParams(url.search);

      params.append('v', `${Date.now().toString()}_${delta || 0}`);
      url.search = params.toString();
      return url.toString();
  }
}
