import {Component, OnInit, Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import { PipeModule } from 'src/app/pipe.module';
import { SellerCompanyLogoModule } from '../seller-company-logo/seller-company-logo.module';
import { DxGalleryModule, DxPopupModule } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import {UserService} from "../../services/user/user.service";

@Component({
  selector: 'app-listing-details',
  templateUrl: './listing-details.component.html',
  styleUrls: ['./listing-details.component.scss']
})
export class ListingDetailsComponent implements OnInit {
  @Input() listing: any;
  imagePopupVisible = false;
  currentImage: any;
  listingExpiryDates = [];
  openProductSpecsPopup: boolean = false;
  hydraEnabled = environment.hydraEnabled;
  constructor(private userService: UserService) { }

  ngOnInit() {
    // Back end event raise for Kibana
    if(this.listing.lots.length > 2){
      this.listingExpiryDates.push(this.listing.lots.at(-1)?.expiry_date);
      this.listingExpiryDates.push(' - ');
      this.listingExpiryDates.push(this.listing.lots[0]?.expiry_date);
    }
    this.userService.logEvent('view_listing_details', {
      'listing_id': this.listing.id,
      'listing_name': this.listing.name
    });
  }

  openProductSpecs() {
    this.openProductSpecsPopup = true;
  }

  documentOpen = (document) => window.open(document.url, '_blank').focus();
  showImage(image) {
    this.currentImage = image;
    this.imagePopupVisible = true;
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SellerCompanyLogoModule,
    PipeModule,
    DxPopupModule,
    DxGalleryModule
  ],
  declarations: [ ListingDetailsComponent ],
  exports: [ ListingDetailsComponent ]
})
export class ListingDetailsModule { }
