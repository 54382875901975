import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectService {

  constructor(private http: HttpClient, private auth0: Auth0Service, private router: Router) { }

  public createDirectBlock$(data): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/direct_blocks`, data);
  }

  public getDirectBlocks$(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/direct_blocks`, {params});
  }

  public updateDirectBlock$(id, data): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/direct_blocks/${id}`, data);
  }

  public updateDirectBlockOrder$(id, up): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/direct_blocks/update_order/${id}`, { id, up });
  }

  public deleteDirectBlock$(id): Observable<any> {
    return this.http.delete<any>(`${environment.api_url_v1}/services/user/direct_blocks/${id}`);
  }

  public getDirectClient$(params?): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/direct_blocks/client`, {params});
  }

  public reinsertLinks(elementRef, renderer) {
    const links = <HTMLAnchorElement[]>elementRef.nativeElement.getElementsByTagName('a');
    if (!links) {return;}
    const linksInitialLength = links.length;
    for (let i = 0; i < linksInitialLength; i++) {
      const link = links[i];
      if (link.host === window.location.host) {
        const nav = link.href.replace(link.host, '').replace(link.protocol, '').replace('//', '')
        renderer.listen(link, 'click', event => {
          event.preventDefault();
          if (nav === '/login') {
            this.auth0.login();
          } else {
            this.router.navigate([nav]);
          }
        });
      }
    }
  }
}
