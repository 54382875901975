import { Component, OnInit, Input, OnChanges, SimpleChanges, NgModule } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { CommonModule } from '@angular/common';
import { DxDataGridModule } from 'devextreme-angular';
import { OfferService } from 'src/app/shared/services/order/offer.service';
import { PipeModule } from 'src/app/pipe.module';

@Component({
  selector: 'app-offer-lots-distribution',
  templateUrl: './offer-lots-distribution.component.html'
})
export class OfferLotsDistributionComponent implements OnInit, OnChanges {

  @Input() listingId: number;
  @Input() quantity: number;
  @Input() earliestExpiry: number;

  lots: any[] = [];

  constructor(private offerService: OfferService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quantity?.currentValue) {
      this.getDistributedLots();
    }
  }

  ngOnInit() {}

  getDistributedLots() {
    const data = {
      listing_id: this.listingId,
      quantity: this.quantity,
      earliest_expiry: this.earliestExpiry
    };

    this.offerService.getDistributedLots(data).subscribe(
      result => this.lots = result.data,
      error => notify({message: error, closeOnClick: true, height: 50, position: 'top'}, 'error', 5000)
    );
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    PipeModule
  ],
  exports: [ OfferLotsDistributionComponent ],
  declarations: [ OfferLotsDistributionComponent ]
})
export class OfferLotsDistributionModule { }
