import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SessionData } from 'src/app/shared/models/session';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../../services/user/company.service';

@Component({
  selector: 'app-medigi-clickwrap',
  templateUrl: './medigi-clickwrap.component.html',
  styleUrls: ['./medigi-clickwrap.component.scss']
})
export class MedigiClickwrapComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<any>();

  showClickwrapPopup = false;
  session: SessionData;
  currentDate;
  termsUrl: string;
  apiUrl: string;

  constructor(private userService: UserService,
              private companyService: CompanyService) { }

  ngOnInit() {
    this.apiUrl = environment.api_url_v2;
    this.currentDate = new Date();
    this.userService.getMe().subscribe(resp => {
      this.session = resp.data;
    });
  }

  onClose(ev) {
    this.visibleChange.emit(ev.value);
  }

  openClickwrapPopup() {
    this.showClickwrapPopup = true;
  }

  closeClickwrapPopup() {
    this.showClickwrapPopup = false;
  }

  transferCompany() {
    this.companyService.updateCompany(this.session.company_id, {agreed_to_medigi: 1}).subscribe(() => {
        this.closeClickwrapPopup();
        window.location.href = `${location.protocol}//${environment.clientDomains.medigiDomain}/catalog`;
    });
  }
}
