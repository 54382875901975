import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellerCompanyLogoComponent } from './seller-company-logo.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SellerCompanyLogoComponent],
  exports: [SellerCompanyLogoComponent]
})
export class SellerCompanyLogoModule { }
