import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-calibration-file-upload',
  templateUrl: './calibration-file-upload.component.html',
  styleUrls: ['./calibration-file-upload.component.scss']
})
export class CalibrationFileUploadComponent implements OnInit {
  @Input() facilityId;
  @Input() companyId;
  @Input() overSixMonths;

  constructor(
    private userService: UserService, 
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    if (!this.facilityId || !this.companyId) {
      this.getMe();
    }
  }

  getMe() {
    this.userService.getMe().subscribe(result => {
      this.facilityId = result.data.primary_facility.id;
      this.companyId = result.data.company_id;
    });
  }

  getFacilityInfo() {
    this.sessionService.primaryFacilityUpdatedTrigger.subscribe(() => this.getMe());
  }
}
