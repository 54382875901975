import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPanelComponent } from './search-panel.component';
import { DxButtonModule, DxTextBoxModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    DxTextBoxModule,
    DxButtonModule
  ],
  declarations: [SearchPanelComponent],
  exports: [SearchPanelComponent]
})
export class SearchPanelModule { }
