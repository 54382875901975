import { CommonModule } from '@angular/common';
import {Component, OnInit, Input, NgModule, Output, EventEmitter } from '@angular/core';
import { CatalogService } from '../../services/listing/catalog.service';
import { NotificationService } from '../../services/notification.service';
import { confirm } from 'devextreme/ui/dialog';
import { MedigiPopupModule } from '../medigi-popup/medigi-popup.module';
import { DxButtonModule, DxCheckBoxModule, DxRadioGroupModule, DxTextAreaModule, DxValidatorModule } from 'devextreme-angular';
import { UserService } from '../../services/user/user.service';
import { MsTooltipModule } from '../ms-tooltip/ms-tooltip.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listing-wishlist-toggle',
  templateUrl: './listing-wishlist-toggle.component.html',
  styleUrls: ['./listing-wishlist-toggle.component.css']
})
export class ListingWishlistToggleComponent implements OnInit {

  @Input() listing: any;
  @Input() allowToggling: boolean;
  @Output() onRecordUpdate = new EventEmitter<boolean>();

  hydraEnabled = environment.hydraEnabled;

  softBlockTypes = [];
  softBlockMap = {};

  selectedSoftBlockType = 'Listing';

  softBlockPopup = false;
  softBlockReason: any;
  currentSoftBlock: any;
  currentUser: any;

  catalogTypeMap = {
    'medigi': 'medigi',
    'medshorts': 'MedShorts',
    '503b': '503B',
  };

  constructor(
    private catalogService: CatalogService,
    private userService: UserService,
    private notifyService: NotificationService
  ) {}


  ngOnInit() {
    let gcnText = 'This item from any manufacturer';
    if (this.listing.active_ingredients.ingredient) {
      gcnText = `${gcnText} (${this.listing.active_ingredients.ingredient} ${this.listing.active_ingredients.concentration})`
    }

    this.softBlockTypes = [
      {
        'value': 'Listing',
        'text': `This NDC only (${this.listing.ndc})`
      },
      {
        'value': 'GCN',
        'text': gcnText
      },
      {
        'value': 'Seller Company',
        'text': `All listings from ${this.listing.company_name}`
      },
      {
        'value': 'Catalog Type',
        'text': `All listings from this catalog (${this.catalogTypeMap[this.listing.catalog_type]})`
      }
    ];
  
    this.softBlockMap = {
      'Listing': this.listing.name,
      'Seller Company': this.listing.company_name,
      'Catalog Type': this.catalogTypeMap[this.listing.catalog_type],
      'GCN': `${this.listing.active_ingredients.ingredient || this.listing.gcn_seqno} ${this.listing.active_ingredients.concentration}`
    }
  }

  addToWishlist() {
    if(!this.allowToggling){return};
    this.catalogService.addToWishlist(this.listing).subscribe(
      () => {
        this.notifyService.showNotify(`${this.listing.name} added to favorites`, 'success');
        this.onRecordUpdate.emit(true);
      },
      error => this.notifyService.showNotify(error, 'error'));
  }

  removeFromWishlist(favoriteId, softBlock=false) {
    if(!this.allowToggling){return};
    this.catalogService.removeFromWishlist(favoriteId).subscribe(
      () => {
        this.notifyService.showNotify(`${this.listing.name} removed from favorites`, 'warning');
        this.onRecordUpdate.emit(true);
      },
      error => this.notifyService.showNotify(error, 'error'));
  }


  upsertSoftBlock(e) {
    const data = this.getSoftblockData();
    if (this.listing.soft_block_id) {
      this.updateSoftBlock();
    } else {
      this.addToSoftBlock(data);
    }
    e.preventDefault();
  }

  addToSoftBlock(data: {}) {
    let result = null;
    if (this.selectedSoftBlockType === 'Listing') {
      result = confirm(`<i>This will mark ${this.softBlockMap[this.selectedSoftBlockType]} as soft-blocked. Are you sure?</i>`, 'Confirm status update.');
    } else if (this.selectedSoftBlockType === 'Seller Company') {
      result = confirm(`<i>This will mark all listings from ${this.softBlockMap[this.selectedSoftBlockType]} as soft-blocked. Are you sure?</i>`, 'Confirm status update.');
    } else if (this.selectedSoftBlockType === 'Catalog Type') {
      result = confirm(`<i>This will mark all listings from the ${this.softBlockMap[this.selectedSoftBlockType]} catalog as soft-blocked. Are you sure?</i>`, 'Confirm status update.');
    } else if (this.selectedSoftBlockType === 'GCN') {
      result = confirm(`<i>This will mark all similar listings from any manufacturer as soft-blocked. Are you sure?</i>`, 'Confirm status update.');
    }
    result.then((dialogResult) => {
      if (dialogResult) {
        this._addToSoftBlock(data);
      }
    });
  }

  getSoftblockData() {
    if (this.selectedSoftBlockType === 'Listing') {
      return { listing_id: this.listing.id, comment: this.softBlockReason };
    } else if (this.selectedSoftBlockType === 'Seller Company') {
      return { seller_company_id: this.listing.company_id, comment: this.softBlockReason };
    } else if (this.selectedSoftBlockType === 'Catalog Type') {
      return { catalog_type: this.listing.catalog_type, comment: this.softBlockReason };
    } else if (this.selectedSoftBlockType === 'GCN') {
      return { gcn: this.listing.gcn_seqno, comment: this.softBlockReason };
    }
  }

  _addToSoftBlock(data: {}) {
    this.catalogService.addToSoftBlock(data).subscribe(
      () => {
        this.notifyService.showNotify(`Soft-blocked items updated`, 'success');
        this.softBlockPopup = false;
        this.softBlockReason = null;
        this.onRecordUpdate.emit(true);
      },
      error => this.notifyService.showNotify(error, 'error')
    );
  }

  updateSoftBlock() {
    this.catalogService.updateSoftBlock(this.currentSoftBlock.id, {comment: this.softBlockReason}).subscribe(
      () => {
        this.notifyService.showNotify(`Soft-blocked items updated`, 'success');
        this.softBlockPopup = false;
        this.softBlockReason = null;
        this.onRecordUpdate.emit(true);
      },
      error => this.notifyService.showNotify(error, 'error')
    );
  }

  removeFromSoftBlock() {
    let result = null;
    if (this.selectedSoftBlockType === 'Listing') {
      result = confirm(`<i>This will mark ${this.softBlockMap[this.selectedSoftBlockType]} as non soft-blocked. Are you sure?</i>`, 'Confirm status update.');
    } else if (this.selectedSoftBlockType === 'Seller Company') {
      result = confirm(`<i>This will mark all listings from ${this.softBlockMap[this.selectedSoftBlockType]} as non soft-blocked. Are you sure?</i>`, 'Confirm status update.');
    } else if (this.selectedSoftBlockType === 'Catalog Type') {
      result = confirm(`<i>This will mark all listings from the ${this.softBlockMap[this.selectedSoftBlockType]} catalog as non soft-blocked. Are you sure?</i>`, 'Confirm status update.');
    } else if (this.selectedSoftBlockType === 'Catalog Type') {
      result = confirm(`<i>This will mark all similar listings from any manufacturer as non soft-blocked. Are you sure?</i>`, 'Confirm status update.');
    }
    
    result.then((dialogResult) => {
      if (dialogResult) {
        this._removeFromSoftBlock(this.listing.soft_block_id);
      }
    });
  }

  _removeFromSoftBlock(softBlockId) {
    this.catalogService.removeFromSoftBlock(softBlockId).subscribe(
      () => {
        this.notifyService.showNotify(`${this.softBlockMap[this.selectedSoftBlockType]} ${this.selectedSoftBlockType} removed from soft-block`, 'warning');
        this.onRecordUpdate.emit(true);
      },
      error => this.notifyService.showNotify(error, 'error'));
  }

  getSoftBlock(softBlockId) {
    this.catalogService.getSoftBlock(softBlockId).subscribe(
      result => {
        this.currentSoftBlock = result.data;
        this.softBlockReason = this.currentSoftBlock.comment;
        if (this.currentSoftBlock.listing_id) {
          this.selectedSoftBlockType = 'Listing';
        } else if (this.currentSoftBlock.seller_company_id) {
          this.selectedSoftBlockType = 'Seller Company';
        } else if (this.currentSoftBlock.catalog_type) {
          this.selectedSoftBlockType = 'Catalog Type';
        } else if (this.currentSoftBlock.gcn_seqno) {
          this.selectedSoftBlockType = 'GCN';
        }
      },
      error => this.notifyService.showNotify(error, 'error'));
  }

  showSoftBlockPopup() {
    if(!this.allowToggling){return};
    if (this.listing.soft_block_id) {
      this.getSoftBlock(this.listing.soft_block_id);
      this.getMe();
    }
    this.softBlockPopup = true;
  }

  getMe() {
    this.userService.getMe().subscribe(
      result => this.currentUser = result.data
    );
  }

  closeSoftBlockPopup() {
    this.softBlockPopup = false;
  }

  onSoftBlockEditorHide() {
    this.softBlockReason = null;
  }

}

@NgModule({
  imports: [
    CommonModule,
    MedigiPopupModule,
    MsTooltipModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxRadioGroupModule
  ],
  exports: [ ListingWishlistToggleComponent ],
  declarations: [ ListingWishlistToggleComponent ]
})
export class ListingWishlistToggleModule { }
