import { Component, Input, OnInit } from '@angular/core';
import { phonePattern } from 'src/app/shared/models/constants';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CsosService } from 'src/app/shared/services/user/csos.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-csos-signup',
  templateUrl: './csos-signup.component.html',
  styleUrls: ['./csos-signup.component.scss']
})
export class CsosSignupComponent implements OnInit {

  @Input() companyId: number;
  @Input() companyName: string;

  signupVisible = false;
  facilityName = '';

  phonePattern = phonePattern;
  countries: string[];

  contactInfo = {
    id: 0,
    email: '',
    name: '',
    dea: '',
    phone: '',
    csos: false
  };

  userIsCSOS = false;
  csosSignUpRequested = false;

  currentUser: any;

  constructor(private notifyService: NotificationService,
              private csosService: CsosService,
              private userService: UserService) { }

  ngOnInit() {
    this.initialSetup();
  }

  signupVisibleEnable() {
    this.signupVisible = true;
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.csosSignUp();
    this.signupVisible = false;
  }

  csosSignUp() {
    this.contactInfo.id = this.companyId;
    this.csosService.csosSignUp(this.contactInfo).subscribe(
      () => {
        this.notifyService.showNotify(`You have successfully requested registration with ${this.companyName} for CII purchases`, 'success');
        this.initialSetup();
      },
      error => this.notifyService.showNotify(error, 'error'),
    );
  }

  initialSetup() {
    this.userService.getMe().subscribe(result => {
      this.currentUser = result.data;
      this.contactInfo.dea = result.data.primary_facility?.dea_number;
      this.facilityName = result.data.primary_facility?.name;
      const status = result.data.primary_facility?.csos_requested?.includes(this.companyId);
      this.csosSignUpRequested = status || false;
    });
  }

  onUserIsCSOSChanged() {
    this.contactInfo = {
      id: this.companyId,
      email: this.userIsCSOS ? this.currentUser.email : '',
      name: this.userIsCSOS ? `${this.currentUser.first_name} ${this.currentUser.last_name}` : '',
      dea: this.currentUser.primary_facility?.dea_number,
      phone: this.userIsCSOS ? this.currentUser.phone : '',
      csos: this.userIsCSOS
    };
  }

}
