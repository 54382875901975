import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ndc-description',
  templateUrl: './ndc-description.component.html'
})
export class NdcDescriptionComponent {
  @Input() listing: any;
  @Input() isBuyer: any;
  @Input() isSeller: any;
  @Input() amu: any;

  constructor() { }

}
