import { Address, DeaData } from './dea';

export class RegistrationData {
  company: CompanyData;
  user: UserData;
  facility: FacilityData;
  terms: TermsData;
}

export class SellerRegistrationData {
  company: SellerCompanyData;
  user: UserData;
  address: Address;
}

export class CompanyData {
  name: string;
  gpo_name: string;
  gpo_sub_group_name: string;
  gpo_name_other: string;
  wholesaler: string;
  referred_by: string;
  vendor_app_str: string;
  vendor_app: boolean;
  payment_option: string;
  tax_id: string;
}

export class SellerCompanyData {
  name: string;
}

export class UserData {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone: string;
  phone_ext: string;
  password: string;
  password_confirm: string;
}

export class FacilityData {
  delivery_address: Address;
  name: string;
  dea_number: string;
  dea_data: any;
  is340b: boolean;
  id_340b: string;
  type: string;
  type_other: string;
  bed_count?: number;
  HIN: string;
  taxonomy_code?: string;
  ap_invoice_email: string;
  ap_invoice_name: string;
}

export class TermsData {
  agree: boolean;
  terms: string;
}

export class PreRegistrationData {
  type: string;
  name: string;
  institution: string;
  email: string;
  department: string;
  phone: string;
  phone_ext: string;
}

export const defaultPreRegistrationData: PreRegistrationData = {
  type: 'request_demo',
  name: '',
  institution: '',
  email: '',
  department: '',
  phone: '',
  phone_ext: '',
};


export class ContactUsData {
  type: string;
  name: string;
  email: string;
  phone: string;
  message: string;
  contact_method?: string;
  contact_time?: string;
}

export const defaultContactUsData: ContactUsData = {
  type: 'general',
  name: '',
  email: '',
  phone: '',
  message: ''
};

export const defaultCareerData: ContactUsData = {
  type: 'Career',
  name: '',
  email: '',
  phone: '',
  message: ''
};

export const defaultTermsData: TermsData = {
  agree: false,
  terms: '',
};

export const defaultFacilityData: FacilityData = {
  name: '',
  dea_number: '',
  delivery_address: null,
  dea_data: null,
  is340b: false,
  id_340b: '',
  type: '',
  type_other: '',
  bed_count: null,
  HIN: '',
  ap_invoice_email: '',
  ap_invoice_name: ''
};

export const defaultRegistrationDataTest: RegistrationData = {
  company: {
    name: 'testName',
    gpo_name: 'Vizient',
    gpo_name_other: '',
    gpo_sub_group_name: '',
    referred_by: '',
    wholesaler: 'AMERISOURCEBERGEN DRUG CORP',
    vendor_app_str: null,
    vendor_app: false,
    payment_option: 'ach_check',
    tax_id: ''
  },
  user: {
    first_name: 'Mike',
    last_name: 'Ivanov',
    title: 'me',
    email: 'mikex1@medshorts.com',
    phone: '(222) 222-2222',
    phone_ext: '',
    password: 'Password1!',
    password_confirm: 'Password1!'
  },
  terms: {
    agree: false,
    terms: ''
  },
  facility: {
    name: '',
    dea_number: 'FG1000000',
    delivery_address: null,
    dea_data: null,
    is340b: false,
    id_340b: '',
    type: '',
    type_other: '',
    bed_count: null,
    HIN: '',
    ap_invoice_email: '',
    ap_invoice_name: ''
  }
};


export const defaultRegistrationData: RegistrationData = {
  company: {
    name: '',
    gpo_name: '',
    gpo_sub_group_name: '',
    gpo_name_other: '',
    referred_by: '',
    wholesaler: '',
    vendor_app_str: null,
    vendor_app: null,
    payment_option: '',
    tax_id: ''
  },
  user: {
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    phone: '',
    phone_ext: '',
    password: '',
    password_confirm: ''
  },
  terms: {
    agree: false,
    terms: ''
  },
  facility: {
    name: '',
    dea_number: '',
    delivery_address: null,
    dea_data: null,
    is340b: false,
    id_340b: '',
    type: '',
    type_other: '',
    bed_count: null,
    HIN: '',
    ap_invoice_email: '',
    ap_invoice_name: ''
  }
};

export const defaultSellerRegistrationData: SellerRegistrationData = {
  company: {
    name: ''
  },
  user: {
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    phone: '',
    phone_ext: '',
    password: '',
    password_confirm: ''
  },
  address: {
    city: '',
    country: '',
    line1: '',
    line2: '',
    line3: '',
    state: '',
    zip: '',
    zipExt: '',
    notes: ''
  }
};
