import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Auth0Guard } from './auth/auth0.guard';
import { RoleGuard } from './auth/role.guard';
import { HydraGuard } from './auth/hydra.guard';

const routes: Routes = [
  // Guest pages
  {
    path: '',
    loadChildren: () => import('./pages/static/static-pages.module').then(m => m.StaticModule)
  },
  {
    path: 'callback',
    loadChildren: () => import('./pages/callback/callback.module').then(m => m.CallbackModule)
  },
  {
    path: 'jwt',
    loadChildren: () => import('./pages/jwt/jwt.module').then(m => m.JwtModule)
  },
  {
    path: 'loading',
    loadChildren: () => import('./shared/components/loading/loading.module').then(m => m.LoadingModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutModule)
  },
  {
    path: 'registration/thankyou',
    loadChildren: () => import('./pages/registration-thankyou/registration-thankyou.module').then(m => m.RegistrationThankyouModule)
  },
  {
    path: 'registration/seller',
    loadChildren: () => import('./pages/registration-seller/registration-seller.module').then(m => m.RegistrationSellerModule)
  },
  {
    path: 'registration/buyer',
    loadChildren: () => import('./pages/registration-admin-user/registration-admin-user.module').then(m => m.RegistrationAdminUserModule)
  },

  {
    path: 'buyer-home',
    loadChildren: () => import('./pages/buyer-home/buyer-home.module').then(m => m.BuyerHomeModule),
    canActivate: [Auth0Guard, RoleGuard, HydraGuard],
    data: {
      role: 'Buyer',
      hydraEnabledFeatrue: false
    }
  },
  {
    path: 'catalog',
    loadChildren: () => import('./pages/buyer-catalog/buyer-catalog.module').then(m => m.BuyerCatalogModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Buyer'
    }
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offers/offers.module').then(m => m.OffersModule),
    canActivate: [Auth0Guard, HydraGuard],
    data: {
      hydraEnabledFeatrue: false
    }
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/order-cart/order-cart.module').then(m => m.OrderCartModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Buyer'
    }
  },
  {
    path: 'cart/success',
    loadChildren: () => import('./pages/order-success/order-success.module').then(m => m.OrderSuccessModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Buyer'
    }
  },
  {
    path: 'seller',
    loadChildren: () => import('./pages/seller-landing/seller-landing.module').then(m => m.SellerLandingModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Seller'
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'review',
    loadChildren: () => import('./pages/review/review.module').then(m => m.ReviewModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'history-details',
    loadChildren: () => import('./pages/item-history/item-history.module').then(m => m.ItemHistoryModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'catalogs',
    loadChildren: () => import('./pages/catalogs/catalogs.module').then(m => m.CatalogsModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'listing-details',
    loadChildren: () => import('./pages/listing/listing.module').then(m => m.ListingModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Seller'
    }
  },
  {
    path: 'detailed/:id',
    loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'seller-home',
    loadChildren: () => import('./pages/seller-landing/seller-landing.module').then(m => m.SellerLandingModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Seller'
    }
  },
  {
    path: 'seller-profile',
    loadChildren: () => import('./pages/seller-profile/seller-profile.module').then(m => m.SellerProfileModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'seller-editor',
    loadChildren: () => import('./pages/seller-profile-editor/seller-profile-editor.module').then(m => m.SellerProfileEditorModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Seller'
    }
  },
  {
    path: 'manage-listings',
    loadChildren: () => import('./pages/listing-editor/listing-editor.module').then(m => m.ListingEditorModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Seller'
    }
  },
  {
    path: 'rfps',
    loadChildren: () => import('./pages/rfps/rfps.module').then(m => m.RfpsModule),
    canActivate: [Auth0Guard, HydraGuard],
    data: {
      hydraEnabledFeatrue: false
    }
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./pages/seller-portfolio/seller-portfolio.module').then(m => m.SellerPortfolioModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'pharmacies',
    loadChildren: () => import('./pages/pharmacies/pharmacies.module').then(m => m.PharmaciesModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Buyer'
    }
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'requested-products',
    loadChildren: () => import('./pages/requested-products/requested-products.module').then(m => m.RequestedProductsModule),
    canActivate: [Auth0Guard, RoleGuard, HydraGuard],
    data: {
      role: 'Buyer',
      hydraEnabledFeatrue: false
    }
  },
  {
    path: 'order-schedule',
    loadChildren: () => import('./pages/order-schedule/order-schedule.module').then(m => m.OrderScheduleModule),
    canActivate: [Auth0Guard, HydraGuard],
    data: {
      hydraEnabledFeatrue: false
    }
  },
  {
    path: 'order-details',
    loadChildren: () => import('./pages/order-details/order-details.module').then(m => m.OrderDetailsModule),
    canActivate: [Auth0Guard]
  },
  {
    path: 'carts',
    loadChildren: () => import('./pages/cart-handler/cart-handler.module').then(m => m.CartHandlerModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Administrator'
    }
  },
  {
    path: 'quicksight',
    loadChildren: () => import('./pages/quicksight/quicksight.module').then(m => m.QuickSightModule)
  },
  {
    path: 'direct-editor/:id',
    loadChildren: () => import('./pages/direct-editor/direct-editor.module').then(m => m.DirectEditorModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Administrator'
    }
  },
  {
    path: 'assistant',
    loadChildren: () => import('./pages/gpt-assistant/gpt-assistant.module').then(m => m.GptAssistantModule)
  },
  {
    path: 'csoscerts',
    loadChildren: () => import('./pages/csos-certificates/csos-certificates-view.module').then(m => m.CsosCertificatesViewModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Buyer'
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Administrator'
    }
  },
  {
    path: 'fdb',
    loadChildren: () => import('./pages/fdb/fdb.module').then(m => m.FdbModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Administrator'
    }
  },
  {
    path: 'hydra-home',
    loadChildren: () => import('./pages/hydra-home/hydra-home.module').then(m => m.HydraHomeModule),
    canActivate: [Auth0Guard, RoleGuard],
    data: {
      role: 'Seller'
    }
  },
  { path: '**', redirectTo: '' }
];


@NgModule({
   imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }) ],
   providers: [ Auth0Guard, RoleGuard ],
   exports: [ RouterModule ],
   declarations: []
})
export class AppRoutingModule { }
