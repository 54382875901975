<nav class="navbar navbar-expand-lg box-shadow">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/"><img class="logo" src="/assets/img/logos/503B_Color.svg" alt="503B Logo"></a>
    <button class="navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
    <div class="collapse navbar-collapse me-auto my-2" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" routerLink="/pharmacy-info"><p>Pharmacy Registration</p></a>
        </li>
        <hr>
        <li class="nav-item">
          <a class="nav-link" routerLink="/manufacturer-info"><p>Outsourcing Facility Registration</p></a>
        </li>
        <hr>
        <li class="nav-item">
          <a class="nav-link" href="#contact-us"><p>Contact Us</p></a>
        </li>
        <hr>
        <li class="nav-item">
          <a class="nav-link" routerLink="" (click)="auth0.login()"><p>Sign In</p></a>
        </li>
      </ul>
    </div>
  </div>
</nav>