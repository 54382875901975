import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ndc'
})
export class NdcPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    value = value && value.toString();
    if( !value || value.length != 11) { return value; }
    else {
      return value.substring(0,5) + "-" + value.substring(5,9) + "-" + value.substring(9,11)
    }
  }

}
