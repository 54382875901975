import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usdCurrency'
})
export class UsdCurrencyPipe implements PipeTransform {

  transform(input: any): any {
    
    if (!input) {
      return '$0';
    }

    if(Number.isFinite(input)){
      return (input).toLocaleString('en-US', { style: 'currency', currency: 'USD', });
    }

    return `$${input}`;

  }

}