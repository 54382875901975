import { environment } from "src/environments/environment";

export class Landing {
    theme: string;
    mainColor: string;
    subColor1: string;
    clientName: string;
    title: string;
    logo: string;
    darkLogo: string;
    contacts: Contacts;
    address: Address;
    registrationLinks: Link[];
    additionalLinks: Link[];
    domainKey: string;
    client: any;
}

export class Contacts {
    site: string;
    phone: string;
    phone_ext: string;
    support_phone: string;
    email: string;
    support_email: string;
    facebook: string;
    twitter: string;
    linkedin: string;
}

export class Address {
    line1: string;
    line2: string;
    line3: string;
    city: string;
    state: string;
    zip: string;
}

export class Link {
    constructor(name, url) {
        this.name = name;
        this.url = url;
    }
    
    name: string;
    url: string;
}

export const medigiLanding: Landing = {
    clientName: 'Medigi',
    theme: 'material.medigi',
    mainColor: 'rgba(1, 32, 58, 1)',
    subColor1: 'rgba(100, 192, 117, 1)',
    logo: '/assets/img/logos/medigi-logo.webp',
    darkLogo: '/assets/img/logos/medigi-logo.png',
    title: 'Medigi',
    domainKey: 'medigi',
    contacts: {
        site: 'https://www.medigi.com/',
        phone: '(844)-733-5633',
        phone_ext: null,
        email: 'info@medigi.com',
        support_phone: '(844)-733-5633',
        support_email: '',
        facebook: '',
        twitter: '',
        linkedin: 'https://www.linkedin.com/company/medigi/',
    },
    address: {
        line1: '200 EAST ROBINSON STREET',
        line2: 'SUITE 1250',
        line3: null,
        city: 'ORLANDO',
        state: 'FL',
        zip: '32801'
    },
    registrationLinks: [{name: "Privacy Policy", url: "https://www.medigi.com/privacy-policy/ "}],
    additionalLinks: [],
    client: false
};

export const medshortsLanding: Landing = {
    clientName: 'MedShorts',
    theme: 'material.medigi',
    mainColor: 'rgba(54, 168, 173, 1)',
    subColor1: 'rgba(186, 218, 85, 1)',
    logo: '/assets/img/logos/ms_full_logo_white.png',
    darkLogo: '/assets/img/logos/ms_teal_logo.svg',
    title: 'MedShorts',
    domainKey: 'medshorts',
    contacts: {
        site: 'https://www.medshorts.com/',
        phone: '(844)-733-5633',
        phone_ext: null,
        email: 'info@medshorts.com',
        support_phone: '(844)-733-5633',
        support_email: '',
        facebook: '',
        twitter: '',
        linkedin: 'https://www.linkedin.com/company/medshorts/',
    },
    address: {
        line1: '200 EAST ROBINSON STREET',
        line2: 'SUITE 1250',
        line3: null,
        city: 'ORLANDO',
        state: 'FL',
        zip: '32801'
    },
    registrationLinks: [{name: "Privacy Policy", url: "https://www.medshorts.com/privacy-policy/ "}],
    additionalLinks: [],
    client: false
};

export const outsourcingLanding: Landing = {
    clientName: '503B',
    theme: 'material.medigi',
    mainColor: 'rgba(2, 64, 89, 1)',
    subColor1: 'rgba(78, 164, 217, 1)',
    logo: '/assets/img/logos/503B_Color.svg',
    darkLogo: '/assets/img/logos/503B_Color.svg',
    title: '503B',
    domainKey: '503b',
    contacts: {
        site: 'https://www.503b.com/',
        phone: '(844)-733-5633',
        phone_ext: null,
        email: 'info@503b.com',
        support_phone: '(844)-733-5633',
        support_email: '',
        facebook: '',
        twitter: '',
        linkedin: 'https://www.linkedin.com/company/503b/',
    },
    address: {
        line1: '200 EAST ROBINSON STREET',
        line2: 'SUITE 1250',
        line3: null,
        city: 'ORLANDO',
        state: 'FL',
        zip: '32801'
    },
    registrationLinks: [{name: "Privacy Policy", url: "https://www.503b.com/privacy-policy/ "}],
    additionalLinks: [],
    client: false
};


export const defaultThemesList = ['generic.light', 'generic.dark',
                                    'generic.carmine', 'generic.greenmist',
                                    'material.teal.light.compact', 'material.teal.dark.compact',
                                    'material.medshorts', 'material.503b'];


