<dx-popup *ngIf="isReady && width" id="offer-popup" class="popup" (onHidden)="onClose.emit()" [visible]="true"
  [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="isPopupClosable" [showCloseButton]="isPopupClosable"
  [width]="width" title="Bid Details for {{currentListing?.name}}">
  <dx-scroll-view width="100%" height="100%" [showScrollbar]="'always'">
    <div id="offer">
      <div class="row">
        <div class="col-lg-6 col-12" id="offerdetails">
          <div>
            <img *ngIf="currentListing.catalog_type==='medigi'" class="print_logo"
              src="/assets/img/logos/medigi-logo.png" alt="medigi logo">
            <img *ngIf="currentListing.catalog_type==='medshorts'" class="print_logo"
              src="/assets/img/logos/ms_logo_teal.png" alt="medshorts logo">
            <img *ngIf="currentListing.catalog_type==='503b'" class="print_logo" src="/assets/img/logos/503B_icon.svg"
              alt="503B logo">
          </div>
          <app-offer-listing-details [userType]="userType" [currentListing]="currentListing" [price]="selectedLotPrice"
            [lastOffer]="lastOffer">
          </app-offer-listing-details>
          <app-offer-history *ngIf="currentOffer?.offer_lines.length" [offer]="currentOffer" [userType]="userType"></app-offer-history>
          <div *ngIf="userType === 'seller'">
            <app-offer-lots-distribution [listingId]="currentOffer.listing_id" [quantity]="offerQuantity"
              [earliestExpiry]="earliestExpiryDate?.date">
            </app-offer-lots-distribution>
          </div>
        </div>

        <div class="col-lg-6 col-12" id="bidbox">
          <div class="dx-card responsive-paddings">

            <!-- Buyer -->
            <div *ngIf="userType === 'buyer' && !currentOffer?.id && currentListing" class="row">
              <div class="col-12">
                <h4>Make a Bid</h4>
              </div>
              <div class="col-12">
                <div class="dx-fieldset">
                  <div class="dx-field">
                    <div class="dx-field-label bid-price-left-padding text-left">Minimum Expiry:</div>
                    <div class="dx-field-value">
                      <dx-select-box [height]="25" class="offer-input" [dataSource]="expiryDates" displayExpr="days"
                        valueExpr="date" [value]="earliestExpiryDate?.date"
                        (onValueChanged)="updateEarliestExpiryDate($event)">
                      </dx-select-box>
                    </div>
                  </div>
                  <div class="dx-fieldset">
                    <div class="dx-field">
                      <div class="dx-field-label bid-price-left-padding text-left">Available Stock:</div>
                      <div class="dx-field-value">
                        {{availableStock}} packs
                      </div>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label bid-price-left-padding text-left">Bid Price (per pack):*</div>
                    <div class="dx-field-value">
                      <dx-number-box [height]="25" [(value)]="offerPrice" [showSpinButtons]="false" [min]="0"
                        [max]="selectedLotPrice-0.01" [format]="currencyFormat" [step]="0" placeholder="$0.00"
                        (onValueChanged)="updateCalcs($event)">
                      </dx-number-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label bid-price-left-padding text-left">
                      Bid Quantity: <span id="quantity-help" class="dx-icon-help icon-adjustment"></span>
                    </div>
                    <div class="dx-field-value">
                      <dx-number-box [height]="25" [(value)]="offerQuantity"
                        [max]="currentListing?.case_size > 1 ? availableStock - availableStock % currentListing?.case_size : availableStock"
                        [min]="currentListing?.offer_minimum_quantity || 1" [step]="0" [showSpinButtons]="false"
                        format="#"
                        (onValueChanged)="updateCalcs($event, 'offerQuantity')">
                      </dx-number-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label bid-price-left-padding text-left">PO #:</div>
                    <div class="dx-field-value">
                      <dx-text-box [height]="25" [(value)]="offerPO" [width]="'auto'" maxLength="40">
                      </dx-text-box>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" id="bidsummary">
                <hr class="ltgray-line" />
                <app-offer-total [currentListing]="currentListing" [offerPrice]="offerPrice"
                  [offerQuantity]="offerQuantity" [calculations]="calculations">
                </app-offer-total>
              </div>
              <div class="col-md-12 text-right">
                <hr class="ltgray-line mt-0" />
                <div>
                  <dx-check-box [(value)]="isAcknowledged" [disabled]="!offerQuantity || !offerPrice"
                    text=""></dx-check-box>&nbsp;&nbsp;
                  <button (click)="showAcknowledgePopup = true;" class="btn btn-link ms-green mr-3" id="termack"
                    type="button">
                    Acknowledge Terms
                  </button>

                  <dx-button type="default" (onClick)="makeOffer()" text="Submit"
                    [disabled]="offerQuantity < currentListing?.offer_minimum_quantity || !isAcknowledged || minRequired || disableButtons || !currentListing.stock || currentListing.stock <= 0 && offerPrice < 0 && offerQuantity < 0">
                  </dx-button>

                </div>
              </div>
              <div *ngIf="onVacation" class="col-md-12 mt-2" style="color: orange;">
                *Note that the seller is currently on vacation from {{vacationStartDate}} until {{ vacationEndDate }}
              </div>
              <div class="col-md-12 mt-2 text-danger">
                {{ errorMessage }}
              </div>
            </div>

            <div *ngIf="userType === 'buyer' && currentOffer?.status === 'initial_offer'">
              Your offer is pending seller action.
            </div>

            <div *ngIf="minRequired" class="text-danger">
              {{companyName}} requires a minimum order of {{minimumOrderTotal | usdCurrency}}.
              Please add an additional {{minimumOrderTotal - offerPrice * offerQuantity | usdCurrency}} to meet the
              requirement.
            </div>

            <div *ngIf="minQtyRequired" class="text-danger">
              {{companyName}} requires a minimum quantity of {{minimumOrderQuantity}}.
              Please add an additional {{minimumOrderQuantity - offerQuantity}} to meet the requirement.
            </div>

            <!-- Seller -->
            <!-- <div *ngIf="userType === 'seller' && currentOffer?.status === 'initial_offer'"> -->
            <div *ngIf="currentOffer?.id">
              <div class="row">
                <div class="col-md-12">
                  <div [ngClass]="{'card-dark-border': !enableCounterBox, 'card-opacity': enableCounterBox}"
                    class="card mb-1">
                    <h4 class="mb-2 card-header">
                      <span class="ml1">{{currentOffer.offer_lines.length % 2 ? 'Buyer' : 'Seller'}}'s Bid</span>
                    </h4>
                    <div class="dx-field pl-2">
                      <div class="dx-field-label bid-price-left-padding text-left">Minimum Expiry:</div>
                      <div class="dx-field-value-static bid-price-left-padding">
                        {{daysToEarliestExpiryDate || 'Lot is expired/out of stock'}}
                      </div>
                    </div>
                    <div class="dx-field pl-2">
                      <div class="dx-field-label bid-price-left-padding text-left">Bid Price (per pack):</div>
                      <div class="dx-field-value-static bid-price-left-padding">
                        {{currentOffer.current_offer_line.price | currency: "USD":"symbol"}}
                      </div>
                    </div>
                    <div class="dx-field pl-2">
                      <div class="dx-field-label bid-price-left-padding text-left">Bid Quantity:</div>
                      <div class="dx-field-value-static bid-price-left-padding ">
                        {{currentOffer.current_offer_line.quantity | number: '1.0-0'}}
                      </div>
                    </div>
                    <div *ngIf="currentOffer.shipping_price" class="dx-field pl-2">
                      <div class="dx-field-label bid-price-left-padding text-left">Shipping:</div>
                      <div class="dx-field-value-static bid-price-left-padding ">
                        {{calculations.shipping_price | usdCurrency}}
                      </div>
                    </div>

                    <div *ngIf="isBuyer && currentOffer.calculations.total_savings" class="dx-field pl-2">
                      <div class="dx-field-label bid-price-left-padding text-left">Bid Savings (per pack):</div>
                      <div class="dx-field-value-static bid-price-left-padding ">
                        {{selectedLotPrice - currentOffer.current_offer_line.price| usdCurrency}}
                      </div>
                    </div>
                    <div *ngIf="isBuyer && currentOffer.calculations.total_savings" class="dx-field pl-2">
                      <div class="dx-field-label bid-price-left-padding text-left">Total Savings:</div>
                      <div class="dx-field-value-static bid-price-left-padding ">
                        {{currentOffer.calculations.total_savings | usdCurrency}}
                        ({{currentOffer.calculations.total_percent_savings | number:'1.1-1'}}%)
                      </div>
                    </div>
                    <div *ngIf="currentOffer.shipping_price" class="dx-field pl-2">
                      <div class="dx-field-label bid-price-left-padding text-left">Sub-Total:</div>
                      <div class="dx-field-value-static bid-price-left-padding ">
                        {{currentOffer.calculations.total | usdCurrency}}
                      </div>
                    </div>
                    <div class="dx-field pl-2">
                      <div class="dx-field-label bid-price-left-padding text-left">Total Amount:</div>
                      <div class="dx-field-value-static bid-price-left-padding ">
                        {{currentOffer.calculations.total + calculations.shipping_price | usdCurrency}}
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <div class="col-6 text-left">
                          <dx-button text="Decline Bid" (onClick)="confirmDeclineOffer()" type="danger" width="150px"
                            [disabled]="disableButtons"></dx-button>
                        </div>
                        <div class="col-6 text-right">
                          <dx-button (onClick)="acceptOffer()" type="default" width="150px" text="Accept Bid"
                            [disabled]="!daysToEarliestExpiryDate || currentListing?.stock < currentOffer.current_offer_line.quantity || currentOffer.current_offer_line.price !== offerPrice || currentOffer.current_offer_line.quantity !== offerQuantity || disableButtons">
                          </dx-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!enableCounterBox" class="col-md-12 text-center mt-3">
                  <dx-button *ngIf="!enableCounterBox && !currentOffer.exceeded_max_counters"
                    [disabled]="disableButtons || !expiryDates.length" (onClick)="onCounterBoxClick()" type="default" height="40px"> Make
                    Counter Bid
                  </dx-button>
                </div>
                <div *ngIf="enableCounterBox" class="col-md-12">
                  <div [ngClass]="{'card-dark-border': enableCounterBox}" class="card mb-1">
                    <h4 class="mb-2 card-header">
                      <span class="ml1">Counter {{currentOffer.offer_lines.length % 2 ? 'Buyer' : 'Seller'}}'s
                        Bid</span>
                    </h4>
                    <div class="dx-fieldset" style="margin: 0px 16px 5px 16px">

                      <div class="dx-field">
                        <div class="dx-field-label bid-price-left-padding text-left">Minimum Expiry:</div>
                        <div class="dx-offer-field-value mr1 mt-1">
                          <dx-select-box [height]="25" class="offer-input" [dataSource]="expiryDates" displayExpr="days"
                            valueExpr="date" [value]="currentOffer.earliest_expiry_date" [disabled]="!offerQuantity"
                            (onValueChanged)="updateEarliestExpiryDate($event)">
                          </dx-select-box>
                        </div>
                      </div>
                      <div class="dx-fieldset">
                        <div class="dx-field">
                          <div class="dx-field-label bid-price-left-padding text-left">Available Stock:</div>
                          <div class="dx-field-value">
                            {{availableStock}} packs
                          </div>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label bid-price-left-padding text-left">Bid Price (per pack):</div>
                        <div class="dx-offer-field-value mr1 text-left">
                          <dx-number-box [height]="25" [(value)]="offerPrice" [showSpinButtons]="false" [step]="0"
                            [min]="0.01" [max]="selectedLotPrice-0.01" [format]="currencyFormat" placeholder="$0.00"
                            (onValueChanged)="updateCalcs(null, 'updateShippingPrice', true)">
                          </dx-number-box>
                        </div>
                      </div>
                      <div class="dx-field" style="margin-bottom: 20px;">
                        <div class="dx-field-label text-left">
                          Bid Quantity:<span *ngIf="currentListing?.case_lock" id="quantity-help"
                            class="dx-icon-help"></span>
                        </div>
                        <div class="dx-offer-field-value mr1">
                          <dx-number-box [height]="25" [(value)]="offerQuantity"
                            [max]="currentListing?.case_size > 1 ? availableStock - availableStock % currentListing?.case_size : availableStock"
                            [min]="currentListing?.offer_minimum_quantity || 1" [showSpinButtons]="false" [step]="0"
                            (onValueChanged)="updateCalcs(null, 'offerQuantity', true)">
                          </dx-number-box>
                        </div>
                      </div>

                      <div class="dx-field">
                        <div class="dx-field-label text-left">Shipping:</div>
                        <div class="dx-field-value">{{currentOffer.calculations.shipping_price | usdCurrency}}</div>
                      </div>
                    </div>
                    <div class="col-12">
                      <app-offer-total *ngIf="!(currentOffer.offer_lines.length % 2)" [currentListing]="currentListing"
                        [offerPrice]="offerPrice" [offerQuantity]="offerQuantity" [calculations]="calculations"
                        [savingsOnly]="true">
                      </app-offer-total>
                    </div>

                    <div class="dx-fieldset" style="margin: 0px 16px 5px 16px">
                      <div class="dx-field">
                        <div class="dx-field-label text-left">Total Amount:</div>
                        <div class="dx-field-value">
                          <span *ngIf="
                        currentOffer.current_offer_line.price !== offerPrice 
                        || currentOffer.current_offer_line.quantity !== offerQuantity
                        || currentOffer.current_offer_line.earliest_expiry_date !== earliestExpiryDate?.date">
                            {{shippingPrice + (offerPrice * offerQuantity) | usdCurrency}}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="enableCounterBox" class="card-footer mt-1">
                      <dx-button type="default" (onClick)="makeCounterOffer()" text="Send Counter Bid" [disabled]="minRequired
                      || currentOffer.current_offer_line.price == offerPrice 
                        && currentOffer.current_offer_line.quantity == offerQuantity 
                        && currentOffer.current_offer_line.earliest_expiry_date === earliestExpiryDate?.date
                      || disableButtons" class="mr-2 pl-1" height="30px" width="150px">
                      </dx-button>
                      <dx-button type="danger" (onClick)="resetCounterOffer()" text="Cancel" height="30px"
                        width="150px">
                      </dx-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </dx-scroll-view>
</dx-popup>

<dx-load-panel [position]="{ of: '#offer' }" [(visible)]="showSpinner" [showIndicator]="true" [showPane]="true"
  [shading]="true" shadingColor="rgba(0,0,0,.4)" [hideOnOutsideClick]="false">
</dx-load-panel>


<dx-popup [(visible)]="showDeclineConfirmation" [hideOnOutsideClick]="true" [showCloseButton]="true"
  [dragEnabled]="true" [height]="'auto'" [resizeEnabled]="true" [showTitle]="true" [width]="'auto'" class="popup"
  title="Decline Bid">
  <p *ngIf="!erroneousOffer">Please leave the reason you are declining the bid.</p>
  <p *ngIf="erroneousOffer">Bid will be considered as erroneously</p>
  <div *ngIf="!erroneousOffer">
    <dx-text-box [(value)]="declineReason" [width]="'auto'" [height]="'auto'" placeholder="Reason"></dx-text-box>
  </div>

  <div class="mt-2">
    <dx-button class="mr-2" type="danger" (onClick)="declineOffer()" height="30px">Decline Bid</dx-button>
    <dx-button type="default" (onClick)="showDeclineConfirmation = false;" height="30px">Cancel</dx-button>
  </div>
</dx-popup>

<dx-popup [(visible)]="showAcknowledgePopup" [hideOnOutsideClick]="true" [showCloseButton]="true" [dragEnabled]="true"
  [resizeEnabled]="true" [showTitle]="true" [width]="600" class="popup" title="User Acknowledgement">
  <dx-scroll-view>
    <div *ngIf="userType === 'buyer' && currentListing">
      <app-user-ack [listing]="currentListing"></app-user-ack>
    </div>
  </dx-scroll-view>
</dx-popup>

<dx-tooltip target="#quantity-help" showEvent="dxhoverstart" hideEvent="dxhoverend" [(visible)]="showQuantityTooltip">
  <p class="mb-0" *ngIf="currentListing?.offer_minimum_quantity && currentListing?.offer_minimum_quantity > 1">
    The seller will only consider offers of at least {{currentListing?.offer_minimum_quantity}} packs.
  </p>
  <p class="mb-0" *ngIf="!currentListing?.offer_minimum_quantity || currentListing?.offer_minimum_quantity == 1">
    The seller will consider offers of any amount of packs.
  </p>
  <p class="mb-0" *ngIf="currentListing?.case_size > 1">
    This listing must be purchased in multiples of {{currentListing?.case_size}}.
  </p>
</dx-tooltip>