import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-offer-history',
  templateUrl: './offer-history.component.html',
  styleUrls: ['./offer-history.component.scss']
})
export class OfferHistoryComponent {

  @Input() offer: any;
  @Input() userType: any;
  hydraEnabled = environment.hydraEnabled;

  constructor() { }

}
