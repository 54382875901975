import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent, DxFormModule, DxValidationGroupModule, DxValidatorModule } from 'devextreme-angular';
import { NotificationService } from '../../services/notification.service';
import { NewPassword } from '../../models/user';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html'
})

export class PasswordUpdateComponent implements OnInit {

  @ViewChild(DxFormComponent) form: DxFormComponent;

  newPassword: NewPassword = {password: '', match_password: ''};

  buttonOptions: any = {
    text: 'Update',
    type: 'success',
    useSubmitBehavior: true
  };

  passwordRuleLower = /^(?=.*[a-z])/; // at least one lower case letter exists
  passwordRuleUpper = /^(?=.*[A-Z])/; // at least one upper case letter exists
  passwordRuleNumber = /^(?=.*[0-9])/; // at least one numeric
  passwordRuleSpSymbol = /^(?=.*[!@#$%^&*])/; // at least one special character

  loading = false;


  constructor(private notificationService: NotificationService, private userService: UserService, private auth0: Auth0Service) { }

  ngOnInit() { }

  onSubmit(e) {
    this.loading = true;
    this.userService.updatePassword(this.newPassword).subscribe(
      () => {
        this.loading = false;
        this.notificationService.showNotify('Password was updated, please log in.', 'success');
        this.auth0.logout();
      },
      error => {
        this.notificationService.showNotify(error, 'error');
        this.loading = false;
      }
    );
    e.preventDefault();
  }

  passwordComparison = () => {
    return this.form.instance.option('formData').password;
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxiValidationRuleModule
  ],
  declarations: [ PasswordUpdateComponent ],
  exports: [ PasswordUpdateComponent ]
})
export class PasswordUpdateModule { }
