import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';
import { PipeModule } from 'src/app/pipe.module';
import { ListingPriceModule } from '../../listing-price/listing-price.module';

@Component({
  selector: 'app-offer-listing-details',
  templateUrl: './offer-listing-details.component.html',
})
export class OfferListingDetailsComponent implements OnInit  {
  @Input() userType: string;
  @Input() currentListing: any;
  @Input() price: any;
  @Input() lastOffer: any;
  constructor() {}

  ngOnInit() {}

}


@NgModule({
  imports: [
    CommonModule,
    PipeModule,
    ListingPriceModule
  ],
  exports: [ OfferListingDetailsComponent ],
  declarations: [ OfferListingDetailsComponent ]
})
export class OfferListingDetailsModule { }
