<div *ngIf="profile" class="text-center">

    <app-user-picture-preview [profile]="profile" [firstName]="firstName" [lastName]="lastName"></app-user-picture-preview>

    <div *ngIf="profile[userMetadata]?.picture">
        <button class="btn btn-link" (click)="deleteAvatar()">Delete</button>
    </div>

    <div *ngIf="!profile[userMetadata]?.picture">
        <form id="form" #form method="post" action="" enctype="multipart/form-data">
            <div class="text-center mx-auto">
                <dx-file-uploader 
                        id="user-pic-upload"
                        [multiple]="false" [(value)]="userPicture"
                        labelText="" accept="*" uploadMode="useForm" 
                        [allowedFileExtensions]="['.jpg','.jpeg','.png','.JPEG','.JPG','.PNG']"
                        selectButtonText="Choose Profile Picture"
                        [height]="'50px'">
                </dx-file-uploader>
            </div>
            <dx-button *ngIf="userPicture.length == 1" text="Upload profile picture" type="default" (onClick)="uploadAvatar()"></dx-button>
            <div style="font-size: x-small;">Allowed file extensions: .jpg, .png.</div>
        </form>
    </div>
</div>
