import { Component, EventEmitter, NgModule, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  constructor() { }

  @Output() closePopup =  new EventEmitter<boolean>();

  ngOnInit() {
  }

  emitClick() {
    this.closePopup.emit();
  }
}

@NgModule({
  imports: [],
  declarations: [ OverlayComponent ],
  exports: [ OverlayComponent ]
})
export class OverlayModule { }