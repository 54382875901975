<div class="row">
    <div class="col-12">
        <div class="dx-fieldset">
            <div *ngIf="userType === 'buyer'" class="dx-field">
                <div class="dx-field-label">MFG:</div>

                <div class="dx-field-value-static py-0">{{currentListing?.company_name}}</div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">NDC:</div>
                <div class="dx-field-value-static py-0">{{currentListing?.ndc_number | ndc}}</div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Product Name:</div>
                <div class="dx-field-value-static truncate py-0">
                    <span title="{{currentListing?.name}}">{{currentListing?.name}}</span>
                </div>
            </div>
            <div *ngIf="currentListing.case_lock" class="dx-field">
                <div class="dx-field-label">Case Size:</div>
                <div class="dx-field-value-static py-0">{{currentListing?.case_size || 1 }}</div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label ">Package Qty:</div>
                <div class="dx-field-value-static py-0">{{currentListing?.eaches_per_pack}} ({{currentListing.package_type}})</div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label ">Total Stock (packs):</div>
                <div class="dx-field-value-static py-0">{{currentListing?.stock | number : '1.0-0'}}</div>
            </div>
            
            <div *ngIf="userType === 'buyer' && currentListing?.pricing?.amu?.amu" class="dx-field">
                <div class="dx-field-label">AMU:</div>
                <div class="dx-field-value-static py-0">{{currentListing?.pricing?.amu?.amu}}</div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Pkg Price:</div>
                <div class="dx-field-value-static py-0">
                    {{price | usdCurrency}}
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Unit Price:</div>
                <div class="dx-field-value-static py-0">
                    {{price / currentListing.eaches_per_pack | usdCurrency}}
                </div>
            </div>
            <div *ngIf="userType === 'buyer' && currentListing?.pricing?.amu?.aac_per_pack" class="dx-field">
                <div class="dx-field-label">EST Wholesaler:</div>
                <div class="dx-field-value-static py-0">
                    {{currentListing?.pricing?.amu?.aac_per_pack | usdCurrency}}
                </div>
            </div>
            <div *ngIf="userType === 'buyer' && currentListing?.pricing?.amu?.aac_per_pack" class="dx-field">
                <div class="dx-field-label">Initial Savings:</div>
                <div class="dx-field-value-static py-0">
                    <span>{{currentListing?.pricing?.amu?.aac_per_pack - price | usdCurrency}}</span>
                    <span> ({{100 - ((price / currentListing?.pricing?.amu?.aac_per_pack) * 100) | number : '1.1-1'}}%) per pack</span>
                </div>
            </div>
            <div *ngIf="userType === 'buyer' && lastOffer" class="dx-field">
                <div class="dx-field-label">Last Purchase:</div>
                <div class="dx-field-value-static py-0">
                    {{lastOffer?.accepted_quantity}} units &#64; {{lastOffer?.current_offer_line?.buyer_price | usdCurrency}} on {{lastOffer?.accepted_date | date}} with shelf life: {{lastOffer?.shelf_life}}
                </div>
            </div>
        </div>
    </div>
</div>
