<div *ngIf="!currentListing && !listingLoadError" id="loaderContainer" style="height: 650px;">
  <div class="loading">
    <img src="../../../../assets/loading.svg" alt="Loading..." />
    <h2>Please Wait</h2>
  </div>
</div>
<div *ngIf="!currentListing && listingLoadError" id="errorContainer" style="height: 650px;">
  <div class="loading">
    <h2>Listing does not exist or you are not eligible for purchase</h2>
  </div>
</div>
<div *ngIf="currentListing" class="row">
  <div class="d-flex align-items-center justify-content-between col-12" style="width: 100%;">
    <div class="mb-1">
      <span style="font-size: 24px; font-weight: 300;" class="mr-2" title="{{currentListing?.id}}">{{currentListing.name}}</span>
      <span *ngIf="!hydraEnabled">
        <i class="dx-icon-copy mr-1"
          style="cursor: pointer; color: #01203A;"
          (click)="_copyToClipboard(currentListing.id)" title="Copy link to listing"></i>
      </span>      
      <span *ngIf="currentListing.images?.length && !hydraEnabled" style="cursor: pointer;" class="dx-icon-image mr-1 grey-icon" (click)="showDailyMed()" title="Product Images"></span>
      <app-listing-notes (onRecordUpdate)="reloadAllData()" [listing]="currentListing"></app-listing-notes>
      <app-listing-wishlist-toggle (onRecordUpdate)="reloadAllData()" [listing]="currentListing" [allowToggling]="true"></app-listing-wishlist-toggle>

      <span *ngIf="currentListing.docs?.length && !hydraEnabled">
        <i *ngFor="let document of currentListing.docs" class="dx-icon-doc mr-1" [title]="document.uri" (click)="documentOpen(document)"></i>
      </span>
    </div>
    <div class="mr-4">
    <!-- <div class="mr-4"> -->
      <img *ngIf="currentListing.catalog_type === 'medigi' && !hydraEnabled" src="/assets/img/logos/medigi-logo.png" [width]="100">
      <img *ngIf="currentListing.catalog_type === 'medshorts' && !hydraEnabled" src="/assets/img/logos/ms_teal_logo.svg" [width]="100">
      <img *ngIf="currentListing.catalog_type === '503b' && !hydraEnabled" src="/assets/img/logos/503B_Color.svg" [width]="100">
      <span *ngIf="currentListing.private_label_gpo_id === 1 && !hydraEnabled">&nbsp;&nbsp;
        | <img loading="lazy" class="private-label-logo" src="assets/img/novapluslogo.jpg" data-container="body" title="Private Label NovaPlus">
      </span>
      <span *ngIf="currentListing.private_label_gpo_id === 2 && !hydraEnabled">&nbsp;&nbsp;
        | <img loading="lazy" class="private-label-logo-premier" src="assets/img/premierProRx.png"  data-container="body" title="Private Label PremierProRx">
      </span>
      <dx-button *ngIf="isPopup" class="mr-3"icon="clear" (onClick)="onCancelClick()"></dx-button>
    </div>
  </div>

  <div class="col-md-12 mb-2">
    <div class="row mb-5 justify-content-start">
      <div class="col">
        <div class="row mb-2">
          <div class="w-100">
            <div class="d-flex" *ngIf="!hydraEnabled">
              <div class="col-md-3">
                <app-stat-data-tile *ngIf="!this.currentListing?.pricing?.amu?.user_id"
                  text="{{currentListing?.pricing?.amu?.override_amu || currentListing?.pricing?.amu?.amu}} packs"
                  title="AMU">
                </app-stat-data-tile>
                <app-stat-data-tile *ngIf="this.currentListing?.pricing?.amu?.user_id"
                  text="{{currentListing?.pricing?.amu?.override_amu || currentListing?.pricing?.amu?.amu}} packs"
                  [tooltip]="priceSignalTooltip"
                  title="{{'AMU' + (currentListing?.pricing.amu.override_amu ? ' (overridden)' : '')}}">
                </app-stat-data-tile>
              </div>
            <div class="col-md-3">
              <app-stat-data-tile *ngIf="currentListing?.current_price_signal?.type == 'User Supplied'"
                [tooltip]="{'id': 'cust_flag', 'tooltipText': 'Price supplied by a user from your pharmacy.'}"
                text="{{currentListing?.current_price_signal?.customer_base_price | usdCurrency}}" title="Your Wholesale">
                <dx-button style="position: absolute; left:0; top: 0" (onClick)="openPriceFlagPopup()" stylingMode="text"
                  type="default" icon="/assets/img/msflag_green.png">
                </dx-button>
              </app-stat-data-tile>
              <app-stat-data-tile *ngIf="currentListing?.current_price_signal?.type == 'History Upload'"
                [tooltip]="{'id': 'cust_flag', 'tooltipText': 'Price from your supplied data. Update using the flag.'}"
                text="{{currentListing?.current_price_signal?.customer_base_price | usdCurrency}}" title="Your Wholesale">
                <dx-button style="position: absolute; left:0; top: 0" (onClick)="openPriceFlagPopup()" stylingMode="text"
                  type="default" icon="/assets/img/msflag_blue.png">
                </dx-button>
              </app-stat-data-tile>
              <app-stat-data-tile *ngIf="currentListing?.current_price_signal?.type == 'Predicted'"
                [tooltip]="{'id': 'no_cust_flag', 'tooltipText': 'Price estimated by us. Set your own price data using the flag.'}"
                text="{{currentListing.pricing?.amu?.comparison_price | usdCurrency}}" title="Est. Wholesale">
                <dx-button style="position: absolute; left:0; top: 0" (onClick)="openPriceFlagPopup()" stylingMode="text"
                  type="default" hint="Set a Price Flag" icon="/assets/img/msflag_gray.png">
                </dx-button>
              </app-stat-data-tile>
            </div>
            <div class="col-md-3">
              <app-stat-data-tile *ngIf="companyHasTerms" text="{{subTotal | usdCurrency}}" title="Amount in Cart">
              </app-stat-data-tile>
            </div>
            <div class="col-md-3">
              <app-stat-data-tile *ngIf="aac - currentListing?.pricing.price_meta.price > 0" [tooltip]="savingsTooltip"
                type="success"
                text="{{ (aac - currentListing.pricing.price_meta.price) * currentListing?.amu | thousandSuff : 0 | usdCurrency }}"
                title="EST Monthly Savings"></app-stat-data-tile>
              <app-stat-data-tile *ngIf="aac - currentListing?.pricing.price_meta.price <= 0" style="color: grey;"
                text="$0" title="EST Monthly Savings"></app-stat-data-tile>
            </div>
          </div>
        </div>
          <div class="col-md-12">
            <app-listing-details [listing]="currentListing"></app-listing-details>
          </div>
        </div>
        <div *ngIf="currentListing.docs?.length">
          <h5><strong>Product Resources</strong></h5>
          <ul class="stripey mb-0">
          <li *ngFor="let document of currentListing.docs" (click)="documentOpen(document)">
            {{document.uri}}
          </li>
        </ul>
        </div>
      </div>
  
      <div class="col-md-4 col-12" style="min-width: 500px;">
       <div *ngIf="hydraEnabled" style="height:0;">&nbsp;</div>
        <div class="card mr-3 add-to-card" style="padding-bottom:40px;">
          <div class="card-header mb-2 px-2 dx-font-s">
            <h3>Add to Cart</h3>
          </div>
          <div *ngIf="!hydraEnabled" class="pt-0">
            <dx-data-grid id="addToCartGrid" class="mb-1" style="width: 100%;"
              [dataSource]="currentListing?.pricing?.prices" [showColumnHeaders]="true" [showRowLines]="false" [cacheEnabled]="false">
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
              <dxi-column dataField="expiry_date" dataType="date" alignment="left" caption="Expiry"
                cellTemplate="expiryTemplate" sortOrder="asc" [allowSorting]="true" [allowEditing]="false" [width]="110"
                cssClass="expiry-grid-col"></dxi-column>
              <dxi-column dataField="price" dataType="number" caption="Pack Price" [allowSorting]="false"
                cellTemplate="priceTemplate" [allowEditing]="false" [width]="82"></dxi-column>
              <dxi-column dataType="number" caption="Unit Price" [allowSorting]="false" cellTemplate="unitPriceTemplate"
                [allowEditing]="false" [width]="76"></dxi-column>
              <dxi-column dataType="number" caption="Stock" [allowSorting]="false" cellTemplate="lotStockTemplate"
                [allowEditing]="false" [width]="'auto'"></dxi-column>
              <dxi-column dataType="number" caption="Pack Qty" [allowSorting]="false" cellTemplate="PackQtyTemplate"
                [allowEditing]="false" [width]="105" [visible]="currentListing?.case_size <= 1"
                [cssClass]="currentListing?.case_size > 1 ? undefined : 'quantity-input'"></dxi-column>
              <dxi-column dataType="number" caption="Case Qty" [allowSorting]="false" cellTemplate="CaseQtyTemplate"
                [allowEditing]="false" [width]="105" [visible]="currentListing?.case_size > 1"
                cssClass="case-quantity-input"></dxi-column>
  
              <div *dxTemplate="let pricing of 'PackQtyTemplate'" class="dx-font-s pl-2">
                <div *ngIf="currentListing?.case_size > 1" class="dx-font-s">
                  <span>{{pricing.data.quantity}}</span>
                </div>
                <dx-number-box *ngIf="currentListing?.case_size <= 1" [max]="pricing.data.stock"
                  [min]="hasMultipleLots ? 0 : pricing.data.min_qty || 0" [showSpinButtons]="false" [step]="0"
                  [disabled]="pricing.data.stock == 0 || cartRefreshing || cartLoading" [value]="pricing.data.quantity"
                  [readOnly]="currentListing?.case_size > 1" 
                  valueChangeEvent="keyup"
                  (onValueChanged)="qtyAmountChange($event, pricing.data)"
                  name="{{pricing.data.lot_name}} + '_input'">
                </dx-number-box>
              </div>
              <div *dxTemplate="let pricing of 'CaseQtyTemplate'" class="dx-font-s pl-2">
                <dx-number-box *ngIf="currentListing?.case_size > 1"
                  [max]="math.floor(pricing.data.stock/currentListing.case_size) || 1"
                  [min]="hasMultipleLots ? 0 : math.ceil(pricing.data.min_qty/currentListing.case_size) || 0"
                  [showSpinButtons]="false" [step]="0"
                  [disabled]="pricing.data.stock == 0 || cartRefreshing || cartLoading"
                  [value]="pricing.data.case_quantity" 
                  valueChangeEvent="keyup"
                  (onValueChanged)="caseQtyAmountChange($event, pricing.data)"
                  name="{{pricing.data.lot_name}} + '_input'">
                </dx-number-box>
              </div>
  
              <div *dxTemplate="let pricing of 'priceTemplate'" class="dx-font-s">
                <span *ngIf="companyHasTerms">{{pricing.data.price | usdCurrency}}</span>
              </div>
              <div *dxTemplate="let pricing of 'unitPriceTemplate'" class="dx-font-s">
                <span *ngIf="companyHasTerms">{{pricing.data.price/currentListing?.eaches_per_pack | usdCurrency}}</span>
              </div>
              <div *dxTemplate="let pricing of 'expiryTemplate'" class="dx-font-s">
                <div class="expiry-cell-container">
                  <span id="expiry-date-pill-{{pricing.data.id}}" class="badge badge-pill dx-font-xs"
                    style="color: #000000" [ngStyle]="{'background-color': pricing.data.expiry_date | dateToColor}">
                    {{pricing.data.expiry_date}}
                  </span>
                  <app-ms-tooltip target="expiry-date-pill-{{pricing.data.id}}"
                    tooltipText="{{fmtDate(pricing.data.expiry_date) | amTimeAgo}}"></app-ms-tooltip>
                  <app-ms-tooltip *ngIf="pricing.data.public_note" class="ml-1" badgeText="i"
                    id="pricing_pn_{{pricing.data.id}}" extraClass="badge-dark" width="260"
                    [tooltipText]="pricing.data.public_note">
                  </app-ms-tooltip>
                </div>
              </div>
              <div *dxTemplate="let pricing of 'lotStockTemplate'" class="dx-font-s">
                {{pricing.data.stock}}
              </div>
            </dx-data-grid>
  
            <hr class="ltgray-line mt-0" />
            <div>
              <dx-button *ngIf="numberofLots > 3 && mode !== 'edit'" class="mb-1" type="normal" stylingMode="contained"
                icon="{{showMoreLotsBtnIcon}}" text="{{showMoreLotsBtnText}}" (onClick)="showMoreLots()"
                style="width: 100%;">
              </dx-button>
            </div>
            <div class="dx-field px-2 mb-1 py-1">
              <div class="dx-font-s" style="width: 220px">Number of {{currentListing?.case_size > 1 ? "Packs / " :
                ""}}Eaches: </div>
              <div class="dx-font-s" *ngIf="currentListing?.eaches_per_pack && packQty">{{currentListing?.case_size > 1 ?
                packQty + " / " : "" | number:'1.0':'en-US' }}{{packQty * currentListing?.eaches_per_pack |
                number:'1.0':'en-US' }}</div>
            </div>
            <div *ngIf="savingsDiff > 0" class="dx-field px-2 mb-1">
              <div class="dx-font-s" style="width: 220px">Savings: </div>
              <div *ngIf="companyHasTerms" class="dx-font-s text-success">{{ savingsDiff | usdCurrency }}</div>
            </div>
            <div class="dx-field px-2 mb-4">
              <div class="dx-font-s" style="width: 220px">Total: </div>
              <div *ngIf="companyHasTerms" class="dx-font-s">{{subTotal | usdCurrency}}</div>
            </div>
            <div class="row px-2">
              <div *ngIf="currentListing" class="col-12 x-font-s">
                <div *ngIf="freeShippingThreshold > subTotal" class="text-info mb-1">{{freeShippingDescription}}</div>
                <div *ngIf="currentListing.soft_block" class="text-danger qty-error">This item has been blocked by your
                  administrator.</div>
                <div *ngIf="qtyErrorMessage" class="qty-error">{{qtyErrorMessage}}</div>
                <div *ngIf="totalPriceThreshold" class="qty-error">{{totalPriceThreshold}}</div>
              </div>
            </div>
            <div class="row mb-1 px-2">
              <div class="col-12">
                <span *ngIf="!csosRequested && !csosApproved && currentListing.is_c2">
                  <app-csos-signup [companyId]="currentListing.company_id" [companyName]="currentListing.company_name">
                  </app-csos-signup>
                </span>
                <dx-button *ngIf="!isSeller && currentListing?.dea !=2 || csosApproved" id="addToCartBtn" type="default"
                  stylingMode="contained" style="width: 100%;"
                  [disabled]="currentListing.stock == 0 || cartLoading || cartRefreshing || atcBtnDisabled || (currentListing.soft_block && !agreeToPurchaseSoftBlock)"
                  (onClick)="onAddClick()">
                  <div *dxTemplate="let data of 'content'">
                    <dx-load-indicator class="button-indicator" [visible]="cartRefreshing"></dx-load-indicator>
                    <span class="dx-button-text">{{ mode === 'add' ? 'Add to Cart' : 'Update Cart' }}</span>
                  </div>
                </dx-button>
                <dx-check-box *ngIf="currentListing.soft_block" class="mt-1 mb-1" [(value)]="agreeToPurchaseSoftBlock"
                  text="Agree to purchase soft block item"></dx-check-box>
              </div>
            </div>
            <div class="row px-2 mb-1">
              <div class="col-6 pr-1">
                <dx-button
                  *ngIf="!isSeller && currentListing?.offer_orders_enabled && currentListing?.dea!='2' && companyHasTerms"
                  id="makeOfferBtn" type="default" text="Place Bid" stylingMode="outlined" style="width: 100%;"
                  [disabled]="currentListing?.vbo_active" (onClick)="setCurrentOffer(currentListing)">
                </dx-button>
              </div>
  
              <div class="col-6 pl-1">
                <dx-button *ngIf="currentListing?.catalog_type != 'medshorts' && currentListing.dea!='2'" id="makeRFPBtn"
                  type="default" text="Build RFP" stylingMode="outlined" style="width: 100%;"
                  [disabled]="currentListing?.vbo_active" (onClick)="setCurrentRFP(currentListing)">
                </dx-button>
              </div>
            </div>
          </div>

          <div *ngIf="hydraEnabled" class="d-flex flex-column">
            <div>
              <dx-data-grid id="addToCartGrid" class="mb-1" style="width: 100%;"
              [dataSource]="currentListing?.pricing?.prices" [showColumnHeaders]="true" [showRowLines]="false">
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
              <dxi-column dataField="purchaseType" dataType="string" caption="Account" [allowSorting]="false"
              cellTemplate="purchaseOptionTemplate" [allowEditing]="false" [width]="100"></dxi-column>
              <dxi-column dataField="price" dataType="number" caption="Pack Price" [allowSorting]="false"
                cellTemplate="priceTemplate" [allowEditing]="false" [width]="100"></dxi-column>
              <dxi-column dataType="number" caption="Unit Price" [allowSorting]="false" cellTemplate="unitPriceTemplate"
                [allowEditing]="false" [width]="100"></dxi-column>
              <!-- <dxi-column dataType="number" caption="Stock" [allowSorting]="false" cellTemplate="lotStockTemplate"
                [allowEditing]="false" [width]="'auto'"></dxi-column> -->
              <dxi-column dataType="number" caption="Pack Qty" [allowSorting]="false" cellTemplate="PackQtyTemplate"
                [allowEditing]="false" [width]="80" [visible]="currentListing?.case_size <= 1"
                [cssClass]="currentListing?.case_size > 1 ? undefined : 'quantity-input'"></dxi-column>
              <dxi-column dataType="number" caption="Case Qty" [allowSorting]="false" cellTemplate="CaseQtyTemplate"
                [allowEditing]="false" [width]="80" [visible]="currentListing?.case_size > 1"
                cssClass="case-quantity-input"></dxi-column>
              <dxi-column caption="" [allowSorting]="false" cellTemplate="removeTemplate" [allowEditing]="false" [width]="20"></dxi-column>
              <div *dxTemplate="let pricing of 'PackQtyTemplate'" class="dx-font-s pl-2">
                <div *ngIf="currentListing?.case_size > 1" class="dx-font-s">
                  <span>{{pricing.data.quantity}}</span>
                </div>
                <dx-number-box *ngIf="currentListing?.case_size <= 1" [max]="pricing.data.stock"
                  [min]="hasMultipleLots ? 0 : pricing.data.min_qty || 0" [showSpinButtons]="false" [step]="0"
                  [disabled]="pricing.data.stock == 0 || cartRefreshing || cartLoading" 
                  [value]="pricing.data.quantity"
                  valueChangeEvent="keyup"
                  [readOnly]="currentListing?.case_size > 1" 
                  (onValueChanged)="qtyAmountChange($event, pricing.data)"
                  name="{{pricing.data.lot_name}} + '_input'">
                </dx-number-box>
              </div>
              <div *dxTemplate="let pricing of 'CaseQtyTemplate'" class="dx-font-s pl-2">
                <dx-number-box *ngIf="currentListing?.case_size > 1"
                  [max]="math.floor(pricing.data.stock/currentListing.case_size) || 1"
                  [min]="hasMultipleLots ? 0 : math.ceil(pricing.data.min_qty/currentListing.case_size) || 0"
                  [showSpinButtons]="false" [step]="0"
                  [disabled]="pricing.data.stock == 0 || cartRefreshing || cartLoading"
                  [value]="pricing.data.case_quantity"
                  valueChangeEvent="keyup"
                  (onValueChanged)="caseQtyAmountChange($event, pricing.data)"
                  name="{{pricing.data.lot_name}} + '_input'">
                </dx-number-box>
              </div>
              <div *dxTemplate="let pricing of 'removeTemplate'" class="dx-font-s pl-2">
                <dx-button 
                  type="danger" 
                  icon="trash" 
                  stylingMode="text" 
                  [disabled]="mode !== 'edit' || pricing.data.quantity == 0 || cartRefreshing || cartLoading"
                  (onClick)="removeLotQty(pricing.data)">
                </dx-button>
              </div>
              <div *dxTemplate="let pricing of 'priceTemplate'" class="dx-font-s">
                <span>{{pricing.data.price | usdCurrency}}</span>
              </div>
              <div *dxTemplate="let pricing of 'unitPriceTemplate'" class="dx-font-s">
                <span>{{pricing.data.price/currentListing?.eaches_per_pack | usdCurrency}}</span>
              </div>
              <div *dxTemplate="let pricing of 'purchaseOptionTemplate'" class="dx-font-m">
                <div class="expiry-cell-container">
                  <span [class]="getAccountType(pricing.data.price_type)">
                    {{pricing.data.price_type || 'WAC' | uppercase}}
                  </span>
                </div>
              </div>
              <div *dxTemplate="let pricing of 'lotStockTemplate'" class="dx-font-s">
                {{pricing.data.stock}}
              </div>
                        </dx-data-grid>
            </div>

            <div *ngIf="false" class="d-flex flex-column">
              <p class="sub-title">Account Overview</p>
              <hr class="ltgray-line mt-0" />
              <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-column flex-grow-1">
                  <div>
                    <p class="type wac-text">WAC</p>
                  </div>
                  <div class="d-flex">
                    <p class="w-50">Eaches:</p>
                    <p>0</p>
                  </div>
                  <div class="d-flex">
                    <p class="w-50">Total:</p>
                    <p>0</p>
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1">
                  <div>
                    <p class="type b340-text">340B</p>
                  </div>
                  <div class="d-flex">
                    <p class="w-50">Eaches:</p>
                    <p>0</p>
                  </div>
                  <div class="d-flex">
                    <p class="w-50">Total:</p>
                    <p>0</p>
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1">
                  <div>
                    <p class="type gpo-text">GPO</p>
                  </div>
                  <div class="d-flex">
                      <p class="w-50">Eaches:</p>
                      <p>0</p>
                  </div>
                  <div class="d-flex">
                    <p class="w-50">Total:</p>
                    <p>0</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="ltgray-line mt-0" />
          <div *ngIf="hydraEnabled">

          <div>
            <dx-button *ngIf="numberofLots > 3 && mode !== 'edit'" class="mb-1" type="normal" stylingMode="contained"
              icon="{{showMoreLotsBtnIcon}}" text="{{showMoreLotsBtnText}}" (onClick)="showMoreLots()"
              style="width: 100%;">
            </dx-button>
          </div>
          <div class="dx-field px-2 mb-1 py-1">
            <div class="dx-font-s" style="width: 220px">Number of {{currentListing?.case_size > 1 ? "Packs / " :
              ""}}Eaches: </div>
            <div class="dx-font-s" *ngIf="currentListing?.eaches_per_pack && packQty">{{currentListing?.case_size > 1 ?
              packQty + " / " : "" | number:'1.0':'en-US' }}{{packQty * currentListing?.eaches_per_pack |
              number:'1.0':'en-US' }}</div>
          </div>
          <div class="dx-field px-2 mb-4">
            <div class="dx-font-s" style="width: 220px">Total: </div>
            <div *ngIf="companyHasTerms || hydraEnabled" class="dx-font-s">{{subTotal | usdCurrency}}</div>
          </div>
          <div class="row px-2">
            <div *ngIf="currentListing" class="col-12 x-font-s">
              <div *ngIf="freeShippingThreshold > subTotal" class="text-info mb-1">{{freeShippingDescription}}</div>
              <div *ngIf="currentListing.soft_block" class="text-danger qty-error">This item has been blocked by your
                administrator.</div>
              <div *ngIf="qtyErrorMessage" class="qty-error">{{qtyErrorMessage}}</div>
              <div *ngIf="totalPriceThreshold" class="qty-error">{{totalPriceThreshold}}</div>
            </div>
          </div>
          <div class="row mb-1 px-2">
            <div class="col-12">
              <span *ngIf="!csosRequested && !csosApproved && currentListing.is_c2">
                <app-csos-signup [companyId]="currentListing.company_id" [companyName]="currentListing.company_name">
                </app-csos-signup>
              </span>
              <dx-button *ngIf="!isSeller && currentListing?.dea !=2 || csosApproved" id="addToCartBtn" type="default"
                stylingMode="contained" style="width: 100%;"
                [disabled]="currentListing.stock == 0 || cartLoading || (cartRefreshing && addBtnClicked) || atcBtnDisabled || (currentListing.soft_block && !agreeToPurchaseSoftBlock)"
                (onClick)="onAddClick()">
                <div *dxTemplate="let data of 'content'">
                  <dx-load-indicator class="button-indicator" [visible]="cartRefreshing && addBtnClicked"></dx-load-indicator>
                  <span class="dx-button-text">{{ mode == 'add' ? 'Add to Cart' : 'Update Cart' }}</span>
                </div>
              </dx-button>
              <dx-check-box *ngIf="currentListing.soft_block" class="mt-1 mb-1" [(value)]="agreeToPurchaseSoftBlock"
                text="Agree to purchase soft block item"></dx-check-box>
            </div>
          </div>
          <div class="row px-2 mb-1">
            <div class="col-6 pr-1">
              <dx-button
                *ngIf="!isSeller && currentListing?.offer_orders_enabled && currentListing?.dea!='2' && companyHasTerms"
                id="makeOfferBtn" type="default" text="Place Bid" stylingMode="outlined" style="width: 100%;"
                [disabled]="currentListing?.vbo_active" (onClick)="setCurrentOffer(currentListing)">
              </dx-button>
            </div>

            <div class="col-6 pl-1">
              <dx-button *ngIf="HydraRFPEnabled" id="makeRFPBtn"
                type="default" text="Build RFP" stylingMode="outlined" style="width: 100%;"
                [disabled]="currentListing?.vbo_active" (onClick)="setCurrentRFP(currentListing)">
              </dx-button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="currentListing?.pricing?.amu?.amu && showStats" class="col-md-12">
      <dx-tabs *ngIf="bucketsStats.length" [dataSource]="tabs" [(selectedIndex)]="selectedTabIndex"
        (onItemClick)="selectTab($event)"></dx-tabs>
  
      <div *ngIf="showTab[0]" class="card-body p-2 dx-font-m mb-2">
        <dx-data-grid [dataSource]="bucketsStats" [showColumnHeaders]="true" [columnAutoWidth]="true"
          [rowAlternationEnabled]="true" [remoteOperations]="true" [showColumnLines]="true" [showBorders]="true"
          [showRowLines]="true" [wordWrapEnabled]="false">
  
          <dxi-column dataField="account_type" caption="Purchase Type" dataType="string"></dxi-column>
          <dxi-column dataField="aac" caption="EST Wholesaler Price" dataType="number" cellTemplate="aacTemplate">
          </dxi-column>
          <dxi-column dataField="amu" caption="AMU (packs)" dataType="number" cellTemplate="amuTemplate"></dxi-column>
          <!-- <dxi-column dataField="pctAmu" caption="AMU Pct." dataType="number" cellTemplate="pctSpendTemplate"></dxi-column> -->
          <dxi-column dataField="value" caption="EST Wholesaler Monthly Spend" dataType="number"
            cellTemplate="wholesalerMonthlySpend"></dxi-column>
          <dxi-column dataField="mdgSpends" caption="Medigi Monthly Spend" dataType="number"
            cellTemplate="mdgSpendsTemplate"></dxi-column>
          <dxi-column dataField="mdgSavings" caption="Medigi Monthly Savings" dataType="number"
            cellTemplate="mdgSavingsTemplate"></dxi-column>
          <!-- <dxi-column dataField="savings" caption="Savings" dataType="number" cellTemplate="savingsTemplate" ></dxi-column> -->
          <div *dxTemplate="let spends of 'aacTemplate'" class="dx-font-m">
            {{spends.data.aac * currentListing.eaches_per_pack | usdCurrency }}
          </div>
          <div *dxTemplate="let spends of 'amuTemplate'" class="dx-font-m">
            {{spends.data.amu }}
          </div>
          <div *dxTemplate="let spends of 'wholesalerMonthlySpend'" class="dx-font-m">
            {{spends.data.value | usdCurrency }}
          </div>
          <div *dxTemplate="let spends of 'spendsTemplate'" class="dx-font-m">
            {{spends.data.estimated_spends | usdCurrency }}
          </div>
          <div *dxTemplate="let spends of 'pctSpendTemplate'" class="dx-font-m">
            {{spends.data.pctAmu}}%
          </div>
          <div *dxTemplate="let spends of 'mdgSavingsTemplate'" class="dx-font-m">
            <div>
              <span *ngIf="spends.data.mdgSavings > 0" class="text-success">{{spends.data.mdgSavings | usdCurrency
                }}</span>
              <span *ngIf="spends.data.mdgSavings < 0" style="color: grey;">&#40;{{spends.data.mdgSavings |
                usdCurrency}}&#41;</span>
              <span *ngIf="spends.data.mdgSavings == 0">{{spends.data.mdgSavings | usdCurrency }}</span>
            </div>
          </div>
          <div *dxTemplate="let spends of 'mdgSpendsTemplate'" class="dx-font-m">
            {{spends.data.mdgSpends | usdCurrency }}
          </div>
        </dx-data-grid>
      </div>
  
      <div *ngIf="showTab[1]" class="card-body p-2 dx-font-m mb-2">
        <div style="width: 3000px;">
          <!-- dx-tabs empty set hack-->
        </div>
        <app-qty-per-month [params]="paramsStats"></app-qty-per-month>
      </div>
  
      <div *ngIf="showTab[2]" class="card-body p-2 dx-font-m mb-2">
        <app-purchasing-history [invoices]="invoices"></app-purchasing-history>
      </div>
    </div>

    <div *ngIf="!hydraEnabled">
      <div *ngIf="currentListing?.alternatives && !hydraEnabled" class="col-md-12">
        <h3>Alternatives</h3>
        <div *ngIf="!alternativesLoaded" id="loaderContainer2" style="height: 200px;">
          <div class="loading">
            <img src="../../../../assets/loading.svg" alt="Loading..." />
            <h2>Please Wait</h2>
          </div>
        </div>
        <dx-data-grid *ngIf="alternativesLoaded" #gridReviewAlternatives id="gridReviewAlternatives"
          [dataSource]="alternatives" [columnAutoWidth]="true" [allowColumnReordering]="false" [showRowLines]="true"
          [showColumnLines]="true" [showBorders]="true" [hoverStateEnabled]="true"
          (onRowClick)="selectAlternativeListing($event)" [rowAlternationEnabled]="false"
          noDataText="None Currently Available">
          <dxo-sorting mode="none"></dxo-sorting>
          <dxo-scrolling [useNative]="false" showScrollbar="onScroll"></dxo-scrolling>
          <dxi-column caption="" [width]="60" alignment="center" [allowHeaderFiltering]="false" [allowSorting]="false"
            cellTemplate="compareTemplate"></dxi-column>
          <dxi-column dataField="name.raw" [fixed]="false" caption="Product Name" cellTemplate="nameTemplate">
          </dxi-column>
          <dxi-column caption="Unit Price" dataType="number" [width]="120" cellTemplate="alternativeUnitCostTemplate"
            [allowSorting]="false"></dxi-column>
          <dxi-column dataField="pricing?.price" caption="Pkg. Price" dataType="number" [width]="120"
            cellTemplate="alternativePriceTemplate" [allowSorting]="true" [sortOrder]="'asc'"></dxi-column>
          <dxi-column caption="Expiration Date" [width]="'auto'" dataType="date" cellTemplate="alternativesExpTemplate"
            alignment="right" [allowSorting]="false"></dxi-column>
          <dxi-column dataField="MFG" [fixed]="false" caption="Supplier" [width]="150" cellTemplate="mfgTemplate"
            [allowSorting]="false"></dxi-column>
          <div *dxTemplate="let listing of 'compareTemplate'">
            <dx-button hint='Select this listing' type="default" icon="check"></dx-button>
          </div>
          <div *dxTemplate="let listing of 'mfgTemplate'">
            <app-seller-company-logo [listing]="listing.data" mode="img" maxWidth="100px"></app-seller-company-logo>
          </div>
          <div *dxTemplate="let listing of 'alternativePriceTemplate'">
            {{listing.data.pricing?.price | currency: "USD":"symbol" }}
          </div>
          <div *dxTemplate="let listing of 'alternativeUnitCostTemplate'">
            {{listing.data.pricing?.price/listing.data.eaches_per_pack | currency: "USD":"symbol" }}
          </div>
          <div *dxTemplate="let cell of 'alternativesExpTemplate'">
            <div class="expiry-cell-container">
              <span id="expiry-date-pill-{{cell.data.id}}" class="badge badge-pill dx-font-xs" style="color: #000000"
                [ngStyle]="{'background-color': cell.data.exp_min| dateToColor}">
                {{cell.data.exp_min}}
              </span>
              <app-ms-tooltip target="expiry-date-pill-{{cell.data.id}}"
                tooltipText="{{fmtDate(cell.data.exp_min) | amTimeAgo}}"></app-ms-tooltip>
            </div>
          </div>
          <div *dxTemplate="let listing of 'nameTemplate'">
            <div style="line-height: 20px; position:relative">
              <span class="dx-font-xs"><b>{{listing.data.name}}&nbsp;&nbsp;</b></span>
            </div>
            <div>
              <span *ngIf="listing.data.private_label_gpo_id === 1">&nbsp;&nbsp;
                <img loading="lazy" class="private-label-logo" src="assets/img/novapluslogo.jpg"
                  style=" width: 35px; height: 15px;" data-container="body" title="Private Label">
              </span>
              <span *ngIf="listing.data.private_label_gpo_id === 2">&nbsp;&nbsp;
                <img loading="lazy" class="private-label-logo-premier" src="assets/img/premierProRx.png"
                  style=" width: 35px; height: 15px;" data-container="body" title="Private Label">
              </span>
            </div>
            <div>
              NDC: {{listing.data.ndc_number | ndc}}
            </div>
            <div style="line-height: 15px;" class="d-flex justify-content-between text-right">
              <div>
                <img *ngIf="listing.data.catalog_type === 'medigi'" src="/assets/img/logos/medigi-logo.png" [width]="75">
                <img *ngIf="listing.data.catalog_type === 'medshorts'" src="/assets/img/logos/ms_teal_logo.svg"
                  [width]="75">
                <img *ngIf="listing.data.catalog_type === '503b'" src="/assets/img/logos/503B_Color.svg" [width]="75">
              </div>
              <div>
                <span *ngIf="listing.data.shortage_id" class="mr-1">
                  <img loading="lazy" class="shortage-logo" src="assets/img/drugShortage.png" tooltip
                    data-container="body"
                    title="Drug Shortage Bulletins are copyrighted by the Drug Information Service of the University of Utah, provided by ASHP as its exclusive authorized distributor, and used with permission.">
                </span>
              </div>
            </div>
            <app-ms-tooltip *ngIf="listing.data.is_c2" badgeText="CII" id="listing_is_c2_{{listing.data.id}}"
              tooltipText="This item is CII medication" extraClass="badge badge-warning">
            </app-ms-tooltip>
            <app-ms-tooltip *ngIf="!listing.data.stock" id="listing_stock_{{listing.data.id}}"
              tooltipText="This item is currently out of stock - please check back later" extraClass="badge badge-dark">
            </app-ms-tooltip>
            <app-ms-tooltip *ngIf="listing.data.can_buy_later" id="listing_cbl_{{listing.data.id}}"
              tooltipText="Contract pricing for this item starts on <b>{{listing.data.start_date}}</b>"
              extraClass="badge badge-dark">
            </app-ms-tooltip>
          </div>
        </dx-data-grid>
      </div>
    </div>
  </div>

  <div *ngIf="currentListing?.pricing?.amu?.amu && !hydraEnabled" class="col-md-12">
    <dx-tabs *ngIf="bucketsStats.length" [dataSource]="tabs" [(selectedIndex)]="selectedTabIndex" (onItemClick)="selectTab($event)"></dx-tabs>

    <div *ngIf="showTab[0]" class="card-body p-2 dx-font-m mb-2">
      <dx-data-grid
        [dataSource]="bucketsStats"
        [showColumnHeaders]="true"
        [columnAutoWidth]="true"
        [rowAlternationEnabled]="true"
        [remoteOperations]="true"
        [showColumnLines]="true"
        [showBorders]="true"
        [showRowLines]="true"
        [wordWrapEnabled]="false"
        [cacheEnabled]="false">

        <dxi-column dataField="account_type" caption="Purchase Type" dataType="string"></dxi-column>
        <dxi-column dataField="aac" caption="EST Wholesaler Price" dataType="number" cellTemplate="aacTemplate"></dxi-column>
        <dxi-column dataField="amu" caption="AMU (packs)" dataType="number" cellTemplate="amuTemplate"></dxi-column>
        <!-- <dxi-column dataField="pctAmu" caption="AMU Pct." dataType="number" cellTemplate="pctSpendTemplate"></dxi-column> -->
        <dxi-column dataField="value" caption="EST Wholesaler Monthly Spend" dataType="number" cellTemplate="wholesalerMonthlySpend"></dxi-column>
        <dxi-column dataField="mdgSpends" caption="Medigi Monthly Spend" dataType="number" cellTemplate="mdgSpendsTemplate"></dxi-column>
        <dxi-column dataField="mdgSavings" caption="Medigi Monthly Savings" dataType="number" cellTemplate="mdgSavingsTemplate"></dxi-column>
        <!-- <dxi-column dataField="savings" caption="Savings" dataType="number" cellTemplate="savingsTemplate" ></dxi-column> -->
        <div *dxTemplate="let spends of 'aacTemplate'" class="dx-font-m">
          {{spends.data.aac * currentListing.eaches_per_pack | usdCurrency }}
        </div>
        <div *dxTemplate="let spends of 'amuTemplate'" class="dx-font-m">
          {{spends.data.amu }}
        </div>
        <div *dxTemplate="let spends of 'wholesalerMonthlySpend'" class="dx-font-m">
          {{spends.data.value | usdCurrency }}
        </div>
        <div *dxTemplate="let spends of 'spendsTemplate'" class="dx-font-m">
          {{spends.data.estimated_spends | usdCurrency }}
        </div>
        <div *dxTemplate="let spends of 'pctSpendTemplate'" class="dx-font-m">
          {{spends.data.pctAmu}}%
        </div>
        <div *dxTemplate="let spends of 'mdgSavingsTemplate'" class="dx-font-m">
          <div>
            <span *ngIf="spends.data.mdgSavings > 0" class="text-success">{{spends.data.mdgSavings | usdCurrency }}</span>
            <span *ngIf="spends.data.mdgSavings < 0" style="color: grey;">&#40;{{spends.data.mdgSavings | usdCurrency}}&#41;</span>
            <span *ngIf="spends.data.mdgSavings == 0">{{spends.data.mdgSavings | usdCurrency }}</span>
          </div>
        </div>
        <div *dxTemplate="let spends of 'mdgSpendsTemplate'" class="dx-font-m">
          {{spends.data.mdgSpends | usdCurrency }}
        </div>
      </dx-data-grid>
    </div>

    <div *ngIf="showTab[1]" class="card-body p-2 dx-font-m mb-2">
      <div style="width: 3000px;"><!-- dx-tabs empty set hack--></div>
      <app-qty-per-month [params]="paramsStats"></app-qty-per-month>
    </div>

    <div *ngIf="showTab[2]" class="card-body p-2 dx-font-m mb-2">
      <app-purchasing-history [invoices]="invoices"></app-purchasing-history>
    </div>
  </div>

  <div *ngIf="currentListing?.alternatives" class="col-md-12">
    <h3>Alternatives</h3>
    <div *ngIf="!alternativesLoaded" id="loaderContainer2" style="height: 200px;">
      <div class="loading">
        <img src="../../../../assets/loading.svg" alt="Loading..." />
        <h2>Please Wait</h2>
      </div>
    </div>
    <dx-data-grid *ngIf="alternativesLoaded"
            #gridReviewAlternatives
            id="gridReviewAlternatives"
            [dataSource]="alternatives"
            [columnAutoWidth]="true"
            [allowColumnReordering]="false"
            [showRowLines]="true"
            [showColumnLines]="true"
            [showBorders]="true"
            [hoverStateEnabled]="true"
            [cacheEnabled]="false"
            (onRowClick) = "selectAlternativeListing($event)"
            [rowAlternationEnabled]="false"
            noDataText="None Currently Available">

      <dxo-sorting mode="none"></dxo-sorting>
      <dxo-scrolling [useNative]="false" showScrollbar="onScroll"></dxo-scrolling>

      <dxi-column caption="" [width]="60" alignment="center" [allowHeaderFiltering]="false" [allowSorting]="false" cellTemplate="compareTemplate"></dxi-column>
      <dxi-column dataField="name.raw" [fixed]="false" caption="Product Name" cellTemplate="nameTemplate"></dxi-column>
      <dxi-column caption="Unit Price" dataType="number"  [width]="120" cellTemplate="alternativeUnitCostTemplate" [allowSorting]="false"></dxi-column>
      <dxi-column dataField="pricing?.price" caption="Pkg. Price" dataType="number" [width]="120" cellTemplate="alternativePriceTemplate" [allowSorting]="true" [sortOrder]="'asc'"></dxi-column>
      <dxi-column caption="Expiration Date" [width]="'auto'" dataType="date" cellTemplate="alternativesExpTemplate" alignment="right" [allowSorting]="false"></dxi-column>
      <dxi-column dataField="MFG" [fixed]="false" caption="Supplier" [width]="150" cellTemplate="mfgTemplate" [allowSorting]="false"></dxi-column>

      <div *dxTemplate="let listing of 'compareTemplate'">
        <dx-button hint='Select this listing' type="default" icon="check"></dx-button>
      </div>

      <div *dxTemplate="let listing of 'mfgTemplate'">
        <app-seller-company-logo [listing]="listing.data" mode="img" maxWidth="100px"></app-seller-company-logo>
      </div>

      <div *dxTemplate="let listing of 'alternativePriceTemplate'">
        {{listing.data.pricing?.price | currency: "USD":"symbol" }}
      </div>
      <div *dxTemplate="let listing of 'alternativeUnitCostTemplate'" >
        {{listing.data.pricing?.price/listing.data.eaches_per_pack | currency: "USD":"symbol" }}
      </div>

      <div *dxTemplate="let cell of 'alternativesExpTemplate'">
        <div class="expiry-cell-container">
      <span id="expiry-date-pill-{{cell.data.id}}" class="badge badge-pill dx-font-xs" style="color: #000000"
            [ngStyle]="{'background-color': cell.data.exp_min| dateToColor}">
        {{cell.data.exp_min}}
      </span>
          <app-ms-tooltip target="expiry-date-pill-{{cell.data.id}}" tooltipText="{{fmtDate(cell.data.exp_min) | amTimeAgo}}"></app-ms-tooltip>
        </div>
      </div>

      <div *dxTemplate="let listing of 'nameTemplate'">
        <div style="line-height: 20px; position:relative">
          <span class="dx-font-xs"><b>{{listing.data.name}}&nbsp;&nbsp;</b></span>
        </div>

        <div>
      <span *ngIf="listing.data.private_label_gpo_id === 1">&nbsp;&nbsp;
        <img loading="lazy" class="private-label-logo" src="assets/img/novapluslogo.jpg" style=" width: 35px; height: 15px;" data-container="body" title="Private Label">
      </span>
          <span *ngIf="listing.data.private_label_gpo_id === 2">&nbsp;&nbsp;
        <img loading="lazy" class="private-label-logo-premier" src="assets/img/premierProRx.png" style=" width: 35px; height: 15px;" data-container="body" title="Private Label">
      </span>
        </div>
        <div>
          NDC: {{listing.data.ndc_number | ndc}}
        </div>

        <div style="line-height: 15px;" class="d-flex justify-content-between text-right">
          <div>
            <img *ngIf="listing.data.catalog_type === 'medigi'" src="/assets/img/logos/medigi-logo.png" [width]="75">
            <img *ngIf="listing.data.catalog_type === 'medshorts'" src="/assets/img/logos/ms_teal_logo.svg" [width]="75">
            <img *ngIf="listing.data.catalog_type === '503b'" src="/assets/img/logos/503B_Color.svg" [width]="75">
          </div>
          <div>
        <span *ngIf="listing.data.shortage_id" class="mr-1">
          <img loading="lazy" class="shortage-logo"
               src="assets/img/drugShortage.png" tooltip data-container="body"
               title="Drug Shortage Bulletins are copyrighted by the Drug Information Service of the University of Utah, provided by ASHP as its exclusive authorized distributor, and used with permission.">
        </span>
          </div>
        </div>

        <app-ms-tooltip *ngIf="listing.data.is_c2" badgeText="CII" id="listing_is_c2_{{listing.data.id}}"
                        tooltipText="This item is CII medication"
                        extraClass="badge badge-warning">
        </app-ms-tooltip>

        <app-ms-tooltip *ngIf="!listing.data.stock" id="listing_stock_{{listing.data.id}}"
                        tooltipText="This item is currently out of stock - please check back later"
                        extraClass="badge badge-dark">
        </app-ms-tooltip>

        <app-ms-tooltip *ngIf="listing.data.can_buy_later" id="listing_cbl_{{listing.data.id}}"
                        tooltipText="Contract pricing for this item starts on <b>{{listing.data.start_date}}</b>"
                        extraClass="badge badge-dark">
        </app-ms-tooltip>
      </div>

    </dx-data-grid>
  </div>
</div>

<app-offer *ngIf="offerPopupVisible" [currentListing]="currentListing" [listingId]="currentListing.id" (update)="offerPopupClosed($event)" (onClose)="offerPopupClosed($event)" userType="buyer"> </app-offer>

<dx-popup *ngIf="errorPopupVisible" class="popup"
          [showTitle]="true" title="Cart Update Alert" [dragEnabled]="true"
          [height]="'auto'" [width]="400" [showCloseButton]="true"
          [hideOnOutsideClick]="true" [(visible)]="errorPopupVisible" [resizeEnabled]="false">
          <div class="alert alert-warning" role="alert">
            {{errorPopupMessage}}
          </div>
</dx-popup>

<dx-popup *ngIf="priceFlagPopupVisible" class="popup"
    [showTitle]="true" title="Price Flag" [dragEnabled]="true"
    [showCloseButton]="true" [width]="400" [height]="'auto'"
    [hideOnOutsideClick]="true" [(visible)]="priceFlagPopupVisible" [resizeEnabled]="false">
    <app-price-flag [listing]="currentListing" [visible]="priceFlagPopupVisible" (onChange)="reloadAllData()"></app-price-flag>
</dx-popup>

<app-rfp
  *ngIf="rfpVisible"
  [show]="rfpVisible"
  [ndc]="currentListing?.ndc_number"
  [isBuyer]="true"
  [ndcItem]="ndcItem"
  [catalogType]="currentListing.catalog_type"
  (onClose)="closeRfpPopup()">
</app-rfp>

<dx-popup *ngIf="dimgpupVisible" class="popup" [showTitle]="true" title="Product Images" [dragEnabled]="true"
  [height]="540" [width]="720" [hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="dimgpupVisible" [resizeEnabled]="true">
  <dx-gallery
    #gallery
    id="gallery"
    [dataSource]="currentListing.images"
    height="100%"
    width="100%"
    [slideshowDelay]="6000"
    [loop]="false"
    [showNavButtons]="true"
    [showIndicator]="true">
    <div *dxTemplate="let galleryItem of 'item'">
      <img src="{{ galleryItem.url }}" style="max-width: 100%; max-height: 100%;"/>
    </div>
  </dx-gallery>
</dx-popup>

<dx-load-panel #loadPanel [position]="{ of: '#loaderContainer' }"
    [(visible)]="cartLoading" [showIndicator]="true" [showPane]="true"
    [hideOnOutsideClick]="false" delay="100">
</dx-load-panel>
