import { Component, Input, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DxContextMenuModule, DxDropDownButtonModule, DxListModule } from 'devextreme-angular';

@Component({
  selector: 'app-help-dropdown-btn',
  templateUrl: 'help-dropdown-btn.component.html',
  styleUrls: ['./help-dropdown-btn.component.scss']
})

export class HelpDropdownBtnComponent implements OnInit {

  @Input() menuItems: any;
  @Input() menuMode: string='context';

  constructor(private router: Router) {}

  ngOnInit() {
    this.setHelpItems();
  }

  setHelpItems() {
    this.menuItems = [
      {
        text: 'Home',
        onClick: () => { this.router.navigate(['/']); }
      },
      {
        text: 'About Us',
        onClick: () => { this.router.navigate(['/about']); }
      },
      {
        text: 'Contact Us',
        onClick: () => { this.router.navigate(['/contacts']); }
      }];
  }

}


@NgModule({
  imports: [
    CommonModule,
    DxDropDownButtonModule,
    DxListModule,
    DxContextMenuModule,
  ],
  declarations: [ HelpDropdownBtnComponent ],
  exports: [ HelpDropdownBtnComponent ]
})
export class  HelpDropdownBtnModule { }
