import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-direct-black-box',
  templateUrl: './direct-black-box.component.html',
  styleUrls: ['./direct-black-box.component.scss']
})
export class DirectBlackBoxComponent implements OnInit, AfterViewInit {
  @Input() content: any;
  @Input() mode = 'expanded';
  @Input() isiExpanded = false;
  @Input() isiMinHight = '120px';
  @Input() isiMaxHight = '300px';

  popupVisible = false;

  constructor(private viewportScroller: ViewportScroller, private renderer: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.mode === 'expanded') {
      this.setFloatIsi();
      this.renderDynamicContent();
    } else {
      this.renderDynamicContent2();
    }
  }

  private renderDynamicContent() {
    const div = document.querySelector('#dynamicContent');
    const button = div.querySelector('#expandBtn');
    this.renderer.listen(button, 'click', event => {
      event.preventDefault();
      this.showPopup();
    });
  }

  private renderDynamicContent2() {
    const div = document.querySelector('#dynamicContent2');
    const button = div.querySelector('#expandBtn');
    this.renderer.listen(button, 'click', event => {
      event.preventDefault();
      this.showPopup();
    });
    var element = div.querySelector('#removeForPage');
    if (element) {
      element.remove(); // Removes the element from the DOM
    }
  }

  private setFloatIsi() {
    if (!this.content || this.mode !== 'expanded') {return;}
    const floatisi = document.querySelector('#float-isi');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          floatisi.classList.add('invisible');
        } else {
          floatisi.classList.remove('invisible');
        }
      });
    });
    const isi = document.querySelector("#isi");
    observer.observe(isi);
  }

  scrollToIsi(): void { 
    this.viewportScroller.scrollToAnchor('isi');
  }

  expandFloater() {
    this.isiExpanded = !this.isiExpanded;
  }

  showPopup() {
    this.popupVisible = true;
    setTimeout(() => {
      // wait for DOM rendering
      const div = document.querySelector('#dynamicContentPup');
      div.innerHTML = this.content.url;
      const button = div.querySelector('#expandBtn');
      this.renderer.listen(button, 'click', event => {
        event.preventDefault();
        this.hidePopup();
      });
    });
  }

  hidePopup() {
    this.popupVisible = false;
  }

}
