import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingPriceComponent } from './listing-price.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ListingPriceComponent],
  exports:[ListingPriceComponent]
})
export class ListingPriceModule { }
