<dx-data-grid id="lots-grid" [dataSource]="lots"
              [columnAutoWidth]="true" [showColumnLines]="true"
              [showRowLines]="true" [showBorders]="true"
              [rowAlternationEnabled]="false" keyExpr="id"
              [cacheEnabled]="false"
              [noDataText]="'No Lot(s) Available to Fullfill the Offer.'">

  <dxo-header-filter [visible]="false"></dxo-header-filter>
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-paging [pageSize]="5"></dxo-paging>

  <dxi-column dataField="id" [visible]="false" caption="ID" dataType="number"></dxi-column>
  <dxi-column dataField="name" caption="Lot Name / ID" dataType="string"></dxi-column>
  <dxi-column dataField="expiry_date" caption="Expiry" dataType="date"></dxi-column>
  <dxi-column dataField="stock" caption="Stock" dataType="number"></dxi-column>
  <dxi-column dataField="selectedQuantity" caption="Fulfillment" dataType="number"></dxi-column>
  <div *dxTemplate="let lot of 'priceTemplate'">
    {{lot.data.price | currency: 'USD' : 'symbol'}}
  </div>
</dx-data-grid>
