<div *ngFor="let ol of offer.offer_lines; index as ix" class="alert alert-light">
  <h4 *ngIf="ol.is_seller_side">
    <span *ngIf="ix === offer.offer_lines.length-1">Initial Buyer Bid</span>
    <span *ngIf="ix !== offer.offer_lines.length-1">Buyer Counter Bid</span>
  </h4>
  <h4 *ngIf="ol.is_buyer_side">Seller Counter Bid</h4>

  <div *ngIf="userType==='buyer'">
    {{ol.quantity}} packs @ {{ol.buyer_price | currency: "USD":"symbol"}} each = {{ol.buyer_price * ol.quantity | currency: "USD":"symbol"}}
  </div>
  
  <div *ngIf="userType==='seller'">
    {{ol.quantity}} packs @ {{ol.base_price | currency: "USD":"symbol"}} each = {{ol.base_price * ol.quantity | currency: "USD":"symbol"}}
  </div>

  <div *ngIf="userType==='administrator' && !hydraEnabled" class="row">
    <div class="col-6">
      <b>Buyer: </b>
      {{ol.quantity}} packs @ {{ol.buyer_price | currency: "USD":"symbol"}} each = {{ol.buyer_price * ol.quantity | currency: "USD":"symbol"}}
    </div>
    <div class="col-6">
      <b>Seller: </b>
      {{ol.quantity}} packs @ {{ol.base_price | currency: "USD":"symbol"}} each = {{ol.base_price * ol.quantity | currency: "USD":"symbol"}}
    </div>
  </div>
  <div>Made on {{ol.created_date | date : 'short'}}</div>
</div>

<div *ngIf="offer.status !== 'initial_offer' && offer.status !== 'counter_offer'" class="ml-2">
  Closed on {{offer.declined_date || offer.expiration_date | date}}
  <span>with <b>{{offer.status | uppercase}}</b> status</span>
</div>
