<a *ngIf="mode==='url'" href="#" (click)="redirectToPage($event)" (click)="sendToGa('Clicked ' + listing.company_name + ' Logo', listing.id)">
  <img
     id="logo_{{listing.id}}" 
     class="catalog-company-logo"
     src="{{listing.company_logo}}" 
     [alt]="listing.company_name"
     style="max-width: {{maxWidth}}; max-height: {{maxHeight}};"/>
</a>

<img *ngIf="mode==='img'" 
     id="logo_{{listing.id}}" 
     class="catalog-company-logo"
     src="{{listing.company_logo}}" 
     [alt]="listing.company_name"
     style="max-width: {{maxWidth}}; max-height: {{maxHeight}};"/>

