import {defaultSupplierConfig} from 'src/environments/hydra/configs';
import {hydraSupplierConfigMap} from 'src/environments/hydra/config.map';

//  TODO: real links to hydra suppliers
const baseURL = 'https://hydraprod-api.medigi.com';
const connectHydraLower = true;

const supplierConfig = window.location.host in hydraSupplierConfigMap ? hydraSupplierConfigMap[window.location.host] : defaultSupplierConfig

const hydraAuth0 = {
    clientId: supplierConfig.auth0.clientId,
    domain: "hydraprod.us.auth0.com",
    apiIdentifier: "https://hydraprod.us.auth0.com/api/v2/",
    appMetadata: "http://hydraprod.com/api/app_metadata",
    userMetadata: "http://hydraprod.com/api/user_metadata",
};

const localAuth0 = {
    clientId: "V8A4kBQ4WOd9Y0RA7w61fOIbOmwyoowh",
    domain: "dev-medshorts.auth0.com",
    apiIdentifier: "https://dev-medshorts.auth0.com/api/v2/",
    appMetadata: "http://dev.medshorts.com/api/app_metadata",
    userMetadata: "http://dev.medshorts.com/api/user_metadata",
    supplierHostMap: {},
};

const auth0Envs = connectHydraLower ? hydraAuth0 : localAuth0;

export const environment = {
    production: false,
    env_name: 'hydraprod',
    domain: 'hydraprod.medigi.com',
    url: 'https://hydraprod.medigi.com',
    api_url_v1: baseURL,
    api_url_v2: baseURL,
    medshorts_url: '',
    flower_url: '',
    health_url: `${baseURL}/misc/health`,
    auth_url: `${baseURL}/auth`,
    auth_url_login: `${baseURL}/auth/login`,
    proxy_url: '',
    express222Form: 'asdf',
    gatag: '',
    clientDomains: {
        outsourcingDomian: '',
        medigiDomain: '',
        medshortsDomain: ''
    },
    availableThemes: {},
    catalogType: {},
    hydraEnabled: connectHydraLower,
    auth0: auth0Envs,
    supplierConfig: supplierConfig,
    sentry: {
      dsn: "https://5e36bed6faf54ea9533855f85db421e0@o4506106420330496.ingest.sentry.io/4506107209580544"
    },
    legisym_url: '',
};
