import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPicturePreviewComponent } from './user-picture-preview.component';
import { PipeModule } from 'src/app/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    PipeModule
  ],
  declarations: [UserPicturePreviewComponent],
  exports:[UserPicturePreviewComponent]
})
export class UserPicturePreviewModule { }
