import {Component, OnInit, EventEmitter, Output, Input, OnDestroy, NgModule} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxScrollViewModule, DxRadioGroupModule, DxPopupModule, DxSwitchModule } from 'devextreme-angular';
import { Cart } from 'src/app/shared/models/cart';
import { CartService } from 'src/app/shared/services/cart/cart.service';
import { Listing } from 'src/app/shared/models/listing';
import { MsTooltipModule } from 'src/app/shared/components/ms-tooltip/ms-tooltip.component';
import { AddToCartModule } from 'src/app/shared/components/add-to-cart/add-to-cart.component';
import { PipeModule } from 'src/app/pipe.module';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ListingWishlistToggleModule } from '../listing-wishlist-toggle/listing-wishlist-toggle.component';
import { environment } from 'src/environments/environment';
import { UserService } from '../../services/user/user.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-cart-side',
  templateUrl: './cart-side.component.html',
  styleUrls: ['./cart-side.component.scss']
})
export class CartSideComponent implements OnInit, OnDestroy {

  @Output() visibleOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() mode;
  @Input() initialCartData;

  public cartModeC2 = false;

  cartData: Cart = new Cart();
  selected_shipping_id: any;
  listing_id: number;
  currentListing: Listing;
  addVisible = false;
  loading = false;
  itemsInCart = 0;
  itemsInCartC2 = 0;
  showC2Switch = true;
  hydraEnabled = environment.hydraEnabled;
  paymentOption: string;
  private onDestroy$: Subject<void> = new Subject<void>();
  deliveryComment = environment.hydraEnabled ? environment.supplierConfig.misc.deliveryComment: '';

  constructor(private cartService: CartService,
              private userService: UserService,
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService) { }

  async ngOnInit() {
    await this.setPaymentOption();
    this.cartRefreshingCheck();
    this.cartModeC2 = this.cartService.getCartModeC2();

    if(this.initialCartData){
      this.cartData = this.initialCartData;
    } else {
      this.getCartData(this.cartModeC2);
    }
  }

  async setPaymentOption() {
    const user = await this.userService.getMe().toPromise();
    this.paymentOption = user.data.company.payment_option;
  }

  cartRefreshingCheck() {
    this.cartService.cartRefreshing.pipe(takeUntil(this.onDestroy$)).subscribe(result => this.onCartRefreshing(result));
  }

  onCartRefreshing(value) {
    this.loading = value;
    if (!this.loading) {
      this.cartModeC2 = this.cartService.getCartModeC2();
      this.getCart();
    }
  }

  switchCartModeC2(e) {
    this.cartModeC2 = e.value;
    this.cartService.setCartModeC2(this.cartModeC2);
    this.getCart();
  }

  getCart() {
    this.getCartData(true);
    this.getCartData(false);
  }

  getCartData(isC2) {
    this.cartService.get(isC2).pipe(takeUntil(this.onDestroy$)).subscribe(
      result => {
        if (isC2) {
          this.itemsInCartC2 = result?.items?.length;
        } else {
          this.itemsInCart = result?.items?.length;
        }
        if (result !== null) {
          this.showC2Switch = this.itemsInCart>0 && this.itemsInCartC2>0;
          if (!this.itemsInCartC2) {
            this.cartModeC2 = false;
          } 
          if (!this.itemsInCart && this.itemsInCartC2) {
            this.cartModeC2 = true;
          }
          this.cartService.setCartModeC2(this.cartModeC2);
          if (this.cartModeC2 == isC2) {
            this.cartData = result;
            this.cartData?.items.forEach(item => {
              item.tota_quantity = 0;
              item.sub_total = 0;
              item.pricing.prices.forEach((price) => {
                item.tota_quantity += price.quantity;
                item.sub_total += price.quantity * price.price;
              });
            });
            this.setShipping();
          }
        }
      }
    );
  }

  // Unsubscribe so subscriptions won't fire after view is destroyed
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  checkout() {
    this.visibleOut.emit(false);
    this.cartService.refresh().then(() => {
      this.router.navigate(['/cart']);
    });
  }

  closeCart() {
    this.visibleOut.emit(false);
  }

  setShipping() {
    if (!this.cartData?.selected_shipping?.id) {

      this.selected_shipping_id = this.cartData?.shipping_options[0]?.id;
      const match = this.cartData.shipping_options.filter(s => s.id === this.selected_shipping_id);
      if ( match.length === 1 ) {
        this.cartService.setShipping(match[0], this.cartModeC2);
      }
    }
  }

  removeItem(lineId, isC2, name) {
    let result = confirm(`Are you sure you want to remove <b>${name}</b> from cart?`, 'Confirm Removal');
    result.then((dialogResult) => { if (dialogResult) {
      this.cartService.delete(lineId, isC2);
     }});
  }

  editItem(listing) {
    this.currentListing = listing;
    this.addVisible = true;
  }

  addOutput() {
    this.addVisible = false;
  }

  upperCasePriceType(type) {
    if (type === '340b') {
      return '340B';
    } else {
      return type.toUpperCase();
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxScrollViewModule,
    MsTooltipModule,
    DxRadioGroupModule,
    DxPopupModule,
    AddToCartModule,
    RouterModule,
    PipeModule,
    DxSwitchModule,
    ListingWishlistToggleModule,
  ],
  declarations: [ CartSideComponent ],
  exports: [ CartSideComponent ]
})
export class CartSideModule { }
