<ng-container *ngIf="auth0.loggedIn; else notAuthorized">
    <app-side-nav-outer-toolbar title="{{landingConfigs.clientName}}">
        <ngx-loading-bar></ngx-loading-bar>
        <div>
            <router-outlet></router-outlet>
        </div>
        <app-medigi-footer [loggedIn]="auth0.loggedIn" [directBlackBox]="directBlackBox"></app-medigi-footer>
    </app-side-nav-outer-toolbar>

    <dx-popup 
        *ngIf="showSwitchFacility" 
        class="popup"
        [showTitle]="true"
        title="Change Selected Facility"
        [height]="300"
        [width]="500"
        [showCloseButton]="true"
        [dragEnabled]="true"
        [hideOnOutsideClick]="true"
        [(visible)]="showSwitchFacility"
        [resizeEnabled]="false">
        <dx-scroll-view>
            <app-switch-facilities mode="profile"></app-switch-facilities>
        </dx-scroll-view>
    </dx-popup>

</ng-container>

<ng-template #notAuthorized>
    <app-nav *ngIf="isMedigi"></app-nav>
    <app-medshorts-nav *ngIf="isMedshorts"></app-medshorts-nav>
    <app-outsourcing-nav *ngIf="is503B"></app-outsourcing-nav>

    <app-direct-nav *ngIf="isSellerDirect && directNav" [content]="directNav"></app-direct-nav>

    <ngx-loading-bar></ngx-loading-bar>
    <div class="container-fluid ch-90 pl-0 pr-0">
        <router-outlet></router-outlet>
    </div>

    <app-medigi-footer *ngIf="isMedigi"></app-medigi-footer>
    <app-medshorts-footer *ngIf="isMedshorts"></app-medshorts-footer>
    <app-outsourcing-footer *ngIf="is503B"></app-outsourcing-footer>

    <app-direct-footer *ngIf="isSellerDirect && directFooter" [content]="directFooter"></app-direct-footer>
</ng-template>
