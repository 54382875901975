import { Component, OnInit, Input, ViewChild, OnDestroy, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { DxChartComponent, DxChartModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { timer, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { StatisticsService } from '../../services/analytica/statistics.service';

@Component({
  selector: 'app-qty-per-month',
  templateUrl: './qty-per-month.component.html',
  styleUrls: ['./qty-per-month.component.scss']
})

export class QtyPerMonthComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('qtypmfsb') chart: DxChartComponent;
  @Input() params: any;
  @Input() dataSource: any[]; // = [{ '340B': 0, GPO: 0, WAC: 0, Date: '04-01-2018' }];
  @Input() subtitle = '(by month)';
  @Input() size: any; // = { height: 380, width: 625 }

  subs: Subscription = new Subscription();
  constructor(private statisticsService: StatisticsService) {}

  ngOnInit() {
    this.oberserableTimer();
  }

  ngOnChanges(changes: SimpleChanges) {
    const paramsChange = changes['params'];
    if (paramsChange) {
      this.GetQtyPerMonth();
    }
  }

  GetQtyPerMonth() {
    const s = this.statisticsService.GetQtyByMonthGpi(this.params).subscribe(
      result => {
        if (Array.isArray(result.data) && result.data.length === 0) {
          this.dataSource = [];
        } else {
          this.dataSource = [];
          result.data.forEach( rec => {
            const item = { '340B': 0, GPO: 0, WAC: 0, Date: '01-01-1900' };
            item.Date = rec.date;
            item[rec.account_type] = rec.purchased_pkg;
            this.dataSource.push(item);
          });
          this.subtitle = `(by month ${result.data[0].date} - ${result.data[result.data.length - 1].date})`;
        }
      },
      () => this.dataSource = []);
  
    this.subs.add(s);
  }

  oberserableTimer() {
    const source = timer(7200, 1000);
    const abc = source.subscribe(val => {
      if (this.chart) {
        this.chart.instance.render();
      }
    });
    this.subs.add(abc);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  customizeTooltip(arg: any) {
    return { text: `${arg.argumentText}: ${arg.percentText} - ${arg.valueText} packs (${arg.seriesName})` };
  }

}


@NgModule({
  imports: [
    CommonModule,
    DxChartModule,
    DxLoadIndicatorModule
  ],
  declarations: [QtyPerMonthComponent],
  exports: [QtyPerMonthComponent],
})  
export class QtyPerMonthModule {}