import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  public getAll(params): any {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/ordergroup`, {params: params});
  }

  public getOrderGroup(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/ordergroup/${params.id}`);
  }

  public updateOrderGroup(params): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/ordergroup/${params.id}`, params);
  }

  public getFacilities$(params?): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/order/ordergroup/facilities`, {params});
  }

  public getInvoice(id): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/ordergroup/${id}/invoice`);
  }

  public getOrders(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order`, {params: params});
  }

  public getOrder(id): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order/${id}`);
  }

  public updateOrder(id, data): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/order/${id}`, data);
  }

  public updateOrderOption(id, data): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/order/${data.order_id}/option/${id}`, data);
  }

  public getOrderOptionByType(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order/${params.orderId}/option?type=${params.type}`);
  }

  public addTracking(params): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/order/${params.order_id}/track`, params);
  }

  public addBulkTracking(params): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/order/add_bulk_track`, params);
  }

  public updateTracking(params): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/order/${params.order_id}/track/${params.id}`, params);
  }

  public removeTracking(params): Observable<any> {
    return this.http['delete']<any>(`${environment.api_url_v1}/services/order/order/${params.order_id}/track/${params.id}`, params);
  }

  public trackOrder(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order/${params.order_id}/track`);
  }

  public updateOrderStatus(params): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/order/order_cancellation`, params);
  }

  public orderSummaryStats(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order/summary/stats`);
  }

  public expediteShipping(orderId, params): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/order/${orderId}/expedite`, params);
  }

  public updateShipping(id, shipping_amount): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/order/order_expedite/${id}`, {id, shipping_amount});
  }

  public getPharmacyOrdersFromDate(id, start_date, end_date): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order/facility/${id}?start_date=${start_date}&end_date=${end_date}`);
  }

  public importFinanceFile(formData) {
    return this.http.post<any>(`${environment.api_url_v2}/services/order/finance/import`, formData);
  }

  public order_cancellation_request(params): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v2}/services/order/order/request_cancellation`, params);
  }

  public getBuyerPO$(): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/order/order/generate_po`);
  }

  public placeOrder$(data: any, orderType='generic_order'): Observable<any> {
    const newData = Object.assign({}, data)
    newData['order_type'] = orderType;
    return this.http.post(`${environment.api_url_v1}/services/order/order/place_order`, newData);
  }

  public getPaymentOptions$(): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/order/order/payment_options`);
  }

  public createCsosOrders$(ogId, orderId): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/order/order/csos/${ogId}/${orderId}`);
  }

  public getInvoicePdf(ogId): any {
    return this.http.get(`${environment.api_url_v1}/services/order/edi/${ogId}/invoice.pdf?dt=${new Date().getTime()}`, { responseType: 'blob' });
  }

  public user_reward_and_system_reward_stats(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order/user_reward_and_system_reward_stats`);
  }

  public getBudgetDataFromDate(data): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order/budget_from_date`, {params: data});
  }

  public getBuyerCreditDetails(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/order/get_buyer_credit_details`);
  }

  public sendInvoiceEmail(id): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/emails/send_invoice/${id}`);
  }
  
}
