import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferStatsComponent } from './offer-stats.component';
import { QtyPerMonthModule } from '../qty-per-month/qty-per-month.component';
import { StatDataTileModule } from '../stat-data-tile/stat-data-tile.module';
import { SpendsTypeComparedModule } from '../spends-type-compared/spends-type-compared.module';
import { PipeModule } from 'src/app/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    PipeModule,
    QtyPerMonthModule,
    StatDataTileModule,
    SpendsTypeComparedModule,
  ],
  declarations: [OfferStatsComponent],
  exports: [OfferStatsComponent]
})
export class OfferStatsModule { }
