<nav *ngIf="content?.children" class="navbar navbar-expand-md navbar-light text-right" style="padding: .1rem 1rem" [ngStyle]="{'background-color': content?.bgcolor, 'color': content?.color}">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto" style="padding-right: 25px;">
      <hr>
      <li *ngFor="let lnk of content.children" class="nav-item" style="color: #000">
        <a class="nav-link nav-font-size p-0 my-0" style="font-size:12px;" [href]="lnk.url" target="_blank">{{lnk.title}}</a>
      </li>
    </ul>
  </div>
</nav>

<nav *ngIf="!content?.body" class="navbar navbar-expand-md navbar-light" [ngStyle]="{'background-color': content?.bgcolor, 'color': content?.color}">
  <a *ngIf="content?.url || landingConfigs?.logo_url" class="navbar-brand" routerLink="/">
    <img [src]="content?.url || landingConfigs.logo_url"  height="50px" class="d-inline-block align-top"  alt="logoms">
  </a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto" style="padding-right: 25px;">
      <hr>
      <li class="nav-item" style="color: #000">
        <a class="nav-link nav-font-size" routerLink="/registration/buyer">Facility Registration</a>
      </li>
      <li class="nav-item" style="color: #fff">
        <button class="btn btn-link nav-link nav-font-size button sign-in-button bg-mdg-green" style="color: #fff" routerLink="" (click)="auth0.login()"><p>Sign In</p></button>
      </li>
    </ul>
  </div>
</nav>

<div *ngIf="content?.body" [ngStyle]="{'color': content.color}" [innerHtml]="content.body | bypassSecurity"></div>