import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { DxButtonModule, DxDataGridModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { PipeModule } from 'src/app/pipe.module';

import { MsTooltipModule } from '../ms-tooltip/ms-tooltip.component';
import { QtyPerMonthModule } from '../qty-per-month/qty-per-month.component';
import { SellerCompanyLogoModule } from '../seller-company-logo/seller-company-logo.module';
import { SpendsTypeComparedModule } from '../spends-type-compared/spends-type-compared.module';

import { StatDataTileModule } from '../stat-data-tile/stat-data-tile.module';
import { ListingExpDateComponent } from '../listing-exp-date/listing-exp-date.component';
import {environment} from '../../../../environments/environment';
import { OfferModule } from '../offers/offer.component';
import { MomentModule } from 'ngx-moment';


@Component({
    selector: 'app-medshorts-alternative-grid',
    templateUrl: './medshorts-alternative-grid.component.html',
    styleUrls: ['./medshorts-alternative-grid.component.scss']
  })


  export class MedShortsAlternativesGridComponent implements OnInit {
    @Input() hasMedshortsAccount: boolean;
    @Input() medShortsAlternatives: any;
    @Input() currentListing: any;

    medShortsAlternativesGrid: any = {};

    ngOnInit() {}
  
    ngOnChanges() {
      if (this.hasMedshortsAccount && !!this.medShortsAlternativesGrid){
        this.renderMedShortsAlternatives();
      }
    }


    
  openMedShortsItem(listing_id) {
    window.open(`${environment.medshorts_url}/catalog/detailed/${listing_id}`, '_blank');
  }

  openMedShortsSearch(ndc) {
    // format ndc to dashed version
    ndc = [...ndc]
    ndc.splice(5, 0, '-')
    ndc.splice(10, 0, '-')
    window.open(`${environment.medshorts_url}/catalog?q=${ndc.join('')}`, '_blank');
  }



  renderMedShortsAlternatives() {
    this.medShortsAlternativesGrid = new DataSource({
      store: {
        type: 'array',
        key: 'id',
        data: this.medShortsAlternatives[this.currentListing.gcn_seqno]
      }
    });
  }




  }

  @NgModule({
    imports: [
      CommonModule,
      QtyPerMonthModule,
      SpendsTypeComparedModule,
      MsTooltipModule,
      StatDataTileModule,
      PipeModule,
      DxDataGridModule,
      DxButtonModule,
      DxPopupModule,
      DxScrollViewModule,
      SellerCompanyLogoModule,
      OfferModule,
      MomentModule
    ],
    exports: [MedShortsAlternativesGridComponent],
    declarations: [MedShortsAlternativesGridComponent, ListingExpDateComponent]
  })
  export class MedShortsAlternativesGridModule { }

  
