import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { environment } from 'src/environments/environment';
import { finalize, share, tap } from 'rxjs/operators';

@Injectable()
export class ApiCacheInterceptor implements HttpInterceptor {
  private cache = new Map<string, HttpResponse<any>>();
  private cacheObservable = new Map<string, Observable<any>>();

  private endpointsToRefresh = new Set([
    '/services/user/user/set_primary_facility'
  ]);
  private endpointsToCache = new Set([
    '/services/user/user/me',
    '/services/user/ms_client',
    '/services/user/direct_blocks/client',
  ]);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.replace(environment.api_url_v1, '');
    const refresh = req.params.get('refresh');
    
    // clear cache if url is in refresh list
    if (this.endpointsToRefresh.has(url) || refresh) {
      this.cache.clear();
      this.cacheObservable.clear();
    }

    if (this.endpointsToCache.has(url) && ['POST', 'PUT'].includes(req.method) ) {
      this.cache.clear();
      this.cacheObservable.clear();
    }

    // check if url is aligible for caching else return the request
    if (this.endpointsToCache.has(url) && req.method === 'GET') {
      let observable: Observable<any>;
      
      const cachedResponse = this.cache.get(req.url);
      const cachedObservable = this.cacheObservable.get(req.url);
      // retunr cached response if available
      // else return cached observable if available to prevent dupe calls
      // else make the call and cache the response and call itself
      if (cachedResponse) {
        observable = of(cachedResponse);
      } else if (cachedObservable) {
        observable = cachedObservable;
      } else {
        observable = next.handle(req).pipe(
          tap((response) => {
            if (response instanceof HttpResponse) {
              this.cache.set(req.url, response);
            }
          }),
          share(),
          finalize(() => this.cacheObservable.delete(url))
        );
        this.cacheObservable.set(req.url, observable);
      }
      return observable;
    }
    
    return next.handle(req);
  }
}
