import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(private http: HttpClient, private storage: StorageService) { }

  public logEvent(event, parameters:{}={}) {
    let data = {'function': event,
                'parameters': parameters}
    return this.http.post<any>(`${environment.api_url_v1}/services/user/user/log_message`, data).subscribe();
  }

  public async login(id) {
    const resp = await this.http.post<any>(`${environment.api_url_v1}/services/user/auth/login`, { id }).toPromise();
    return resp.data;
  }

  public async getNotifications() {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/user/me/notifications`).toPromise();
  }

  public async setNotificationRead(notificationId) {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/user/me/notifications/${notificationId}/read`, {}).toPromise();
  }

  public getMsClient$(id?) {
    if (id) {
      return this.http.get<any>(`${environment.api_url_v1}/services/user/ms_client/${id}`);
    } else {
      return this.http.get<any>(`${environment.api_url_v1}/services/user/ms_client`);
    }
  }

  public getMsClients() {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/ms_client/all`);
  }

  public enableMsDirect(id, data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/ms_client/${id}`, data);
  }


  public async _getMsClient() {
    try {
      const result = await this.getMsClient$().toPromise();
      return result.data;
    } catch (e) {
      return await null;
    }
  }

  public getClientFromStorage() {
    let client = this.storage.get('msClient');
    if ( client ) {
      return JSON.parse(client);
    }
    else {
      return null;
    }
  }

  public getPharmacies(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/facility`);
  }


  public clearMsClientFromStorage() {
    return this.storage.remove('msClient');
  }

  public getMe(refresh?): Observable<any> {
    let params = {};
    if (refresh) {
      params = {refresh};
    }
    // set request into a var
    // set sentry user with Sentry.setUser()
    // return request
    return this.http.get<any>(`${environment.api_url_v1}/services/user/user/me`, { params });
  }

  public updateMe(data): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/user/me`, data);
  }

  public getUsers(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/user`);
  }

  public getUser(id): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/user/${id}`);
  }

  public facility_users(facilityId): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/user/facility_users/${facilityId}`);
  }

  public company_users(companyId): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/user/company_users/${companyId}`);
  }

  public addUser(data): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/user`, data);
  }

  public updateUser(id, data): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/user/${id}`, data);
  }

  public setPrimaryFacility(data): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/user/set_primary_facility`, data);
  }

  public updateAvatar(data) {
    return this.http.patch<any>(`${environment.api_url_v1}/services/user/auth0/update_user_pic`, data);
  }

  public deleteAvatar() {
    return this.http.delete<any>(`${environment.api_url_v1}/services/user/auth0/update_user_pic`);
  }

  public updatePassword(password_data: NewPassword) {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/auth0/set_new_password`, password_data);
  }

  public get_email_subscription(userId: number): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/email_subscription/${userId}`);
  }

  public update_email_subscription(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/email_subscription/${id}`,data);
  }

  public create_email_subscription(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/email_subscription`,data);
  }

  public setBudget(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/budget/`,data);
  }

  public getStripeIntent(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/payment`, data);
  }
}

export class NewPassword {
  password: string;
  match_password: string;
}
