import { Component, OnInit, Input, OnDestroy, NgModule, ElementRef } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { Facility } from '../../models/facility';
import { SessionData } from '../../models/session';
import { Subject, Subscription } from 'rxjs';
import { Address } from '../../models/address';
import { CommonModule } from '@angular/common';
import { DxBulletModule, DxButtonModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { MsTooltipModule } from '../ms-tooltip/ms-tooltip.component';
import { UserService } from '../../services/user/user.service';
import { PharmacyService } from '../../services/user/pharmacy.service';
import { NotificationService } from '../../services/notification.service';
import _ from 'lodash';
import { OrdersService } from '../../services/order/orders.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DxoTooltipModule } from 'devextreme-angular/ui/nested';
import { OverlayModule } from '../overlay/overlay.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-switch-facilities',
  templateUrl: './switch-facilities.component.html',
  styleUrls: ['./switch-facilities.component.scss']
})

export class SwitchFacilitiesComponent implements OnInit, OnDestroy {
  @Input() readOnly = false;
  @Input() mode; //profile, header, link
  @Input() items: any[];

  subscriptions: Array<Subscription> = [];
  profile: any;
  session: SessionData;
  primaryFacility: any;
  primaryFacilityId: number;
  company_id: number;
  isCompanyAdmin = false;
  changeFacility = false;
  isAdmin = false;
  isVetted = false;
  canSwitch = false;
  facilitiesList: Array<Facility> = [];
  deliveryAddress: Address;
  private onDestroy$: Subject<void> = new Subject<void>();
  showFacilityInfo: boolean = false;

  tooltipContent: string;
  target: any;
  isVisible = false;
  eventListenersAdded = false;
  budgetAmountUsed: any;
  budgetPercentage: any;
  savingsAmountUsed: any;
  savingsPercentage: any;
  budgetEndDate: Date;
  savingsEndDate: Date;
  formattedBudgetEnd: any;
  formattedBudgetStart: any;
  canManageBudget: any;
  canSetBudgetRoles = ['Administrator', 'CompanyAdmin', 'FinanceAdmin', 'FacilityAdmin'];
  hydraEnabled = environment.hydraEnabled;

  today: Date = new Date();
  showPharmacyBudgetModal = false;

  budget = {
    target: '',
    amount: 0,
    start_date: '',
    end_date: ''
  };
  savings = {
    target: '',
    amount: 0,
    start_date: '',
    end_date: ''
  };

  companySpentBudget: any;
  companySavingsBudget: any;
  facilitySpentBudget: any;
  facilitySavingsBudget: any;


  constructor(private elementRef: ElementRef,
              public sessionService: SessionService,
              private userService: UserService,
              public pharmacyService: PharmacyService,
              private ordersService: OrdersService,
              private notifyService: NotificationService,
              private router: Router){}

  ngOnInit() {
    this.getProfileInformation();
    this.getPharmacies();
    this.watchPrimaryFacilityUpdatedTrigger();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getProfileInformation() {
    this.userService.getMe().toPromise().then(res => {
    this.profile = res.data;
    this.isCompanyAdmin = this.profile.is_company_admin;
    this.changeFacility = this.profile.roles.includes('ChangeFacility');
    this.isAdmin = this.profile.is_admin;
    this.isVetted = this.profile.is_vetted;
    this.primaryFacility = this.profile.primary_facility;
    this.deliveryAddress = this.primaryFacility?.delivery_address;
    this.primaryFacilityId = this.primaryFacility?.id;
    this.company_id = this.profile.company_id
    this.canSwitch = !this.readOnly && (this.isCompanyAdmin  || this.changeFacility || this.isAdmin);
    this.populateBudgetData(this.profile);
    this.canManageBudget = this.canSetBudgetRoles.some(role => this.profile?.roles?.includes(role));

    if (this.budget.amount || this.savings.amount) {
      this.getOrdersFromDate();
      this.budgetEndDate = new Date(this.budget.end_date);
      this.savingsEndDate = new Date(this.savings.end_date);

    } else {
      this.budgetEndDate = null;
      this.savingsEndDate = null;
    }
    });
  }

  getPharmacies() {
    const s = this.pharmacyService.getPharmacies({with_company: 0, with_buyers: 0, with_documents: 0}).subscribe(
      result => this.facilitiesList = result.data,
      error => this.notifyService.showNotify(error, 'error')
    );
    this.subscriptions.push(s);
  }

  onFacilityChanged(e) {
    const params = {facility_id: e.value,user_id: this.profile.id};
    const s = this.userService.setPrimaryFacility(params).subscribe(
      () => this.onFacilityChangeSuccess(),
      error => this.onFacilityChangedError(error)
    );
    this.subscriptions.push(s);
  }

  private onFacilityChangeSuccess() {
    this.notifyService.showNotify('Primary facility was changed', 'success');
    this.sessionService.switchFacilityTrigger.next(false);
    this.sessionService.primaryFacilityUpdatedTrigger.next(true);
    this.showFacilityInfo = false;
  }

  private onFacilityChangedError(error: any) {
    this.notifyService.showNotify(error, 'error');
    this.primaryFacilityId = this.primaryFacility?.id;
  }

  watchPrimaryFacilityUpdatedTrigger() {
    const s = this.sessionService.primaryFacilityUpdatedTrigger.subscribe(() => this.getProfileInformation());
    this.subscriptions.push(s);
  }

  switchFacility() {
    this.sessionService.switchFacilityTrigger.next(true);
  }

  openFacilityDialog() {
    this.showFacilityInfo = !this.showFacilityInfo;
  }


  customizeBudgetTooltip(arg) {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
   });

   const budgetText = arg.target - arg.value > 0 ? 'Budget Remaining' : 'Budget Exceeded By';
    return {
      html: `<p class='text-left mb-0'>Budget Spent: \$${formatter.format(arg.value)} <br>` + `${budgetText}: \$${formatter.format(Math.abs((arg.target - arg.value)))}</p>`,
    };
  }

  customizeSavingsTooltip(arg) {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
   });

   const savingsText = arg.target - arg.value > 0 ? 'Savings Goal Remaining' : 'Savings Goal Exceeded By';
    return {
      html: `<p class='text-left mb-0'> Savings Earned: \$${formatter.format(arg.value)} <br>` + `${savingsText}: \$${formatter.format((arg.target - arg.value))}</p>`,
    };
  }

  getOrdersFromDate() {
    const budgetReqData = {}

    if (this.budget.start_date) {
      budgetReqData['budget_start_date'] = this.budget.start_date;
      budgetReqData['budget_end_date'] = this.budget.end_date;
      budgetReqData['budget_target'] = this.budget.target;
    }

    if (this.savings.start_date) {
      budgetReqData['savings_start_date'] = this.savings.start_date;
      budgetReqData['savings_end_date'] = this.savings.end_date;
      budgetReqData['savings_target'] = this.savings.target;
    }

    this.subscriptions.push(this.ordersService
      .getBudgetDataFromDate(budgetReqData)
      .subscribe(
        (result) => {
          this.budgetAmountUsed = result.data.total_cost;
          this.budgetPercentage = (
            (this.budgetAmountUsed / this.budget.amount) * 100
          ).toFixed(0);

          this.savingsAmountUsed = result.data.total_savings;
          this.savingsPercentage = (
            (this.savingsAmountUsed / this.savings.amount) * 100
          ).toFixed(0);
        },
        (error) => this.notifyService.showNotify(error, "error")
      ));
  }

  populateBudgetData(result) {
    this.companySpentBudget = result.company?.budget.find(x => x.target === 'company' && x.type === 'spent');
    this.companySavingsBudget = result.company?.budget.find(x => x.target === 'company' && x.type === 'savings');
    this.facilitySpentBudget = result.primary_facility?.budget.find(x => x.target === 'facility' && x.type === 'spent');
    this.facilitySavingsBudget = result.primary_facility?.budget.find(x => x.target === 'facility' && x.type === 'savings');

    let tmpBudget = this.companySpentBudget || this.facilitySpentBudget || this.budget;
    let tmpSavings = this.companySavingsBudget || this.facilitySavingsBudget || this.savings;
    this.budget = tmpBudget
    this.savings = tmpSavings

    this.formattedBudgetStart = this.budget.start_date ? moment(this.budget.start_date, 'MM-DD-YYYY').format('MMM Do YY') : null;
    this.formattedBudgetEnd = this.budget.end_date ? moment(this.budget.end_date, 'MM-DD-YYYY').format('MMM Do YY') : null;
  }

  setTooltip(index, e) {
    this.tooltipContent = this.items[index].text;
    this.target = e.target;
    this.isVisible = (typeof this.items[index].text !== 'undefined');
  }

  hideTooltip() {
    this.isVisible = false;
  }

  goToBudgetManager(){
    this.showFacilityInfo = false;
    this.router.navigate(['/pharmacies'])
  }

}

@NgModule({
  imports: [ CommonModule,
             DxSelectBoxModule,
             MsTooltipModule,
             DxTextBoxModule,
             DxBulletModule,
             DxButtonModule,
             DxoTooltipModule,
             OverlayModule,
            ],
  declarations: [ SwitchFacilitiesComponent ],
  exports: [ SwitchFacilitiesComponent ]
})
export class SwitchFacilitiesModule { }
