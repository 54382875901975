<section>
    <div *ngIf="hasMessage" class="text-white" style="font-size: xx-large;">
        Thanks for the enquiry. Our representative will contact you soon!
    </div>
    <section *ngIf="!hasMessage" id="footer-form" class="shadow p-3 rounded card">
        <form id="form-user-update" #regForm (submit)="onSubmit($event)" class="mt-2">
            <dx-form id="form" [(formData)]="contactData" [readOnly]="false" labelLocation="top"
                [showColonAfterLabel]="true" [showValidationSummary]="true" validationGroup="customerData">
                <dxi-item itemType="group" cssClass="second-group" [colCount]="2">
                    <dxi-item itemType="group">
                        <dxi-item dataField="name">
                            <dxo-label text="Name">
                            </dxo-label>
                            <dxi-validation-rule type="required" message="Name is required">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item dataField="email">
                            <dxo-label text="E-mail"></dxo-label>
                            <dxi-validation-rule type="required" message="Email is required">
                            </dxi-validation-rule>
                            <dxi-validation-rule type="email" message="Email is invalid">
                            </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item dataField="phone" helpText="Enter the phone number in USA phone format">
                            <dxo-label text="Phone"></dxo-label>
                            <dxi-validation-rule type="required" message="Phone is required">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item dataField="message" editorType="dxTextArea" [editorOptions]="{ height: 175 }">
                        <dxo-label text="Message"></dxo-label>
                        <dxi-validation-rule type="required" message="Message is required">
                        </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item itemType="button" [colSpan]="2" [buttonOptions]="buttonNextOptions"
                        horizontalAlignment="right">
                    </dxi-item>
                </dxi-item>
            </dx-form>
        </form>
    </section>
</section>