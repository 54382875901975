<div *ngIf="documents">
    <label *ngIf="documents.length<1 && context=='facility' && type == 'state_license'">
    </label>
    <label *ngIf="documents.length<1 && context=='facility' && type == 'dea_license'">
    </label>
    <label *ngIf="documents.length<1 && context=='facility' && type == 'reseller_certificate'">
        <app-ms-tooltip tooltipKey="RESELLER_CERTIFICATE" width="300" id="usl_pi" extraClass="badge-default">
        </app-ms-tooltip>
        Upload a reseller certificate:
    </label>
</div>

<div *ngIf="!disableUpload && !disabled && filteredDocuments.length<limit || persistUploadButton">
    <dx-file-uploader
        selectButtonText="Choose file" [multiple]="false" [(value)]="filesToUpload" labelText=""
        uploadMode="useForm"
        [allowedFileExtensions]="allowedFileExtensions"
        (onValueChanged)="uploadFile()">
    </dx-file-uploader>
    <span class="note">Allowed file extensions: <span *ngFor="let ext of allowedFileExtensionsAllCases">{{ext}} </span></span>
</div>

<div *ngIf="documents" class="row">
    <div *ngFor="let document of filteredDocuments" class="col-sm-12 col-md-12">
        <div class="d-flex flex-row align-items-center">
            <a *ngIf="preview" [routerLink]="[]" (click)="documentOpen(document)">
                <img [src]="document.url" style="max-width: 100px; max-height: 100px;"/>
            </a>
            <span class="link" (click)="documentOpen(document)">{{document.uri}} </span>
            <dx-button *ngIf="(showDelete || context == 'order' || context == 'profile')" 
                stylingMode="text" icon="trash" type="danger" (onClick)="askDelete(document)"
                class="ml-2">
            </dx-button>
        </div>
      </div>
</div>

<dx-popup *ngIf="askDeleteVisible" [width]="380" [height]="170" [showTitle]="true" 
          title="Delete File" [dragEnabled]="false"
          [hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="askDeleteVisible">
    <div *dxTemplate="let data of 'content'">
        <p>Are you sure you want to delete this file?</p>
        <div class="row">
            <div class="col-6 text-left">
                <dx-button text="Yes" type="danger" width="120" (onClick)="documentCurrentDelete()">
                </dx-button>
            </div>
            <div class="col-6 text-right">
                <dx-button text="No" type="default" width="120" (onClick)="noClick()">
                </dx-button>
            </div>
        </div>
    </div>
</dx-popup>

<div *ngIf="documents?.length<1 && context=='facility' && type == 'state_license'" class="alert alert-warning">
    Please upload your state license to make purchases.
</div>
<div *ngIf="documents?.length<1 && context=='facility' && type == 'state_license'" class=" alert alert-info">
    Allow 48 hours for our team to review your license.
</div>
