import { Component, OnInit, Output, EventEmitter, OnDestroy, NgModule } from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { DxButtonModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { CartService } from 'src/app/shared/services/cart/cart.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-cart-panel',
  templateUrl: './cart-panel.component.html',
  styleUrls: ['./cart-panel.component.scss']
})
export class CartPanelComponent implements OnInit, OnDestroy {

  @Output() cartToggle = new EventEmitter<boolean>();

  cart: any;
  itemsInCart = 0;
  itemsInCartC2 = 0;
  loading = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private cartService: CartService, private sessionService: SessionService) {}

  ngOnInit() {
    this.cartRefreshingCheck();
    this.getCart(false);
    this.getCart(true);
    this.watchPrimaryFacilityUpdatedTrigger();
  }

  watchPrimaryFacilityUpdatedTrigger() {
    this.sessionService.primaryFacilityUpdatedTrigger.subscribe(result => this.onPrimaryFacilityUpdatedTrigger(result));
  }

  onPrimaryFacilityUpdatedTrigger(value) {
    if(value) {
      this.loading = true;
      this.cartService.refresh();
    }
  }

  cartRefreshingCheck() {
    this.cartService.cartRefreshing.pipe(takeUntil(this.onDestroy$)).subscribe(result => this.loading = result);
  }

  getCart(isC2) {
    this.cartService.get(isC2).pipe(takeUntil(this.onDestroy$)).subscribe(
      result => {
        if (isC2) {
          this.itemsInCartC2 = result?.items?.length;
        } else {
          this.itemsInCart = result?.items?.length;
          if(result === null) {
            this.cartService.refresh();
          }
        }
        this.loading = false;
      },
      () => this.loading = false
    );
  }

  // Unsubscribe so subscriptions won't fire after view is destroyed
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggleCart() {
    this.cartToggle.emit();
  }

}


@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxLoadIndicatorModule
  ],
  declarations: [ CartPanelComponent ],
  exports: [ CartPanelComponent ]
})
export class CartPanelModule { }
