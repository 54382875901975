<div class="dx-fieldset">
    <div *ngIf="!savingsOnly" class="dx-field">
        <div class="dx-field-label bid-price-left-padding text-left">Subtotal:</div>
        <div class="dx-field-value bid-price-left-padding">
            <span>{{ offerQuantity * offerPrice | usdCurrency }} </span>
        </div>
    </div>
    <div *ngIf="!savingsOnly" class="dx-field">
        <div class="dx-field-label bid-price-left-padding text-left">
            Shipping:
            <span *ngIf="calculations?.shipping_notification" id="shipping-help" class="dx-icon-help icon-adjustment"></span>
        </div>
        <div class="dx-field-value bid-price-left-padding">
            <span style="text-align: right;">{{ calculations?.shipping_price | usdCurrency}}</span>
        </div>
    </div>
    <div *ngIf="calculations?.total_percent_savings > 0" class="dx-field">
        <div class="dx-field-label bid-price-left-padding text-left">Additional Savings:</div>
        <div class="dx-field-value bid-price-left-padding">
            <span>
                {{calculations?.additional_savings | currency : 'USD' : 'symbol'}}
                ({{calculations?.additional_percent_savings | number : '1.1-1'}}%)
            </span>
        </div>
    </div>
    <div class="dx-field">
        <div class="dx-field-label bid-savings-padding text-left">
            Total Savings: <span id="savings-help" class="dx-icon-help icon-adjustment"></span>
        </div>
        <div class="dx-field-value bid-price-left-padding">
            <span>
                {{calculations?.total_savings | usdCurrency}}
                ({{calculations?.total_percent_savings | number:'1.1-1'}}%)
            </span>
        </div>
    </div>
    <div *ngIf="!savingsOnly" class="dx-field">
        <div class="dx-field-label bid-price-left-padding text-left" style="font-weight:700">Total Spend:</div>
        <div class="dx-field-value bid-price-left-padding">
            <span>
                {{(calculations?.shipping_price + (offerPrice * offerQuantity)) | usdCurrency}}
            </span>
        </div>
    </div>
</div>

<dx-tooltip *ngIf="calculations?.shipping_notification" target="#shipping-help" showEvent="dxhoverstart" hideEvent="dxhoverend" [(visible)]="showShippingTooltip">
  {{calculations?.shipping_notification}}
</dx-tooltip>

<dx-tooltip *ngIf="currentListing" target="#savings-help" showEvent="dxhoverstart" hideEvent="dxhoverend" [(visible)]="showSavingsTooltip">
    <div *dxTemplate="let data of 'content'" class="tooltip-word-wrap">
        <div *ngIf="currentListing?.pricing?.amu?.aac_per_pack">
            Total Savings is calculated by your Initial Savings + your Additional Bid Savings
        </div>
        <div *ngIf="!currentListing?.pricing?.amu?.aac_per_pack">
            Calculated based off of the list price <span *ngIf="currentListing?.pricing?.price">({{currentListing?.pricing?.price | usdCurrency}})</span> available now.
        </div>
    </div>
</dx-tooltip>