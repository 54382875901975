import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})

export class RoleGuard  {

  constructor(private userService: UserService, private router: Router) { }
  
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean> | Promise<boolean | UrlTree> | boolean> {
    const user = await this.getRoles();

    const isSeller = user.is_seller;
    const isBuyer = user.is_buyer;
    const isAdmin = user.is_admin;

    if (isAdmin) {
      return true;
    }
    if (!!next.data.role && user.roles.indexOf(next.data.role) === -1) {
      if (isBuyer) {
        this.router.navigate(['/buyer-home'])
      } else if (isSeller) {
        this.router.navigate(['/seller-home'])
      } else if (isAdmin) {
        this.router.navigate(['/admin/home'])
      } else {
        this.router.navigate(['/']);
      }
      return true;
    }
  
    return true
  }

  async getRoles() {
    const currentUser = await this.userService.getMe().toPromise();
    return currentUser.data;
  }

}
