import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor() { }

  public showNotify(message, type, time: number = 2500) {
    notify({message, position: {my: 'center top', at: 'center top'}}, type, time);
  }

  public showSuccessMessage(message, time: number = 2500) {
    this.showNotify(message, 'success', time);
  }
  
  public showErrorMessage(message, time: number = 2500) {
    this.showNotify(message, 'error', time);
  }

}
