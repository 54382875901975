import {Component, OnInit, Input, EventEmitter, Output, NgModule, ChangeDetectorRef} from '@angular/core';
import {QuantityBaseComponent} from '../quantity/quantity.component';
import * as _ from 'underscore';
import * as moment from 'moment';
import {CommonModule} from '@angular/common';
import {
    DxAccordionModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxLoadPanelModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTooltipModule
} from 'devextreme-angular';
import {UserAckModule} from 'src/app/shared/components/offers/user-ack/user-ack.component';
import {NotificationService} from '../../services/notification.service';
import {PipeModule} from 'src/app/pipe.module';
import {OfferService} from '../../services/order/offer.service';
import {CatalogService} from '../../services/listing/catalog.service';
import {OfferStatsModule} from '../offer-stats/offer-stats.module';
import {UserService} from '../../services/user/user.service';
import {OfferListingDetailsModule} from './offer-listing-details/offer-listing-details.component';
import {OfferTotalModule} from './offer-total/offer-total.component';
import {OfferHistoryModule} from '../offer-history/offer-history.module';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import {OfferLotsDistributionModule} from './offer-lots-distribution/offer-lots-distribution.component';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    styleUrls: ['./offer.component.scss']
})
export class OfferComponent extends QuantityBaseComponent implements OnInit {
    @Input() currentListing: any;
    @Input() listingId: number;
    @Input() offerId: number;
    @Input() userType: string;

    @Input() offerPrice: any;
    @Input() offerQuantity: any;

    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    disableButtons = false;

    currencyFormat = {
        type: 'currency',
        precision: 2
    };
    lots: Array<any>;
    offerableLots: Array<any>;
    calculations: any;
    expiryDates: Array<any>;

    currentOffer: any;
    quantityStepSize: number = 1;

    maxPrice: any;
    shippingPrice: any;

    offerPO: string;
    declineReason: string = '';

    isAcknowledged: boolean = false;
    showAcknowledgePopup: boolean = false;
    showSpinner = false;
    showQuantityTooltip = false;
    showSavingsTooltip = false;
    showShippingTooltip = false;
    showDeclineConfirmation = false;
    erroneousOffer = false;
    enableCounterBox = false;

    isReady: boolean = false;
    session: any;

    minimumOrderTotal: number;
    minimumOrderQuantity: number;
    companyName: string;

    minRequired = false;
    minTotalRequired = false;
    minQtyRequired = false;
    errorMessage = '';

    onVacation = false;
    vacationStartDate: any;
    vacationEndDate: any;
    buyerParticipated = false;
    paramsStats: any;

    isBuyer = false;
    isSeller = false;
    isAdmin = false;
    facilityId: any;

    lastOffer: any;

    width: any;
    height: any;
    math = Math;

    selectedLotPrice: any;

    earliestExpiryDate: any;
    daysToEarliestExpiryDate: any;
    isPopupClosable = true;

    availableStock: any;

    updateCalcs = _.throttle((ev, field?, isCounter = false) => {
        if (field === 'offerQuantity') {
            this.setValidQuantity(ev?.value);
        } else if (field === 'updateShippingPrice') {
            this.updateShippingPrice();
        }
        this.updateSavings();
        this.isMinRequired();
    }, 1000)


    constructor(private offerService: OfferService,
                private catalogService: CatalogService,
                private userService: UserService,
                private notifyService: NotificationService,
                private googleAnalyticsService: GoogleAnalyticsService,
                private cdRef: ChangeDetectorRef) {
                    
        super();
    }

    async ngOnInit() {
        await this.setRole();
        if (this.offerId) {
            try {
                await this.getOffer();
            } catch (error) {
                this.notifyService.showErrorMessage(error);
                return;
            }
        } else {
            this.setOfferCalcsFromListing();
        }
        this.getPreviousOffers();
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    getPreviousOffers() {
        const params = {
            page: 1,
            per_page: 1,
            status: 'accepted',
            period: 360,
            listing_id: this.currentListing.id,
            facilityId: this.facilityId
        }
        this.offerService.get(params).subscribe(result => {
            if (result.data.length) {
                this.lastOffer = result.data[0];
            }
        });
    }

    setOfferCalcsFromListing() {
        this.minimumOrderTotal = this.currentListing.company_minimum_order_total;
        this.minimumOrderQuantity = this.currentListing.offer_minimum_quantity;

        this.calculations = {
            shipping_threshold: 0,
            shipping_price: 0,
            shipping_cost: 0,
            total_savings: 0,
            total_percent_savings: 0,
            additional_savings: 0,
            additional_percent_savings: 0,
            total_stock: this.currentListing.stock
        };

        this.quantityStepSize = this.getQuantityStepSize(this.currentListing);
        this.lots = this.currentListing.lots;
        this.expiryDates = this.getExpiryDatesSelectOptions(this.currentListing);
        this.earliestExpiryDate = this.earliestExpiryDate || this.expiryDates[0];

        this.getOfferableLots({
            listing_id: this.currentListing.id,
            earliest_expiry: this.earliestExpiryDate?.date
        });

        this.selectedLotPrice = this.earliestExpiryDate?.price;
        this.companyName = this.currentListing.company_name;
        this.paramsStats = {ndc: this.currentListing.ndc_number, type: 'gpi', annual: true};
        this.isReady = true;
        this.googleAnalyticsService.eventEmitter(this.currentListing.name, 'Opened Bid Modal');
    }

    async updateSavings() {
        if (!this.offerQuantity || !this.offerPrice || !this.earliestExpiryDate) {
            return;
        }
        await this.offerService.getCalc({
            listing_id: this.currentListing.id,
            price: this.offerPrice,
            quantity: this.offerQuantity,
            earliest_expiry: this.earliestExpiryDate.date
        }).then(resp => {
            this.calculations.savings = resp.data.savings;
            this.calculations.total_savings = resp.data.total_savings;
            this.calculations.total_percent_savings = resp.data.total_percent_savings;
            this.calculations.listing_total_savings = resp.data.listing_total_savings;
            this.calculations.listing_total_percent_savings = resp.data.listing_total_percent_savings;
            this.calculations.additional_savings = resp.data.additional_savings;
            this.calculations.additional_percent_savings = resp.data.additional_percent_savings;
            this.calculations.shipping_price = resp.data.shipping_price;
            this.calculations.shipping_cost = resp.data.shipping_cost;
            this.calculations.shipping_threshold = resp.data.shipping_threshold;

            if (this.currentOffer) {
                this.currentOffer.calculations = resp.data;
            }
            this.updateShippingPrice();
        });
    }

    async setRole() {
        const user = await this.userService.getMe().toPromise();
        this.isAdmin = user.data.roles.includes('Administrator');
        this.isSeller = user.data.roles.includes('Seller');
        this.isBuyer = user.data.roles.includes('Buyer');
        this.facilityId = user.data.primary_facility_id;
        this.setPopupSize();
    }

    setPopupSize() {
        this.width = '900';
    }

    makeOffer() {
        this.isPopupClosable = false;
        this.disableButtons = true;
        this.showSpinner = true;
        const data = {
            listing_id: this.currentListing.id,
            earliest_expiry: this.earliestExpiryDate?.date,
            price: this.offerPrice,
            quantity: this.offerQuantity,
            acknowledged: this.isAcknowledged,
            buyer_po: this.offerPO
        };
        this.offerService.makeOffer(data).subscribe(
            result => {
                this.offerId = result.data.id;
                this.emitUpdate('You have made an initial bid', true);
                this.googleAnalyticsService.eventEmitter(this.currentListing.name, 'Bid Placed');
            },
            error => {
                this.disableButtons = true;
                this.errorMessage = error;
            }
        ).add(() => {
            this.showSpinner = false;
            this.isPopupClosable = true;
        });
    }

    makeCounterOffer() {
        this.isPopupClosable = false;
        this.disableButtons = true;
        this.showSpinner = true;
        const data = {
            id: this.currentOffer.id,
            price: this.offerPrice,
            quantity: this.offerQuantity,
            earliest_expiry: this.earliestExpiryDate?.date
        };
        this.offerService.makeCounterOffer(data).subscribe(
            result => {
                this.currentOffer = result.data;
                this.emitUpdate('You have made a counter bid', true);
                this.googleAnalyticsService.eventEmitter(this.currentListing.name, 'Bid Countered');
            },
            error => {
                this.notifyService.showNotify(error, 'error');
                this.disableButtons = true;
                this.errorMessage = error;
            }
        ).add(() => {
            this.showSpinner = false;
            this.isPopupClosable = true;
        });
    }

    async getListing(listingId) {
        if (this.isSeller) {
            return this.catalogService.getSellerListing(listingId).toPromise();
        }
        return this.catalogService.getListing(listingId).toPromise();
    }

    async getOffer() {
        return this.offerService.getById(this.offerId).toPromise().then(async result => {
            this.currentOffer = result.data;

            this.disableButtons = this.currentOffer.status === 'accepted' || this.currentOffer.status === 'declined';
            const listing = await this.getListing(this.currentOffer.listing_id);
            this.currentListing = listing.data;
            this.lots = this.currentListing.lots;
            this.calculations = JSON.parse(JSON.stringify(result.data.calculations));
            this.expiryDates = this.getExpiryDatesSelectOptions(this.currentListing);
            this.earliestExpiryDate = this.expiryDates.find(d => d.date === this.currentOffer.current_offer_line.earliest_expiry_date);

            this.selectedLotPrice = this.earliestExpiryDate?.price;
            this.daysToEarliestExpiryDate = this.earliestExpiryDate?.days;
            this.maxPrice = this.currentListing.highest_pack_price - 0.01;
            this.offerPrice = this.currentOffer.current_offer_line.price;
            this.offerQuantity = this.currentOffer.current_offer_line.quantity;
            this.offerPO = this.currentOffer.buyer_po;
            this.shippingPrice = this.calculations.shipping_price;
            this.quantityStepSize = this.getQuantityStepSize(result.data.listing);

            this.minimumOrderTotal = result.data.minimum_order_total;
            this.minimumOrderQuantity = this.currentListing.offer_minimum_quantity;

            this.companyName = result.data.company_name;

            this.getOfferableLots({
                listing_id: this.currentListing.id,
                earliest_expiry: this.earliestExpiryDate?.date
            });

            this.isReady = true;

            this.setPopupSize();

            this.paramsStats = {ndc: this.currentListing.ndc_number, type: 'gpi', annual: true};
            this.updateCalcs(null, 'updateShippingPrice', true);
        });
    }

    isMinRequired() {
        this.minTotalRequired = this.minimumOrderTotal > (this.offerPrice * this.offerQuantity);
        this.minQtyRequired = this.minimumOrderQuantity > this.offerQuantity;
        this.minRequired = this.minTotalRequired && !this.isSeller;
    }

    updateShippingPrice() {
        this.shippingPrice = this.calculations.shipping_cost;
    }

    getExpiryDatesSelectOptions(listing) {
        return listing.lots.reduce((acc, current) => {
            const x = acc.find(item => item.expiry_days === current.expiry_days);
            if (x) {
                return acc;
            } else {
                return acc.concat([current]);
            }
        }, []).sort((lot1, lot2) => {
            return lot1.expiry_days - lot2.expiry_days;
        }).map(lot => {
            const daysToExpiry = this.getDaysToExpiryDate(lot.expiry_date);
            return {
                date: lot.expiry_date,
                days: `${lot.expiry_date} (${daysToExpiry} days)`,
                price: this.isBuyer ? lot?.price?.price : this.currentOffer.facility_base_price
            };
        });
    }

    getDaysToExpiryDate(expiryDate) {
        return moment.utc(expiryDate, 'MM/DD/YYYY').diff(moment.utc(), 'days');
    }

    getQuantityStepSize(listing) {
        return listing.case_size > 1 ? listing.case_size : 1;
    }

    setValidQuantity(value?) {
        // TODO: Figure out why this won't work in current event loop
        setTimeout(() => {
            this.offerQuantity = this.calculateValidQuantity(
                value || this.offerQuantity,
                this.quantityStepSize,
                this.currentListing
            );
            this.isMinRequired();
            this.updateShippingPrice();
        });
    }

    resetCounterOffer() {
        this.offerPrice = this.currentOffer.current_offer_line.price;
        this.offerQuantity = this.currentOffer.current_offer_line.quantity;
        this.enableCounterBox = false;
    }

    acceptOffer() {
        this.isPopupClosable = false;
        this.disableButtons = true;
        this.showSpinner = true;
        if (this.userType === 'seller') {
            this.isAcknowledged = true;
        }

        this.offerService.acceptOffer({
            id: this.currentOffer.id,
            buyer_po: this.offerPO,
            acknowledged: this.isAcknowledged
        }).subscribe(
            data => {
                this.currentOffer = data.data;
                this.emitUpdate('You have accepted the bid and an order has been placed', true);
                this.googleAnalyticsService.eventEmitter(this.currentListing.name, 'Bid Accepted');
            },
            error => {
                this.notifyService.showNotify(error, 'error');
                this.disableButtons = false;
            }
        ).add(() => {
            this.showSpinner = false;
            this.isPopupClosable = true;
        });
    }

    confirmDeclineOffer(erroneous = false) {
        this.isPopupClosable = false;
        this.showDeclineConfirmation = true;
        this.erroneousOffer = erroneous;
    }

    declineOffer() {
        this.isPopupClosable = false;
        this.disableButtons = true;
        this.showSpinner = true;
        const data = {id: this.currentOffer.id, reason: this.declineReason, erroneous: this.erroneousOffer};
        this._declineOffer(data);
    }

    _declineOffer(data: { id: any; reason: string; erroneous: boolean; }) {
        this.offerService.declineOffer(data).subscribe(
            result => this.onDeclineOfferSuccess(result),
            error => this.onDeclineOfferError(error)
        ).add(() => this.onDeclineOfferComplete());
    }

    onDeclineOfferSuccess(result: any) {
        this.currentOffer = result.data;
        this.emitUpdate('You have declined the bid', true);
        this.googleAnalyticsService.eventEmitter(this.currentListing.name, 'Bid Declined');
    }

    onDeclineOfferError(error: any) {
        this.notifyService.showNotify(error, 'error');
        this.disableButtons = false;
    }

    onDeclineOfferComplete() {
        this.showDeclineConfirmation = false;
        this.onClose.emit();
        this.showSpinner = false;
    }

    onCounterBoxClick() {
        this.enableCounterBox = true;
        if (this.offerQuantity > this.availableStock) {
            this.offerQuantity = this.availableStock;
        }
    }

    emitUpdate(message, success) {
        this.update.emit({
            offer: this.currentOffer,
            message,
            success
        });
    }

    updateEarliestExpiryDate(ev) {
        this.earliestExpiryDate = this.expiryDates.find(d => ev.value === d.date);
        this.selectedLotPrice = this.earliestExpiryDate?.price;
        const data = {
            listing_id: this.currentListing.id,
            quantity: this.offerQuantity,
            earliest_expiry: this.earliestExpiryDate?.date
        };
        this.getOfferableLots(data);
        if (this.offerQuantity >= this.calculations.total_stock) {
            this.offerService.getDistributedLots(data).subscribe(result => this.calcTotalStock(result.data));
        }
        this.updateSavings();
    }

    getOfferableLots(data) {
        this.offerService.getOfferableLots(data).subscribe(
            result => {
                this.offerableLots = result.data;
                this.availableStock = this.offerableLots.map(a => a.stock).reduce((a, cv) => {return a + cv;});
            },
            () => {}
        );
    }

    calcTotalStock(data: any): void {
        this.calculations.total_stock = data.reduce((ac, currentValue) => {
            return ac + currentValue.stock;
        }, 0);
    }
}


@NgModule({
    imports: [
        CommonModule,
        DxPopupModule,
        DxScrollViewModule,
        DxAccordionModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxTextBoxModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxLoadPanelModule,
        DxTooltipModule,
        UserAckModule,
        OfferListingDetailsModule,
        OfferTotalModule,
        PipeModule,
        OfferStatsModule,
        OfferHistoryModule,
        OfferLotsDistributionModule
    ],
    exports: [OfferComponent],
    declarations: [OfferComponent]
})
export class OfferModule {
}