import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtRoutesModule } from './jwt-routes.module';
import { JwtComponent } from './jwt.component';

@NgModule({
    imports: [
      CommonModule,
      JwtRoutesModule
    ],
    declarations: [ JwtComponent ],
    exports: [ JwtComponent ]
  })
  export class  JwtModule { }
