import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotesService } from '../../services/listing/notes.service';
import { NotificationService } from '../../services/notification.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-listing-notes',
  templateUrl: './listing-notes.component.html'
})
export class ListingNotesComponent implements OnInit {
  @Input() listing: any;
  @Input() user: any;
  @Output() onRecordUpdate = new EventEmitter<boolean>();
  comment: string;
  currentNote: any;
  notesPopupVisible = false;
  notesEditorPopupVisible = false;
  notes: any[] = [];
  loading = false;
  noteTypes = [];
  selectedNoteType: string;
  noteMap = {};

  constructor(private notesService: NotesService, private notifyService: NotificationService) { }

  ngOnInit() {
    let gcnText = 'This item from any manufacturer';
    if (this.listing.active_ingredients.ingredient) {
      gcnText = `${gcnText} (${this.listing.active_ingredients.ingredient} ${this.listing.active_ingredients.concentration})`
    }

    this.noteTypes = [
      {
        'value': 'listing',
        'text': `This NDC only (${this.listing.ndc})`
      },
      {
        'value': 'gcn',
        'text': gcnText
      }
    ];
  
    this.noteMap = {
      'Listing': this.listing.name,
      'gcn': `${this.listing.active_ingredients.ingredient || this.listing.gcn_seqno} ${this.listing.active_ingredients.concentration}`
    }
  }

  showNotesPopup() {
    this.getNotes();
    this.notesPopupVisible = true;
  }

  showNotesEditorPopup(note) {
    this.currentNote = note;
    this.notesEditorPopupVisible = true;
  }

  upsertNote(e){
    e.preventDefault();
  }

  getNotes(loading = true) {
    this.loading = loading;
    this.notesService.getNotes({listing_id: this.listing.id}).subscribe(
      result => this.notes = result.data,
      () => this.notifyService.showErrorMessage('Error fetching notes')
    ).add(() => this.loading = false);
  }

  addNote() {
    var data = this.getNotedata();
    
    this.notesService.addNote(data).subscribe(
      () => {
        this.comment = '';
        this.notifyService.showSuccessMessage('Note successfully added');
        this.onPopupHiding();
      },
      error => this.notifyService.showErrorMessage(error)
    ).add(() => this.getNotes(false));
  }

  private getNotedata() {
    if (this.selectedNoteType === 'listing') {
      return { comment: this.comment, listing_id: this.listing.id };
    }
    if (this.selectedNoteType === 'gcn') {
      return { comment: this.comment, gcn: this.listing.gcn_seqno };
    }
  }

  updateNote() {
    this.notesService.updateNote(this.currentNote.id, this.currentNote).subscribe(
      () => {
        this.notifyService.showSuccessMessage('Note successfully updated');
        this.notesEditorPopupVisible = false;
        this.onPopupHiding();
      },
      error => this.notifyService.showErrorMessage(error)
    ).add(() => this.getNotes(false));
  }

  expireNote(id) {
    const result = confirm(`<i>This will delete selected note. Are you sure?</i>`, 'Confirm deletion.');
    result.then((dialogResult) => { if (dialogResult) { this._expireNote(id);} });
  }

  _expireNote(id) {
    this.notesService.expireNote(id).subscribe(
      () => {
        this.notifyService.showSuccessMessage('Note removed successfully');
        this.onPopupHiding();
      },
      error => this.notifyService.showErrorMessage(error)
    ).add(() => this.getNotes(false));
  }

  onPopupHiding() {
    this.notesPopupVisible = false;
    this.onRecordUpdate.emit(true);
  }
}
