<div *ngIf="!isCatalogComponent" id="new-theme" class="search-panel">
  <div class="search-info">
    <div class="btn-container">
      <dx-button 
      id="btn-search" 
      stylingMode="text" 
      icon="small-search" 
      type="normal" 
      (onClick)="searchAndRedirect()">
    </dx-button>
    </div>
    <dx-text-box
    id="header-search-text-box" 
    placeholder="Search All Listings" 
    style="width: 100%; margin-right: 4px;"
    [showClearButton]="true" 
    [(value)]="searchValue" 
    (onValueChanged)="setSearchText()"
    (onEnterKey)="searchAndRedirect()"
    [stylingMode]="'underlined'">
  </dx-text-box>
  </div>
</div>

<!-- Catalog Search -->
<div *ngIf="isCatalogComponent" id="new-theme" class="catalog-search-panel">
  <div class="search-info">
    <div class="catalog-btn-container">
      <dx-button 
      id="btn-search" 
      stylingMode="text" 
      icon="small-search" 
      type="normal" 
      (onClick)="searchClick()">
    </dx-button>
    </div>
    <dx-text-box 
    id="catalog-search-text-box" 
    placeholder="Search Catalog" 
    style="width: 100%; margin-right: 4px;" 
    [showClearButton]="true"
    [(value)]="searchValue" 
    (onValueChanged)="setSearchText()"
    (onEnterKey)="searchClick()"
    [stylingMode]="'underlined'">
  </dx-text-box>
  </div>
</div>