import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialStatementsUploaderComponent } from './financial-statements-uploader.component';
import { DxButtonModule, DxFileUploaderModule, DxLoadPanelModule, DxPopupModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    DxPopupModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxLoadPanelModule
  ],
  declarations: [FinancialStatementsUploaderComponent],
  exports: [FinancialStatementsUploaderComponent]
})
export class FinancialStatementsUploaderModule { }
