import { Component, NgModule, OnInit } from '@angular/core';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { CommonModule } from '@angular/common';
import { UserPicturePreviewModule } from '../user-picture-preview/user-picture-preview.module';
import { DxButtonModule, DxFileUploaderModule } from 'devextreme-angular';

@Component({
  selector: 'app-user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss']
})
export class UserPictureComponent implements OnInit {

  profile: any;
  firstName = '';
  lastName = '';
  userMetadata = environment.auth0.userMetadata;
  userPicture = [];

  constructor(public auth0: Auth0Service,
              private userService: UserService,
              private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.userService.getMe().subscribe( res => {
      this.firstName = res.data.first_name;
      this.lastName = res.data.last_name;
    });
    this.auth0.userProfile$.subscribe( res => {
      this.profile = res;
    });
  }

  uploadAvatar() {
    if (this.userPicture.length > 0) {
      const formData = new FormData();
      formData.append('user_pic', this.userPicture[0]);
      this.userService.updateAvatar(formData).subscribe(
        result => {
          this.profile[this.userMetadata].picture = result.data;
          this.auth0.updateUserSubject(this.profile);
          this.notifyService.showNotify('User\'s image was updated', 'success');
        },
        error => {
          this.notifyService.showNotify(error, 'error');
        }
      );
    } else {
      this.notifyService.showNotify('Image can not be empty', 'error');
    }
  }

  deleteAvatar() {
    this.userService.deleteAvatar().subscribe(
      () => {
        this.profile[this.userMetadata].picture = '';
        this.auth0.updateUserSubject(this.profile);
        this.notifyService.showNotify('User\'s image was deleted', 'success');
      },
      error => {
        this.notifyService.showNotify(error, 'error');
      }
    );
  }

}

@NgModule({
  imports: [
    CommonModule,
    UserPicturePreviewModule,
    DxFileUploaderModule,
    DxButtonModule
  ],

  declarations: [UserPictureComponent],
  exports: [UserPictureComponent]
})
export class UserPictureModule { }