import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { DirectService } from 'src/app/shared/services/user/direct.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-direct-nav',
  templateUrl: './direct-nav.component.html',
  styleUrls: ['./direct-nav.component.css']
})
export class DirectNavComponent implements OnInit {
  @Input() content;

  landingConfigs: any;

  constructor(public auth0: Auth0Service,
              private directService: DirectService,
              public userService: UserService,
              private elementRef: ElementRef,
              private renderer: Renderer2) { }

  ngOnInit() {
    this.getAppConfigs();
    setTimeout(() => {
      // wait for DOM rendering
      this.directService.reinsertLinks(this.elementRef, this.renderer);
    });
  }

  getAppConfigs() {
    this.userService.getMsClient$().subscribe(
      result => this.landingConfigs = result.data
    );
  }

}
