import { Component, Input, OnInit, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DxListModule, DxContextMenuModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { PipeModule } from 'src/app/pipe.module';
import { environment } from 'src/environments/environment';
import { UserPicturePreviewModule } from 'src/app/shared/components/user-picture-preview/user-picture-preview.module';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent implements OnInit{
  @Input() menuItems: any;
  @Input() menuMode: string;
  firstName = '';
  lastName = '';
  profile: any;
  userMetadata = environment.auth0.userMetadata;

  constructor(public auth0: Auth0Service, private userService: UserService) {}

  ngOnInit(): void {
    this.setName();
    this.auth0.userProfile$.subscribe( res => this.profile = res);
  }

  setName() {
    this.userService.getMe().subscribe(
      result => {
        this.firstName = result.data?.first_name;
        this.lastName = result.data?.last_name;
      },
      () => this.auth0.logout()
    );
  }
}

@NgModule({
  imports: [
    DxListModule,
    DxContextMenuModule,
    CommonModule,
    PipeModule,
    UserPicturePreviewModule
  ],
  declarations: [ UserPanelComponent ],
  exports: [ UserPanelComponent ]
})
export class UserPanelModule { }
