import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../notification.service';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  VETT_STATUSES = ['Unreviewed', 'Approved', 'Denied'];
  COMPANY_TYPE = ['GPO Institution', 'Supplier', 'GPO'];

  constructor(private notifyService: NotificationService, 
              public auth0Service: Auth0Service,
              public router: Router,
              private http: HttpClient) { }

  public getCompanies(params=null): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/admin/companies`, { params: params });
  }

  public getFacilities(params=null): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/admin/facilities`, { params: params });
  }

  public getUsers(params=null): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/admin/users`, { params: params });
  }

  userStatusUpdate(id, vett_status): Observable<any> {
    const user = { id, vett_status };
    return  this.http.put<any>(`${environment.api_url_v1}/services/user/admin/users/${user.id}`, user);
  }

  userImpersonate(impersonator_id, target_id) {
    const data = { impersonator_id, target_id };
    const apiCall = this.http.put<any>(`${environment.api_url_v1}/services/user/admin/users/impersonate`, data);
    apiCall.subscribe(
      () => this.onImpersonateSuccess(),
      error => this.notifyService.showNotify(error, 'error')
    )
  }

  async onImpersonateSuccess() {
    this.auth0Service.login();
    this.notifyService.showNotify('User was impersonated, please login with current credentials', 'success')
  }

  public vetCompany(facilityId: number): Observable<any> {
    const url = `${environment.api_url_v1}/services/user/admin/vet_company?facility_id=${facilityId}`;
    return this.http.put<any>(url, null);
  }

  public declineFacility(facilityId: number): Observable<any> {
    const url = `${environment.api_url_v1}/services/user/admin/decline_facility?facility_id=${facilityId}`;
    return this.http.put<any>(url, null);
  }

  public updateFacility(facility: any): Observable<any> {
    const url = `${environment.api_url_v1}/services/user/admin/facility`;
    return this.http.put<any>(url, facility);
  }

  public updateManufacturers(facilityId: number): Observable<any> {
    const url = `${environment.api_url_v1}/services/user/admin/update_manufacturers?facility_id=${facilityId}`;
    return this.http.get<any>(url);
  }

  public getSellerCatalog(sellerId, params=null): any {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/admin/listings/${sellerId}`, { params });
  }

  public getCsosSuppliers(): any {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/admin/csos_suppliers`);
  }

  public csosApprove(facility_id, seller_id, approve): any {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/admin/csos_approve`, { facility_id, seller_id, approve });
  }

  public setContactUser(user_id, company_id): any {
    return this.http.put<any>(`${environment.api_url_v1}/services/user/admin/set_contact_user`, {user_id, company_id});
  }

  public askGpt(message): any {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/admin/gpt/ask`, { message });
  }

  public initGpt(params?): any {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/admin/gpt/init`, { params });
  }

  public runSql(query): any {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/admin/gpt/run_sql`, { query });
  }

  public getGptShipmentFromText(message): any {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/admin/gpt/get_shipment/text`, { message });
  }

  public getGptShipmentFromImage(data): any {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/admin/gpt/get_shipment/image`, data);
  }

  public talkGptAssistant(data): any {
    return this.http.post<any>(`${environment.api_url_v1}/services/user/admin/gpt/gpt_assist`, data);
  }

  public getGptAssistantMessages(threadId): any {
    return this.http.get<any>(`${environment.api_url_v1}/services/user/admin/gpt/get_assistant_messages/${threadId}`);
  }

  public getBuyerCatalog(data, params): any {
    return this.http.post<any>(`${environment.api_url_v1}/services/listing/admin/listings/get_buyer_catalog`, data, { params });
  }
  
}
