import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient) { }

  getTopNdc(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/top_spends_ndc?take=25`);
  }

  getTopGeneric(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/top_spends_generic?take=25`);
  }

  getSavings(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/savings`);
  }

  getTotalSpendsByMonth(params=null): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/total_spends_by_month`, {params});
  }

  getTotalSpendsByType(): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/total_spends_by_type`);
  }

  getSpendsByType(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/spends_by_type`, {params});
  }

  getSpends(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/spends`, {params});
  }

  GetQtyByMonthGpi(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/qtybymonthgpi`, {params});
  }

  getPredictionAnomaly(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/prediction/anomaly`, {params});
  }

  getUnitCostChange(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/statistics/unit_cost_change`, {params});
  }

  getHistory(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/analytica/history`, {params});
  }

}
