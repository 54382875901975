import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class NotesService {

  constructor(private http: HttpClient) { }

  public getNotes(params): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/listing/notes`, { params });
  }

  public getNote(id): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/listing/notes/${id}`);
  }

  public addNote(data): Observable<any> {
    return this.http.post(`${environment.api_url_v1}/services/listing/notes`, data);
  }

  public updateNote(id, data): Observable<any> {
    return this.http.put(`${environment.api_url_v1}/services/listing/notes/${id}`, data);
  }

  public expireNote(id): Observable<any> {
    return this.http.delete(`${environment.api_url_v1}/services/listing/notes/${id}`);
  }
}
