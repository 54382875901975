<dx-context-menu
        *ngIf="menuMode === 'context'"
        [items]="menuItems"
        target=".help-button"
        showEvent="dxclick"
        width="100px"
        [position]="{ my: 'top center', at: 'bottom center' }"
        cssClass="user-menu">
</dx-context-menu>

<dx-list
    *ngIf="menuMode === 'list'"
    class="dx-toolbar-menu-action"
    [items]="menuItems">
</dx-list>
