import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroBlockComponent } from './hero-block/hero-block.component';
import { DirectBlockComponent } from './direct-block/direct-block.component';
import { NewsBlockComponent } from './news-block/news-block.component';
import { CarouselComponent } from './carousel/carousel.component';
import { PipeModule } from 'src/app/pipe.module';
import { DirectNavComponent } from './direct-nav/direct-nav.component';
import { RouterModule } from '@angular/router';
import { DirectFooterComponent } from './direct-footer/direct-footer.component';
import { StaticFooterModule } from 'src/app/layouts/static-footer/static-footer.component';
import { DirectBlackBoxComponent } from './direct-black-box/direct-black-box.component';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipeModule,
    StaticFooterModule,
    DxButtonModule,
    DxPopupModule
  ],
  declarations: [
    HeroBlockComponent,
    DirectBlockComponent,
    NewsBlockComponent,
    CarouselComponent,
    DirectNavComponent,
    DirectFooterComponent,
    DirectBlackBoxComponent
  ],
  exports: [
    HeroBlockComponent,
    DirectBlockComponent,
    NewsBlockComponent,
    CarouselComponent,
    DirectNavComponent,
    DirectFooterComponent,
    DirectBlackBoxComponent
  ]
})
export class DirectModule { }
