<dx-chart id="qtypmfsb" [dataSource]="dataSource" [size]="size" #qtypmfsb>
  <dxo-title text="Purchasing history" horizontalAlignment="center">
    <dxo-subtitle [(text)]="subtitle"></dxo-subtitle>
  </dxo-title>
  <dxi-series valueField="340B" name="340B" color="#a66b60"></dxi-series>
  <dxi-series valueField="GPO" name="GPO" color="#78b6d9"></dxi-series>
  <dxi-series valueField="WAC" name="WAC" color="#6682bb"></dxi-series>

  <dxo-common-series-settings argumentField="Date" type="stackedBar" ></dxo-common-series-settings>
  <dxo-legend verticalAlignment="top" horizontalAlignment="center" itemTextPosition="right"></dxo-legend>
  <dxo-label [visible]="true" backgroundColor="#c18e92"></dxo-label>
  <dxo-argument-axis>
    <dxo-label wordWrap="none" overlappingBehavior="stagger"></dxo-label>
  </dxo-argument-axis>
  <dxi-value-axis position="left">
    <dxo-title text="Packages"></dxo-title>
  </dxi-value-axis>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" [zIndex]="5000"></dxo-tooltip>
</dx-chart>

<div *ngIf="!dataSource" class="m-auto" style="display: flex; justify-content: center; align-items: center; width: 100%;
                                                height: 100%; position: absolute; top: 0;left: 0;">
    <div class="border rounded shadow" style="width: 200px; height: 100px;">
        <dx-load-indicator id="large-indicator" height="60" width="60"></dx-load-indicator>
        <div>Waiting for data...</div>
    </div>
</div>