import { Output, Injectable, EventEmitter } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()
export class ScreenService {
  @Output() changed = new EventEmitter();

  XSmall = '(max-width: 599.99px)';
  Small = '(min-width: 600px) and (max-width: 959.99px)';
  Medium = '(min-width: 960px) and (max-width: 1599.99px)';
  Large = '(min-width: 1600px) and (max-width: 1919.99px)';
  XLarge = '(min-width: 1920px)';

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([this.XSmall, this.Small, this.Medium, this.Large])
      .subscribe(() => this.changed.next());
  }

  private isLargeScreen() {
    const isLarge = this.breakpointObserver.isMatched(this.Large);
    const isXLarge = this.breakpointObserver.isMatched(this.XLarge);

    return isLarge || isXLarge;
  }

  public get sizes() {
    return {
      'screen-x-small': this.breakpointObserver.isMatched(this.XSmall),
      'screen-small': this.breakpointObserver.isMatched(this.Small),
      'screen-medium': this.breakpointObserver.isMatched(this.Medium),
      'screen-large': this.isLargeScreen(),
    };
  }
}
