import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProxyUrlPipe } from './shared/pipes/proxy-url.pipe';
import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';
import { NdcPipe } from './shared/pipes/ndc.pipe';
import { PoPipe } from './shared/pipes/po.pipe';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { BypassSecurityPipe } from './shared/pipes/bypass-security.pipe';
import { PackageTypeImagePipe } from './shared/pipes/package-type-image.pipe';
import { DateToColorPipe } from './shared/pipes/date-to-color.pipe';
import { VersionUrlPipe } from './shared/pipes/version-url.pipe';
import { IsPreShippedStatusPipe } from './shared/pipes/is-pre-shipped-status.pipe';
import { FullNamePipe } from './shared/pipes/full-name.pipe';
import { IsInArrayPipe } from './shared/pipes/is-in-array.pipe';
import { EmailSplitPipe } from './shared/pipes/email-split.pipe';
import { TrustURLPipe } from './shared/pipes/trust-url.pipe';
import { ThousandSuffixesPipe } from './shared/pipes/thousand-suff.pipe';
import { UsdCurrencyPipe } from './shared/pipes/currency-usd.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ProxyUrlPipe,
    SafeUrlPipe,
    NdcPipe,
    PoPipe,
    TruncatePipe,
    BypassSecurityPipe,
    PackageTypeImagePipe,
    DateToColorPipe,
    VersionUrlPipe,
    IsPreShippedStatusPipe,
    FullNamePipe,
    IsInArrayPipe,
    EmailSplitPipe,
    TrustURLPipe,
    ThousandSuffixesPipe,
    UsdCurrencyPipe
  ],
  exports: [
    ProxyUrlPipe,
    SafeUrlPipe,
    NdcPipe,
    PoPipe,
    TruncatePipe,
    BypassSecurityPipe,
    PackageTypeImagePipe,
    DateToColorPipe,
    VersionUrlPipe,
    IsPreShippedStatusPipe,
    FullNamePipe,
    IsInArrayPipe,
    EmailSplitPipe,
    TrustURLPipe,
    ThousandSuffixesPipe,
    UsdCurrencyPipe
  ]
})
export class PipeModule { }
