import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

@Component({
  selector: 'app-purchasing-history',
  templateUrl: './purchasing-history.component.html'
})
export class PurchasingHistoryComponent implements OnInit {

  @Input() invoices: any;
  @Input() title: string = 'Purchasing history';
  constructor() { }

  ngOnInit() {
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.title);

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.title + '.xlsx');
      });
    });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule
  ],
  declarations: [PurchasingHistoryComponent],
  exports: [PurchasingHistoryComponent],
})  
export class PurchasingHistoryModule {}