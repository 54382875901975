import { Component, OnInit, Input, ChangeDetectorRef, AfterViewChecked, OnChanges, SimpleChanges, NgModule, Output, EventEmitter } from '@angular/core';
import { TooltipService } from '../../services/tooltip.service';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxPopoverModule } from 'devextreme-angular';
import { PipeModule } from 'src/app/pipe.module';

@Component({
  selector: 'app-ms-tooltip',
  templateUrl: './ms-tooltip.component.html',
  styleUrls: ['./ms-tooltip.component.scss']
})

export class MsTooltipComponent implements OnInit, AfterViewChecked, OnChanges {

  @Input() tooltipText;
  @Input() tooltipKey: string;
  @Input() tooltipTitle = false;
  @Input() size = 'dx-font-xs';
  @Input() badgeText = false;
  @Input() extraClass = '';
  @Input() id;
  @Input() target;
  @Input() width = '';
  @Input() position = 'top';
  @Input() hideEvent = 'mouseleave';
  @Input() animation = false;
  @Output() mouseEnter = new EventEmitter<boolean>();
  @Output() mouseLeave = new EventEmitter<boolean>();
  tooltips: any;
  defaultVisible = false;
  elementClass = '';

  constructor(private cdRef: ChangeDetectorRef, private tooltipService: TooltipService) { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    if (this.tooltipKey) {
      this.tooltips = this.tooltipService.getRaw();
      this.tooltipText = this.tooltips[this.tooltipKey]['text'];
      this.tooltipTitle = this.tooltips[this.tooltipKey]['title'];
    }

    if (this.target) {  // When targeting another element no css is needed
      this.elementClass = '';
    } else {
      this.elementClass = `badge badge-pill ${this.size} ${this.extraClass}`;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const idChange = changes['id'];
    if (idChange) {
      this.id = idChange.currentValue;
    }
  }

  toggleDefault() {
    this.defaultVisible = !this.defaultVisible;
  }

  onMouseEnter() {
    this.mouseEnter.emit(true);
  }

  onMouseLeave() {
    this.mouseLeave.emit(true);
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxPopoverModule,
    PipeModule
  ],
  declarations: [ MsTooltipComponent ],
  exports: [ MsTooltipComponent ]
})
export class MsTooltipModule { }
