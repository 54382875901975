import { Component, OnInit, NgModule } from '@angular/core';
import { Landing } from 'src/app/shared/models/landing';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { AppInfoService } from 'src/app/shared/services/app-info.service';
import { SessionData } from 'src/app/shared/models/session';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DxButtonModule } from 'devextreme-angular';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  session: SessionData;
  reg_complete = false;

  isDarkMode = false;
  hasDarkMode = false;
  landingConfigs: Landing;
  mobileUser: boolean = false;

  isSellerUrl = true;

  constructor(public auth0: Auth0Service,
              public appInfo: AppInfoService,
              private screenService: ScreenService) { }

  ngOnInit() {
    this.setIsSellerUrl();
    this.getAppConfigs();
    this.userScreenSize();
  }

  private setIsSellerUrl() {
    Object.entries(environment.clientDomains).forEach(
      ([_, value]) => {
        if (value === window.location.host) this.isSellerUrl = false;
      }
    );
  }

  getAppConfigs() {
    this.appInfo.get().subscribe(result => {this.landingConfigs = result;});
  }

  userScreenSize() {
    this.screenService.sizes['screen-small'] || this.screenService.sizes['screen-x-small'] ? this.mobileUser = true : this.mobileUser = false;
  }

}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    DxButtonModule
  ],
  declarations: [ NavComponent ],
  exports: [ NavComponent ]
})
export class  NavModule { }
