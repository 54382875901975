<i *ngIf="listing.notes" class="dx-icon-comment mr-1" style="cursor: pointer; color: #01203A;" title="Notes left by other team members" (click)="showNotesPopup()"></i>
<i *ngIf="!listing.notes" class="dx-icon-comment mr-1 heart-default" style="cursor: pointer;" title="Create a note for your other team member"  (click)="showNotesPopup()"></i>


<app-medigi-popup
  *ngIf="notesPopupVisible"
  class="popup"
  [fullScreen]="true"
  [showTitle]="true"
  title="Notes for {{listing.name}}"
  [dragEnabled]="true"
  [(visible)]="notesPopupVisible"
  [resizeEnabled]="true"
  [width]="700"
  [minHeight]="500"
>
  <div class="text-center">
    <div *ngIf="!loading && !notes.length" class="text-secondary">No notes for now...</div>
    <dx-load-indicator *ngIf="loading" height="60" width="60"></dx-load-indicator>
  </div>
  <div *ngFor="let note of notes" class="alert alert-info mb-2" role="alert">
    <div class="row">
      <div class="col-10">
        <b>{{note.first_name}} {{note.last_name}}, </b>
        <i *ngIf="note.gcn">for item</i><i *ngIf="note.listing_id">for NDC</i>
        ({{note.created_date}})
      </div>
      <div class="col-2 text-right">
        <i class="dx-icon-edit mr-1 alert-link" style="cursor: pointer;" (click)="showNotesEditorPopup(note)"></i>
        <i class="dx-icon-trash alert-link" style="cursor: pointer;" (click)="expireNote(note.id)"></i>
      </div>
    </div>
    <div>{{note.comment}}</div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <dx-text-area [height]="60" [(value)]="comment"></dx-text-area>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-9">
      <div class="dx-field">
        <div class="dx-field-label" style="width:25%">Note for:</div>
        <div class="dx-field-value" style="width:75%">
            <dx-radio-group
                [items]="noteTypes"
                valueExpr="value"
                displayExpr="text"
                [(value)]="selectedNoteType"
                >
            </dx-radio-group>
        </div>
      </div>
    </div>
    
    <div class="col-3 text-right">
      <dx-button text="Add Note" (onClick)="addNote()" [disabled]="!selectedNoteType"></dx-button>
    </div>
  </div>

  
</app-medigi-popup>


<app-medigi-popup
  *ngIf="notesEditorPopupVisible"
  class="popup"
  [fullScreen]="true"
  [showTitle]="true"
  title="Edit note"
  [dragEnabled]="true"
  [(visible)]="notesEditorPopupVisible"
  [resizeEnabled]="true"
  [width]="500"
  [minHeight]="200"
>
<dx-text-area [height]="40" [(value)]="currentNote.comment" class="mb-2"></dx-text-area>
<div class="text-right">
  <dx-button text="Update Note" (onClick)="updateNote()"></dx-button>
</div>
</app-medigi-popup>