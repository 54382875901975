<ng-content></ng-content>


<dx-tooltip *ngIf="_compactMode" [target]="target" position="right" [(visible)]="isVisible">  
    <div *dxTemplate="let data = data of 'content'">  
      <span>{{tooltipContent}}</span>  
    </div>
  </dx-tooltip>

<div *ngIf="!_compactMode" class="logo"><img [src]="largeLogo" [alt]="logoAltText" width="112"/></div>
<div *ngIf="_compactMode" class="compact-logo"><img [src]="smallLogo" [alt]="logoAltText" width="24"></div>

<div class="menu-container">
  <dx-tree-view
      id="top-menu"
      expandEvent="click"
      width="100%"
      [items]="items"
      (onInitialized)="onMenuInitialized($event)"
      (onItemClick)="onItemClick($event)"
      (onContentReady)="onContentReady($event)"
      (onSelectionChanged)="updateSelection($event)"
      [selectByClick]="true"
      selectionMode="single"
      keyExpr="path"
      [noDataText]="' '">

      <div *dxTemplate="let item of 'profileTemplate'" [ngClass]="isMedigiAdmin ? 'medigi-admin-bottom-button-one' : 'bottom-button-one' " (click)="openUserProfile()">
        <dxi-item class="d-flex flex-row">
          <i class="dx-icon-user-menu" style="margin-right: 18px;"></i><p class="mb-0">Profile</p>
        </dxi-item>
      </div>
      <div *dxTemplate="let item of 'collapseTemplate'" [ngClass]="isMedigiAdmin ? 'medigi-admin-bottom-button-two' : 'bottom-button-two' " (click)="toggleMenu()">
        <dxi-item class="d-flex flex-row">
          <i class="dx-icon-collapse {{_compactMode ? 'rotate-icon' : ''}}" style="margin-right: 18px;"></i><p class="mb-0">Collapse</p>
        </dxi-item>
      </div>
  </dx-tree-view>
</div>

<dx-popup 
[showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="true" [width]="850" [height]="485"
title="{{user?.first_name}} {{user?.last_name}}" [showCloseButton]="true" [(visible)]="isProfilePopupOpen" (hiding)="closingPopup()">
  <app-user-profile></app-user-profile>
</dx-popup>
