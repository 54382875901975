import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { environment } from '../../../../environments/environment';
import { DirectModule } from 'src/app/shared/components/direct/direct.module';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-medigi-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})

export class MedigiFooterComponent {
    @Input() loggedIn: boolean = false;
    @Input() directBlackBox: any;
  
    mobileUser;
    copyrightYear;
    hydraEnabled;

    isBuyer = false;

    constructor(
      private userService: UserService,
      private screenSize: ScreenService
    ){}
  
    ngOnInit() {
      this.hydraEnabled = environment.hydraEnabled;
      this.mobileUser = this.screenSize.sizes['screen-small'] === true || this.screenSize.sizes['screen-x-small'] === true;
      this.copyrightYear = new Date().getFullYear();
      this.userService.getMe().subscribe(user => this.isBuyer = user.data.is_buyer);
    }

    goToSocials(link) {
      window.open(link, "_blank");
    }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DirectModule
  ],
  declarations: [ MedigiFooterComponent ],
  exports: [ MedigiFooterComponent ]
})
export class MedigiFooterModule { }
