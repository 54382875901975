<form action="your-action" (submit)="onSubmit($event)">
  <dx-form id="form" method="post" [formData]="newPassword" [readOnly]="false" [showColonAfterLabel]="true"
    [showValidationSummary]="true">

    <dxi-item dataField="password" [editorOptions]="{ mode: 'password' }" helpText="(Min. 8, with upper, lower, number, and special)">
      <dxo-label text="Password"></dxo-label>
      <dxi-validation-rule message="Password is required" type="required">
      </dxi-validation-rule>
      <dxi-validation-rule [min]="8" message="Must be minimum 8 characters" type="stringLength">
      </dxi-validation-rule>
      <dxi-validation-rule [max]="64" message="Must be max 64 characters" type="stringLength">
      </dxi-validation-rule>
      <dxi-validation-rule [pattern]="passwordRuleLower"
                           message="Must contain at least one lower case letter" type="pattern">
      </dxi-validation-rule>
      <dxi-validation-rule [pattern]="passwordRuleUpper"
                           message="Must contain at least one upper case letter" type="pattern">
      </dxi-validation-rule>
      <dxi-validation-rule [pattern]="passwordRuleNumber" message="Must contain at least one numeric digit"
                           type="pattern">
      </dxi-validation-rule>
      <dxi-validation-rule [pattern]="passwordRuleSpSymbol"
                           message="Must contain at least one special character" type="pattern">
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item dataField="match_password" editorType="dxTextBox" [editorOptions]="{ mode: 'password' }">
      <dxo-label text="Confirm Password">
      </dxo-label>
      <dxi-validation-rule type="required" message="Confirm Password is required">
      </dxi-validation-rule>
      <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
        message="Password and Confirm Password do not match">
      </dxi-validation-rule>
    </dxi-item>
    
    <dxi-item itemType="button" [buttonOptions]="buttonOptions" horizontalAlignment="right">
    </dxi-item>
  </dx-form>
</form>