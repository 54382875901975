import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  constructor(private http: HttpClient) { }

  public save(data): Observable<any> {
    const priceId = data['id'];
    return this.http.put<any>(`${environment.api_url_v1}/services/listing/pricing/price/${priceId}`, data);
  }

  public create(data): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/listing/pricing/price`, data);
  }

  public getPriceHistory(listingId): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/pricing/price_history/${listingId}`);
  }

  public getAmu(ndc): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/pricing/amu/${ndc}`);
  }

  public getAmuByGcn(gcn, pack_size): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/pricing/amu/${gcn}/${pack_size}`);
  }

  public setPriceFlag(listing): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/listing/pricing/price_signal`, listing);
  }

  public resetPriceFlag(psId): Observable<any> {
    return this.http.delete<any>(
      `${environment.api_url_v1}/services/listing/pricing/price_signal/${psId}`
    );
  }

  public setOverrideAmu(listing, overrideAmu) {
    return this.http.post<any>(
      `${environment.api_url_v1}/services/listing/pricing/amu/${listing.ndc_number}`, {override_amu: overrideAmu,
          listing_id: listing.id, package_size: listing.package_size, eaches_per_pack: listing.eaches_per_pack,
          gcn_seqno: listing.gcn_seqno}
    );
  }

  public resetOverrideAmu(listing) {
    return this.http.delete<any>(`${environment.api_url_v1}/services/listing/pricing/amu/${listing.ndc_number}`);
  }

  public createPriceRule(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/listing/pricing/rules`, data);
  }

  public getPriceRules(listingId) {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/pricing/rules/${listingId}`);
  }

  public updatePriceRule(ruleId, data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/listing/pricing/rules/${ruleId}`, data);
  }

  public deletePriceRule(id) {
    return this.http.delete<any>(`${environment.api_url_v1}/services/listing/pricing/rules/${id}`);
  }
}
