import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DxButtonModule } from 'devextreme-angular';
import { Auth0Service } from 'src/app/auth/auth0.service';

@Component({
  selector: 'app-outsourcing-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class OutsourcingNavComponent implements OnInit {

  constructor(public auth0: Auth0Service) { }

  ngOnInit() {
  }

}
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    DxButtonModule
  ],
  declarations: [ OutsourcingNavComponent ],
  exports: [ OutsourcingNavComponent ]
})
export class  OutsourcingNavModule { }
