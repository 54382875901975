import { Component, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-outsourcing-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})

export class OutsourcingFooterComponent {
  copyrightYear;

  ngOnInit() {
    this.copyrightYear = new Date().getFullYear();
  }
}


@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [ OutsourcingFooterComponent ],
  exports: [ OutsourcingFooterComponent ]
})
export class OutsourcingFooterModule { }