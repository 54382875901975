import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Auth0Service} from 'src/app/auth/auth0.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authenticationService: Auth0Service) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401].indexOf(err.status) !== -1) {
        this.authenticationService.logout();
        this.router.navigate(['/']);
      }

      if (err.error?.data && this.hasValues(err.error.data)) {
        return throwError(err.error);
      }

      const error = err.error.error || err.error.message || err.statusText;
      return throwError(error);
    }));
  }

  hasValues(obj) {
    return Object.values(obj).some(v => v !== null && typeof v !== 'undefined');
  }
}
