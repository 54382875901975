import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DxAccordionModule, DxButtonModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { NotificationService } from '../../services/notification.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../services/user/user.service';
import { OverlayModule } from '../overlay/overlay.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() displayOnDashboard = false;
  @Input() isBuyer = false;
  @Input() isSeller = false;

  listOfUnreadNotifications;
  listOfReadNotifications;
  newNotifications = 0;
  oldNotifications = 0;
  numberWithinNotificationsIcon;
  isNotificationsOpen = false;
  isDataLoading = false;
  isAfk = false;

  constructor(private userService: UserService,
              private notificationService: NotificationService,
              private sessionService: SessionService,
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.watchSession();
    this.getListOfNotifications();
    setInterval(() => { this.getListOfNotifications(); }, 600000);
  }

  watchSession() {
    this.sessionService.switchFacilityTrigger.subscribe(() => this.getListOfNotifications());
    this.sessionService.userAfk.subscribe((afk) => this.isAfk = afk);
  }

  async getListOfNotifications() {
    if (this.isAfk) { return; }
    this.isDataLoading = true;
    try {
      const result = await this.userService.getNotifications();
      this.listOfUnreadNotifications = result.data.unread;
      this.listOfReadNotifications = result.data.read;
      this.populateFrontendVariables();
      this.isDataLoading = false;
    }
    catch (err){
      this.notificationService.showNotify('Error trying to retrieve your notifications', 'error', 5000);
    }
  }

  populateFrontendVariables(){
    this.newNotifications = this.listOfUnreadNotifications.length;
    this.oldNotifications = this.listOfReadNotifications.length;
    this.newNotifications > 9 ? this.numberWithinNotificationsIcon = '9+' : this.numberWithinNotificationsIcon = this.newNotifications;
  }

  openOrCloseNotifications() {
    this.isNotificationsOpen ? this.isNotificationsOpen = false : this.isNotificationsOpen = true;
  }

 async markNotificationAsRead(notificationId){
    await this.userService.setNotificationRead(notificationId);
    this.googleAnalyticsService.eventEmitter(notificationId, 'Notification Read');
    this.getListOfNotifications();
  }

 navigateToNotificationsURL(notification){
    this.isNotificationsOpen = false;
    this.markNotificationAsRead(notification.id);
    this.googleAnalyticsService.eventEmitter(notification.id, 'Notfication URL Used');
    this.router.navigate([notification.relative_url]);
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxPopupModule,
    DxScrollViewModule,
    DxAccordionModule,
    OverlayModule
  ],
  declarations: [ NotificationsComponent ],
  exports: [ NotificationsComponent ]
})

export class NotificationsModule { }
