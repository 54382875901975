import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import {Listing} from '../../models/listing';
import * as _ from 'underscore';
import {PricingService} from '../../services/pricing.service';
import {CartService} from 'src/app/shared/services/cart/cart.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxNumberBoxModule, DxSliderModule, DxButtonModule, DxPopoverModule, DxPopupModule, DxSelectBoxModule, DxCheckBoxModule, DxLoadPanelModule } from 'devextreme-angular';
import { MsTooltipModule } from 'src/app/shared/components/ms-tooltip/ms-tooltip.component';
import { NotificationService } from '../../services/notification.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';


@Component({
  selector: 'app-price-flag',
  templateUrl: './price-flag.component.html',
  styleUrls: ['./price-flag.component.scss']
})
export class PriceFlagComponent implements OnInit, OnChanges, OnDestroy {

  @Input() listing: Listing;
  @Input() visible: boolean;
  @Input() size = 'full';
  @Input() order_facility_id: number;
  @Output() onChange = new EventEmitter<any>(); 
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  cart: any;

  currentPriceVisible = false;
  fairPriceVisible = false;
  priceSuggestEqualBestPrice = false;

  baseUpdated = false;
  suggestUpdated = false;
  overrideAmuUpdated = false;

  origBase: number;
  origSuggest: number;
  origAmu: number;

  lot = [];
  lotExpiry: any;
  selectedLotExpiry: any;
  showSpinner = false;

  basePriceSliderOpt = {
    floor: 0,
    ceil: 100,
    step: 1,
    value: 0
  };

  overrideAmuSliderOpt = {
    floor: 0,
    ceil: 1000,
    step: 1,
    value: 0
  };

  isVetted = true;

  uploadVisible = false;

  basePriceChanged = _.throttle(this.basePriceChangedOrig, 50);
  overrideAmuChanged = _.throttle(this.overrideAmuChangedOrig, 50);

  flagDataSubmited = false;
  amuChanged = false;
  priceChanged = false;

  basePriceTooltip = 'Set your baseline price you typically pay for this product. Blue flags indicate that the pricing comparison is customized.  Grey flags are comparing the list price to the WAC.'

  constructor(private cartService: CartService,
              private pricingService: PricingService,
              private notificationService: NotificationService,
              private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnChanges(changes: SimpleChanges) {
    const listingChange = changes.listing;
    const visibleChange = changes.visible;
    if (listingChange) {
      this.listing = listingChange.currentValue;
      this.setOriginalValues();
      this.setSliderOptions();
      this.recalculate();
    }
    if (visibleChange) {
      this.visible = visibleChange.currentValue;
    }
  }

  ngOnInit() {
    this.setOriginalValues();
    this.setSliderOptions();
    this.recalculate();
    this.googleAnalyticsService.eventEmitter(this.listing.name, 'Price Flag Modal Opened');
  }

  ngOnDestroy() {
    if (!this.flagDataSubmited) {
      this.cancelFlagDataUpdate();
    }
  }

  updatePriceCeil(e) {
    this.priceChanged = this.basePriceSliderOpt.value && this.basePriceSliderOpt.value != this.origBase;
    this.basePriceSliderOpt.ceil = Math.max(this.basePriceSliderOpt.ceil, e.value);
    this.recalculate();
  }

  updateAmuCeil(e) {
    this.amuChanged =  this.overrideAmuSliderOpt.value && this.overrideAmuSliderOpt.value != this.origAmu;
    this.overrideAmuSliderOpt.ceil = Math.max(this.overrideAmuSliderOpt.ceil, e.value);
    this.recalculate();
  }

  setOriginalValues() {
    this.origBase = this.listing.current_price_signal?.customer_base_price || null;
    this.origAmu = this.listing.pricing.amu?.override_amu || this.listing.pricing.amu?.amu;
  }

  async sendPricingSignal() {
    if (this.baseUpdated) {
      const data = {
        listing_id: this.listing.id,
        customer_base_price: this.basePriceSliderOpt.value,
        gcn_seqno: this.listing.gcn_seqno,
        package_size: this.listing.package_size,
        eaches_per_pack: this.listing.eaches_per_pack
      };

      try {
        const resp = await this.pricingService.setPriceFlag(data).toPromise();
        this.cartService.refresh();
        this.listing.current_price_signal = resp.data;
      } catch(error) {
        this.notificationService.showNotify('Failed to update Price Flag', 'error');
      }
      
    } else if (this.listing.current_price_signal) {
      this.listing.current_price_signal.customer_base_price = this.origBase;
    }
  }

  clearPricingSignal() {
    this.origBase = null;
    this.origAmu = null;
    this.listing.lowest_lot_discount = (1 - (this.listing.pricing.price / this.listing.pricing.price_meta.wac_price)) * 100;

    this.listing.savings_per_pack = 0;

    if (this.listing.amu > 0) {
      this.listing.monthly_exact_savings = 0;
    }

    this.baseUpdated = false;
    
    if(this.listing.current_price_signal) {
      this.pricingService.resetPriceFlag(this.listing.current_price_signal.id).subscribe(() => this.cartService.refresh())
    }

  }

  async setOverrideAmu() {
    if(this.overrideAmuUpdated) {
      try {
        await this.pricingService.setOverrideAmu(this.listing, this.overrideAmuSliderOpt.value).toPromise();
      } catch(error) {
        this.notificationService.showNotify('Failed to update AMU', 'error');
      }
    }
    this.showSpinner = false;
  }

  resetOverrideAmu() {
    this.pricingService.resetOverrideAmu(this.listing).subscribe(resp => { });
  }

  setSliderOptions() {
    this.basePriceSliderOpt = {
      floor: 0.10,
      ceil: 10000,
      step: (this.listing.pricing.price * 2.5) / 200,
      value: this.listing.current_price_signal?.customer_base_price || this.listing.pricing.amu?.aac_per_pack || this.listing.pricing.price
    };

    this.overrideAmuSliderOpt = {
      floor: 0,
      ceil: 10000,
      step: 1,
      value: this.listing.pricing.amu?.override_amu || this.listing.pricing.amu?.amu || 0
    };
  }

  toggleCurrentPrice() {
    this.currentPriceVisible = !this.currentPriceVisible;
  }

  refreshSliders() {
    this.basePriceSliderOpt.value = this.listing.current_price_signal?.customer_base_price || this.listing.pricing.price_meta.wac_price;
  }

  basePriceChangedOrig(e) {
    if (this.visible) {
      this.baseUpdated = true;
      this.basePriceSliderOpt.value = e.value;

      if(this.listing.current_price_signal) {
        this.listing.current_price_signal.customer_base_price = e.value;
      }
    }
  }

  overrideAmuChangedOrig(e) {
    if (this.visible) {
      this.overrideAmuUpdated = true;
      this.overrideAmuSliderOpt.value = e.value;
    }
  }

  async submitOverrideData() {
    this.showSpinner = true;
    if (this.amuChanged) {
      await this.setOverrideAmu();
      this.amuChanged = false;
    }
    if (this.priceChanged) {
      await this.submitFlagData();
      this.priceChanged = false;
    }
    this.showSpinner = false;
    this.flagDataSubmited = true;
    this.onChange.emit();
    this.visibleChange.emit(false);
  }

  async submitFlagData() {
    await this.sendPricingSignal();
    this.googleAnalyticsService.eventEmitter(this.listing.name, 'Price Flag Submited');
  }

  cancelFlagDataUpdate() {
    this.baseUpdated = false;
    this.overrideAmuUpdated = false;
    if(this.listing.current_price_signal) {
      this.listing.current_price_signal.customer_base_price = this.origBase;
    }
    if (this.amuChanged || this.priceChanged) {
      this.onChange.emit();
    }
    
    this.visibleChange.emit(false);
  }

  recalculate() {
    this.listing.lowest_lot_discount = 100 - ((this.listing.pricing.lowest_price / this.basePriceSliderOpt.value) * 100);
    this.listing.lowest_lot_discount = this.listing.lowest_lot_discount > 0 ? this.listing.lowest_lot_discount : 0;
    const priceDifference = this.basePriceSliderOpt.value - this.listing.pricing.lowest_price;
    this.listing.monthly_exact_savings = this.overrideAmuSliderOpt.value ? (this.overrideAmuSliderOpt.value * priceDifference) : 0;
    this.listing.monthly_exact_savings = Math.max(this.listing.monthly_exact_savings, 0);
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxSliderModule,
    DxNumberBoxModule,
    DxPopoverModule,
    DxPopupModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    MsTooltipModule,
    DxLoadPanelModule,
  ],
  declarations: [PriceFlagComponent],
  exports: [PriceFlagComponent]
})
export class PriceFlagModule { }
