import { Component, OnInit, Input, NgModule } from '@angular/core';
import * as nav_const from '../../app-navigation';
import { Router, NavigationEnd } from '@angular/router';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { BUYER_SOFT_LAUNCH_PARTICIPANTS, SELLER_SOFT_LAUNCH_PARTICIPANTS, StorageType } from 'src/app/shared/models/constants';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { SideNavigationMenuModule } from '../side-navigation-menu/side-navigation-menu.component';
import { DxDrawerModule, DxScrollViewModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { MedigiClickwrapModule } from 'src/app/shared/components/medigi-clickwrap/medigi-clickwrap.module';
import { HeaderModule } from '../header/header.component';
import { UserService } from 'src/app/shared/services/user/user.service';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import _ from 'lodash';

@Component({
  selector: 'app-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss']
})
export class SideNavOuterToolbarComponent implements OnInit {
  menuItems = [];
  selectedRoute = '';

  menuOpened: boolean;
  cartOpened: boolean;
  showClickwrap: boolean;
  temporaryMenuOpened = false;

  @Input()
  title: string;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;

  user: any;
  client: any;
  userEmail = '';
  medigiAdmin = false;
  hydraEnabled = false;
  rfpEnabled = true;
  bidEnabled = true;

  constructor(private screen: ScreenService,
              public auth0: Auth0Service,
              private router: Router,
              private userService: UserService,
              private storageService: StorageService,
              private googleAnalyticsService: GoogleAnalyticsService) { }


  setBuyerItems() {
    if (location.host === environment.clientDomains.medshortsDomain) {
        this.menuItems = this.menuItems.concat(nav_const.medigi);
    }

    this.menuItems = this.menuItems.concat(nav_const.buyer);
    this.menuItems = this.menuItems.concat(nav_const.bids);

    if(window.location.host !== environment.clientDomains.medshortsDomain) {
        this.menuItems = this.menuItems.concat(nav_const.rfps);
    }
    this.menuItems = this.menuItems.concat(nav_const.buyer_requests);

    // does Seller also need CSOS Signer?
    if (this.user.roles.includes('CSOS Signer')){
      this.menuItems = this.menuItems.concat(nav_const.csos);
    }

    if ((this.user.roles.includes('CompanyAdmin')) || (this.user.roles.includes('FacilityAdmin'))) {
      this.menuItems = this.menuItems.concat(nav_const.buyer_editing);
    }
  }

  setSellerItems() {
    this.menuItems = this.menuItems.concat(nav_const.seller_home);

    if (this.user.company.is_503b) {
      this.menuItems = this.menuItems.concat(nav_const.seller_listings_503b);
    } else {
      this.menuItems = this.menuItems.concat(nav_const.seller_listings);
    } 

    this.menuItems = this.menuItems.concat(nav_const.seller_default);

    if (!this.user.is_field_rep) {
      this.menuItems = this.menuItems.concat(nav_const.reports);
    }

    if (this.user.roles.includes('CompanyAdmin')) {
      this.menuItems = this.menuItems.concat(nav_const.seller_admin);
    }
  }

  setMedigiItems() {
    if (this.user.roles.includes('Buyer')) {
      this.setBuyerItems();
    }

    if (this.user.roles.includes('Seller')) {
      this.setSellerItems();
    }

    if (this.user.roles.includes('Administrator')) {
      this.menuItems = this.menuItems.concat(nav_const.admin);
    }

    if (this.user.roles.includes('Buyer')) {
      this.menuItems = this.menuItems.concat(nav_const.reports);
    }

    this.menuItems = this.menuItems.concat(nav_const.lowerMenu);
  }

  setHydraBuyerItems() {
    this.menuItems = this.menuItems.concat(nav_const.hydra_buyer);

    if (this.bidEnabled) {
      this.menuItems = this.menuItems.concat(nav_const.bids);
    }

    if (this.rfpEnabled) {
      this.menuItems = this.menuItems.concat(nav_const.rfps);
    }

    if (this.user.roles.includes('CSOS Signer')){
      this.menuItems = this.menuItems.concat(nav_const.csos);
    }

    if ((this.user.roles.includes('CompanyAdmin')) || (this.user.roles.includes('FacilityAdmin'))) {
      this.menuItems = this.menuItems.concat(nav_const.buyer_editing);
    }

    this.menuItems = this.menuItems.concat(nav_const.reports_only);
  }

  

  setHydraSellerItems() {
    this.menuItems = this.menuItems.concat(nav_const.seller_hydra_listings);
    this.menuItems = this.menuItems.concat(nav_const.seller_hydra_default);
    
    if (this.bidEnabled) {
      this.menuItems = this.menuItems.concat(nav_const.bids);
    }

    if (this.rfpEnabled) {
      this.menuItems = this.menuItems.concat(nav_const.rfps);
    }

    if (this.user.roles.includes('CompanyAdmin')) {
      this.menuItems = this.menuItems.concat(nav_const.hydra_seller_admin);
    }

    if (this.user.is_admin) {
      this.menuItems = this.menuItems.concat(nav_const.hydra_admin);
    }
    this.menuItems = this.menuItems.concat(nav_const.reports_only);
  }

  async setHydraItems() {
    this.client = await this.userService._getMsClient();

    this.rfpEnabled = this.client?.hydra_config?.rfp_enabled;
    this.bidEnabled = this.client?.hydra_config?.bid_enabled;

    if (this.user.roles.includes('Buyer')) {
      this.setHydraBuyerItems();
    }

    if (this.user.roles.includes('Seller')) {
      this.setHydraSellerItems();
    }

    if (this.user.roles.includes('Administrator')) {
      this.menuItems = this.menuItems.concat(nav_const.admin);
    }

    this.menuItems = this.menuItems.concat(nav_const.lowerMenu);
  }

  setMenuItems() {
    this.menuItems = [];

    this.userService.getMe().subscribe(user => {
      this.user = user.data;
      this.userEmail = user.data.email;
      
      if (this.userEmail.includes('admin@medigi')) {
        this.medigiAdmin = true;
      }

      if (!environment.hydraEnabled) {
        this.setMedigiItems();
      } else {
        this.bidEnabled = false;
        this.rfpEnabled = false;
        this.setHydraItems();
      }
    });
    
  }

  ngOnInit() {
    this.setMenuItems();
    this.storageService.watch().subscribe((data: string) => {
      if (data === StorageType.session) {
        this.setMenuItems();
      }
    });

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-small'];
    const isLarge = this.screen.sizes['screen-large'];
    this.minMenuSize = isXSmall ? 0 : 60;
    this.menuOpened = isLarge;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path) {
      if(path === '/medigi') {
        if (!this.user.company.agreed_to_medigi){
          this.showClickwrap = true;
        }
        else {
          window.location.href = `${location.protocol}//${environment.clientDomains.medigiDomain}/catalog`;
        }
        return;
      }

      this.router.navigate([path]);

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
    
    if (event.node.children.length !== 0) {
      this.menuOpened = true;
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [ SideNavigationMenuModule, DxDrawerModule, HeaderModule, DxScrollViewModule, CommonModule, MedigiClickwrapModule ],
  exports: [ SideNavOuterToolbarComponent ],
  declarations: [ SideNavOuterToolbarComponent ]
})
export class SideNavOuterToolbarModule { }
