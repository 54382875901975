<ul class="text-justify mr-4">
  <li class="mb-2">
    “By clicking “Submit”, you agree that you are authorized to make such offer on behalf
    of {{session?.primary_facility.name}}
    and {{session?.primary_facility.name}}  agrees to purchase the product from the manufacturer at the price and
    quantity specified in the offer details. If the manufacturer accepts the offer, the offer will be
    automatically processed as an order at the price and quantity detailed on the screen. If the
    manufacturer counters the offer with a different price and/or quantity, {{session?.primary_facility.name}} will have the
    ability to accept or decline the offer prior to the order being processed. The manufacturer’s shipment of
    the ordered products indicates the manufacturer’s final acceptance of an order. In the event any
    manufacturer is unable to fulfill the order or any portion of an order for any reason, the manufacturer
    may cancel the order in whole or in part, and {{session?.primary_facility.name}} will not be charged for the cancelled
    portion of any order. An accepted order by either party is a legally binding agreement between
    {{session?.primary_facility.name}}  and the manufacturer identified on such order. Unless the product is damaged in
    transit or if the incorrect product or quantity was mistakenly shipped, no product that is ordered will be
    subject to refunds or return.<br>
  </li>
  <li class="mb-2">Medigi is a technology company that provides services on behalf of pharmaceutical
    manufacturers,
    including an e-commerce platform for facilitating the sale of products and certain billing, payment, and
    collection services in connection with such sales. Medigi does not at any time possess the products
    and is not in any way responsible for the products ordered through its platform or any liability arising
    therefrom. Your use of the platform is subject to the terms and conditions set forth in the participation
    agreement with Medigi, and Medigi will invoice {{session?.primary_facility.name}}, and {{session?.primary_facility.name}}
    shall pay such invoices, in accordance with the terms of such agreement.”
  </li>
  <!-- userAck according to Company -->
  <li *ngIf="listing?.company?.meta?.userAck" [innerHTML]="listing.company.meta.userAck"></li>
  <!-- userAck according to Listing -->
  <li *ngIf="listing?.meta?.userAck" [innerHTML]="listing.meta.userAck"></li>
</ul>
