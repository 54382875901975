import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public switchFacilityTrigger = new Subject<boolean>();
  public primaryFacilityUpdatedTrigger = new Subject<boolean>();
  public orderPlacedTrigger = new Subject<boolean>();
  public updatePharmaDataTrigger = new BehaviorSubject<boolean>(false);
  public inactiveTime = new BehaviorSubject<number>(0);
  public userAfk = new BehaviorSubject<boolean>(false);

  constructor() { }

}
