import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-jwt',
  templateUrl: './jwt.component.html'
})
export class JwtComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      localStorage.setItem('auth0Token', params.jwt);
      this.router.navigate(['/catalog']);
    });
  }
}
