import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Landing, medigiLanding, medshortsLanding } from '../models/landing';
import { environment } from 'src/environments/environment';
import themes from 'devextreme/ui/themes';
import { UserService } from 'src/app/shared/services/user/user.service';
import {StorageService} from "./storage.service";
@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  private landingDataSubject = new BehaviorSubject<Landing>(null);
  configs: Landing;
  windowURL = window.location.host;

  constructor(private userService: UserService, private storageService: StorageService) {
    this.setDomainConfigs();
  }

  public get(): BehaviorSubject<Landing> {
    return this.landingDataSubject;
  }

  public get theme(): string {
    return this.configs.theme;
  }

  public setDomainConfigs() {
    // Grab the env file based settings
    if (location.host.includes('medshorts.com')) {
        this.configs = medshortsLanding;
    }  else {
        this.configs = environment.availableThemes[this.windowURL] || medigiLanding;
    }
    themes.current(this.configs.theme);

    // Add any Client info
    // Client data is set after auth0 authenticates on startup...
    const client = this.userService.getClientFromStorage()
    if (client) {
      // Should mean the domain matched the client record
      if (this.windowURL.startsWith(client['hostkey'])) {
        // Lock down to this seller until the domain changes
        this.storageService.set('catalogSellerId', client['company_id']);
        this.storageService.set('catalogSellerLocked', 'true');
      }
    } else {
      this.configs.client = false;
      // Clean up local storage if the client changes
      this.storageService.remove('catalogSellerId');
      this.storageService.set('catalogSellerLocked', 'false');
    }

    this.configs.client = client;
    return this.landingDataSubject.next(this.configs);
  }


}
