import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { PipeModule } from 'src/app/pipe.module';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { CatalogService } from '../../services/listing/catalog.service';
import { OrdersService } from '../../services/order/orders.service';
import { SessionService } from '../../services/session.service';
import { OverlayModule } from '../overlay/overlay.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-facility-and-user-stats',
  templateUrl: './facility-and-user-stats.component.html',
  styleUrls: ['./facility-and-user-stats.component.scss']
})
export class FacilityAndUserStatsComponent implements OnInit {

  constructor(private catalogService: CatalogService,
              private sessionService: SessionService,
              private orderService: OrdersService,
              private googleAnalyticsService: GoogleAnalyticsService) { }

  @Input() isHeaderButton: boolean = true;

  facilityStats: any;
  rewardStatsData: any;
  companyStatsMonths: any;
  companyStatsBadge: any;
  userRewardOrderCount: any;
  progressWidth: any;
  progressWidthLeft: any;
  isStatsPanelOpen: boolean = false;
  hydraEnabled = environment.hydraEnabled;

  ngOnInit() {
    this.watchPrimaryFacilityUpdatedTrigger();
    if (!environment.hydraEnabled) {
      this.watchOrderPlacedTrigger();
      this.getFacilityStatData();
      this.getUserAndFacilityRewardsData();
    }
  }

  watchPrimaryFacilityUpdatedTrigger() {
    this.sessionService.primaryFacilityUpdatedTrigger.subscribe(() => this.getFacilityStatData());
  }

  watchOrderPlacedTrigger() {
    this.sessionService.orderPlacedTrigger.subscribe(() => this.getFacilityStatData());
  }

  async getFacilityStatData(){
    await this.catalogService.getFacilityStats().toPromise().then(
      result => {
        let {data} = result;
        let output = {}
        output['totalSpend'] = Math.trunc((data['503b'] ? 0.0 + parseFloat(data['503b']['total_spend']) : 0) + (data['medigi'] ? parseFloat(data['medigi']['total_spend']) : 0) + (data['medshorts'] ? parseFloat(data['medshorts']['total_spend']) : 0))
        output['savings'] = Math.trunc((data['503b'] ? parseFloat(data['503b']['savings']) : 0) + (data['medigi'] ? parseFloat(data['medigi']['savings']) : 0) + (data['medshorts'] ? parseFloat(data['medshorts']['savings']) : 0))
        output['savingsPct'] = output['savings'] / (output['totalSpend'] + output['savings'])
        output['catalogMatches'] = (data['503b'] ? parseInt(data['503b']['catalog_matches']) : 0) + (data['medigi'] ? parseInt(data['medigi']['catalog_matches']) : 0) + (data['medshorts'] ? parseInt(data['medshorts']['catalog_matches']) : 0)
        output['catalogCount'] = (data['503b'] ? parseInt(data['503b']['catalog_count']) : 0) + (data['medigi'] ? parseInt(data['medigi']['catalog_count']) : 0) + (data['medshorts'] ? parseInt(data['medshorts']['catalog_count']) : 0)
        output['detailed'] = data;
        this.facilityStats = output;
      });
    }

    getUserAndFacilityRewardsData() {
      this.orderService.user_reward_and_system_reward_stats().toPromise().then(result => {
        this.rewardStatsData = result.data;
        this.createRewardStatsVars();
        this.getProgressBarWidth();
      });
    }

    createRewardStatsVars() {
      this.companyStatsMonths = this.rewardStatsData?.company_stats.months;
      this.companyStatsBadge = this.rewardStatsData?.company_stats.badge;
      this.userRewardOrderCount = Math.min(this.rewardStatsData?.user_reward_order_count, 15);
    }

    getProgressBarWidth() {
      this.progressWidth =  this.userRewardOrderCount * 6.66; // 100 divided by 15
      this.progressWidth > 96 ? this.progressWidth = 96 : this.progressWidth; // 96 is the specific width of the progress bar
    }

    openMerchStoreLink() {
      this.googleAnalyticsService.eventEmitter('Click Event','MedigiSwag Outbound Click');
      window.open('https://medigiswag.com/', "_blank");
    }

    openStatsPanel(){
      this.isStatsPanelOpen = !this.isStatsPanelOpen;
    }
  }

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxPopupModule,
    PipeModule,
    OverlayModule
  ],
  declarations: [ FacilityAndUserStatsComponent ],
  exports: [ FacilityAndUserStatsComponent ]
})

export class FacilityAndUserStatsModule { }

