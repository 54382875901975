<span id="{{id}}" [class]="elementClass" (mouseenter) ="onMouseEnter()"  (mouseleave) ="onMouseLeave()">
  <span *ngIf="!target">
    <span *ngIf="badgeText">{{badgeText}}</span>
    <i *ngIf="!badgeText" class="dx-icon-info"></i>
  </span>
  <dx-popover
    target="#{{target ? target : id}}"
    showEvent="dxhoverstart"
    [hideEvent]="hideEvent"
    position="{{position}}"
    title="{{tooltipTitle}}"
    width="{{width}}"
    [showTitle]="tooltipTitle"
    [(visible)]="defaultVisible"
    [hideOnOutsideClick]="false">
    <dxo-animation *ngIf="animation">
      <dxo-show
          type="pop"
          [from]="{ scale: 0.1, opacity: 0 }"
          [to]="{ opacity: 1, scale: 1 }">
      </dxo-show>
      <dxo-hide
          type="pop"
          [delay]="1500"
          [from]="{ scale: 1, opacity: 1 }"
          [to]="{ opacity: 0, scale: 0.1 }">
      </dxo-hide>
    </dxo-animation>

    <div *dxTemplate="let data = data of 'content'" [innerHTML]="tooltipText | bypassSecurity" routeTransformer>
    </div>
  </dx-popover>
</span>
