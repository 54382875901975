import { CommonModule } from '@angular/common';
import {Component, Input, NgModule, OnInit} from '@angular/core';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-user-ack',
  templateUrl: './user-ack.component.html',
  styleUrls: ['./user-ack.component.scss']
})
export class UserAckComponent implements OnInit {
  @Input() listing: any;
  session: any;
  currentListing: any;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.getMe().subscribe(res => {
      this.session = res.data;
    });
  }

}

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [ UserAckComponent ],
  declarations: [ UserAckComponent ]
})
export class UserAckModule { }
