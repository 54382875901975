<div *ngIf="false">
    <div *ngIf="listing" class="row">
        <!-- Details -->
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="card mr-2" style="height: 100%; width: 100%;">
                <div class="card-header mb-2 px-2 dx-font-s">
                    <span class="dx-font-s text-ms-default">Basics</span>
                </div>
                <div class="card-body pt-0 px-2">
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Company</div>
                        <div class="dx-field-value-static pt-1">
                            <app-seller-company-logo [listing]="listing" mode="url"></app-seller-company-logo>
                        </div>
                    </div>
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Pharm. Classification</div>
                        <div class="dx-field-value-static pt-1">{{listing.pharm_class | titlecase}}</div>
                    </div>
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Listing ID</div>
                        <div class="dx-field-value-static pt-1">{{listing.id}}</div>
                    </div>
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">NDC</div>
                        <div class="dx-field-value-static pt-1">{{listing.ndc | ndc}}</div>
                    </div>
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Active Ingredient</div>
                        <div class="dx-field-value-static pt-1">{{listing.substance_name}}</div>
                    </div>
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Route</div>
                        <div class="dx-field-value-static pt-1">{{listing.route | titlecase}}</div>
                    </div>
                    <div *ngIf="listing?.lots?.length" class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Brand Equivalent:</div>
                        <div class="dx-field-value-static pt-1">
                            {{listing.proprietary_name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Packaging -->
        <div class="col-sm-12 col-md-6 col-lg-6 ">
            <div class="card" style="height: 100%; width: 100%;">
                <div class="card-header mb-2 px-2 dx-font-s">Packaging</div>
                <div class="card-body pt-0 px-2">
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Minimum Order</div>
                        <div class="dx-field-value-static pt-1">{{ listing.min_packs }} pack ({{ listing.min_packs * listing.eaches_per_pack }} eaches)</div>
                    </div>
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Sold In Cases</div>
                        <div class="dx-field-value-static pt-1">{{ listing.case_size > 1 ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="dx-field" style="height: 20px;">
                        <div class="dx-field-label pt-1" style="width: 150px">Storage Conditions</div>
                        <div class="dx-field-value-static pt-1">{{listing.storage_conditions}}</div>
                    </div>
                    <div>
                        <div *ngIf="listing.case_size > 1">1 Case = {{listing.case_size}} packs</div><br>
                        <div>1 Pack = {{listing.eaches_per_pack}} each(es)</div><br>
                        <div>1 Each = 1 {{listing.package_type}}</div><br>
                        <div>{{listing.package_description | titlecase}}</div>
                    </div>
                </div>
                <div *ngIf="listing?.hcpcs" class="dx-field" style="height: 20px;">
                    <div class="dx-field-label pt-1" style="width: 150px">HCPCS:</div>
                    <div class="dx-field-value-static pt-1">
                        {{listing.hcpcs}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="listing" class="row flex-column">
    <div class="align-items-start mt-3 col">
        <div class="d-flex flex-row align-items-center">
        <p class="product-title">Product Info</p>
        </div>
        <hr class="ltgray-line mt-0"/>
    </div>


    <div class="d-flex flex-row justify-content-start col">
        <div class="product-images mr-4">
            <dx-gallery
            #gallery
            id="gallery"
            [dataSource]="listing.images"
            height="100%"
            style="max-height:400px;"
            width="400px"
            [loop]="true"
            [showNavButtons]="true"
            [showIndicator]="true"
            noDataText="No Product Images">
            <div *dxTemplate="let galleryItem of 'item'">
              <img src="{{ galleryItem.url }}" style="max-width: 100%; max-height:100%; object-fit: contain;"/>
            </div>
          </dx-gallery>

        </div>
        <div class="d-flex flex-column justify-content-center col">
            <div *ngIf="!hydraEnabled" class="dx-field" style="height: 20px;">
                <div class="dx-field-label product-info-name pt-1" style="width: 150px">Company</div>
                <div class="dx-field-value-static pt-1">
                    <app-seller-company-logo [listing]="listing" mode="url"></app-seller-company-logo>
                </div>
            </div>

            <div class="dx-field">
                <div class="dx-field-label product-info-name pt-1" style="width: 150px">NDC</div>
                <div class="dx-field-value-static ndc-background">{{listing.ndc | ndc}}</div>
            </div>

            <div class="dx-field" style="height: 20px;">
                <div class="dx-field-label product-info-name pt-1" style="width: 150px">Details</div>
                <div class="dx-field-value-static pt-1 product-specs" (click)="openProductSpecs()">View Product Specs -></div>
            </div>

            <div *ngIf="listing?.primary_option?.description" class="dx-field" style="height: 20px;">
                <div class="dx-field-label product-info-name pt-1" style="width: 150px">Shipping:</div>
                <div class="dx-field-value-static pt-1">
                    <span *ngIf="listing?.primary_option?.display_price == 'Free' && hydraEnabled">{{listing?.primary_option?.description}}</span>
                    <span *ngIf="listing?.primary_option?.display_price == 'Free' && !hydraEnabled">{{listing?.primary_option?.description | lowercase}} for this {{listing?.primary_option?.primary ? 'supplier' : 'listing'}}</span>
                    <span *ngIf="listing?.primary_option?.display_price != 'Free'">{{listing?.primary_option?.display_price}}, {{listing?.primary_option?.description | lowercase}} for this {{listing?.primary_option?.primary ? 'supplier' : 'listing'}}</span>
                </div>            </div>

            <div class="dx-field">
                <div class="dx-field-label product-info-name pt-1" style="width: 150px">Quantites</div>
                <div class="dx-field-value-static pt-1">
                <!-- <div class="dx-field" style="height: 20px;">
                    <div class="dx-field-label pt-1" style="width: 150px">Sold In Cases</div>
                    <div class="dx-field-value-static pt-1">{{ listing.case_size > 1 ? 'Yes' : 'No' }}</div>
                </div>
                <div class="dx-field" style="height: 20px;">
                    <div class="dx-field-label pt-1" style="width: 150px">Storage Conditions</div>
                    <div class="dx-field-value-static pt-1">{{listing.storage_conditions}}</div>
                </div>
                <div *ngIf="listing?.primary_option?.description" class="dx-field" style="height: 20px;">
                    <div class="dx-field-label pt-1" style="width: 150px">
                        Shipping:
                    </div>

                </div>
                <div> -->
                    <div *ngIf="listing.case_size > 1">1 Case = {{listing.case_size}} packs</div><br>
                    <div>1 Pack = {{listing.eaches_per_pack}} each(es)</div><br>
                    <div>1 Each = 1 {{listing.package_type}}</div><br>
                    <div>{{listing.package_description | titlecase}}</div>
                </div>
                <div *ngIf="!hydraEnabled" style="height: 50px;">
                    <span *ngFor="let image of listing.images; let i = index" >
                        <img *ngIf="i<4" src="{{ image.url }}" (click)="showImage(image)" class="mr-1" style="display: inline-block; cursor: pointer; max-height: 100%; border:1px solid #cdcdcd;"/>
                    </span>
                </div>
            </div>

            <div class="dx-field" style="height: 20px;">
                <div class="dx-field-label product-info-name pt-1" style="width: 150px">Minimum Order</div>
                <div class="dx-field-value-static pt-1">{{ listing.min_packs }} pack ({{ listing.min_packs * listing.eaches_per_pack }} eaches)</div>
            </div>

            <div *ngIf="listing.hcpcs" class="dx-field" style="height: 20px;">
                <div class="dx-field-label product-info-name pt-1" style="width: 150px">HCPCS</div>
                <div class="dx-field-value-static pt-1">{{listing.hcpcs}}</div>
            </div>
        </div>
    </div>
</div>

<dx-popup 
    *ngIf="openProductSpecsPopup" class="popup" 
    [showTitle]="true" title="Product Specs" [dragEnabled]="true"
    [hideOnOutsideClick]="true" [showCloseButton]="true" 
    (onHiding)="this.openProductSpecsPopup = false" 
    [(visible)]="openProductSpecsPopup"
    [resizeEnabled]="false" [width]="'484px'" [height]="'auto'">

    <div class="d-flex flex-column">
        <p class="product-name">{{listing.name}}</p>

        <div class="dx-field">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">NDC</div>
            <div class="dx-field-value-static ndc-background pt-1">{{listing.ndc | ndc}}</div>
        </div>

        <div class="dx-field" style="height: 20px;">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">Classification</div>
            <div class="dx-field-value-static pt-1">{{listing.pharm_class | titlecase}}</div>
        </div>

        <div class="dx-field" style="height: 20px;">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">Active Ingredient</div>
            <div class="dx-field-value-static pt-1">{{listing.substance_name}}</div>
        </div>

        <div class="dx-field" style="height: 20px;">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">Route</div>
            <div class="dx-field-value-static pt-1">{{listing.route | titlecase}}</div>
        </div>

        <div class="dx-field" style="min-height: 20px;">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">Storage Conditions</div>
            <div class="dx-field-value-static pt-1">{{listing.storage_conditions}}</div>
        </div>

        <div class="dx-field" style="height: 20px;">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">Sold In Cases</div>
            <div class="dx-field-value-static pt-1">{{ listing.case_size > 1 ? 'Yes' : 'No' }}</div>
        </div>

        <div class="dx-field">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">Quantites</div>
            <div class="dx-field-value-static pt-1">
                <div *ngIf="listing.case_size > 1">1 Case = {{listing.case_size}} packs</div><br>
                <div>1 Pack = {{listing.eaches_per_pack}} each(es)</div><br>
                <div>1 Each = 1 {{listing.package_type}}</div><br>
                <div>{{listing.package_description | titlecase}}</div>
            </div>
        </div>

        <div class="dx-field" style="height: 20px;">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">Minimum Order</div>
            <div class="dx-field-value-static pt-1">{{ listing.min_packs }} pack ({{ listing.min_packs * listing.eaches_per_pack }} eaches)</div>
        </div>

        <div *ngIf="listing.hcpcs" class="dx-field" style="height: 20px;">
            <div class="dx-field-label product-info-name pt-1" style="width: 150px">HCPCS</div>
            <div class="dx-field-value-static pt-1">{{listing.hcpcs}}</div>
        </div>
    </div>
</dx-popup>

<dx-popup 
    *ngIf="imagePopupVisible" 
    class="popup" 
    [showTitle]="true" 
    title="Product Image" 
    [dragEnabled]="true"
    [height]="540" 
    [width]="720"
    [hideOnOutsideClick]="true" 
    [showCloseButton]="true" 
    [(visible)]="imagePopupVisible" 
    [resizeEnabled]="true">
  <img src="{{ currentImage.url }}" style="max-width: 100%; max-height: 100%;"/>
</dx-popup>
