import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CsosService {
  constructor(private http: HttpClient) { }

  public createCsosUser$(data: any): Observable<any> {
    return this.http.post(`${environment.api_url_v1}/services/user/csos/create_signer_in_axway`, data);
  }

  public getCsosCertificates$(params): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/user/csos/certificates`, { params });
  }

  public importCsosCertificate$(data): Observable<any> {
    return this.http.post(`${environment.api_url_v1}/services/user/csos/import_certificate`, data);
  }

  public deleteExpiredCsosCertificate$(): Observable<any> {
    return this.http.delete(`${environment.api_url_v1}/services/user/csos/delete_expired_certificates`);
  }

  public csosSignUp(contacts: any): Observable<any> {
      return this.http.post<any>(`${environment.api_url_v1}/services/user/csos/signup`, contacts);
  }
}
