import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage.service';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class CatalogService {
  currentTopFilter = 0;
  public termsCache = {
    balance: 0.0,
    terms: '',
    creditLimit: 0.0,
    remaining: 0.0
  };
  private docsCache = {};

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public get(params=null): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/listing/catalog`, { params: params });
  }

  public getListing(id): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/listing/catalog/${id}`);
  }

  public catalogDetails(idList: number[]): Observable<any> {
    const param = JSON.stringify(idList);
    return this.http.get(`${environment.api_url_v1}/listing_details.json?ids=${param}`);
  }

  public getListingDocs(id: number): Observable<any> {
    if (this.docsCache.hasOwnProperty(id)) {
      return of(this.docsCache[id]);
    } else {
      return this.http.get(`${environment.api_url_v1}/listing_docs.json?id=${id}`).pipe(
        tap(res => this.docsCache[id] = res)
      );
    }
  }

  public addToWishlist(listing: any): Observable<any> {
    const item = { listing_id: listing.id, gcn: listing.gcn_seqno};
    return this.addToFavorites(item);
  }

  public getSoftBlock(id): Observable<any> {
    return this.http.get(`${environment.api_url_v2}/services/listing/soft_block/${id}`);
  }

  public updateSoftBlock(id, data): Observable<any> {
    return this.http.put(`${environment.api_url_v2}/services/listing/soft_block/${id}`, data);
  }

  public addToSoftBlock(data): Observable<any> {
    return this.http.post(`${environment.api_url_v2}/services/listing/soft_block`, data);
  }
  
  public removeFromSoftBlock(id): Observable<any> {
    return this.http.delete(`${environment.api_url_v2}/services/listing/soft_block/${id}`);
  }

  public addToFavorites(item): Observable<any> {
    return this.http.post(`${environment.api_url_v2}/services/listing/favorite`, item);
  }

  public removeFromWishlist(favoriteId): Observable<any> {
    return this.http.delete(`${environment.api_url_v2}/services/listing/favorite/${favoriteId}`);
  }

  public getCategories(params): Observable<any> {
    params = this.addSellerParams(params);
    return this.http.get(`${environment.api_url_v1}/services/listing/catalog/categories`, {params});
  }

  public getListingTypes(params): Observable<any> {
    params = this.addSellerParams(params);
    return this.http.get(`${environment.api_url_v1}/services/listing/catalog/listings_types`, { params });
  }

  public getMedshortsAlternatives(catalogGCNIds: any, email: any): Observable<any> {
    return this.http.get(`${environment.api_url_v2}/services/listing/catalog/medshorts/alternatives?gcn_ids=${catalogGCNIds}&user_email=${email}`);
  }

  public getMedshortsCatalog(): Observable<any> {
    return this.http.get(`${environment.api_url_v2}/services/listing/catalog/medshorts/catalog`);
  }

  public getHomepageListings(params): Observable<any> {
    params = this.addSellerParams(params);
    params.page = 1;
    params.per_page = 5;
    return this.http.get(`${environment.api_url_v2}/services/listing/catalog/homepage_listings`, {params});
  }

  public getFacilityStats(): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/listing/catalog/facility_stats`);
  }

  public searchMedshortsCatalog(searchTerm: any): Observable<any> {
    return this.http.get(`${environment.api_url_v2}/services/listing/catalog/medshorts/search?search_term=${searchTerm}`);
  }

  public genEdi832(companyId, facilityId): Observable<any> {
    return this.http.get(`${environment.api_url_v2}/services/listing/admin/edi/${companyId}/${facilityId}`);
  }

  public getSellerListing(id): Observable<any> {
    return this.http.get(`${environment.api_url_v2}/services/listing/listings/${id}`);
  }

  public updateDailymed(ndc): Observable<any> {
    return this.http.get(`${environment.api_url_v2}/services/listing/listings/dailymed/${ndc}`);
  }
  
  private addSellerParams(params) {
    const catalogSellerId = this.storageService.get('catalogSellerId');

    if(catalogSellerId && catalogSellerId !== 'null') {
        params.seller_id = catalogSellerId;
    }
    return params;
  }

  public hasPriceData(facilityId): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/catalog/has_history/${facilityId}`);
  }

  public getPurchaseRecommendations(data): Observable<any> {
    return this.http.post(`${environment.api_url_v2}/services/listing/catalog/purchase_recommendations`, data);
  }

  reMapListing(outListing, baseListing) {
    outListing['pkg_price_340b'] = outListing.pricing.price || 0;
    outListing['pkg_price_gpo'] = outListing.pricing.price || 0;
    outListing['pkg_price_wac'] = outListing.pricing.price || 0;
    outListing['unit_cost_340b'] = outListing['pkg_price_340b'] / outListing.eaches_per_pack;
    outListing['unit_cost_gpo'] = outListing['pkg_price_gpo'] / outListing.eaches_per_pack;
    outListing['unit_cost_wac'] = outListing['pkg_price_wac'] / outListing.eaches_per_pack;
    outListing['diff_unit_cost_340b'] = outListing['unit_cost_340b'] - baseListing.unit_cost_340b;
    outListing['diff_unit_cost_gpo'] = outListing['unit_cost_gpo'] - baseListing.unit_cost_gpo;
    outListing['diff_unit_cost_wac'] = outListing['unit_cost_wac'] - baseListing.unit_cost_wac;
    
    return outListing;
  }


}
