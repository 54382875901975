import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { Cart } from '../../models/cart';
import { environment } from 'src/environments/environment';
import { Shipping } from '../../models/shipping';


@Injectable({
  providedIn: 'root'
})

export class CartService {

  constructor(private http: HttpClient) {}

  private cartModeC2 = false;
  public switchCartModeC2 = new BehaviorSubject<boolean>(false);

  public cartItems: ArrayStore = new ArrayStore({ key: 'id', data: [] });
  public cartSub = new BehaviorSubject<Cart>(null);
  public cart: Cart;

  public cartItemsC2: ArrayStore = new ArrayStore({ key: 'id', data: [] });
  public cartSubC2 = new BehaviorSubject<Cart>(null);
  public cartC2: Cart;

  public cartRefreshing = new BehaviorSubject<boolean>(false);

  public refresh$(params?): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/cart/cart`, { params });
  }

  public refresh() {
    this.cartRefreshing.next(true);
    return this.refresh$().toPromise().then(
      result => {
        this.prepareCart(result.data.cart);
        this.prepareCartC2(result.data.cart_c2);
        this.cartRefreshing.next(false);
        return
      },
      () => this.cartRefreshing.next(false)
    );
  }


  getCartModeC2() {
    return this.cartModeC2;
  }


  setCartModeC2(value) {
    this.cartModeC2 = value;
    this.switchCartModeC2.next(value);
  }

  prepareCart(data, showMessage=true) {
    this.cart = data;
      if (this.cart?.shipping_options?.length) {
        this.cart.selected_shipping = this.cart.shipping_options[0];
      }
      if (showMessage && (this.cart?.notifications?.length || this.cart?.errors?.length)) {
        notify({ message: `There were some changes in your cart`, closeOnClick: true, height: 50, position: 'top' }, 'warning', 5000)
      }
      this.cartItems = new ArrayStore({ key: 'id', data: data?.items});
      this.cartSub.next(data);
  }

  prepareCartC2(data, showMessage=true) {
    this.cartC2 = data;
    if (this.cartC2?.shipping_options?.length) {
      this.cartC2.selected_shipping = this.cartC2.shipping_options[0];
    }
    if (showMessage && (this.cartC2?.notifications?.length || this.cartC2?.errors?.length)) {
      notify({ message: `There were some changes in your CII cart`, closeOnClick: true, height: 50, position: 'top' }, 'warning', 5000)
    }
    this.cartItemsC2 = new ArrayStore({ key: 'id', data: data?.items});
    this.cartSubC2.next(data);
  }

  public get(isC2): BehaviorSubject<Cart> {
    return isC2 ? this.cartSubC2 : this.cartSub;
  }

  public getId(listingid, isC2): any {
    const cartItems = isC2 ? this.cartItemsC2 : this.cartItems;
    const q = cartItems.createQuery();
    const res = q.filter(['id', '=', listingid]).toArray();
    return res.length === 1 ? res[0] : null;
  }

  public addItem(listing: any): Promise<any> {
    this.cartRefreshing.next(true);
    const data = {
      id: listing.id,
      is_c2: listing?.is_c2,
      ndc_number: listing.ndc_number,
      pricing: {prices: listing.pricing.prices},
      name: listing.name,
      eaches_per_pack: listing.eaches_per_pack
    }

    const cartItems = listing?.is_c2 ? this.cartItemsC2 : this.cartItems;
    const cart = listing?.is_c2 ? this.cartC2 : this.cart;

    const q = cartItems.createQuery();
    if (q.filter(['id', '=', data.id]).toArray().length === 1) {
      cartItems.update(listing.id, data);
    } else {
      cartItems.insert(data);
    }
    cart.items = q.filter(['id', '>', 0]).toArray();

    return this.storeCart(cart, listing?.is_c2);
  }

  public setShipping(shipping: Shipping, isC2) {
    let cart = isC2 ? this.cartC2 : this.cart;
    cart.selected_shipping = shipping;
    this.storeCart(cart, isC2);
  }

  public storeCart$(cart, isC2): Observable<any> {
    return this.http.post(`${environment.api_url_v1}/services/cart/cart/${isC2?'c2':'c0'}`, cart);
  }

  public storeCart(cart: Cart, isC2) {
    // TODO: uncommit this line after CART UPDATE is merged
    // const data = this.stripCart(cart);
    return this.storeCart$(cart, isC2).toPromise().then(
      cart => {
        notify({ message: 'Cart was updated', closeOnClick: true, height: 50, position: 'top' }, 'success', 5000);
        this.prepareCart(cart.data.cart, false);
        this.prepareCartC2(cart.data.cart_c2, false);
        this.cartRefreshing.next(false);
        return cart.data;
      },
      error => this.handleCartError(error)
    );
  }

  public stripCart(cart: Cart) {
    var data = {
      items: []
    }
    cart.items.forEach(item => {
        var listing = {
          name: item.name,
          pricing: {prices: []},
        }
        var prices = [];
        item.pricing.prices.forEach(price => {
          if (price.quantity > 0) {
            prices.push({
              id: price.price,
              listing_id: price.listing_id,
              lot_id: price.lot_id,
              expiry_date: price.expiry_date,
              quantity: price.quantity,
              price: price.price
            });
          }
        });
        if (prices.length) {
          listing.pricing.prices = prices;
          data.items.push(listing);
        }
      }
    );
    return data;
  }

  public clearCart$(isC2): Observable<any> {
    return this.http.delete(`${environment.api_url_v1}/services/cart/cart/${isC2?'c2':'c0'}`);
  }

  public async clearCart(isC2) {
    this.cartRefreshing.next(true);
    try {
      const result = await this.clearCart$(isC2).toPromise();
      this.prepareCart(result.data.cart);
      this.prepareCartC2(result.data.cart_c2);
    } catch (error) {
      this.handleCartError(error);
    }
  }

  handleCartError(error) {
    notify({ message: error, closeOnClick: true, height: 50, position: 'top' }, 'error', 5000);
    this.cartRefreshing.next(false);
  }

  public delete(lineId, isC2) {
    this.cartRefreshing.next(true);
    const cartItems = isC2 ? this.cartItemsC2 : this.cartItems;
    const cart = isC2 ? this.cartC2 : this.cart;

    const q = cartItems.createQuery();
    const match = q.filter(['id', '=', lineId]).toArray();

    if (match.length === 1) {
      cartItems.remove(lineId).then();
      cart.items = q.filter(['id', '>', 0]).toArray();
      this.storeCart(cart, isC2);
    }
  }

  public getCarts$(id): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/cart/cart/get_carts/${id}`);
  }

  public getCompaniesWithCarts$(): Observable<any> {
    return this.http.get(`${environment.api_url_v1}/services/cart/cart/get_companies_with_carts`);
  }

  public clearCartAdmin$(id): Observable<any> {
    return this.http.delete(`${environment.api_url_v1}/services/cart/cart/clear/${id}`);
  }
}
