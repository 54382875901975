import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent, DxFormModule, DxValidationGroupModule, DxValidatorModule } from 'devextreme-angular';
import { Profile } from '../../models/profile';
import { NotificationService } from '../../services/notification.service';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { CommonModule } from '@angular/common';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html'
})
export class PersonalInfoComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  profile: Profile;
  profile_data: UserProfile = { id: null, phone: '', email: '' };

  buttonOptions: any = {
    text: 'Update',
    type: 'success',
    useSubmitBehavior: true
  };

  value: any[] = [];


  constructor(private userService: UserService,
              private notificationService: NotificationService,
              public auth0: Auth0Service) { }

  ngOnInit() {
    this.userService.getMe().subscribe( res => {
      this.profile = res;
      this.profile_data.id = this.profile.data.id;
      this.profile_data.phone = this.profile.data.phone;
      this.profile_data.email = this.profile.data.email;
    });
  }

  onSubmit(e) {
    const data = {
      id: this.profile.data.id,
      phone:this.profile_data.phone.replace(/\(*\)*\-* */gi, ''),
      email: this.profile_data.email
    }
    this.userService.updateMe(data).subscribe(
      () => this.notificationService.showNotify('Profile information was updated', 'success'),
      error => this.notificationService.showNotify(error, 'error')
    );
    e.preventDefault();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxiValidationRuleModule
  ],

  declarations: [PersonalInfoComponent],
  exports: [PersonalInfoComponent]
})
export class PersonalInfoModule { }

export class UserProfile {
  id: number;
  email: string;
  phone: string;
}
