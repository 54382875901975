import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NdcService {

  constructor(private http: HttpClient) { }

  public get(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/ndc`,  {params: params});
  }

  public getGrouped(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/ndc/grouped`,  {params: params});
  }

  public getById(id): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/listing/ndc/${id}`);
  }


}
