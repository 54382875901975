import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-picture-preview',
  templateUrl: './user-picture-preview.component.html',
  styleUrls: ['./user-picture-preview.component.scss']
})
export class UserPicturePreviewComponent implements OnInit {

  @Input() firstName: any;
  @Input() lastName: any;
  @Input() profile: any;
  @Input() size = 100;
  style = ""
  userMetadata = environment.auth0.userMetadata;
  

  constructor() { }

  ngOnInit() {
    this.style = `background-color:rgb(11,45,77); width: ${this.size}px; height: ${this.size}px; line-height: ${this.size}px; font-size: ${this.size/2}px;`
  }

}
