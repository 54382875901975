import { Component } from '@angular/core';

@Component({
  selector: 'app-quantity',
  template: ''
})
export class QuantityBaseComponent {
  calculateValidQuantity(value, multiple, listing) {
    if (!value || value % multiple) {
      value = this.roundToNearestQuantityStep(value || listing.offer_minimum_quantity, multiple);
    }
    return value;
  }

  roundToNearestQuantityStep(value, multiple) {
    if (value < 1) {
      value = 1;
    }
    const distanceToFloor = value % multiple;
    const distanceToCeil = multiple - distanceToFloor;

    if (distanceToCeil > distanceToFloor) {
      return Math.floor(value / multiple) * multiple;
    }

    return Math.ceil(value / multiple) * multiple;
  }
}
