
<nav *ngIf="!mobileUser" id="mktpg" class="navbar navbar-expand-lg navbar-light pt-4">
  <div class="container">
  <div class="d-flex align-items-center">
    <a *ngIf="landingConfigs?.logo" class="mainlogo" routerLink="/">
      <img src="assets/img/logos/mdg-logo-vect.svg" data-toggle="collapse" data-target="#navbarSupportedContent" height="40px" class="d-inline-block align-top"  alt="medigi Logo">
    </a>
  </div>

  <button class="navbar-toggler navbar-dark" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    
    <ul *ngIf="!isSellerUrl" class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link nav-link-white" routerLink="/pharmacy-info">pharmacies</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-link-white" routerLink="/manufacturer-info">manufacturers</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-link-white" routerLink="/about">about us</a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li *ngIf="!isSellerUrl" class="nav-item dropdown">
        <a class="nav-link nav-link-yellow dropdown-toggle"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          register now
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/registration/buyer">Pharmacies</a>
          <a class="dropdown-item" routerLink="/registration/seller">Pharmaceutical Manufacturers</a>
        </div>
      </li>
      <li *ngIf="isSellerUrl" class="nav-item dropdown">
        <a class="nav-link nav-link-yellow" routerLink="/registration/buyer">
          register pharmacy
        </a>
      </li>
      <li class="nav-item" style="color: #fff">
        <button class="nav-link sign-in-button" routerLink="" (click)="auth0.login()"><p>sign in &rsaquo;</p></button>
      </li>
    </ul>
</div>
  </div>
</nav>


<nav *ngIf="mobileUser" id="mktpg" class="navbar navbar-expand-lg navbar-light pt-4">
  <div class="container">
  <div class="d-flex align-items-center">
    <a *ngIf="landingConfigs?.logo" class="mainlogo" routerLink="/">
      <img src="assets/img/logos/mdg-logo-vect.svg" data-toggle="collapse" data-target="#navbarSupportedContent" height="40px" class="d-inline-block align-top"  alt="medigi Logo">
    </a>
  </div>

  <button class="navbar-toggler navbar-dark" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link nav-link-white" data-toggle="collapse" data-target="#navbarSupportedContent" routerLink="/pharmacy-info">pharmacies</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-link-white" data-toggle="collapse" data-target="#navbarSupportedContent" routerLink="/manufacturer-info">manufacturers</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-link-white" data-toggle="collapse" data-target="#navbarSupportedContent" routerLink="/about">about us</a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link nav-link-yellow dropdown-toggle"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          register now
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" data-toggle="collapse" data-target="#navbarSupportedContent" routerLink="/registration/buyer">Pharmacies</a>
          <a class="dropdown-item" data-toggle="collapse" data-target="#navbarSupportedContent" routerLink="/registration/seller">Pharmaceutical Manufacturers</a>
        </div>
      </li>
      <li class="nav-item" style="color: #fff">
        <button class="nav-link sign-in-button" data-toggle="collapse" data-target="#navbarSupportedContent" routerLink="" (click)="auth0.login()"><p>sign in &rsaquo;</p></button>
      </li>
    </ul>
</div>
  </div>
</nav>

