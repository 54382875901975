import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { NotificationService } from './notification.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { AppInfoService } from './app-info.service';
// import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CatalogConfigurationsService {

  configurations = new CatalogConfigurations();
  appliedConfigurations = new CatalogConfigurations();

  public applyFilterDisabled = true;
  
  public catalogConfigurationsSubject = new BehaviorSubject<CatalogConfigurations>(this.configurations);
  public searchTrigger = new Subject<boolean>();

  constructor(
    private appInfo: AppInfoService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private googleAnalyticsService: GoogleAnalyticsService) {
    this.setLandingBasedCatalogTypes();
  }

  public get(): BehaviorSubject<CatalogConfigurations> {
    return this.catalogConfigurationsSubject;
  }

  private push() {
    this.setApplyFilterDisabled();
    this.catalogConfigurationsSubject.next(this.configurations);
  }

  public search() {
    this.appliedConfigurations = JSON.parse(JSON.stringify(this.configurations));
    this.setApplyFilterDisabled();
    this.searchTrigger.next(true);
    this.googleAnalyticsService.search(this.configurations);
  }

  public reset() {
    this.configurations = new CatalogConfigurations();
    this.setLandingBasedCatalogTypes();
    this.appliedConfigurations = JSON.parse(JSON.stringify(this.configurations));
    this.push();
    this.search();
  }

  set searchText(searchText) {
    this.configurations.searchText = searchText;
    this.push();
  }

  get searchText() {
    return this.configurations.searchText;
  }

  set sellerId(sellerId) {
    this.setSellerId(sellerId);
    this.push();
  }

  setApplyFilterDisabled() {
    this.applyFilterDisabled = JSON.stringify(this.configurations) === JSON.stringify(this.appliedConfigurations);
  }

  private setSellerId(sellerId: number) {
    if (this.storageService.get('catalogSellerLocked') === 'true') {
      sellerId = parseInt(this.storageService.get('catalogSellerId'), 10);
    }
    this.configurations.sellerId = sellerId;
  }

  get sellerId() {
    if (this.storageService.get('catalogSellerLocked') === 'true') {
      return parseInt(this.storageService.get('catalogSellerId'), 10);
    }
    return this.configurations.sellerId;
  }

  set category(category) {
    this.configurations.category = category;
    this.push();
  }

  get category() {
    return this.configurations.category;
  }

  set packTypes(packTypes) {
    this.configurations.packTypes = packTypes;
    this.push();
  }

  get packTypes() {
    return this.configurations.packTypes;
  }

  get expiration() {
    return this.configurations.expiration;
  }

  get range() {
    return this.configurations.range;
  }

  set range(range) {
    this.configurations.range = range;
    this.push();
  }

  setLandingBasedCatalogTypes() {
    if (this.appInfo.configs.domainKey != 'medigi') {
      this.setCatalogType(this.appInfo.configs.domainKey, true);
    } else {
      this.setCatalogType('503b|medigi|medshorts', true);
    }
  }

  setCatalogType(catalogType, value) {
    this.catalogType = { catalogType, value };
  }

  set catalogType({ catalogType, value }: any) {
    const catalogTypes = catalogType.split('|');
    if (this.configurations.catalogTypes.catalogType === catalogType && !value) {
      value = true
      this.notificationService.showErrorMessage('At least one catalog must be selected');
    }
    catalogTypes.forEach((type) => this.updateCatalogSearchValue(type, value));
    this.push();
  }

  private updateCatalogSearchValue(type: any, value: any) {
    if (type === 'medigi') {
      this.configurations.catalogTypes.searchMedigiFlag = value;
    } else if (type === '503b') {
      this.configurations.catalogTypes.search503bFlag = value;
    } else if (type === 'medshorts') {
      this.configurations.catalogTypes.searchMedshortsFlag = value;
    }
  }

  get catalogType() {
    return this.configurations.catalogTypes.catalogType;
  }

  set listingTags(tag: string) {
    if (tag === 'all') {
      if (this.configurations.listingTags.isAllListingActive) {
        return;
      }
      this.configurations.listingTags.isAllListingActive = !this.configurations.listingTags.isAllListingActive;
    } else if (tag === 'ashp') {
      this.configurations.listingTags.isAshpActive = !this.configurations.listingTags.isAshpActive;
    } else if (tag === 'fav') {
      this.configurations.listingTags.isFavoritesActive = !this.configurations.listingTags.isFavoritesActive;
    } else if (tag === 'sb') {
      this.configurations.listingTags.isSoftblockActive = !this.configurations.listingTags.isSoftblockActive;
    } else if (tag === 'rfp') {
      this.configurations.listingTags.isRfpActive = !this.configurations.listingTags.isRfpActive;
    } else if (tag === 'vbo') {
      this.configurations.listingTags.isVboActive = !this.configurations.listingTags.isVboActive;
    } else if (tag === 'match') {
      this.configurations.listingTags.isMatchesActive = !this.configurations.listingTags.isMatchesActive;
    } else if (tag === 'exact') {
      this.configurations.listingTags.isExactActive = !this.configurations.listingTags.isExactActive;
    }
    this.push();
    this.search();
  }

  get catalogSellerLocked() {
    return this.storageService.get('catalogSellerLocked') === 'true';
  }

  get catalogSellerId() {
    if (this.storageService.get('catalogSellerLocked') === 'true') {
      return parseInt(this.storageService.get('catalogSellerId'), 10);
    } 
    return null;
  }

}


export class CatalogConfigurations {
  searchText: string = '';
  sellerId: number = null;
  category: string = null;
  packTypes: string[] = [];
  range: number[] = [0, 4];
  catalogTypes = new CatalogTypes();
  listingTags = new ListingTags();

  get expiration(): any {
    return {
      start_date: EXPIRATION_MAP[this.range[0]],
      end_date: EXPIRATION_MAP[this.range[1]]
    };
  }
  
  enableAllCatalogs() {
    this.catalogTypes.search503bFlag = true;
    this.catalogTypes.searchMedigiFlag = true;
    this.catalogTypes.searchMedshortsFlag = true;
  }
}

export class CatalogTypes {
  searchMedigiFlag = false;
  search503bFlag = false;
  searchMedshortsFlag = false;

  get catalogType() {
    let catalogs = '';
    if (this.searchMedigiFlag) {
      catalogs += `medigi`;
    }
    if (this.searchMedshortsFlag) {
      catalogs += `${catalogs.length ? '|' : ''}medshorts`;
    }
    if (this.search503bFlag) {
      catalogs += `${catalogs.length ? '|' : ''}503b`;
    }
    return catalogs;
  }

  set catalogType(catalogType: any) {
    const catalogTypes = catalogType.split('|');
    catalogTypes.forEach((type) => {
      if (type === 'medigi') {
        this.searchMedigiFlag = true;
      } else if (type === '503b') {
        this.search503bFlag = true;
      } else if (type === 'medshorts') {
        this.searchMedshortsFlag = true;
      }
    });
  }

  get disabled() {
    return !this.search503bFlag && !this.searchMedigiFlag && !this.searchMedshortsFlag;
  }
}

export class ListingTags {
  private _isAllListingActive = true;
  private _isAshpActive = false;
  private _isFavoritesActive = false;
  private _isSoftblockActive = false;
  private _isRfpActive = false;
  private _isVboActive = false;
  private _isMatchesActive = false;
  private _isExactActive = false;

  get query() {
    if (this._isAllListingActive) {
      return null;
    }
    if (this._isAshpActive) {
      return 'shortages';
    }
    if (this._isFavoritesActive) {
      return 'favorites';
    }
    if (this._isSoftblockActive) {
      return 'softblock';
    }
    if (this._isRfpActive) {
      return 'rfp_enabled';
    }
    if (this._isVboActive) {
      return 'vbo_enabled';
    }
    if (this._isMatchesActive) {
      return 'product_match';
    }
    if (this._isExactActive) {
      return 'exact_ndc_amu';
    }
  }

  get isAllListingActive() {
    return this._isAllListingActive;
  }

  set isAllListingActive(value) {
    this.disableFilterButtons();
    this._isAllListingActive = value;
    this.activateAllListingsFilterButtons();
  }

  get isAshpActive() {
    return this._isAshpActive;
  }

  set isAshpActive(value) {
    this.disableFilterButtons();
    this._isAshpActive = value;
    this.activateAllListingsFilterButtons();
  }

  get isFavoritesActive() {
    return this._isFavoritesActive;
  }

  set isFavoritesActive(value) {
    this.disableFilterButtons();
    this._isFavoritesActive = value;
    this.activateAllListingsFilterButtons();
  }

  get isSoftblockActive() {
    return this._isSoftblockActive;
  }

  set isSoftblockActive(value) {
    this.disableFilterButtons();
    this._isSoftblockActive = value;
    this.activateAllListingsFilterButtons();
  }

  get isRfpActive() {
    return this._isRfpActive;
  }

  set isRfpActive(value) {
    this.disableFilterButtons();
    this._isRfpActive = value;
    this.activateAllListingsFilterButtons();
  }

  get isVboActive() {
    return this._isVboActive;
  }

  set isVboActive(value) {
    this.disableFilterButtons();
    this._isVboActive = value;
    this.activateAllListingsFilterButtons();
  }

  get isExactActive() {
    return this._isExactActive;
  }

  set isExactActive(value) {
    this.disableFilterButtons();
    this._isExactActive = value;
    this.activateAllListingsFilterButtons();
  }

  get isMatchesActive() {
    return this._isMatchesActive;
  }

  set isMatchesActive(value) {
    this.disableFilterButtons();
    this._isMatchesActive = value;
    this.activateAllListingsFilterButtons();
  }

  disableFilterButtons() {
    this._isAllListingActive = false;
    this._isAshpActive = false;
    this._isFavoritesActive = false;
    this._isSoftblockActive = false;
    this._isRfpActive = false;
    this._isVboActive = false;
    this._isMatchesActive = false;
    this._isExactActive = false;
  }
  activateAllListingsFilterButtons() {
    this._isAllListingActive = !this._isAshpActive &&
      !this._isFavoritesActive && !this._isSoftblockActive &&
      !this._isRfpActive && !this._isVboActive &&
      !this._isMatchesActive && !this._isExactActive;
  }
}

export const EXPIRATION_MAP = [14, 90, 180, 270, 3650];

export const EXPIRATION_FILTERS = [
  { id: 0, name: 'All Expiration Dates', value: null },
  { id: 1, name: 'Greater than 30 days', value: 30 },
  { id: 2, name: 'Greater than 60 days', value: 60 },
  { id: 2, name: 'Greater than 90 days', value: 90 },
  { id: 2, name: 'Greater than 6 month', value: 180 },
  { id: 4, name: 'Greater than 12 month', value: 365 }
];
