import {Component, Input, NgModule, OnInit} from '@angular/core';
import { UserService } from 'src/app/shared/services/user/user.service';
import {NotificationService} from '../../services/notification.service';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxCheckBoxModule } from 'devextreme-angular';

@Component({
  selector: 'app-subscribe-emails',
  templateUrl: './subscribe-emails.component.html'
})
export class SubscribeEmailsComponent implements OnInit {
  @Input() userId: number;
  subList = {};
  index: number;
  emailList = {
    id: null,
    user_id: null,
    fav_listings: true,
    monthly_executive: true,
    order_notifications: true,
    trending_products: true,
    weekly_changes: true
  };

  constructor(private userService: UserService,
              private notifyService: NotificationService) {
  }

  ngOnInit() {
    this.emailList.user_id = this.userId;
    this.getEmailSubscription();
  }

  getEmailSubscription() {
    this.userService.get_email_subscription(this.userId).subscribe(
      result => this.emailList = result.data,
      () => this.createEmailSubscription()
    );
  }

  onSubmit() {
    if (this.emailList.id) {
      this.updateEmailSubscription();
    } else {
      this.createEmailSubscription();
    }
  }

  updateEmailSubscription() {
    this.userService.update_email_subscription(this.emailList.id, this.emailList).subscribe(
      () =>this.notifyService.showNotify('Saved', 'success'),
      error =>this.notifyService.showNotify(error, 'error')
    );
  }

  createEmailSubscription() {
    this.userService.create_email_subscription(this.emailList).subscribe(
      () =>this.notifyService.showNotify('Saved', 'success'),
      error =>this.notifyService.showNotify(error, 'error')
    );
  }
}


@NgModule({
  imports: [
    CommonModule,
    DxCheckBoxModule,
    DxButtonModule
  ],

  declarations: [SubscribeEmailsComponent],
  exports: [SubscribeEmailsComponent]
})
export class SubscribeEmailsModule { }