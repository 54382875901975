import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  public get(params): Observable<any> {
    return this.http.get<any>(`${environment.api_url_v1}/services/document/document`, { params });
  }

  set(data): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/document/document`, data);
  }

  upload(data): Observable<any> {
    return this.http.post<any>(`${environment.api_url_v1}/services/document/document/upload`, data);
  }

  public delete(id): Observable<any> {
    return this.http.delete<any>(`${environment.api_url_v1}/services/document/document/${id}`);
  }
}
