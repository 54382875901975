import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxLoadIndicatorModule, DxChartModule } from 'devextreme-angular';
import { SpendsTypeComparedComponent } from './spends-type-compared.component';

@NgModule({
  imports: [
    CommonModule,
    DxChartModule,
    DxLoadIndicatorModule
  ],
  declarations: [SpendsTypeComparedComponent],
  exports: [SpendsTypeComparedComponent],
})
export class SpendsTypeComparedModule {}
