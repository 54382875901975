import {Injectable} from '@angular/core';
import {HttpRequest, HttpHeaders, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let httpRequest = req;
    if (req?.method === 'GET') {
      httpRequest = req.clone({
          headers: new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
            'Txn-Id': Math.random().toString(36).substring(2, 10)
          })
        });
    }

    return next.handle(httpRequest);
  }
}
