import {Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import {Auth0Service} from './auth/auth0.service';
import {Router, NavigationStart, NavigationEnd} from '@angular/router';
import {SessionData} from './shared/models/session';
import {filter, map} from 'rxjs/operators';
import { Landing } from './shared/models/landing';
import { ScreenService } from './shared/services/screen.service';
import { AppInfoService } from './shared/services/app-info.service';
import { StorageService } from './shared/services/storage.service';
import { Observable, Subject, Subscription, interval, timer } from 'rxjs';
import { CatalogService } from './shared/services/listing/catalog.service';
import { UserService } from './shared/services/user/user.service';
import { SessionService } from './shared/services/session.service';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { devExtremeKey } from './shared/models/constants';
import config from 'devextreme/core/config';
import { DirectService } from './shared/services/user/direct.service';

declare var gtag;

config({
  licenseKey: devExtremeKey
});

const getURLParam = key => {
  let param = [];

  if (window.location.search.length) {
    param = window.location.search.slice(1).split('&').filter(p => p.indexOf(`${key}=`) >= 0);
  }

  if (param.length) {
    return decodeURIComponent(param[0].split('=')[1]);
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {


  session: SessionData;
  regComplete = false;
  browserRefresh = false;
  showSwitchFacility = false;
  showUpdatePharmaData = false;
  navSub;
  msClient;
  legisymSubscription: Subscription;
  landingConfigs: Landing;
  subs: Subscription = new Subscription();
  copyrightYear = 0;
  is503B = false;
  isMedshorts = false;
  isMedigi = false;
  isSellerDirect = false;
  directNav: any;
  directFooter: any;

  userInactive: Subject<number> = new Subject();
  sessionTime = 0;
  $inactiveTime: Observable<number>;
  directBlackBox: any;

  chatlioAvatarSet = false;
  chatlioMessageAvatarSet = false;
  chatlioAvatarHeaderImage = false;
  
  constructor(public auth0: Auth0Service,
              private screen: ScreenService,
              public appInfo: AppInfoService,
              private sessionService: SessionService,
              private router: Router,
              public catalogService: CatalogService,
              public storageService: StorageService,
              public userService: UserService,
              private directService: DirectService,
              private googleAnalyticService: GoogleAnalyticsService) {
    this.navSub = router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
      }
    });
    this.createInactiveCounter();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.oberserableTimer();
    this.getAppConfigs();
    this.auth0.localAuthSetup(this.msClient);
    this.setScrollTop();
    this.checkAuth0UserData();
    this.setSwitchFacilityTrigger();
    this.setUpdatePharmaDataTrigger();
    this.setCopyrightYear();
    this.setChatlioWidgetContent();
    this.observeInactiveCounter();
    this.watchInactiveTime();
  }

  setChatlioWidgetContent() {
    if (document.getElementsByClassName('chatlio-chat-header__primary-text')[0]) {
      document.getElementsByClassName('chatlio-chat-header__primary-text')[0].innerHTML = environment.supplierConfig.chatlio.widgetTitle;
    }
    if (document.getElementById('chatlio-header-avatar-image') && !this.chatlioAvatarHeaderImage) {
      document.getElementById('chatlio-header-avatar-image').setAttribute('src', environment.supplierConfig.chatlio.logo);
      this.chatlioAvatarHeaderImage = true;
    }
    if (document.querySelector('.chatlio-avatar img') && !this.chatlioAvatarSet) {
      document.querySelector('.chatlio-avatar img').setAttribute('src', environment.supplierConfig.chatlio.logo);
      this.chatlioAvatarSet = true;
    }
    if (document.querySelector('.chatlio-message-avatar img') && !this.chatlioMessageAvatarSet) {
      document.querySelector('.chatlio-message-avatar img').setAttribute('src', environment.supplierConfig.chatlio.logo);
      this.chatlioMessageAvatarSet = true;

    }
  }

  watchInactiveTime() {
    this.sessionService.inactiveTime.subscribe((time) => {
      if (this.auth0.loggedIn && time > 60*30) {
        this.auth0.logout();
      }
      if (time > 60*5) {
        this.sessionService.userAfk.next(true);
      } else {
        this.sessionService.userAfk.next(false);
      }
    });
  }

  async getAppConfigs() {
    this.appInfo.get().subscribe(result => this.landingConfigs = result);
    this.msClient = await this.userService._getMsClient();
    this.isSellerDirect = this.msClient !== null && this.msClient.use_direct;
    this.is503B = environment.clientDomains.outsourcingDomian === window.location.host;
    this.isMedshorts = environment.clientDomains.medshortsDomain === window.location.host;
    this.isMedigi = !this.isSellerDirect && !this.is503B && !this.isMedshorts;
    this.getDirectClient();
  }

  getDirectClient() {
    this.directService.getDirectClient$({}).subscribe(result => {
      this.directNav = result.data.find(x => x.block_type === 'navigation');
      this.directFooter = result.data.find(x => x.block_type === 'footer');
      this.directBlackBox = result.data.find(x => x.block_type === 'blackbox');
    });
  }

  setCopyrightYear() {
    this.copyrightYear = new Date().getFullYear();
  }

  setScrollTop() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

  checkAuth0UserData() {
    let destination  = getURLParam('destination');
    this.auth0.userSubject().subscribe(user => {
      if (user != null) {
        // Page refresh
        if (this.browserRefresh) {
          this.browserRefresh = false;
        } else {
          this.navigateBasedOnSession(destination);
        }
      }
    });
  }

  navigateBasedOnSession(destination) {
    const msClient = this.storageService.get('msClient') ? JSON.parse(this.storageService.get('msClient')): undefined;

    if(location.pathname == '/jwt') {
      return;
    }
    this.userService.getMe().subscribe(
        user => {
          let url = '';
          let clientDomain = '';
          let buyer_default = environment.hydraEnabled ? '/catalog' : '/buyer-home';
          let buyer_destination = destination || buyer_default;

          if (user.data.roles.includes('Buyer')) {
            if (user.data.company.agreed_to_medigi) {
              if(this.is503B) {
                url = `${location.protocol}//${environment.clientDomains.outsourcingDomian}${buyer_destination}`
                clientDomain = environment.clientDomains.outsourcingDomian;
              } else {
                url = `${location.protocol}//${environment.clientDomains.medigiDomain}${buyer_destination}`
                clientDomain = environment.clientDomains.medigiDomain;
              }

              if (clientDomain !== window.location.host) {
                if (msClient?.hostkey === location.hostname) {
                  this.router.navigate([buyer_destination])
                  return;
                }
                if (window.location.pathname !== '/admin/user-manager') {
                  this.auth0.deleteIsAuthedCookie();
                }
                window.location.href = url;
              } else if(window.location.pathname === '/loading') {
                this.router.navigate([buyer_destination])
              }
            } else {
              url = `${location.protocol}//${environment.clientDomains.medshortsDomain}${buyer_destination}`
              clientDomain = environment.clientDomains.medshortsDomain;

              if (clientDomain !== window.location.host) {
                if (window.location.pathname !== '/admin/user-manager') {
                  this.auth0.deleteIsAuthedCookie();
                }
                window.location.href = url;
              } else if(window.location.pathname === '/loading') {
                this.router.navigate([buyer_destination])
              }
            }
          } else if (user.data.roles.includes('Seller')) {
            if (destination){
              let [dest, params_dict] = this.getDestinationWithParam(destination);
              this.router.navigate([dest], {queryParams: params_dict});
            } else {
              let seller_default = environment.hydraEnabled ? '/orders' : '/seller-home';
              this.router.navigate([seller_default]);
            }
          } else if (user.data.roles.includes('Administrator')) {
            this.router.navigate([destination || '/admin/home']);
          } else {
            this.router.navigate([destination]);
          }
        },
        () => this.auth0.logout()
    );
  }

  getDestinationWithParam(destination) {
    let paramsDict = {};
    var paramsArr = destination.split('?');
    if (paramsArr.length < 2) {
      return [destination, paramsDict];
    }
    const dest = paramsArr[0];
    paramsArr = paramsArr[1].split('&');
    paramsArr.forEach((param) => {
      let key_val = param.split('=');
      paramsDict[key_val[0]] = key_val[1];
    });
    return [dest, paramsDict];
  }

  setSwitchFacilityTrigger() {
    this.sessionService.switchFacilityTrigger.subscribe(result => this.showSwitchFacility = result);
  }

  setUpdatePharmaDataTrigger() {
    this.sessionService.updatePharmaDataTrigger.subscribe(result => this.showUpdatePharmaData = result);
  }

  oberserableTimer() {
    const source = timer(60, 1000);
    const abc = source.subscribe(() => {
        this.setChatlioWidgetContent();
    });
    this.subs.add(abc);
  }

  observeInactiveCounter() {
    this.$inactiveTime.subscribe((x) => this.sessionService.inactiveTime.next(x));
  }

  createInactiveCounter() {
    this.sessionTime=0;
    this.$inactiveTime = interval(1000).pipe(
        map(() => {
          this.sessionTime++;
          return this.sessionTime;
        })
    );
  }

  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  // Prevent backspace from navigating backwards and taking you out of the app...
  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    if ( evt.key === 'Backspace') {
      let doPrevent = true;

      const types = ['text', 'password', 'file', 'search',
                      'email', 'number', 'date', 'color',
                      'datetime', 'datetime-local', 'month', 'range',
                      'search', 'tel', 'time', 'url', 'week'];

      const target = (evt.target as HTMLInputElement);

      const disabled = target.disabled || (evt.target as HTMLInputElement).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
  }

  @HostListener('window:mousemove', ['$event']) 
  refreshUserState() {
    this.createInactiveCounter();
  }

}
