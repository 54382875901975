<app-direct-black-box *ngIf="loggedIn && directBlackBox && isBuyer" mode="default" [content]="directBlackBox"></app-direct-black-box>

<footer *ngIf="loggedIn">
    <div *ngIf="!hydraEnabled" class="px-2 text-center">&copy;{{copyrightYear}} Medigi LLC.</div>
</footer>

<footer *ngIf="loggedIn === false" class="static-footer">
    <div *ngIf="!mobileUser" class="container">
        <div class="footer-container">
            <div class="top-line">
                <div class="top-line-left-side">
                    <a routerLink="/">home</a>
                    <a routerLink="/pharmacy-info">pharmacies</a>
                    <a routerLink="/manufacturer-info">manufacturers</a>
                    <a routerLink="/about">about us</a>
                    <a class="green" routerLink="/registration/buyer">register pharmacy <i class="dx-icon-chevronright"></i></a>
                    <a class="green" routerLink="/registration/seller">register manufacturer <i class="dx-icon-chevronright"></i></a>
                </div>
                <div class="top-line-right-side">
                    <img src="/assets/img/logos/medigi-logo-blue.svg" alt="medigi logo">
                </div>
            </div>
            <div class="bottom-line">
                <div class="bottom-line-left-side">
                    200 East Robinson Street, Suite 1250, Orlando, FL 32801 | <a class="green"
                        href="tel: (844)-733-5633">(844) 733-5633</a> | <a class="green"
                        href="mailto: info@medigi.com">info&#64;medigi.com</a><br>
                    <small>&copy;{{copyrightYear}} medigi | All Rights Reserved</small>
                </div>
                <div class="bottom-line-right-side">
                    <img class="social" src="assets/img/static-pages/medigi/v2-images/company-logos/linkedin.svg" alt="Linked In"
                        (click)="goToSocials('https://www.linkedin.com/company/medigidirect')">
                    <img class="social" src="assets/img/static-pages/medigi/v2-images/company-logos/facebook.svg" alt="Facebook"
                        (click)="goToSocials('https://www.facebook.com/medigidirect')">
                    <img class="social" src="assets/img/static-pages/medigi/v2-images/company-logos/twitter.svg" alt="Twitter"
                        (click)="goToSocials('https://twitter.com/medigiDirect')">
                    <img class="social" src="assets/img/static-pages/medigi/v2-images/company-logos/insta.svg" alt="Instagram"
                        (click)="goToSocials('https://www.instagram.com/medigidirect')">
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mobileUser">
        <div class="mobile-footer-container">
            <div class="mobile-top-line">
                <div>
                    <img src="/assets/img/logos/medigi-logo-blue.svg" alt="medigi logo">
                </div>
                <div class="bottom-line-right-side">
                    <img src="assets/img/static-pages/medigi/v2-images/company-logos/linkedin.svg" alt="Linked In"
                        (click)="goToSocials('https://www.linkedin.com/company/medigidirect')">
                    <img src="assets/img/static-pages/medigi/v2-images/company-logos/facebook.svg" alt="Facebook"
                        (click)="goToSocials('https://www.facebook.com/medigidirect')">
                    <img src="assets/img/static-pages/medigi/v2-images/company-logos/twitter.svg" alt="Twitter"
                        (click)="goToSocials('https://twitter.com/medigiDirect')">
                    <img src="assets/img/static-pages/medigi/v2-images/company-logos/insta.svg" alt="Instagram"
                        (click)="goToSocials('https://www.instagram.com/medigidirect')">
                </div>
            </div>
            <div class="mobile-middle-line mt-3">
                <div class="links">
                    <a routerLink="/">home</a>
                    <a routerLink="/pharmacy-info">pharmacies</a>
                    <a routerLink="/manufacturer-info">manufacturers</a>
                    <a routerLink="/about">about us</a>
                </div>
                <div class="links">
                    <a class="green" routerLink="/registration/buyer">register pharmacy <i class="dx-icon-chevronright"></i></a>
                    <a class="green" routerLink="/registration/seller">register manufacturer <i class="dx-icon-chevronright"></i></a>
                </div>
            </div>
            <div class="bottom-line mt-3 mb-3">
                <div class="bottom-line-left-side">
                    200 East Robinson Street, Suite 1250, Orlando, FL 32801 | <a class="green"
                        href="tel: (844)-733-5633">(844) 733-5633</a> | <a class="green"
                        href="mailto: info@medigi.com">info&#64;medigi.com</a><br>
                    <small>&copy;{{copyrightYear}} medigi | All Rights Reserved</small>
                </div>
            </div>
        </div>
    </div>
</footer>
