import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { StorageType } from '../models/constants';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  constructor(private storageService: StorageService) {
    this.tooltipsSubject = new BehaviorSubject<any>(JSON.parse(this.storageService.get(StorageType.tooltip)));
    this.tooltips = this.tooltipsSubject.asObservable();
  }

  public get tooltipsValue(): any {
    return this.tooltipsSubject.value;
  }

  private tooltipsSubject: BehaviorSubject<any>;
  public tooltips: Observable<any>;

  private tooltipData = {
    LISTING_SHORTAGES_PICK: {
      title: 'Pick a shortage',
      // tslint:disable-next-line: max-line-length
      text: 'If your listing is associated with a current shortage, select it here. This will enable customers to find it easily when browsing a specific shortage.'
    },
    LISTING_INGREDIENTS: {
      title: 'Add Ingredients',
      text: 'Specify each ingredient and its concentration. If you have additional ingredients, click the plus sign.'
    },
    LISTING_CATEGORIES: {
      title: 'Assign Categories',
      text: 'You must pick one or more categories for your listing. This will enable buyers to use our catalog to find your products.'
    },
    LISTING_PACKAGING: {
      title: 'Package Description',
      text: 'Provide a detailed description of your packaging, for example \'25 Vial In 1 Tray > 10 mL In 1 Vial\''
    },
    LISTING_PKG_TYPE: {
      title: 'Thumbnail',
      text: 'Shown in search results'
    },
    LISTING_PRICE: {
      title: 'WAC Price',
      text: 'This should be your published wholesale price. It will be used to calculate your discount when you price individual lots.'
    },
    LISTING_PRIVATE_LABEL: {
      title: 'Private Label',
      text: 'Check this box if the listing is GPO Private Label, for example Nova Plus. This is important for 340b facilities.'
    },
    LISTING_MIN_PACKS: {
      title: 'Minimum',
      text: 'The smallest total number of packs (from multiple lots) required for an order.'
    },
    LISTING_CASE_LOCK: {
      title: 'Sell By Full Case Only',
      // tslint:disable-next-line: max-line-length
      text: 'If checked, then this product will only be sold in full cases as per your specified case size. Remainders (last incomplete case in a lot) can still be used to make up minimum order quantity.'
    },
    LISTING_NDC: {
      title: 'Look up an existing NDC number',
      text: 'This will fill in this form for you from the federal NDC registry. You will still be able to make further edits.'
    },
    LISTING_INTREF: {
      title: 'Internal Reference',
      // tslint:disable-next-line: max-line-length
      text: 'This can be used to easily connect this listing to your internal systems, for example by providing a product identifier or SKU here. It will not be shown to buyers.'
    },
    LISTING_EXPIRY: {
      title: '',
      text: 'Optional, but if you provide it the listing will automatically be removed from the site on this date.'
    },
    LISTING_DESCRIPTION: {
      title: '',
      text: 'Optional notes about your listing. Detail any special features, or provide information about bulk discounting for example.'
    },
    LISTING_AVAILABILITY: {
      title: 'Stock Availability',
      text: 'Since we do not currently link to your internal inventory, this is to provide an indication to buyers of your stock levels.'
    },
    LISTING_WHENAVAIL: {
      title: 'Product Availability',
      // tslint:disable-next-line: max-line-length
      text: 'Provide the date on which you expect to be able to ship this product. If it is currently available, just click \'Now\'. Alternatively provide a Lead Time.'
    },
    LISTING_LEADTIME: {
      title: 'Product Availability',
      text: 'If you do not typically stock this product, but can make it on request, provide the lead time from date of order here.'
    },
    LISTING_IMAGES: {
      title: 'Upload Images',
      // tslint:disable-next-line: max-line-length
      text: 'You must provide at least one image for your listing. It is recommended that you provide images of the container and the label. You cannot delete the last image - if you need to change it please add another image and delete the first one.<br/><br/>Images will be automatically resized.'
    },
    LISTING_DOCUMENTS: {
      title: 'Upload Documents',
      // tslint:disable-next-line: max-line-length
      text: 'You must provide at least one document for your listing. This should be a product data sheet with complete information about your listing, but you can also upload test procedures or any other relevant information.<br/><br/>.doc or .pdf formats are allowed.'
    },
    LISTING_EXT_DOCUMENTS: {
      title: 'External Documents',
      // tslint:disable-next-line: max-line-length
      text: 'A list of external documents that are associated with this listing. These can be viewed by buyers. <br> To add external links, please, contact administrator via info@medigi.com or chat.'
    },
    LISTING_EACHES_SIZE: {
      title: 'Eaches per Pack / Carton',
      text: 'Number of individual units per pack or carton. Enter one for singly packaged units.'
    },
    LISTING_CASE_SIZE: {
      title: 'Packs / Cartons per Case',
      // tslint:disable-next-line: max-line-length
      text: 'If your product is packaged in cases, please specify the number of cartons / packs per case here. Shipping will then be calculated per case. If your items are individually packaged, enter 1 here.'
    },
    LISTING_SHELF_LIFE: {
      title: 'Shelf Life',
      text: 'The number of days your product may be stored for before it reaches it\'s beyond use.'
    },
    LISTING_PACKAGE_SIZE: {
      title: 'Package Size',
      text: 'Enter the fill amount or volume of the product, e.g. 5mL or 10g.'
    },
    NEEDS_ATTENTION_BUYER: {
      title: 'Order Status',
      // tslint:disable-next-line: max-line-length
      text: 'This order has been modified by the seller (for example changing the quantity, shipping date or price.)<br/><br/>You need to review the changes and any messages and either agree to or cancel the order.'
    },
    NEEDS_ATTENTION_SELLER: {
      title: 'Order Status',
      // tslint:disable-next-line: max-line-length
      text: 'You have modified this order (for example changing the quantity, shipping date or price.)<br/><br/>The buyer will need to review the changes and any messages and either agree to or cancel the order before you can proceed.'
    },
    REG_PRIMARY_CONTACT: {
      title: 'Primary Contact',
      // tslint:disable-next-line: max-line-length
      text: 'Please provide the person to contact at this pharmacy regarding any orders placed for it, for example the primary buyer or the pharmacy contact. This person can be, but does not have to be a Medshorts user. If necessary, you can create accounts for more users below, or on the user management page of your profile.'
    },
    REG_VERIFICATION_CONTACT_NAME: {
      title: 'What should I enter here?',
      text: 'Please provide the primary contact name at your institution, in case we are unable to reach you.'
    },
    REG_VERIFICATION_CONTACT_EMAIL: {
      title: 'What should I enter here?',
      text: 'Please provide the primary contact email at your institution, in case we are unable to reach you.'
    },
    REG_VERIFICATION_CONTACT_PHONE: {
      title: 'What should I enter here?',
      text: 'Please provide the primary contact phone at your institution, in case we are unable to reach you.'
    },
    REG_ADD_BUYER: {
      title: 'Add Additional Buyers',
      // tslint:disable-next-line: max-line-length
      text: 'You may create additional users here. They will receive an invitation to the site and be set up as buyers for this pharmacy under your company account.'
    },
    REG_STATE_LICENSE: {
      title: 'State License Information is Required',
      // tslint:disable-next-line: max-line-length
      text: 'Please provide your license number here - it will be required to actually make any purchases. You can update this on your Profile page later if necessary.'
    },
    REG_STATE_LICENSE_EXP: {
      title: 'State License Information is Required',
      // tslint:disable-next-line: max-line-length
      text: 'Please provide your license expiration here - it will be required to actually make any purchases. You can update this on your Profile page later if necessary.'
    },
    REG_DEA: {
      title: 'DEA Number for this pharmacy',
      // tslint:disable-next-line: max-line-length
      text: 'This will be verified against the DEA database and used to fill in your pharmacy address.<p>Without a valid DEA number you will not be able to proceed.</p>'
    },
    REG_NPI: {
      title: 'NPI Number is optional',
      text: 'If provided, it will be used to look up your address information.'
    },
    REG_INVOICE_ADDRESS: {
      title: 'Invoice Address',
      // tslint:disable-next-line: max-line-length
      text: '<p>This is optional - if you do not provide it now, you can update it on your profile later.</p><p>It will be required to actually place an order.</p>'
    },
    ADMIN_INVOICE_ADDRESS: {
      title: 'Invoice Address',
      text: '<p>This is optional - if not provided, the invoice address of the parent company will be used</p>'
    },
    NO_PRICE_DISPLAY: {
      title: 'Why can\'t I see pricing for this listing?',
      text: 'List price for this listing is only displayed once you are a registered user.'
    },
    PRICE_DISPLAY_MSS: {
      title: 'Medigi Sourcing Prices.',
      // tslint:disable-next-line: max-line-length
      text: 'These are list prices and may be higher or lower on order processing, depending on supplier order quantity discounts and product beyond use dating.'
    },
    DEA_RESTRICTION: {
      title: 'Restricted Product',
      text: 'This is a DEA Schedule II product, as as such a validated DEA license must be uploaded to your profile before purchase.'
    },
    PRODUCT_REQUESTS: {
      title: 'What are Product Requests?',
      // tslint:disable-next-line: max-line-length
      text: '<p>Not all of our suppliers\' products are listed on Medigi at all times.</p><p>If you need a custom formulation or packaging, and don\'t see it listed, you can use Product Requests to send a message to all our suppliers and the Medigi sourcing team.</p><p>You will receive a response through the site messaging system.</p>'
    },
    EMAIL_REQUESTS: {
      title: 'Email Alert button',
      // tslint:disable-next-line: max-line-length
      text: '<p>Ensure that you are receiving order status updates, price drop alerts, and new pharmacy-specific matches by sending info@medigi.com a quick note from your inbox.</p>'
    },
    CATALOG_HISTORY: {
      title: 'Purchase History',
      // tslint:disable-next-line: max-line-length
      text: '<p>NDCs with an AMU next to them have been purchased by your institution within the last year..</p><p>Click here to only show listings from your institution purchase history.</p><p>Click the <i class=\'glyphicon glyphicon-list btn btn-xs btn-info\'></i> button next to the NDC number to view the purchase history details.</p>'
    },
    LICENSE_UPLOAD: {
      title: 'State License Upload',
      // tslint:disable-next-line: max-line-length
      text: '<p>Please upload a clear copy of your current state license. We will track expiry and notify you when it needs to be re-uploaded.</p>'
    },
    RESELLER_CERTIFICATE: {
      title: 'Reseller Certificate Upload',
      text: '<p>Please upload a clear copy of your current reseller certificate.</p>'
    },
    MANAGE_BUDGET: {
      title: 'Managing your Pharmacy\'s budget',
      text: '<p>Enable the manage budget feature to track the amount spent between two dates.</p>'
    }
  };

  public getRaw(): any {
    return this.tooltipData;
  }

  public loadToStorage() {
    this.storageService.set(StorageType.tooltip, JSON.stringify(this.tooltipData));
  }
}
