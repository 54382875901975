<dx-popup
  class="popup"
  [width]="width"
  [title]="title"
  [dragEnabled]="true"
  [showTitle]="!!title"
  [minWidth]="400"
  [height]="height"
  [hideOnOutsideClick]="!title || hideOnOutsideClick"
  [position]="position"
  [(showCloseButton)]="showCloseButton"
  [(visible)]="visible"
  (onHiding)="onHidingInner($event)"
  (onHidden)="onHidden.emit([$event])"
  (onContentReady)="onContentReady.emit([$event])"
  (onDisposing)="onDisposing.emit([$event])"
  (onInitialized)="onInitialized.emit([$event])"
  (onOptionChanged)="onOptionChanged.emit([$event])"
  (onResize)="onResize.emit([$event])"
  (onResizeEnd)="onResizeEnd.emit([$event])"
  (onResizeStart)="onResizeStart.emit([$event])"
  (onShowing)="onShowing.emit([$event])"
  (onShown)="onShown.emit([$event])"
  (onTitleRendered)="onTitleRendered.emit([$event])"
>
  <ng-content></ng-content>
</dx-popup>
