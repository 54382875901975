<div *ngIf="isHeaderButton">
  <dx-button
    id="chart-button"
    *ngIf="isHeaderButton && hydraEnabled"
    type="default"
    stylingMode="text"
    icon="chart-dark"
    hint="View Facility Stats"
    (onClick)="openStatsPanel()"
    class="mr-1">
  </dx-button>
</div>

<div id="user-panel-stats" *ngIf="isStatsPanelOpen">
  <div class="stats-panel">
    <div class="d-flex flex-row justify-content-between align-items-center pl-2 pt-2">
      <h4 class="mb-0"><b>Facility Stats</b></h4>
      <dx-button class="mr-1" icon="clear" type="text" (onClick)="openStatsPanel()"></dx-button>
    </div>
    <hr class="mb-1 mt-1">
    <div class="d-flex justify-content-center">
      <div>
        <div class="stat-box">
          <p class="stat-title">Total Spend</p>
          <p class="stat">{{facilityStats?.totalSpend | usdCurrency}}</p>
        </div>
        <div class="stat-box">
          <p class="stat-title">Estimated Savings</p>
          <p class="stat">{{facilityStats?.savings | usdCurrency}}</p>
        </div>
      </div>
      <div>
        <div class="stat-box">
          <p class="stat-title">Percent Saved</p>
          <p class="stat">{{facilityStats?.savingsPct | percent}}</p>
        </div>
        <div class="stat-box">
          <p class="stat-title">Catalog Matches</p>
          <p class="stat">{{facilityStats?.catalogMatches}} / {{facilityStats?.catalogCount}}</p>
        </div>
      </div>
    </div>
    <div class="ml-2 mt-2">
      <h5 class="mb-2"><b>User Rewards</b></h5>
      <div>
        <div class="">
          <span class="progress-bar"></span>
          <span class="progress-bar-progress" [ngStyle]="{'width': progressWidth + '%'}"></span>
        </div>
        <div>
          <p>{{userRewardOrderCount || 0}}/15 Points <u class="outbound-link" (click)="openMerchStoreLink()">Vist Store</u></p>
        </div>
      </div>

    </div>
  </div>
</div>

<app-overlay *ngIf="isStatsPanelOpen" (closePopup)="isStatsPanelOpen = !isStatsPanelOpen"></app-overlay>