import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  constructor(private http: HttpClient) {}

  public getProposals(request_id) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/proposal/${request_id}`);
  }

  public placeProposal(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/proposal`, data);
  }

  public acceptProposal(data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/proposal/accept/${data.proposal_id}`, data);
  }

  public declineProposal(data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/proposal/decline/${data.id}`, data);
  }

  public calc(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/proposal/calc`, data);
  }

}
