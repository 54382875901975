import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { CatalogConfigurations, CatalogConfigurationsService } from 'src/app/shared/services/catalog-configurations.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit {
  @Input() searchValue: any = '';
  @Input() isCatalogComponent: boolean = false;

  catalogConfigurations: CatalogConfigurations = null;
  
  constructor(
    private router: Router,
    public catalogConfigsService: CatalogConfigurationsService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.catalogConfigsService.get().subscribe(result => {
      this.catalogConfigurations = result;
      this.searchValue = this.catalogConfigurations.searchText;
    });
  }

  setSearchText() {
    this.catalogConfigsService.searchText = this.searchValue;
  }

  searchAndRedirect() {
    this.searchValue = this.catalogConfigurations.searchText;
    this.router.navigate(['/catalog']);
  }

  searchClick() {
    this.catalogConfigsService.search();
  }

}
