import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, NgModule, SimpleChanges } from '@angular/core';
import { Auth0Service } from 'src/app/auth/auth0.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AppInfoService } from 'src/app/shared/services/app-info.service';
import { Landing } from 'src/app/shared/models/landing';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxPopupModule, DxTextBoxModule, DxToolbarModule } from 'devextreme-angular';
import { HelpDropdownBtnModule } from './help-dropdown-btn/help-dropdown-btn.component';
import { UserPanelModule } from './user-panel/user-panel.component';
import { CartPanelModule } from './cart-panel/cart-panel.component';
import { UserService } from 'src/app/shared/services/user/user.service';
import { CartSideModule } from 'src/app/shared/components/cart-side/cart-side.component';
import { SwitchFacilitiesModule } from 'src/app/shared/components/switch-facilities/switch-facilities.component';
import { environment } from 'src/environments/environment';
import { AdminService } from 'src/app/shared/services/admin/admin.service';
import { SearchPanelModule } from './search-panel/search-panel.module';
import { DocumentUploadModule } from 'src/app/shared/components/document-upload/document-upload.component';
import { CalibrationFileUploadModule } from 'src/app/shared/components/calibration-file-upload/calibration-file-upload.module';
import { NotificationsModule } from 'src/app/shared/components/notifications/notifications.component';
import { FacilityAndUserStatsModule } from 'src/app/shared/components/facility-and-user-stats/facility-and-user-stats.component';
import { SessionService } from 'src/app/shared/services/session.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { UserProfileModule } from 'src/app/shared/components/user-profile/user-profile.component';
import { FinancialStatementsUploaderModule } from 'src/app/shared/components/financial-statements-uploader/financial-statements-uploader.module';



@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  @Input() menuToggle = true;
  @Input() menuToggleEnabled = false;
  @Input() title: string;

  cartOpened: boolean;
  items_in_cart = 0;
  cart: any;
  roles: Array<string> = [];

  userMenuItems: Array<any> = [];
  isImpDataSub = false;

  isDarkMode = false;
  hasDarkMode = false;
  landingConfigs: Landing = null;

  isBuyer = false;
  isSeller = false;

  isImpersonating = false;
  auth0UserData: any;
  userId: number;
  isDocumentUploadOpen = false;
  documentUploadPopupTitle = 'Upload Your Documents';
  documentUploadPopupWidth: any = 'auto';
  documentUploadFileType;
  facilityId;
  companyId;
  user;

  hydraEnabled = environment.hydraEnabled;
  isProfilePopupOpen: boolean = false;
  
  // Unsubscribe so subscriptions won't fire after view is destroyed
  private onDestroy$: Subject<void> = new Subject<void>();
  subscriptions: Subscription = new Subscription();

  constructor(public auth0: Auth0Service,
              public adminService: AdminService,
              public appService: AppInfoService,
              private userService: UserService,
              private sessionService: SessionService,
              private router: Router,
              private googleAnaylyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.getAppConfigs();
    this.setRolesAndMenu();
    this.impersonationCheck();
    this.watchPrimaryFacilityUpdatedTrigger();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.menuToggle = this.menuToggle;
  }

  watchPrimaryFacilityUpdatedTrigger() {
    const s = this.sessionService.primaryFacilityUpdatedTrigger.subscribe(
      () => this.setRolesAndMenu()
    );
    this.subscriptions.add(s);
  }

  getAppConfigs() {
    this.appService.get().subscribe(
      result => {
        this.landingConfigs = result;
        this.isDarkMode = result.theme.search('dark') !== -1;
      }
    );
  }

  async setRolesAndMenu() {
    this.userService.getMe().subscribe(
      user => {
        this.user = user.data;
        this.roles = user.data.roles;
        this.isBuyer = this.hasRole('Buyer');
        this.isSeller = this.hasRole('Seller');
        this.facilityId = user.data.primary_facility_id;
        this.companyId = user.data.company_id;
        this.setUserMenu();
      });
  }

  setUserMenu() {
    if(this.isBuyer){
      this.userMenuItems = [
        {
          text: 'Profile',
          icon: 'profile',
          onClick: () => { this.isProfilePopupOpen = true; }
        },
        {
          text: 'Upload Your Documents',
          icon: 'file',
          onClick: () => { this.isDocumentUploadOpen = true;}
        },
        {
          text: 'Logout',
          icon: 'runner',
          onClick: () => { this.unimpersonateAndLogout(); }
        }
      ];
    }
    else {
      this.userMenuItems = [
        {
          text: 'Profile',
          icon: 'profile',
          onClick: () => { this.isProfilePopupOpen = true; }
        },
        {
          text: 'Logout',
          icon: 'runner',
          onClick: () => { this.unimpersonateAndLogout(); }
        }
      ];
    }
  }

  updateDocumentUploadPopup(fileType){
    this.documentUploadPopupWidth = 800;
    this.documentUploadFileType = fileType;
    if (fileType === 'SL') {
      this.documentUploadPopupTitle = "Upload Your State License"
    }
    else if (fileType === 'DEA') {
      this.documentUploadPopupTitle = "Upload Your DEA License"
    } 
    else if (fileType === 'RX') {
      this.documentUploadPopupTitle = "Upload Purchase History"
    }
  }

  unimpersonateAndLogout() {
    if(this.isImpersonating) { 
      this.unimpersonate();
    }
    this.auth0.logout();
  }

  hasRole(role: string): boolean {
    if (this.roles && this.roles.length > 0) {
      return this.roles.includes(role);
    } else {
      return false;
    }
  }

  closeCar(event) {
    this.cartOpened = event;
  }

  logIn = () => { this.auth0.login(); };

  switchTheme = () => {
    let theme = this.appService.theme;
    const hasDark = theme.search('dark') !== -1;
    const hasLight = theme.search('light') !== -1;

    if (hasDark) {
      this.setTheme(false, theme = theme.replace('dark', 'light'));
    } else if (hasLight) {
      this.setTheme(true, theme.replace('light', 'dark'));
    } else {
      this.isDarkMode = false;
    }
  }

  setTheme(isDark: boolean, color: string) {
    this.isDarkMode = isDark;

  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  goHome() {
    this.userService.getMe().subscribe(
      user => {
        if (user.data.roles.includes('Buyer')) {
          this.router.navigate(['/catalog']);
        }
        if (user.data.roles.includes('Seller')) {
          this.router.navigate(['/seller-home']);
        }
      });
  }

  impersonationCheck() {
    this.auth0.userProfile$.subscribe(result => {
      this.auth0UserData = result;

      this.userId = this.auth0UserData[environment.auth0.appMetadata]?.id;
      const impersonatorId = this.auth0UserData[environment.auth0.appMetadata]?.impersonator_id;
      this.isImpersonating = impersonatorId && impersonatorId != this.userId;
    });
  }

  unimpersonate() {
    const impersonatorId = this.auth0UserData[environment.auth0.appMetadata]?.impersonator_id;
    this.auth0.deleteIsAuthedCookie();
    this.adminService.userImpersonate(impersonatorId, impersonatorId);
  }

  openedNotifications(){
    this.googleAnaylyticsService.eventEmitter(this.user.email, 'Opened Notifications Side Menu');
  }

  openedStats(){
    this.googleAnaylyticsService.eventEmitter(this.user.email, 'Opened Notifications Facility Stats');
  }

  openedSwitchFacility(){
    this.googleAnaylyticsService.eventEmitter(this.user.email, 'Opened Switch Facility');
  }

  closingPopup() {
    this.isProfilePopupOpen = false;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    CartPanelModule,
    HelpDropdownBtnModule,
    CartSideModule,
    SwitchFacilitiesModule,
    DxTextBoxModule,
    SearchPanelModule,
    DxPopupModule,
    DocumentUploadModule,
    CalibrationFileUploadModule,
    NotificationsModule,
    FacilityAndUserStatsModule,
    UserProfileModule,
    FinancialStatementsUploaderModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
