import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/services/user/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HydraGuard  {

  constructor(private userService: UserService, private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean> | Promise<boolean | UrlTree> | boolean> {

    const hydraEnabled = environment.hydraEnabled;
    const user = await this.getMe();

    if (!hydraEnabled) {
      return true;
    }
    if (user.is_admin) {
      return true;
    }
    if (user.is_buyer) {
      if (next.data?.hydraEnabledFeatrue === undefined || next.data.hydraEnabledFeatrue) {
        return true;
      }
      this.router.navigate(['/catalog']);
    }

    return true;
  }

  async getMe() {
    const currentUser = await this.userService.getMe().toPromise();
    return currentUser.data;
  }

}
