import { Component, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-medshorts-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})


export class MedshortsFooterComponent {

  copyrightYear;

  constructor(private router: Router){}

  ngOnInit() {
    this.copyrightYear = new Date().getFullYear();
  }

  navigateToSubPages(page){
    if (page === 'register'){this.router.navigate(['/register']);}
    else if (page === 'contact'){this.router.navigate(['/contact']);}
    else if (page === 'demo'){ this.router.navigate(['/request-demo']);}
  }
}


@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [ MedshortsFooterComponent ],
  exports: [ MedshortsFooterComponent ]
})
export class MedshortsFooterModule { }