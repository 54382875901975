import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth0Service } from './auth0.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class Auth0Guard  {

  constructor(private auth: Auth0Service) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.auth.isAuthenticated$.pipe(
      tap(
        loggedIn => {
          if (!loggedIn) {
            this.auth.login(state.url);
          }
        })
      );
  }

  checkCommonRoles(roteRols, userRoles): boolean {
    roteRols.filter(role => { return userRoles.indexOf(role) > -1; });
    return false;
  }

}
