import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
  NgModule
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {Document} from 'src/app/shared/models/document';
import {NotificationService} from '../../services/notification.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DxFileUploaderModule, DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { MsTooltipModule } from '../ms-tooltip/ms-tooltip.component';
import { DocumentService } from '../../services/documet/document.service';


@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})

export class DocumentUploadComponent implements OnInit, OnChanges {

  @Input() showDelete: boolean;
  @Input() context: any;
  @Input() listingId: number;
  @Input() companyId: string;
  @Input() facilityId: string;
  @Input() orderId: string;
  @Input() type: string;
  @Input() limit: number = 1;
  @Input() listingImage: string;
  @Input() listingImageUrl: string;
  @Input() email: string;
  @Input() documents: Document[] = [];
  @Input() disabled: boolean;
  @Input() preview = false;
  @Input() allowedFileExtensions = ['.pdf', '.jpg', '.jpeg', '.png', '.PDF', '.JPG', '.JPEG', '.PNG'];
  @Input() persistUploadButton = false;
  @Input() disableUpload = false;
  @Output() update = new EventEmitter<boolean>();
  allowedFileExtensionsAllCases: any[] = [];

  filteredDocuments: any[] = [];

  filesToUpload: any[] = [];

  askDeleteVisible = false;

  selectedDocument: any;

  constructor(private sanitizer: DomSanitizer,
              private documentService: DocumentService,
              private notifyService: NotificationService) {}

  ngOnInit() {
    this.getReadebleExtentions();
    this.getDocuments();
    this.filteredDocuments = [];
  }

  private getReadebleExtentions() {
    this.allowedFileExtensionsAllCases = this.allowedFileExtensions.map(extension => extension.toLowerCase());
    this.allowedFileExtensionsAllCases = this.allowedFileExtensionsAllCases.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const facilityIdChange = changes['facilityId'];
    if (typeof facilityIdChange?.previousValue !== 'undefined') {
      this.getDocuments();
    }
  }

  getDocuments() {
    const search = {
      context: this.context,
      type: this.type,
    };

    if (this.context === 'listing' && this.type !== 'global_listing') {
      search['listing_id'] = this.listingId;
    } else if (this.context === 'listing' && this.type === 'global_listing') {
      search['company_id'] = this.companyId;
    } else if (this.context === 'company' && this.type === 'open_document') {
      search['company_id'] = this.companyId;
    } else if (this.context === 'profile') {
      search['company_id'] = this.companyId;
    } else if (this.context === 'order') {
      search['order_id'] = this.orderId;
    } else if (this.context === 'facility') {
      search['facility_id'] = this.facilityId;
      search['company_id'] = this.companyId;
    }

    this.documentService.get(search).subscribe(
      result => {
        this.documents = result.data;
        this.disabled = this.documents.length 
                        && this.context === 'facility'
                        && ['terms_pdf', 'state_license', 'reseller_certificate'].includes(this.type);
        this.filterDocuments();
      },
      error => {
        this.notifyService.showNotify(error, 'error');
      }
    );
  }

  documentURL(url): SafeResourceUrl {
    if (url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return null;
  }

  filterDocuments() {
    this.filteredDocuments = this.documents.filter(document => document.type === this.type);
    if (this.limit) {
      this.filteredDocuments = this.filteredDocuments.slice(0, this.limit);
    }
    return this.filteredDocuments;
  }

  documentCurrentDelete() {
    this.askDeleteVisible = false;
    this.documentService.delete(this.selectedDocument.id)
      .subscribe(
        () => {
          this.notifyService.showNotify('Document was deleted', 'success');
          this.getDocuments();
          this.update.emit(true);
        },
        error => {
          this.notifyService.showNotify(error, 'error');
        }
      );
  }

  documentOpen = (document) => window.open(document.url, '_blank').focus();

  uploadFile() {
    if (!this.filesToUpload.length) {
      return;
    }
    const extension = this.filesToUpload[0].name.split('.').pop();

    if (!this.allowedFileExtensions.includes(`.${extension}`)) {
      this.notifyService.showNotify('This file type is not supported', 'error');
      this.filesToUpload = [];
      return;
    }

    const formData = new FormData();

    var params = {};
    if (this.context === 'listing' && this.type !== 'global_listing') {
      params['listing_id'] = String(this.listingId);
    } else if (this.context === 'profile') {
      params['company_id'] = this.companyId;
    } else if (this.context === 'order') {
      params['order_id'] = this.orderId;
    } else if (this.context === 'derma') {
      params['email'] = this.email;
      params['product'] = 'scarpack';
    } else if (this.context === 'facility' && ['state_license', 'reseller_certificate', 'Calibration_File', 'terms_pdf', 'dea_license'].includes(this.type)) {
      params['facility_id'] = this.facilityId;
      params['company_id'] = this.companyId;
    } else if (this.context === 'facility' && this.type !== 'terms_pdf' && 'Calibration_File' && 'state_license') {
      params['facility_id'] = this.facilityId;
    } else if (this.context === 'company' && this.type === 'open_document') {
      params['company_id'] = this.companyId;
    } else if (this.context === 'listing' && this.type === 'global_listing') {
      params['company_id'] = this.companyId;
    }
    params['context'] = this.context;
    params['type'] = this.type;
    formData.append('params', JSON.stringify(params));
    formData.append('doc', this.filesToUpload[0]);

    this.documentService.upload(formData).subscribe(
      () => {
        this.filesToUpload = [];
        this.getDocuments();
        this.update.emit(true);
      },
      error => this.notifyService.showNotify(error, 'error')
    );
  }

  askDelete(document) {
    this.selectedDocument = document;
    this.askDeleteVisible = true;
  }

  noClick() {
    this.askDeleteVisible = false;
  }
}



@NgModule({
  imports: [
    CommonModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxPopupModule,
    MsTooltipModule,
    RouterModule
  ],
  declarations: [ DocumentUploadComponent ],
  exports: [ DocumentUploadComponent ]
})
export class DocumentUploadModule { }
