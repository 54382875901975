import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { finalize, share } from 'rxjs/operators';

@Injectable()
export class ApiDupeInterceptor implements HttpInterceptor {
  private cacheObservable = new Map<string, Observable<any>>();

  private endpoints = new Set([
    '/services/listing/catalog/purchase_recommendations'
  ]);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.replace(environment.api_url_v1, '');

    if (this.endpoints.has(url)) {
      let observable: Observable<any>;
      
      const cachedObservable = this.cacheObservable.get(req.url);
      if (cachedObservable) {
        observable = cachedObservable;
      } else {
        observable = next.handle(req).pipe(
          share(),
          finalize(() => this.cacheObservable.delete(url))
        );
        this.cacheObservable.set(req.url, observable);
      }
      return observable;
    }
    
    return next.handle(req);
  }
}
