<nav class="navbar navbar-expand-lg navbar-dark">
  <a class="navbar-item" routerLink="/">
    <img src="assets/img/logos/ms_full_logo_white.png" height="40" class="d-inline-block align-top" alt="logoms">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-center align-items-center" id="navbarSupportedContent">
    <ul class="navbar-nav align-content-around">
      <li class="nav-item">
        <a class="nav-link text-white nav-font-size" routerLink="/pharmacy-info" routerLinkActive="black">Pharmacies</a>
      </li>
      <hr>
      <li class="nav-item">
        <a class="nav-link text-white nav-font-size" routerLink="/manufacturer-info" routerLinkActive="black">Manufacturers</a>
      </li>
      <hr>
      <ul class="navbar-nav position-relative mr-5">
        <li class="nav-item dropdown">
          <a class="nav-link text-white nav-font-size dropdown-toggle" data-toggle="dropdown" routerLink="/about" routerLinkActive="black">About</a>
          <ul class="dropdown-menu bg-white">
            <!-- <li><a class="nav-link text-black-50" routerLink="/public-news">News</a></li> -->
            <li><a class="nav-link text-black-50" routerLink="/contact">Contact Us</a></li>
            <!-- <li><a class="nav-link text-black-50" routerLink="/careers">Careers</a></li> -->
          </ul>
        </ul>
        <hr>
      </ul>
    <ul class="navbar-nav position-relative">
      <li class="nav-item dropdown">
        <button class="btn btn-link nav-link dropdown-toggle text-white nav-font-size" type="button" data-toggle="dropdown">Registration</button>
        <ul class="dropdown-menu bg-white text-black-50">
          <li><a class="nav-link text-black-50" routerLink="/registration/buyer">Pharmacy</a></li>
          <li><a class="nav-link text-black-50" routerLink="/registration/seller">Manufacturer</a></li>
        </ul>
      </li>
      <hr>
      <li class="nav-item">
        <button id="qsLoginBtn" class="btn btn-link nav-link text-white nav-font-size" (click)="auth0.login()">
          Login
        </button>
      </li>
    </ul>
    <hr>
    <button class="btn btn-link nav-link text-white nav-font-size demo" type="button" routerLink="/request-demo">Demo</button>
  </div>
</nav>