<div *ngIf="profile" id="user-panel-id" class="user-panel">
    <div class="user-info">
        <div class="image-container">
            <app-user-picture-preview [profile]="profile" [firstName]="firstName" [lastName]="lastName" size="30"></app-user-picture-preview>
        </div>
        <div class="user-name">{{firstName}} {{lastName}}</div>
    </div>
    <dx-context-menu
        *ngIf="menuMode === 'context'"
        [items]="menuItems"
        target=".user-button"
        showEvent="dxclick"
        width="170px"
        [position]="{ my: 'top center', at: 'bottom center' }"
        cssClass="user-menu">
    </dx-context-menu>
    <dx-list
        *ngIf="menuMode === 'list'"
        class="dx-toolbar-menu-action"
        [items]="menuItems">
    </dx-list>
    
</div>
