import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RfpService {

  constructor(private http: HttpClient) {}

  CONTRACT_LENGTH = [2, 3, 4, 5, 6, 9, 12];

  PERIOD_LIST = [{
    value: 'biweekly',
    name: 'Every 2 Weeks'
    },{
    value: 'monthly',
    name: 'Monthly'
  },{
    value: 'quarterly',
    name: 'Quarterly'
  }];

  PERIOD_MAP = {
    biweekly: '2 Weeks',
    monthly: 'Month',
    quarterly: 'Quarterly',
  };

  PERIOD_DURATION_MAP = {
    biweekly: 2,
    monthly: 1,
    quarterly: 1/3
  };

  public getRequests(params=null) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/rfp`, {params: params});
  }

  public getRequest(id) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/rfp/${id}`);
  }

  public placeRequest(data) {
    return this.http.post<any>(`${environment.api_url_v1}/services/order/rfp`, data);
  }

  public updateRfp(id, data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/rfp/${data.id}`, data);
  }

  public declineRequestSeler(id, data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/rfp/decline/${id}`, data);
  }

  public getRecommendations() {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/rfp/recommendations`);
  }

  public resendRfpPlacedEmails(role, rfpId) {
    return this.http.get<any>(`${environment.api_url_v1}/services/order/emails/resend_rfp_placed/${role}/${rfpId}`);
  }

  public extendRfps(data) {
    return this.http.put<any>(`${environment.api_url_v1}/services/order/rfp/extend`, data);
  }

}
