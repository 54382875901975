<div class="row mb-2">
  <div class="col-md-4"><span>Product Name:</span></div>
  <div class="col-md-8">{{ listing?.description }}</div>
</div>
<div class="row mb-2">
  <div class="col-md-4"><span>Size:</span></div>
  <div class="col-md-8"><span *ngIf="listing?.csp && listing?.pack_size && listing?.listing_package_type">{{ listing?.csp }}  X {{ listing?.pack_size }} {{ listing?.listing_package_type }}</span></div>
</div>
<div class="row mb-2">
  <div class="col-md-4"><span>Category:</span></div>
  <div class="col-md-8">{{ listing?.hic2_desc }}</div>
</div>
<div class="row mb-2">
  <div class="col-md-4"><span>Pharm. Classification:</span></div>
  <div class="col-md-8">{{ listing?.hic_desc }}</div>
</div>
<div class="row mb-2">
  <div class="col-md-4"><span>Therapeutic Class:</span></div>
  <div class="col-md-8">{{ listing?.hic3_desc }}</div>
</div>
<div class="row mb-2">
  <div class="col-md-4"><span>Route:</span></div>
  <div class="col-md-8">{{ listing?.route }}</div>
</div>
<div class="row mb-2">
  <div class="col-md-4"><span>Storage Conditions:</span></div>
  <div class="col-md-8">{{ listing?.storage_cond }}</div>
</div>
<div *ngIf="amu?.amu" class="row mb-2">
  <div class="col-md-4"><span>AMU:</span></div>
  <div class="col-md-8">{{ amu?.amu }}</div>
</div>
<div *ngIf="amu?.aac_per_pack" class="row mb-2">
  <div class="col-md-4"><span>Wholesaler AAC:</span></div>
  <div class="col-md-8">{{ amu?.aac_per_pack | usdCurrency}}</div>
</div>
